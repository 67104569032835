import { useEffect, useState } from 'react'

const easeOutQuart = (t) => 1 - Math.pow(1 - t, 4)

function useAnimatePane(animationDuration = 500, expandedPaneSize = '50%', shouldAnimate = true) {
    const [paneVisible, setPaneVisible] = useState(true)
    const [paneSize, setPaneSize] = useState(expandedPaneSize)

    useEffect(() => {
        if (!shouldAnimate) {
            setPaneSize(paneVisible ? expandedPaneSize : '0%')
            return
        }

        const targetSize = paneVisible ? expandedPaneSize : '0%'
        let startTime
        let animationFrame

        const animateResize = (timestamp) => {
            if (!startTime) startTime = timestamp
            const elapsedTime = timestamp - startTime
            const progress = Math.min(1, elapsedTime / animationDuration)

            const easingProgress = easeOutQuart(progress)

            const currentSize = parseFloat(paneSize)
            const targetSizeValue = parseFloat(targetSize)
            const newSize = currentSize + (targetSizeValue - currentSize) * easingProgress

            setPaneSize(newSize + '%')

            if (progress < 1) {
                animationFrame = requestAnimationFrame(animateResize)
            } else {
                setPaneSize(targetSize)
            }
        }

        animationFrame = requestAnimationFrame(animateResize)

        return () => cancelAnimationFrame(animationFrame)
    }, [paneVisible]) // eslint-disable-line react-hooks/exhaustive-deps

    return { setPaneVisible, paneSize, paneVisible }
}

export default useAnimatePane



