import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Menu, MenuItem, IconButton, Tooltip, Snackbar, Box, ListItemIcon, ListItemText } from '@mui/material'
import Alert from '@mui/material/Alert'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import { Icon as Iconify } from '@iconify/react'
import InputModal from 'components/common/InputModal'
import * as yup from 'yup'
import { userPrefsAtom, currentWellSelector, currentWellAtom } from 'atoms'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import StyledBadge from './StyledBadge'
import useChat from 'components/common/hooks/useChat'
import ChatControl from './ChatControl'
import useUnits from 'components/common/hooks/useUnits'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useObjectsOfInterest from 'components/common/hooks/useObjectsOfInterest'
import useUserDatabaseAccess from 'components/common/hooks/useUserDatabaseAccess'
import { appColors } from 'utils'
import ChatBotControl from './ChatBotControl'

const NavBarUserProfile = ({ isAuthenticated, logout }) => {
  const _isMounted = useRef(false)
  const [showObjectsOfInterestModal, setShowObjectsOfInterestModal] = useState(false)
  const [showUnitsModal, setUnitsModal] = useState(false)
  const [showPasswordModal, setPasswordModal] = useState(false)
  const [userPrefs, setUserPrefs] = useRecoilState(userPrefsAtom)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { user, authType, saveDatabaseOrg } = useInnovaAuth()
  const [{ unreadMessages }] = useChat()
  const [chatToggle, setChatToggle] = useState(false)
  const { unitListOrg } = useUnits() // eslint-disable-line no-unused-vars
  const navigate = useNavigate()
  const { getUserDatabaseAccessList, hasMultiDbAccess, getCurrentDatabaseOrg, setAccessDatabase } =
    useUserDatabaseAccess(false)

  const setCurrentWell = useSetRecoilState(currentWellSelector)
  const { dropDowns, objectsOfInterest, getDropDowns, updateUserObjectsOfInterest } = useObjectsOfInterest()
  const currentWell = useRecoilValue(currentWellAtom)

  const [chatBotToggle, setChatBotToggle] = useState(false)


  const updateUnits = useInnovaAxios({
    url: '/user/storePrefs',
  })

  const updatePwd = useInnovaAxios({
    url: '/admin/resetUserPwd',
  })

  const fetchUserPrefs = useInnovaAxios({
    url: '/user/getPrefs',
  })

  useEffect(() => {
    _isMounted.current = true

     if (hasMultiDbAccess()){
       const dbList = getUserDatabaseAccessList()
        if (dbList.includes(currentWell?.organization)){
         saveDatabaseOrg(currentWell?.organization ? currentWell.organization : '')
        }
     }
    getDropDowns()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const openChat = (event) => {
    event.stopPropagation()
    setChatToggle(true)
  }

  //const openChatBot = (event) => {
   // event.stopPropagation()
    //setChatBotToggle(true)
  //}

  const onClickLogout = () => {
    logout()
    navigate('/')
  }

  const onClickUserProfile = () => {
    navigate('/user-profile')
  }

  const getPickListObjArray = (objArray) => {
    if (!Array.isArray(objArray)) return []

    let newObjArray = []
    objArray.forEach((item) => {
      newObjArray.push({
        id: item.value,
        desc: item.label,
      })
    })
    return newObjArray
  }

  const getPickListValArray = (objArray) => {
    if (!Array.isArray(objArray)) return []

    let newObjArray = []
    objArray.forEach((item) => {
      newObjArray.push({
        label: item,
        desc: item,
      })
    })
    return newObjArray
  }

  const ObjectsOfInterestModal = () => {
    let useInputData = false
    if (
      objectsOfInterest &&
      objectsOfInterest.hasOwnProperty('rigs') &&
      objectsOfInterest.hasOwnProperty('operators') &&
      objectsOfInterest.hasOwnProperty('districts')
    ) {
      useInputData = true
    }

    let formData = [
      {
        tag: 'rigs',
        value: useInputData ? getPickListValArray(objectsOfInterest?.rigs) : '',
        inputType: 'picklist',
        pickListValues: getPickListObjArray(dropDowns?.rigs),
        text: 'Rigs',
      },
      {
        tag: 'operators',
        value: useInputData ? getPickListValArray(objectsOfInterest?.operators) : [],
        inputType: 'picklist',
        pickListValues: getPickListObjArray(dropDowns?.operators),
        text: 'Operators',
      },
      {
        tag: 'districts',
        value: useInputData ? getPickListValArray(objectsOfInterest?.districts) : [],
        inputType: Array.isArray(dropDowns?.districts) && dropDowns?.districts.length > 0 ? 'picklist' : '-',
        pickListValues: getPickListObjArray(dropDowns?.districts),
        text: 'Districts',
      },
    ]

    return (
      <InputModal
        open={showObjectsOfInterestModal}
        onClose={() => setShowObjectsOfInterestModal(false)}
        title={'Following'}
        formData={formData}
        submitForm={handleChangeObjectsOfInterest}
        cancelFunction={() => setShowObjectsOfInterestModal(false)}
        validationSchema={yup.object({})}
      />
    )
  }

  const ChangeUnitsModal = () => {
    const formValidationSchema = yup.object({
      unitPrefs: yup.string().required('units required'),
    })

    let unitPrefValue = 0
    for (let i = 0; i < unitListOrg.length; i++) {
      if (unitListOrg[i].label === userPrefs?.unitPrefs) {
        unitPrefValue = unitListOrg[i].value
        break
      }
    }

    let formData = [
      {
        tag: 'unitPrefs',
        value: userPrefs ? unitPrefValue : '',
        text: 'Units',
        inputType: 'dropdown',
        dropDownValues: unitListOrg,
        useLabel: true,
      },
    ]

    return (
      <InputModal
        open={showUnitsModal}
        onClose={() => setUnitsModal(false)}
        title={'Change units'}
        formData={formData}
        submitForm={handleChangeUnits}
        cancelFunction={() => setUnitsModal(false)}
        validationSchema={formValidationSchema}
      />
    )
  }

  const ChangePasswordModal = () => {
    const formValidationSchema = yup.object({
      password: yup
        .string()
        .required('Password is required')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&-]{8,}$/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
        ),
      passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    })

    let formData = [
      {
        tag: 'authUserName',
        value: user?.name,
        inputType: '-',
      },
      {
        tag: 'password',
        value: '',
        text: 'New Password',
        inputType: 'text',
      },
      {
        tag: 'passwordConfirmation',
        value: '',
        text: 'Confirm Password',
        inputType: 'text',
      },
    ]

    return (
      <InputModal
        open={showPasswordModal}
        onClose={() => {
          setPasswordModal(false)
        }}
        title={'Change Password'}
        formData={formData}
        submitForm={handleChangePassword}
        cancelFunction={() => {
          setPasswordModal(false)
        }}
        validationSchema={formValidationSchema}
      />
    )
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleChangeObjectsOfInterest = async (data) => {
    setShowObjectsOfInterestModal(false)
    if (!data) return false

    let res = await updateUserObjectsOfInterest({ ...data })
    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Failed to update objects of interest',
      })

      return
    }

    setStatus({ show: true, severity: 'success', message: 'Objects of interest updated' })
    window.location.reload()
  }

  const handleChangeUnits = async (data) => {
    setUnitsModal(false)
    if (!data) return

    for (let i = 0; i < unitListOrg.length; i++) {
      if (unitListOrg[i].value === data.unitPrefs) {
        data.unitPrefs = unitListOrg[i].label
        break
      }
    }

    let res = await updateUnits({ ...data })
    if (!_isMounted.current) return

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Failed to update units',
      })

      return
    }

    setStatus({ show: true, severity: 'success', message: 'Units updated' })

    res = await fetchUserPrefs()
    if (!_isMounted.current) return
    if (!res?.data) return

    setUserPrefs(res.data)
    window.location.reload() //refresh the entire page to response to updated units across global data
  }

  const handleChangePassword = async (data) => {
    setPasswordModal(false)
    if (!data) return

    let res = await updatePwd({
      ...data,
      sendEmail: true,
      incPwdInEmail: false,
      resetRequiredOnNextLogin: false,
    })

    if (!_isMounted.current) return
    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Failed to update password',
      })
      return
    }

    setStatus({ show: true, severity: 'success', message: 'Password updated' })
  }

  const NavBarIcon = ({
    title,
    icon,
    color = '#429ceb',
    onClick,
    badgeContent = null,
    menuItems = null,
    visible = true,
  }) => {
    const ref = useRef(null)
    const [showMenu, setShowMenu] = useState(false)

    const onClose = () => {
      setShowMenu(false)
    }

    if (!visible) return null

    return (
      <Box
        ref={ref}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        onClick={() => {
          if (Array.isArray(menuItems) && menuItems.length > 0) setShowMenu(!showMenu)
        }}>
        <Tooltip
          title={title}
          placement='bottom'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <IconButton
            size='large'
            onClick={(event) => {
              if (Array.isArray(menuItems) && menuItems.length > 0) return
              if (onClick) onClick(event)
            }}>
            <Iconify color={color} icon={icon} fontSize={28} />
            <StyledBadge
              badgecolor={'#0F0'}
              badgeContent={badgeContent}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            />
          </IconButton>
        </Tooltip>
        {showMenu ? (
          Array.isArray(menuItems) && menuItems.length > 0 ? (
            <Menu
              anchorEl={ref.current}
              open={showMenu}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              onClose={onClose}>
              {menuItems.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    if (item.onClick) {
                      item.onClick()
                      onClose()
                    }
                  }}>
                  <ListItemIcon>
                    <Iconify icon={item.icon} fontSize={16} color={item.color ? item.color : 'black'} />
                  </ListItemIcon>
                  <ListItemText sx={{ color: item.color !== 'black' ? appColors.itemTextColor : '#202020' }}>
                    {item.label}
                  </ListItemText>
                </MenuItem>
              ))}
            </Menu>
          ) : null
        ) : null}
      </Box>
    )
  }

  const dbAccessList = useMemo(() => {
    let databases = getUserDatabaseAccessList()
    if (!Array.isArray(databases)) return []
    let databaseOrg = getCurrentDatabaseOrg()
    return databases.map((item) => {
      return {
        onClick: () => {
          if (item === databaseOrg) return
          setAccessDatabase(item)
          setCurrentWell({
            wellName: '',
            organization: item,
          })
          navigate('/well-list')
          window.location.reload()
        },
        label: item,
        icon: 'material-symbols-light:database',
        color: item === databaseOrg ? appColors.itemTextColor : 'black',
      }
    })
  }, [navigate, getUserDatabaseAccessList, getCurrentDatabaseOrg, setAccessDatabase, setCurrentWell])

  return isAuthenticated ? (
    <React.Fragment>
      {showUnitsModal ? <ChangeUnitsModal /> : null}
      {showObjectsOfInterestModal ? <ObjectsOfInterestModal /> : null}
      {showPasswordModal ? <ChangePasswordModal /> : null}
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <NavBarIcon
          title={'Following'}
          color={'gold'}
          icon={'material-symbols:star'}
          onClick={() => setShowObjectsOfInterestModal(!showObjectsOfInterestModal)}
        />
        <NavBarIcon
          title={'Help'}
          icon={'material-symbols:help'}
          onClick={() =>
            window.open(
              'https://docs.innova-drilling.com/introduction/video-guides/how-to-videos/web-portal',
              '_blank',
              'noreferrer',
            )
          }
        />
        <NavBarIcon
          title={'Chat'}
          icon={'material-symbols:chat'}
          onClick={(event) => openChat(event)}
          badgeContent={unreadMessages ? unreadMessages.toString() : null}
        />
        {/* <NavBarIcon
          title={'Chat Bot'}
          icon={'hugeicons:chat-bot'}
          onClick={(event) => openChatBot(event)}
        /> */}
        <NavBarIcon
          title={'Organization'}
          icon={'material-symbols-light:database'}
          menuItems={dbAccessList}
          visible={hasMultiDbAccess()}
        />
        <NavBarIcon
          title={'User Profile'}
          icon={'healthicons:ui-user-profile'}
          menuItems={(() => {
            const items = [
              { onClick: () => onClickUserProfile(), label: 'Profile', icon: 'healthicons:ui-user-profile' },
              { onClick: () => setUnitsModal(!showUnitsModal), label: 'Change Units', icon: 'fa:balance-scale' },
            ]
            if (authType === 'Innova') {
              items.push({
                onClick: () => setPasswordModal(!showPasswordModal),
                label: 'Change Password',
                icon: 'material-symbols:password',
              })
            }
            items.push({ onClick: () => onClickLogout(), label: 'Logout', icon: 'material-symbols:logout' })
            return items
          })()}
        />
      </Box>
      <ChatControl open={chatToggle} onClose={() => setChatToggle(false)} />
      <ChatBotControl open={chatBotToggle} onClose={() => setChatBotToggle(false)} />
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  ) : null
}

export default NavBarUserProfile
