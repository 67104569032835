import { Icon as Iconify } from '@iconify/react'

export const BUTTON_SIZE = 32

export const ContextMenuItemIcon = ({ icon, color, isImage }) => {
  if (isImage) {
    return (
      <img
        alt={'Icon'}
        src={icon}
        style={{
          width: `15px`,
          height: `15px`,
        }}
      />
    )
  }

  return <Iconify icon={icon} width={'15px'} height={'15px'} color={color || '#a9abac'} />
}
