import cloneDeep from 'lodash/cloneDeep'
import {getItemFromLS} from 'utils/localStorage'

const weightUnits = getItemFromLS('weightUnits', 'weightUnits')

const EngCaseParamDefs = [
  //----------------------------------------Case Details-----------------------------------------------
  {
    id: 0,
    group: 'Case Details',
    objTag: '',
    tag: 'recordId',
    value: '',
    label: 'Selected Case',
    type: 'dropDown',
    dropDownField: 'engCases',
    units: 'BUTTON_GROUP',
    singleSelect: true,
  },
  {
    id: 0,
    group: 'Case Details',
    objTag: '',
    tag: 'active',
    value: false,
    label: 'Active',
    type: 'boolean',
    dropDownField: '',
    units: '',
  },
  {
    id: 0,
    group: 'Case Details',
    objTag: '',
    tag: 'bhaNum',
    value: '',
    label: 'BHA',
    type: 'dropDown',
    dropDownField: 'drillString',
    units: 'BUTTON_GROUP',
    singleSelect: true,
  },
  {
    id: 0,
    group: 'Case Details',
    objTag: '',
    tag: 'geoId',
    value: '',
    label: 'Wellbore',
    type: 'dropDown',
    dropDownField: 'wellbores',
    units: 'BUTTON_GROUP',
    singleSelect: true,
  },
  {
    id: 0,
    group: 'Case Details',
    objTag: '',
    tag: 'bitDepth',
    value: 0,
    label: 'Calc Depth',
    type: 'numeric',
    dropDownField: '',
    units: 'DEPTH',
    defValue: 0,
    minVal: 0,
    maxValue: 100000,
    precision: 3,
  },
  //----------------------------------------Drilling Parameters-----------------------------------------------
  {
    id: 0,
    group: 'Drilling Parameters',
    objTag: '',
    tag: 'rop',
    value: 0,
    label: 'ROP',
    type: 'numeric',
    dropDownField: '',
    units: 'ROP',
    defValue: 0,
    minVal: 0,
    maxValue: 100000,
    precision: 2,
  },
  {
    id: 0,
    group: 'Drilling Parameters',
    objTag: '',
    tag: 'rpm',
    value: 0,
    label: 'RPM',
    type: 'numeric',
    dropDownField: '',
    units: '',
    defValue: 0,
    minVal: 0,
    maxValue: 1000,
    precision: 0,
  },
  {
    id: 0,
    group: 'Drilling Parameters',
    objTag: 'tndParams',
    tag: 'wobRot',
    value: 0,
    label: 'WOB',
    type: 'numeric',
    dropDownField: '',
    units: 'WEIGHT',
    defValue: 0,
    minVal: -100000,
    maxValue: 1000000,
    precision: 2,
  },
  {
    id: 0,
    group: 'Drilling Parameters',
    objTag: 'tndParams',
    tag: 'wobSlide',
    value: 0,
    label: 'WOB - Slide',
    type: 'numeric',
    dropDownField: '',
    units: 'WEIGHT',
    defValue: 0,
    minVal: -100000,
    maxValue: 1000000,
    precision: 2,
  },
  //----------------------------------------Mud-----------------------------------------------
  {
    id: 0,
    group: 'Mud',
    objTag: 'fluids',
    tag: 'fluidDesc',
    value: '',
    label: 'Description',
    type: 'text',
    dropDownField: '',
    units: '',
  },
  {
    id: 0,
    group: 'Mud',
    objTag: 'fluids',
    tag: 'mudWeight',
    value: 8.4,
    label: 'Mud Weight',
    type: 'numeric',
    dropDownField: '',
    units: 'MUD',
    defValue: 8.4,
    minVal: 0,
    maxValue: 1000000,
    precision: 3,
  },
  {
    id: 0,
    group: 'Mud',
    objTag: 'fluids',
    tag: 'pv',
    value: 15,
    label: 'PV',
    type: 'numeric',
    dropDownField: '',
    units: '',
    defValue: 15,
    minVal: 0,
    maxValue: 1000000,
    precision: 0,
  },
  {
    id: 0,
    group: 'Mud',
    objTag: 'fluids',
    tag: 'yp',
    value: 15,
    label: 'YP',
    type: 'numeric',
    dropDownField: '',
    units: '',
    defValue: 15,
    minVal: 0,
    maxValue: 1000000,
    precision: 0,
  },
  {
    id: 0,
    group: 'Mud',
    objTag: 'fluids',
    tag: 'sixhun',
    value: 0,
    label: '600',
    type: 'numeric',
    dropDownField: '',
    units: '',
    defValue: 0,
    isVisibleTest: (defs) => {
      if (!Array.isArray(defs)) return false
      let index = defs.findIndex((param) => param.tag === 'hydModel')
      if (index < 0) return false
      if (defs[index].value === 'Bingham Plastic') return false
      return true
    },
    minVal: 0,
    maxValue: 1000000,
    precision: 0,
  },
  {
    id: 0,
    group: 'Mud',
    objTag: 'fluids',
    tag: 'threehun',
    value: 0,
    label: '300',
    type: 'numeric',
    dropDownField: '',
    units: '',
    defValue: 0,
    isVisibleTest: (defs) => {
      if (!Array.isArray(defs)) return false
      let index = defs.findIndex((param) => param.tag === 'hydModel')
      if (index < 0) return false
      if (defs[index].value === 'Bingham Plastic') return false
      return true
    },
    minVal: 0,
    maxValue: 1000000,
    precision: 0,
  },
  {
    id: 0,
    group: 'Mud',
    objTag: 'fluids',
    tag: 'twohun',
    value: 0,
    label: '200',
    type: 'numeric',
    dropDownField: '',
    units: '',
    defValue: 0,
    isVisibleTest: (defs) => {
      if (!Array.isArray(defs)) return false
      let index = defs.findIndex((param) => param.tag === 'hydModel')
      if (index < 0) return false
      if (defs[index].value === 'Bingham Plastic') return false
      return true
    },
    minVal: 0,
    maxValue: 1000000,
    precision: 0,
  },
  {
    id: 0,
    group: 'Mud',
    objTag: 'fluids',
    tag: 'onehun',
    value: 0,
    label: '100',
    type: 'numeric',
    dropDownField: '',
    units: '',
    defValue: 0,
    isVisibleTest: (defs) => {
      if (!Array.isArray(defs)) return false
      let index = defs.findIndex((param) => param.tag === 'hydModel')
      if (index < 0) return false
      if (defs[index].value === 'Bingham Plastic') return false
      return true
    },
    minVal: 0,
    maxValue: 1000000,
    precision: 0,
  },
  {
    id: 0,
    group: 'Mud',
    objTag: 'fluids',
    tag: 'dialSix',
    value: 0,
    label: '6',
    type: 'numeric',
    dropDownField: '',
    units: '',
    defValue: 0,
    isVisibleTest: (defs) => {
      if (!Array.isArray(defs)) return false
      let index = defs.findIndex((param) => param.tag === 'hydModel')
      if (index < 0) return false
      if (defs[index].value === 'Bingham Plastic') return false
      return true
    },
    minVal: 0,
    maxValue: 1000000,
    precision: 0,
  },
  {
    id: 0,
    group: 'Mud',
    objTag: 'fluids',
    tag: 'dialThree',
    value: 0,
    label: '3',
    type: 'numeric',
    dropDownField: '',
    units: '',
    defValue: 0,
    isVisibleTest: (defs) => {
      if (!Array.isArray(defs)) return false
      let index = defs.findIndex((param) => param.tag === 'hydModel')
      if (index < 0) return false
      if (defs[index].value === 'Bingham Plastic') return false
      return true
    },
    minVal: 0,
    maxValue: 1000000,
    precision: 0,
  },
  //----------------------------------------Hydraulics-----------------------------------------------
  {
    id: 0,
    group: 'Hydraulics',
    objTag: 'hydraulicParams',
    tag: 'hydModel',
    value: 'Bingham Plastic',
    label: 'Model',
    type: 'dropDownSimple',
    dropDownField: 'hydraulicModels',
    units: '',
  },
  {
    id: 0,
    group: 'Hydraulics',
    objTag: 'hydraulicParams',
    tag: 'surfacePloss',
    value: 0,
    label: 'Surface P. Loss',
    type: 'numeric',
    dropDownField: '',
    units: 'PRESSURE',
    defValue: 0,
    minVal: 0,
    maxValue: 1000000,
    precision: 2,
  },
  {
    id: 0,
    group: 'Hydraulics',
    objTag: 'hydraulicParams',
    tag: 'surgeAndSwabType',
    value: 'Open Ended',
    label: 'Surge & Swab Type',
    type: 'dropDownSimple',
    dropDownField: 'surgeAndSwab',
    units: '',
  },
  {
    id: 0,
    group: 'Hydraulics',
    objTag: 'hydraulicParams',
    tag: 'ssRef',
    value: 'Bit',
    label: 'Surge & Swab Ref',
    type: 'dropDownSimple',
    dropDownField: 'surgeAndSwabRef',
    units: '',
  },
  {
    id: 0,
    group: 'Hydraulics',
    objTag: 'hydraulicParams',
    tag: 'ssRefDepth',
    value: 0,
    label: 'Reference Depth',
    type: 'numeric',
    dropDownField: '',
    units: 'DEPTH',
    defValue: 0,
    isVisibleTest: (defs) => {
      if (!Array.isArray(defs)) return false
      let index = defs.findIndex((param) => param.tag === 'ssRef')
      if (index < 0) return false
      if (defs[index].value !== 'User Defined') return false
      return true
    },
  },
  //----------------------------------------Surge & Swab-----------------------------------------------
  {
    id: 0,
    group: 'Advanced - Surge & Swab',
    objTag: 'hydraulicParams',
    tag: 'contCirc',
    value: false,
    label: 'Continuous Circ',
    type: 'boolean',
    dropDownField: '',
    units: '',
  },
  {
    id: 0,
    group: 'Advanced - Surge & Swab',
    objTag: 'hydraulicParams',
    tag: 'contTrip',
    value: false,
    label: 'Continuous Tripping',
    type: 'boolean',
    dropDownField: '',
    units: '',
  },
  {
    id: 0,
    group: 'Advanced - Surge & Swab',
    objTag: 'hydraulicParams',
    tag: 'incGelStrength',
    value: false,
    label: 'Inc Gels',
    type: 'boolean',
    dropDownField: '',
    units: '',
  },
  {
    id: 0,
    group: 'Advanced - Surge & Swab',
    objTag: 'hydraulicParams',
    tag: 'incPipeAccel',
    value: false,
    label: 'Inc Pipe Accel.',
    type: 'boolean',
    dropDownField: '',
    units: '',
  },
  {
    id: 0,
    group: 'Advanced - Surge & Swab',
    objTag: 'hydraulicParams',
    tag: 'limitAccel',
    value: true,
    label: 'Limit Accel.',
    type: 'boolean',
    dropDownField: '',
    units: '',
  },
  {
    id: 0,
    group: 'Advanced - Surge & Swab',
    objTag: 'hydraulicParams',
    tag: 'useBitTfa',
    value: false,
    label: 'Use bit TFA',
    type: 'boolean',
    dropDownField: '',
    units: '',
  },
  {
    id: 0,
    group: 'Advanced - Surge & Swab',
    objTag: '',
    tag: 'ssMinEmw',
    value: 0,
    label: 'Min EMW',
    type: 'numeric',
    dropDownField: '',
    units: 'MUD',
    defValue: 8.3,
    minVal: 0,
    maxValue: 1000000,
    precision: 2,
  },
  {
    id: 0,
    group: 'Advanced - Surge & Swab',
    objTag: '',
    tag: 'ssMaxEmw',
    value: 0,
    label: 'Max EMW',
    type: 'numeric',
    dropDownField: '',
    units: 'MUD',
    defValue: 8.3,
    minVal: 0,
    maxValue: 1000000,
    precision: 2,
  },
  //----------------------------------------Advanced Hydraulics-----------------------------------------------
  {
    id: 0,
    group: 'Advanced - Hydraulics',
    objTag: '',
    tag: 'incStabCent',
    value: false,
    label: 'Inc Stabs',
    type: 'boolean',
    dropDownField: '',
    units: '',
  },
  {
    id: 0,
    group: 'Advanced - Hydraulics',
    objTag: 'hydraulicParams',
    tag: 'ecdAdj',
    value: false,
    label: 'ECD Adjustment',
    type: 'numeric',
    dropDownField: '',
    units: 'MUD',
    defValue: 0,
    minVal: -100000,
    maxValue: 1000000,
    precision: 2,
  },
  {
    id: 0,
    group: 'Advanced - Hydraulics',
    objTag: 'hydraulicParams',
    tag: 'sppSafety',
    value: 0,
    label: 'Spp Safety Factor',
    type: 'numeric',
    dropDownField: '',
    units: '%',
    defValue: 0,
    minVal: 0,
    maxValue: 50,
    precision: 2,
  },
  {
    id: 0,
    group: 'Advanced - Hydraulics',
    objTag: 'hydraulicParams',
    tag: 'standLength',
    value: 100,
    label: 'Interval',
    type: 'numeric',
    dropDownField: '',
    units: 'DEPTH',
    defValue: 100,
    minVal: 10,
    maxValue: 1000,
    precision: 2,
  },
  //--------------------------------------------T&D--------------------------------------------------
  {
    id: 0,
    group: 'Torque & Drag',
    objTag: 'tndParams',
    tag: 'autoBitTq',
    value: true,
    label: 'Auto Bit Tq',
    type: 'boolean',
    dropDownValues: '',
    units: '',
    defValue: true,
  },
  {
    id: 0,
    group: 'Torque & Drag',
    objTag: 'tndParams',
    tag: 'bitTq',
    value: 5,
    label: 'Bit Tq',
    type: 'numeric',
    dropDownField: '',
    units: 'TORQUE',
    defValue: 5,
    isVisibleTest: (defs) => {
      if (!Array.isArray(defs)) return true
      let index = defs.findIndex((param) => param.tag === 'autoBitTq')
      if (index < 0) return true
      if (defs[index].value === 'true' || defs[index].value === true) return false
      return true
    },
    minVal: 0,
    maxValue: 1000000,
    precision: 2,
  },
  {
    id: 0,
    group: 'Torque & Drag',
    objTag: 'tndParams',
    tag: 'blockWtRot',
    value: 0,
    label: 'Block Weight',
    type: 'numeric',
    dropDownField: '',
    units: 'WEIGHT',
    defValue: 0,
    minVal: 0,
    maxValue: 1000000,
    precision: 2,
  },
  {
    id: 0,
    group: 'Torque & Drag',
    objTag: 'tndParams',
    tag: 'overpull',
    value: 0,
    label: 'Overpull',
    type: 'numeric',
    dropDownField: '',
    units: 'WEIGHT',
    defValue: 0,
    minVal: 0,
    maxValue: 10000000,
    precision: 2,
  },
  {
    id: 0,
    group: 'Torque & Drag',
    objTag: 'tndParams',
    tag: 'reamingSpd',
    value: 0,
    label: 'Reaming Speed',
    type: 'numeric',
    dropDownField: '',
    units: 'REAMING',
    defValue: 0,
    minVal: 0,
    maxValue: 20000,
    precision: 2,
  },
  {
    id: 0,
    group: 'Torque & Drag',
    objTag: 'tndParams',
    tag: 'flowRate',
    value: 0,
    label: 'Flow Rate',
    type: 'numeric',
    dropDownField: '',
    units: 'FLOWRATE',
    defValue: 0,
    minVal: 0,
    maxValue: 1000000,
    precision: 2,
  },
  {
    id: 0,
    group: 'Torque & Drag',
    objTag: 'tndParams',
    tag: 'tripSpeed',
    value: 0,
    label: 'Trip Speed',
    type: 'numeric',
    dropDownField: '',
    units: 'TRIPSPEED',
    defValue: 0,
    minVal: 0,
    maxValue: 1000000,
    precision: 2,
  },
  //--------------------------------------------T&D - Advanced---------------------------------------
  {
    id: 0,
    group: 'Advanced - Torque & Drag',
    objTag: 'tndParams',
    tag: 'csgWearFac',
    value: 1,
    label: 'Casing Wear Factor',
    type: 'numeric',
    dropDownField: '',
    units: '',
    defValue: 1,
    minVal: 0.1,
    maxValue: 500,
    precision: 3,
  },
  {
    id: 0,
    group: 'Advanced - Torque & Drag',
    objTag: 'tndParams',
    tag: 'incViscDrag',
    value: false,
    label: 'Viscous Drag',
    type: 'boolean',
    dropDownField: '',
    units: '',
  },
  {
    id: 0,
    group: 'Advanced - Torque & Drag',
    objTag: 'tndParams',
    tag: 'sfUnits',
    value: 100,
    label: `SF Units ${weightUnits}/`,
    type: 'numeric',
    dropDownField: '',
    units: 'DEPTH',
    defValue: 100,
    precision: 2,
  },
  //----------------------------------------Tortuosity-----------------------------------------------
  {
    id: 0,
    group: 'Tortuosity',
    objTag: '',
    tag: 'tortApply',
    value: false,
    label: 'Apply Tort.',
    type: 'boolean',
    dropDownField: '',
    units: '',
  },
  {
    id: 0,
    group: 'Tortuosity',
    objTag: '',
    tag: 'tortDepthrange',
    value: false,
    label: 'Depth Range',
    type: 'boolean',
    dropDownField: '',
    units: '',
    isVisibleTest: (defs) => {
      if (!Array.isArray(defs)) return false
      let index = defs.findIndex((param) => param.tag === 'tortApply')
      if (index < 0) return false
      if (defs[index].value !== true) return false
      return true
    },
  },
  {
    id: 0,
    group: 'Tortuosity',
    objTag: '',
    tag: 'tortStartdepth',
    value: 0,
    label: 'Start Depth',
    type: 'numeric',
    dropDownField: '',
    units: 'DEPTH',
    defValue: 0,
    isVisibleTest: (defs) => {
      if (!Array.isArray(defs)) return false
      let index = defs.findIndex((param) => param.tag === 'tortDepthrange')
      if (index < 0) return false
      if (defs[index].value === false) return false
      return true
    },
    minVal: 0,
    maxValue: 1000000,
    precision: 2,
  },
  {
    id: 0,
    group: 'Tortuosity',
    objTag: '',
    tag: 'tortEnddepth',
    value: 100000,
    label: 'End Depth',
    type: 'numeric',
    dropDownField: '',
    units: 'DEPTH',
    defValue: 100000,
    isVisibleTest: (defs) => {
      if (!Array.isArray(defs)) return false
      let index = defs.findIndex((param) => param.tag === 'tortDepthrange')
      if (index < 0) return false
      if (defs[index].value === false) return false
      return true
    },
    minVal: 0,
    maxValue: 1000000,
    precision: 2,
  },
  {
    id: 0,
    group: 'Tortuosity',
    objTag: '',
    tag: 'tortMethod',
    value: 'Sinusoidal',
    label: 'Method',
    type: 'dropDownSimple',
    dropDownField: 'tortuosity',
    units: '',
    isVisibleTest: (defs) => {
      if (!Array.isArray(defs)) return false
      let index = defs.findIndex((param) => param.tag === 'tortApply')
      if (index < 0) return false
      if (defs[index].value !== true) return false
      return true
    },
  },
  {
    id: 0,
    group: 'Tortuosity',
    objTag: '',
    tag: 'tortPeriod',
    value: 100,
    label: 'Period',
    type: 'numeric',
    dropDownValues: '',
    units: 'DEPTH',
    defValue: 100,
    isVisibleTest: (defs) => {
      if (!Array.isArray(defs)) return false
      let index = defs.findIndex((param) => param.tag === 'tortMethod')
      if (index < 0) return false
      if (defs[index].value !== 'Sinusoidal') return false
      return true
    },
  },
  //-------------------------------------Well Control --------------------------------
  {
    id: 0,
    group: 'Well Control',
    objTag: 'wellControl',
    tag: 'fitLot',
    value: 13,
    label: 'LOT / FIT',
    type: 'numeric',
    dropDownField: '',
    units: 'MUD',
    defValue: 13,
    minVal: 0,
    maxValue: 1000000,
    precision: 2,
  },
  {
    id: 0,
    group: 'Well Control',
    objTag: 'wellControl',
    tag: 'gasGrad',
    value: 0.11,
    label: 'Gas Gradient',
    type: 'numeric',
    dropDownField: '',
    units: 'PRESS/FT',
    defValue: 0.11,
    minVal: 0.01,
    maxValue: 0.8,
    precision: 3,
  },
  {
    id: 0,
    group: 'Well Control',
    objTag: 'wellControl',
    tag: 'kickIntensity',
    value: 0.5,
    label: 'Kick Intensity',
    type: 'numeric',
    dropDownField: '',
    units: 'MUD',
    defValue: 0.5,
    minVal: 0.1,
    maxValue: 100,
    precision: 3,
  },
  {
    id: 0,
    group: 'Well Control',
    objTag: 'wellControl',
    tag: 'safetyMargin',
    value: 0,
    label: 'Safety Margin',
    type: 'numeric',
    dropDownField: '',
    units: 'PRESSURE',
    defValue: 0,
    minVal: 0,
    maxValue: 1000000,
    precision: 2,
  },
  {
    id: 0,
    group: 'Well Control',
    objTag: 'wellControl',
    tag: 'pitGain',
    value: 50,
    label: 'Pit Gain',
    type: 'numeric',
    dropDownField: '',
    units: 'VOLUME',
    defValue: 50,
    minVal: 0,
    maxValue: 1000000,
    precision: 2,
  },
  //-------------------------------------Centralizer Standoff--------------------------------
  {
    id: 0,
    group: 'Casing Standoff',
    objTag: '',
    tag: 'standOffReq',
    value: 85,
    label: 'Standoff Required',
    type: 'numeric',
    dropDownField: '',
    units: 'PERCENT',
    defValue: 85,
    minVal: 0,
    maxValue: 100,
    precision: 2,
  },
  {
    id: 0,
    group: 'Casing Standoff',
    objTag: '',
    tag: 'cementWt',
    value: 14,
    label: 'Cement Wt',
    type: 'numeric',
    dropDownField: '',
    units: 'MUD',
    defValue: 14,
    minVal: 0,
    maxValue: 1000,
    precision: 3,
  },
]

function addRowIdsToDefs(defs) {
  if (!defs) return
  if (!Array.isArray(defs)) return
  for (let i = 0; i < defs.length; i++) {
    defs[i].id = i
  }
}

function updateDefValues(defs, selectedEngCase) {
  if (!defs) return
  if (selectedEngCase === null || selectedEngCase === undefined) return
  if (!Array.isArray(defs)) return
  for (let i = 0; i < defs.length; i++) {
    if (!defs[i].hasOwnProperty('tag')) continue
    if (!defs[i].hasOwnProperty('objTag')) continue
    if (typeof defs[i].objTag !== 'string') continue
    if (typeof defs[i].tag !== 'string') continue
    if (defs[i].tag === '') continue

    let tag = defs[i].tag
    if (defs[i].objTag !== '') {
      if (selectedEngCase.hasOwnProperty(defs[i].objTag)) {
        const objTag = defs[i].objTag
        defs[i].value = selectedEngCase[objTag][tag]
      }
    }

    if (defs[i].objTag === '') {
      defs[i].value = selectedEngCase[tag]
    }
  }
}

export function getEngParamDefs(selectedEngCase) {
  if (selectedEngCase === null) {
    return [
      {
        id: 0,
        group: 'Case Details',
        objTag: '',
        tag: 'recordId',
        value: '',
        label: 'Selected Case',
        type: 'dropDown',
        dropDownField: 'engCases',
        units: 'BUTTON_GROUP',
        singleSelect: true,
      },
    ]
  }

  let defs = cloneDeep(EngCaseParamDefs)
  addRowIdsToDefs(defs)
  updateDefValues(defs, selectedEngCase)
  return defs
}

export function getGridData(curDefs) {
  let newGridData = []
  if (!Array.isArray(curDefs)) return newGridData

  for (let i = 0; i < curDefs.length; i++) {
    if (!curDefs[i].hasOwnProperty('isVisibleTest')) {
      newGridData.push(cloneDeep(curDefs[i]))
      continue
    }

    if (curDefs[i].isVisibleTest(curDefs)) {
      newGridData.push(cloneDeep(curDefs[i]))
    }
  }

  return newGridData
}

export function getId(curDefs, label) {
  if (typeof label !== 'string') return -1
  if (!Array.isArray(curDefs)) return -1

  for (let i = 0; i < curDefs.length; i++) {
    if (curDefs[i].label === label) return curDefs[i].id
  }

  return -1
}

export function getGroupIcon(group) {
  if (group === 'Case Details') return 'mdi:gear'
  if (group === 'Drilling Parameters') return 'material-symbols:view-list-outline'
  if (group === 'Mud') return 'material-symbols:oil-barrel-rounded'
  if (group === 'Hydraulics') return 'mdi:hydraulic-oil-level'
  if (group === 'Advanced - Hydraulics') return 'mdi:hydraulic-oil-level'
  if (group === 'Advanced - Surge & Swab') return 'mdi:hydraulic-oil-level'
  if (group === 'Torque & Drag') return 'fluent:pipeline-20-regular'
  if (group === 'Advanced - Torque & Drag') return 'fluent:pipeline-20-regular'
  if (group === 'Tortuosity') return 'ph:path'
  if (group === 'Well Control') return 'fa6-solid:oil-well'
  if (group === 'Casing Standoff') return 'icon-park-outline:horizontal-spacing-between-items'
  return 'fluent-mdl2:unknown-solid'
}
