import { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { Box } from '@mui/material'

const DrillStringRecommendationsFilterGrid = ({recommendationFilterData, setRecommendationFilters, isLoading}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const centerAlignCell = useMemo(() => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }, [])

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'header',
        colId: 'header',
        cellStyle: centerAlignCell,
        width: 100,
      },
      {
        headerName: 'Filter',
        field: 'filter',
        colId: 'filter',
        editable: true,
        width: 350,
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: (params) => {
          if (!params.data.values) return
          if (!Array.isArray(params.data.values)) return
          if (params.data.values.length === 0) return
            return{
                multiSelect: true,
                valueListMaxWidth: 350,
                values: params.data.values
            }
        },
      },
      {
        headerName: 'Check',
        field: 'checked',
        colId: 'checked',
        editable: true,
        width: 50,
        cellRenderer: 'agCheckboxCellRenderer',
      }
    ],
    [centerAlignCell],
  )

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      cellStyle: centerAlignCell,
    }
  }, [centerAlignCell])


  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered =() => {
    if (gridApi.current === null) return
  }

  const getRowId = (data) => {
    return getStringId(data.data.header)
  }

  const gridOptions ={
    onCellEditingStopped: (event) =>{
        let rowData = []
        gridApi.current.forEachNode((node) => {
            rowData.push(node.data)
        })

        setRecommendationFilters(rowData)
    }
  }

  const getContextMenuItems = (params) => {
    let menuItem = [
      {
        name: 'Reset Filters',
        action: () => {
          let rowData = []
          gridApi.current.forEachNode((node) => {
            node.setDataValue('checked', false)
            node.setDataValue('filter', null)
            rowData.push(node.data)
          })
          setRecommendationFilters(rowData)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      }
    ]

    return menuItem
  }

  return (
    <Box className={getAgGridTheme()} sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
         defaultColDef={defaultColDef}
         gridOptions={gridOptions}
         onGridReady={onGridReady}
         rowData={recommendationFilterData}
         headerHeight={0}
         onFirstDataRendered={onFirstDataRendered}
         getRowId={getRowId}
         loading={isLoading}
         getContextMenuItems={getContextMenuItems}
      /> 
    </Box>
  )
}

export default DrillStringRecommendationsFilterGrid
