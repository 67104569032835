import React from 'react'
import {
  convertUserToPixels,
  createRotateStyle,
} from 'components/WellPages/WallplotComposer/Viewport/cssTransformUtils'

// the outer container is a div with the class 'wp-element', so that the Moveable and Selecto libraries can find and maniuplate the elements

const ElementContainer = ({
  children,
  uid,
  elementRef,
  units,
  scale,
  style,
  zIndex,
  top,
  left,
  width,
  height,
  rotateDeg,
}) => {
  // stateless container style update, if the child component supports the callback
  const handleStyleUpdate = (newStyle) => {
    if (newStyle.backgroundColor) elementRef.current.style.backgroundColor = newStyle.backgroundColor
    if (newStyle.borderStyle) elementRef.current.style.borderStyle = newStyle.borderStyle
    if (newStyle.borderColor) elementRef.current.style.borderColor = newStyle.borderColor
    if (newStyle.borderWidth) elementRef.current.style.borderWidth = newStyle.borderWidth
    if (newStyle.opacity) elementRef.current.style.opacity = newStyle.opacity
  }

  return (
    <div
      key={uid}
      className='wp-element' // important
      ref={elementRef}
      onContextMenu={(e) => {
        e.preventDefault()
        // e.stopPropagation() // only enable if don't want parent context menu
      }}
      // important this is style in a <div> and not sx in a <Box>
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: `${convertUserToPixels(top, units, scale)}px`,
        left: `${convertUserToPixels(left, units, scale)}px`,
        maxWidth: 'auto',
        maxHeight: 'auto',
        minWidth: 'auto',
        minHeight: 'auto',
        width: `${convertUserToPixels(width, units, scale)}px`,
        height: `${convertUserToPixels(height, units, scale)}px`,
        position: 'absolute',
        fontWeight: 'bold',
        borderStyle: style.borderStyle,
        borderColor: style.borderColor,
        borderWidth: style.borderWidth,
        opacity: style.opacity,
        color: '#333',
        backgroundColor: style.backgroundColor,
        transform: createRotateStyle(rotateDeg),
        zIndex: zIndex,
        overflow: 'hidden',
        cursor: style.cursor ? style.cursor : 'move',
      }}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { updateContainer: handleStyleUpdate, containerRef: elementRef }) // cloneElement merges the props of the child with the new props
        }
      })}
    </div>
  )
}

export default ElementContainer
