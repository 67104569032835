import React, { useEffect, useRef } from 'react'

import { Line } from 'react-chartjs-2'
import { CircularProgress, Box } from '@mui/material'
import { appColors } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { DRAWERWIDE, DRAWERSLIM } from 'components/WellPages/EngineeringDashboard/EngineeringToolBar'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'

const EngineeringChart = ({
  showCasing,
  showLegend,
  chartData,
  showBhaElements,
  loading,
  casingAnnotations = null,
  bhaElements = null,
  title = '',
  xAxisLabel = 'X Axis',
  units = '',
  xBeginAtZero = true,
  slideSheetData = [],
  witsData = [],
  isToolbarExpanded,
  printing = false,
  showGridData = false,
  bhaComponents = [],
}) => {
  const _isMounted = useRef(false)
  const { getUnitsText } = useUnits()
  const { theme, getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const getAnnotations = () => {
    if (showCasing && showBhaElements) {
      const annotations = {}
      for (const key in casingAnnotations) {
        annotations[key] = casingAnnotations[key]
      }
      for (const key in bhaElements) {
        annotations[key] = bhaElements[key]
      }
      return annotations
    }

    if (showCasing) {
      return casingAnnotations
    }

    if (showBhaElements) {
      return bhaElements
    }
  }

  const findMinX = () => {
    if (!chartData) return 0
    if (!chartData.hasOwnProperty('datasets')) return 0
    if (!Array.isArray(chartData.datasets)) return 0
    if (chartData.datasets.length === 0) return 0

    let minX = 999999
    let maxX = -999999
    for (let i = 0; i < chartData.datasets.length; i++) {
      if (!chartData.datasets[i].data) continue
      if (!Array.isArray(chartData.datasets[i].data)) continue
      if (chartData.datasets[i].data.length === 0) continue

      for (let j = 0; j < chartData.datasets[i].data.length; j++) {
        if (chartData.datasets[i].data[j].x < minX) {
          minX = chartData.datasets[i].data[j].x
        }
        if (chartData.datasets[i].data[j].x > maxX) {
          maxX = chartData.datasets[i].data[j].x
        }
      }
    }

    const range = maxX - minX

    return minX - range * 0.05
  }

  const getChartOptions = () => {
    return {
      animation: false,
      layout: {
        padding: {
          left: 20 + (isToolbarExpanded ? DRAWERWIDE : DRAWERSLIM),
          bottom: 50,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scaleShowLabels: false,
      elements: {
        point: {
          radius: 0,
          hitRadius: 1,
        },
      },
      interaction: {
        mode: 'y',
        intersect: false,
      },
      plugins: {
        annotation: {
          annotations: getAnnotations(),
        },
        title: {
          display: true,
          text: title,
          color: appColors.itemTextColor,
          font: {
            size: 20,
          },
        },
        shadingAreaPluginX: {
          showShading: false,
          color: 'rgba(255, 0, 0, 0.2)',
        },
        tooltip: {
          mode: 'nearest',
          intersect: false,
          callbacks: {
            title: (item) => {
              if (!Array.isArray(item)) return ''
              if (item.length === 0) return ''

              if (bhaElements) {
                let component = `Component:`
                for (const key in bhaElements) {
                  if (item[0].raw.y > bhaElements[key].value) {
                    component = `Component: ${bhaElements[key].label.content}`
                    break
                  }
                }

                return `Depth: ${item[0].formattedValue} ${getUnitsText(UNITS_FOR.Depth)} - ${component}`
              }

              return `Depth: ${item[0].formattedValue} ${getUnitsText(UNITS_FOR.Depth)}`
            },
            label: (item) => {
              return `${item?.dataset?.label} - ${
                typeof item?.label === 'string' ? parseFloat(item?.label.replace(',', '')).toFixed(2) : item?.label
              } (${units})`
            },
          },
        },
        legend: {
          display: showLegend,
          position: 'right',
          labels: {
            generateLabels: (chart) => {
              const data = chart?.data
              if (data?.labels?.length && data?.datasets?.length) {
                return data?.datasets.map((label, i) => {
                  let text = label?.label
                    .replace('Usft/min', '(ft/min)')
                    .replace('Ft/min', '(ft/min)')
                    .replace('m/min', '(m/min)')
                    .replace('(ppg)', '')
                    .replace('(Usft)', '')
                    .replace('(ft)', '')
                    .replace('(m)', '')
                    .replace('(Ft)', '')
                    .replace('(klbs)', '')
                    .replace('(tons)', '')
                    .replace('klbs', '')
                    .replace('tons', '')
                    .replace('/100.0', '')
                    .replace('/30.0', '')
                    .replace('/10.0', '')

                  let splitArray = text.split('@')
                  let meta = chart.getDatasetMeta(i)

                  text = splitArray.length > 1 ? splitArray[1] : splitArray[0]
                  if (label.label.includes('Surge')) {
                    text = 'Surge @' + text
                  }

                  if (label.label.includes('Swab')) {
                    text = 'Swab @' + text
                  }

                  if (label.label.includes('Dirty')) {
                    text = 'Dirty @' + text
                  }

                  if (label.label.includes('Clean')) {
                    text = 'Clean @' + text
                  }

                  let textColor = '#ffffff'
                  if (printing || theme !== 'dark') {
                    textColor = '#000000'
                  }

                  return {
                    text: text,
                    fillStyle: label.borderColor,
                    strokeStyle: label.borderColor,
                    hidden: meta.hidden,
                    fontColor: textColor,
                    datasetIndex: i,
                    lineDash: label?.borderDash,
                    lineWidth: 4,
                  }
                })
              }
              return []
            },
          },
          onHover: (event, item, legend) => {
            legend.chart.data.datasets.forEach((dataset, index) => {
              dataset.borderWidth = item.datasetIndex === index ? 5 : 1
            })
            legend.chart.update()
          },
          onLeave: (event, item, legend) => {
            item.lineWidth = 3
            legend.chart.data.datasets.forEach((dataset) => {
              dataset.borderWidth = 3
            })
            legend.chart.update()
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'xy',
          },
          limits: {
            x: {
              min: 'original',
              max: 'original',
            },
            y: {
              min: 'original',
              max: 'original',
            },
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'xy',
          },
        },
        GradientPlugIn: {
          showGradient: !printing,
          theme: theme,
        },
        datalabels: {
          labels: {
            value: {
              font: {
                weight: 'bold',
              },
              color: '#000',
            },
          },
        },
      },
      scales: {
        x: {
          type: 'linear',
          align: 'center',
          beginAtZero: xBeginAtZero,
          min: findMinX(),
          title: {
            display: true,
            text: `${xAxisLabel} (${units})`,
            font: {
              size: 15,
            },
            color: appColors.itemTextColor,
          },
          ticks: {
            color: printing ? '#000000' : appColors.headerTextColor,
          },
          grid: {
            color: printing ? '#000000' : appColors.headerTextColor,
          },
        },
        y: {
          type: 'linear',
          beginAtZero: true,
          reverse: true,
          title: {
            display: true,
            text: `Depth (${getUnitsText(UNITS_FOR.Depth)})`,
            font: {
              size: 15,
            },
            color: appColors.itemTextColor,
          },
          ticks: {
            color: printing ? '#000000' : appColors.headerTextColor,
          },
          grid: {
            color: printing ? '#000000' : appColors.headerTextColor,
          },
        },
      },
    }
  }

  const getChartData = () => {
    return chartData?.datasets.concat(slideSheetData, witsData)
  }

  const getGridData = () => {
    const gridData = []
    const labels = []
    const depths = []

    const data = getChartData()
    if (!data) return depths

    for (let i = 0; i < data.length; i++) {
      if (!data[i].data) continue
      if (!Array.isArray(data[i].data)) continue

      for (let j = 0; j < data[i].data.length; j++) {
        if (!depths.includes(data[i].data[j].y)) {
          depths.push(data[i].data[j].y)
        }
      }
    }

    depths.sort((a, b) => a - b)

    for (let i = 0; i < depths.length; i++) {
      const row = { depth: numberWithCommasDecimals(depths[i], 2) }
      const label = {}
      for (let j = 0; j < data.length; j++) {
        label[j] = data[j].label
        if (!data[j].data) continue
        if (!Array.isArray(data[j].data)) continue
        let dataFound = false
        for (let k = 0; k < data[j].data.length; k++) {
          if (data[j].data[k].y === depths[i]) {
            row[j] = numberWithCommasDecimals(data[j].data[k].x, 2)
            dataFound = true
            break
          }
        }
        if (!dataFound) {
          row[j] = '-999.25'
        }
      }

      if (bhaComponents.length > 0) {
        let buckling = ''
        let component = ''
        for (let j = 0; j < bhaComponents.length; j++) {
          if (Math.abs(depths[i] - bhaComponents[j].depth) < .01) {
            buckling = bhaComponents[j].buckling
            component = bhaComponents[j]?.component?.description
            break
          }
        }

        row.buckling = buckling
        row.component = component

        label[data.length + 1] = 'Buckling'
        label[data.length + 2] = 'Component'
      }

      gridData.push(row)
      labels.push(label)
    }

    gridData.labels = labels

    return gridData
  }

  const getColDefs = () => {
    const colDefs = []

    const data = getGridData()

    if (!data) return colDefs
    if (!Array.isArray(data)) return colDefs
    if (data.length === 0) return colDefs

    colDefs.push({
      headerName: `Depth (${getUnitsText(UNITS_FOR.Depth)})`,
      field: 'depth',
      pinned: 'left',
    })

    const keys = Object.keys(data[0])
    if (!data.hasOwnProperty('labels')) return colDefs
    if (!Array.isArray(data.labels)) return colDefs
    if (data.labels.length === 0) return colDefs

    const labels = data.labels[0]

    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === 'depth') continue

      if (keys[i] === 'buckling' || keys[i] === 'component') {
        colDefs.push({
          headerName: labels[keys[i]],
          field: keys[i],
        })
        continue
      }

      colDefs.push({
        headerName: `${labels[i]} (${units})`,
        field: keys[i],
      })
    }

    return colDefs
  }

  return (
    <>
      {loading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            height: '100px',
            width: '100px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 999999,
          }}
        />
      ) : null}
      {!showGridData && (
        <Line options={getChartOptions()} data={{ labels: chartData?.labels, datasets: getChartData() }} />
      )}
      {showGridData && (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
          <Box
            sx={{
              padding: '10px',
              backgroundColor: appColors.modalBackColor,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}>
            <h2
              style={{
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 2 + (isToolbarExpanded ? DRAWERWIDE : DRAWERSLIM),
                color: appColors.itemTextColor,
              }}>
              {title}
            </h2>
          </Box>
          <div
            className={getAgGridTheme()}
            style={{ marginLeft: 2 + (isToolbarExpanded ? DRAWERWIDE : DRAWERSLIM), width: '100%', height: '100%' }}>
            <AgGridReact
              rowData={getGridData()}
              columnDefs={getColDefs()}
              rowHeight={30}
              headerHeight={30}
              theme={getAgGridTheme()}
            />
          </div>
        </Box>
      )}
    </>
  )
}

export default EngineeringChart
