import React from 'react'
import { appColors } from 'utils'
import { InputLabel, MenuItem, Select as MuiSelect } from '@mui/material'
import useInnovaTheme from 'components/common//hooks/useInnovaTheme'

function DropDownPicker({ value, defaultValue, displayEmpty, listOptions, onChange, label = null, height }) {
  const { getBackColor, getTextColor } = useInnovaTheme()

  return (
    <React.Fragment>
      {label !== null ? <InputLabel>{label}</InputLabel> : null}
      <MuiSelect
        id="dropdown-picker-select"
        value={value}
        defaultValue={defaultValue}
        displayEmpty={displayEmpty}
        onChange={(e) => {
          if (onChange) onChange(e.target.value)
        }}
        style={{
          width: '100%',
          margin: 0,
          borderRadius: 0,
          border: '',
          height: height || '40px',
          boxSizing: 'border-box',
          backgroundColor: getBackColor(),
        }}
        sx={{ svg: { color: getTextColor() } }}
        MenuProps={{
          PaperProps: {
            sx: {
              borderRadius: 0,
              backgroundColor: getBackColor(),
              '& ul': {
                backgroundColor: getBackColor(),
              },
              '& li': {
                fontSize: 12,
              },
            },
          },
        }}
        variant='outlined'>
        {Array.isArray(listOptions)
          ? listOptions.map((item) => (
              <MenuItem
                id={item?.value}
                value={item?.value}
                key={item?.value}
                style={{
                  margin: 5,
                  fontSize: 16,
                  paddingVertical: 2,
                  paddingHorizontal: 10,
                  borderWidth: 1,
                  height: '32px',
                  color: appColors.itemTextColor,
                  borderColor: appColors.borderColor,
                  backgroundColor: getBackColor(),
                  paddingRight: 10, // to ensure the text is never behind the icon
                  '&:before': {
                    backgroundColor: getBackColor(),
                    borderColor: appColors.borderColor,
                  },
                }}>
                {item.label}
              </MenuItem>
            ))
          : null}
      </MuiSelect>
    </React.Fragment>
  )
}

export default DropDownPicker
