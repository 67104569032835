import { useEffect, useMemo, useRef, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { currentWellAtom } from 'atoms'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { CustomHeader } from 'components/common/AgGridUtils'

const CreateOffsetPlanModal = ({ open, onClose, handleCreateOffsetPlan, node }) => {
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(currentWell)
  const gridApiTieOnPointData = useRef(null)
  const [tieOnPointData, setTieOnPointData] = useState([])
  const tieOnPointDataRef = useRef(tieOnPointData)
  const [elevationData, setElevationData] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const _isMounted = useRef(false)
  const [crsUnits, setCRSUnits] = useState('')
  const planWellRef = useRef(null)
  const gridApiTargetLineData = useRef(null)
  const { getAgGridTheme, getTextColor } = useInnovaTheme()
  const [targetLineData, setTargetLineData] = useState([
    { id: 0, tvdChange: 0, tvdVS: 0, newTvd: 0, dipAngle: 0, tvdRef: false },
  ])

  const getPlannedWells = useInnovaAxios({
    url: '/plannedWell/getPlannedWells',
  })

  const interpMd = useInnovaAxios({
    url: '/survey/interpMd',
  })

  const getCrsUnits = useInnovaAxios({
    url: '/well/getCrsUnits',
  })

  useEffect(() => {
    currentWellRef.current = currentWell
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true
    interpSurvey(0.0)
    fetchWellPlans()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const interpSurvey = async (md, event) => {
    if (isLoading) return
    if (!currentWellRef.current) return
    setIsLoading(true)

    let originalNode = node.name
    if (node.name.includes(' - TVD')) {
      originalNode = node.name.split(' - TVD')[0]
    }

    const response = await interpMd({ wellName: originalNode, md: md, isPlan: true })

    if (response?.error) return
    if (!_isMounted.current) return

    setTieOnPointData([response.data])
    tieOnPointDataRef.current = [response.data]
    setIsLoading(false)

    if (!event) return
    updateTargetLineData(event)

    setTimeout(() => {
      autoSizeColumnsTieOnPoint()
    }, 100)
  }

  const fetchWellPlans = async () => {
    if (isLoading) return
    if (!node) return
    setIsLoading(true)
    let originalNode = node.name
    if (node.name.includes(' - TVD')) {
      originalNode = node.name.split(' - TVD')[0]
    }

    const response = await getPlannedWells({ planName: originalNode })
    if (response?.error) return
    if (!_isMounted.current) return

    if (!Array.isArray(response.data) || response.data.length === 0) return
    setElevationData(response.data[0]?.depthReference?.Elevation)

    let crs = response.data[0]?.crs

    const responseCrsUnits = await getCrsUnits({ crsName: crs })

    if (responseCrsUnits?.error) return
    if (!_isMounted.current) return

    setCRSUnits(responseCrsUnits.data)

    planWellRef.current = response.data[0]

    setIsLoading(false)
  }

  const updateTargetLineData = (event) => {
    if (!event) return
    if (!targetLineData) return
    if (!tieOnPointDataRef.current) return

    let newTargetLineData = [...targetLineData]
    if (event.column.colId === 'md') {
      if (newTargetLineData[0].tvdRef) {
        let degToRad = Math.PI / 180
        let scale = 1
        if (tieOnPointDataRef.current[0].vs < 0) {
          scale = -1
        }

        let dipAngle = newTargetLineData[0].dipAngle
        if (newTargetLineData[0].dipAngle === 0) {
          dipAngle = 10.0
        }
        newTargetLineData[0].newTvd =
          targetLineData[0].tvdVS -
          Math.abs(tieOnPointDataRef.current[0].vs) * Math.tan((dipAngle - 90) * scale * degToRad)
      } else {
        newTargetLineData[0].newTvd = targetLineData[0].tvdChange + tieOnPointDataRef.current[0].tvd
      }
      newTargetLineData[0].dipAngle = tieOnPointDataRef.current[0].inc
    }
    if (event.column.colId === 'tvdChange') {
      newTargetLineData[0].newTvd = event.value + tieOnPointDataRef.current[0].tvd
    }
    if (event.column.colId === 'tvdVS') {
      let degToRad = Math.PI / 180
      let scale = 1
      if (tieOnPointDataRef.current[0].vs < 0) {
        scale = -1
      }
      let dipAngle = newTargetLineData[0].dipAngle
      if (newTargetLineData[0].dipAngle === 0) {
        dipAngle = 10.0
      }
      newTargetLineData[0].newTvd =
        event.value - Math.abs(tieOnPointDataRef.current[0].vs) * Math.tan((dipAngle - 90) * scale * degToRad)

      newTargetLineData[0].tvdChange = newTargetLineData[0].newTvd - tieOnPointDataRef.current[0].tvd
    }
    if (event.column.colId === 'newTvd') {
      newTargetLineData[0].tvdChange = event.value - tieOnPointDataRef.current[0].tvd
    }
    if (event.column.colId === 'tvdRef') {
      if (newTargetLineData[0].tvdRef) {
        let degToRad = Math.PI / 180
        let scale = 1
        if (tieOnPointDataRef.current[0].vs < 0) {
          scale = -1
        }
        let dipAngle = newTargetLineData[0].dipAngle
        if (newTargetLineData[0].dipAngle === 0) {
          dipAngle = 10.0
        }
        newTargetLineData[0].newTvd =
          targetLineData[0].tvdVS -
          Math.abs(tieOnPointDataRef.current[0].vs) * Math.tan((dipAngle - 90) * scale * degToRad)

        newTargetLineData[0].tvdChange = newTargetLineData[0].newTvd - tieOnPointDataRef.current[0].tvd
      } else {
        newTargetLineData[0].newTvd = targetLineData[0].tvdChange + tieOnPointDataRef.current[0].tvd
      }
    }
    if (event.column.colId === 'dipAngle') {
      if (newTargetLineData[0].tvdRef) {
        let degToRad = Math.PI / 180
        let scale = 1
        if (tieOnPointDataRef.current[0].vs < 0) {
          scale = -1
        }

        let dipAngle = newTargetLineData[0].dipAngle
        if (newTargetLineData[0].dipAngle === 0) {
          dipAngle = 10.0
        }
        newTargetLineData[0].newTvd =
          targetLineData[0].tvdVS -
          Math.abs(tieOnPointDataRef.current[0].vs) * Math.tan((dipAngle - 90) * scale * degToRad)

        newTargetLineData[0].tvdChange = newTargetLineData[0].newTvd - tieOnPointDataRef.current[0].tvd
      } else {
        newTargetLineData[0].newTvd = targetLineData[0].tvdChange + tieOnPointDataRef.current[0].tvd
      }
    }

    setTargetLineData(newTargetLineData)
    setTimeout(() => {
      autoSizeColumnsTargetLine()
    }, 100)
  }

  let columnDefsTieOnPoint = [
    {
      field: 'md',
      colId: 'md',
      headerName: 'MD',
      editable: true,
      cellStyle: {
        backgroundColor: 'green',
      },
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
      headerComponentParams: { units: crsUnits?.toLowerCase() },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 100000,
        precision: 2,
      },
    },
    {
      field: 'inc',
      colId: 'inc',
      headerName: 'Inc',
      editable: false,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
      headerComponentParams: { units: '°' },
    },
    {
      field: 'azi',
      colId: 'azi',
      headerName: 'Azi',
      editable: false,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
      headerComponentParams: { units: '°' },
    },
    {
      field: 'tvd',
      colId: 'tvd',
      headerName: 'TVD',
      editable: false,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
      headerComponentParams: { units: crsUnits?.toLowerCase() },
    },
    {
      colId: 'ns',
      field: 'ns',
      headerName: 'NS',
      editable: false,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
      headerComponentParams: { units: crsUnits?.toLowerCase() },
    },
    {
      colId: 'ew',
      field: 'ew',
      headerName: 'EW',
      editable: false,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
      headerComponentParams: { units: crsUnits?.toLowerCase() },
    },
    {
      colId: 'vs',
      field: 'vs',
      headerName: 'VS',
      editable: false,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
      headerComponentParams: { units: crsUnits?.toLowerCase() },
    },
    {
      colId: 'gridNorth',
      field: 'gridNorth',
      headerName: 'Grid N',
      editable: false,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
      headerComponentParams: { units: crsUnits?.toLowerCase() },
    },
    {
      colId: 'gridEast',
      field: 'gridEast',
      headerName: 'Grid E',
      editable: false,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
      headerComponentParams: { units: crsUnits?.toLowerCase() },
    },
  ]

  const defaultColDefTieOnPoint = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: true,
      headerClass: 'header-no-padding',
    }
  }, [])

  const getRowIdTieOnPoint = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const gridOptionsTieOnPoint = {
    onCellEditingStopped: (event) => {
      interpSurvey(event?.value, event)
    },
  }

  const onGridReadyTieOnPoint = (params) => {
    gridApiTieOnPointData.current = params.api
  }

  const onFirstDataRenderedTieOnPoint = (params) => {
    autoSizeColumnsTieOnPoint()
  }

  const autoSizeColumnsTieOnPoint = () => {
    if (gridApiTieOnPointData.current === null) return
    gridApiTieOnPointData.current?.autoSizeAllColumns()
  }

  let columnDefsTargetLine = [
    {
      field: 'tvdChange',
      colId: 'tvdChange',
      headerName: 'TVD Change',
      editable: true,
      hide: targetLineData[0]?.tvdRef,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
      cellStyle: {
        backgroundColor: 'green',
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: -100000,
        max: 100000,
        precision: 2,
      },
    },
    {
      field: 'tvdVS',
      colId: 'tvdVS',
      headerName: 'TVD @0 VS',
      editable: true,
      cellStyle: {
        backgroundColor: 'green',
      },
      hide: !targetLineData[0]?.tvdRef,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
      headerComponentParams: { units: crsUnits?.toLowerCase() },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: -100000,
        max: 100000,
        precision: 2,
      },
    },
    {
      field: 'newTvd',
      colId: 'newTvd',
      headerName: 'New Target TVD',
      editable: !targetLineData[0]?.tvdRef,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
      cellStyle: {
        backgroundColor: !targetLineData[0]?.tvdRef ? 'green' : '',
      },
      headerComponentParams: { units: crsUnits?.toLowerCase() },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 100000,
        precision: 2,
      },
    },
    {
      field: 'dipAngle',
      colId: 'dipAngle',
      headerName: 'Dip Angle',
      editable: true,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
      cellStyle: {
        backgroundColor: 'green',
      },
      headerComponentParams: { units: '°' },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 90,
        precision: 2,
      },
    },
    {
      field: 'tvdRef',
      colId: 'tvdRef',
      headerName: 'TVD Ref Zero VS',
      editable: true,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
    },
  ]

  const defaultColDefTargetLine = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: true,
      headerClass: 'header-no-padding',
    }
  }, [])

  const getRowIdTargetLine = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const addRowId = (data) => {
    if (!Array.isArray(data)) return data
    if (data.id) return data

    return data.map((row) => {
      return { ...row, id: getStringId() }
    })
  }

  const gridOptionsTargetLine = {
    onCellEditingStopped: (event) => {
      updateTargetLineData(event)
    },
  }

  const onGridReadyTargetLine = (params) => {
    gridApiTargetLineData.current = params.api
  }

  const onFirstDataRenderedTargetLine = (params) => {
    autoSizeColumnsTargetLine()
  }

  const autoSizeColumnsTargetLine = () => {
    if (gridApiTargetLineData.current === null) return
    gridApiTargetLineData.current?.autoSizeAllColumns()
  }

  const getReturnData = () => {
    let sign = targetLineData[0].tvdChange > 0 ? '+' : '-'
    let originalNode = node.name

    let params = {
      tieOnMd: tieOnPointData[0].md,
      dipAngle: targetLineData[0].dipAngle,
      deltaTvd: targetLineData[0].tvdChange,
      wellName:
        originalNode +
        ' -TVD ' +
        sign +
        targetLineData[0].tvdChange +
        ' Dip ' +
        numberWithCommasDecimals(targetLineData[0].dipAngle, 2),
      useStep: true,
      smoothDls: 0,
    }
    return { offSetPlan: params, wellPlan: planWellRef.current }
  }

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    }
  }, [])

  const isValidDip = () => {
    if (targetLineData[0].dipAngle === 0) {
      return false
    }
    return true
  }

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '70vw',
          height: '60vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          Create Offset Plan
          <Typography variant='body2' color={getTextColor()} align='right' sx={{ fontStyle: 'italic' }}>
            All depths relative to: RTE @ {elevationData} ({crsUnits})
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' color={getTextColor()}>
          <Box mt={2}>
            <Box mb={1}>Tie On</Box>
            <div className={getAgGridTheme()} style={{ width: '100%', height: '120px' }}>
              <AgGridReact
                loading={isLoading}
                rowData={addRowId(tieOnPointData) || []}
                columnDefs={columnDefsTieOnPoint}
                defaultColDef={defaultColDefTieOnPoint}
                getRowId={getRowIdTieOnPoint}
                gridOptions={gridOptionsTieOnPoint}
                onGridReady={onGridReadyTieOnPoint}
                onFirstDataRendered={onFirstDataRenderedTieOnPoint}
                components={components}
              />
            </div>
          </Box>

          <Box mt={4}>
            <Box mb={1}>Target Line Change</Box>
            <div className={getAgGridTheme()} style={{ width: '100%', height: '120px' }}>
              <AgGridReact
                loading={isLoading}
                rowData={targetLineData || []}
                columnDefs={columnDefsTargetLine}
                defaultColDef={defaultColDefTargetLine}
                getRowId={getRowIdTargetLine}
                gridOptions={gridOptionsTargetLine}
                onGridReady={onGridReadyTargetLine}
                onFirstDataRendered={onFirstDataRenderedTargetLine}
                components={components}
              />
            </div>
            {!isValidDip() && (
              <Box color='red' mt={1.5} display='flex' alignItems='center' sx={{ gap: 1 }}>
                <ErrorIcon />
                Invalid Dip Angle
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant='contained' type='button' color='primary' onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          variant='contained'
          type='button'
          color='primary'
          onClick={() => {
            onClose()
            handleCreateOffsetPlan(getReturnData())
          }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateOffsetPlanModal
