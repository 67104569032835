import React, { useRef, useEffect, useMemo } from 'react'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const EngineeringReportDetailsGrid = ({ engineeringDashboardDetailsData }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme, theme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const toggleColumn = (colKey, checked) => {
    engineeringDashboardDetailsData.forEach((row) => {
      row[colKey] = checked
    })

    gridApi.current.refreshCells({
      columns: [colKey],
      force: true,
    })
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return params.data?.reportType
    }
  }, [])

  const leftAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  })

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const HeaderCheckbox = ({ toggleColumn, colKey, displayName }) => {
    let allChecked = engineeringDashboardDetailsData.every((row) => row[colKey] === true)
    const noneChecked = engineeringDashboardDetailsData.every((row) => row[colKey] === false)
    const isIndeterminate = !allChecked && !noneChecked

    const handleChange = (event) => {
      toggleColumn(colKey, event.target.checked)
      allChecked = event.target.checked
      gridApi.current.refreshCells()
      gridApi.current.refreshHeader()
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <input
          id={displayName}
          type='checkbox'
          checked={allChecked}
          ref={(el) => {
            if (el) el.indeterminate = isIndeterminate
          }}
          onChange={handleChange}
        />
        {displayName}
      </Box>
    )
  }

  const columnDefs = [
    {
      headerName: 'Report Type',
      field: 'reportType',
      colId: 'reportType',
      cellStyle: leftAlignCell(),
    },
    {
      headerName: 'Charts',
      field: 'charts',
      colId: 'charts',
      cellStyle: centerAlignCell(),
      headerComponent: HeaderCheckbox,
      headerComponentParams: {
        colKey: 'charts',
        toggleColumn,
      },
      editable: true,
    },
    {
      headerName: 'Summary Data',
      field: 'summaryData',
      colId: 'summaryData',
      cellStyle: centerAlignCell(),
      headerComponent: HeaderCheckbox,
      headerComponentParams: {
        colKey: 'summaryData',
        toggleColumn,
      },
      editable: true,
    },
    {
      headerName: 'Calculated Data',
      field: 'calculatedData',
      colId: 'calculatedData',
      cellStyle: centerAlignCell(),
      headerComponent: HeaderCheckbox,
      headerComponentParams: {
        colKey: 'calculatedData',
        toggleColumn,
      },
      editable: true,
    },
  ]

  const gridOptions = {
    onCellEditingStopped: (event) => {
      if (event.colDef.colId === 'summaryData') {
        if (
          event.data.reportType === 'Pump Pressure' ||
          event.data.reportType === 'ECD' ||
          event.data.reportType === 'ECD Snapshot'
        ) {
          for (let i = 0; i < engineeringDashboardDetailsData.length; i++) {
            if (
              engineeringDashboardDetailsData[i].reportType === 'Pump Pressure' ||
              engineeringDashboardDetailsData[i].reportType === 'ECD' ||
              engineeringDashboardDetailsData[i].reportType === 'ECD Snapshot'
            ) {
              engineeringDashboardDetailsData[i].summaryData = event.newValue
            }
          }

          event.api.refreshCells({
            columns: ['summaryData'],
            force: true,
          })
        }
        if (
          event.data.reportType === 'Drillers Hookloads' ||
          event.data.reportType === 'Driller Torques' ||
          event.data.reportType === 'Side Force' ||
          event.data.reportType === 'Apparent WOB' ||
          event.data.reportType === 'Pipe Twist' ||
          event.data.reportType === 'Pipe Stretch' ||
          event.data.reportType === 'On Btm Tension Snapshot' ||
          event.data.reportType === 'Off Btm Tension Snapshot'
        ) {
          for (let i = 0; i < engineeringDashboardDetailsData.length; i++) {
            if (
              engineeringDashboardDetailsData[i].reportType === 'Drillers Hookloads' ||
              engineeringDashboardDetailsData[i].reportType === 'Driller Torques' ||
              engineeringDashboardDetailsData[i].reportType === 'Side Force' ||
              engineeringDashboardDetailsData[i].reportType === 'Apparent WOB' ||
              engineeringDashboardDetailsData[i].reportType === 'Pipe Twist' ||
              engineeringDashboardDetailsData[i].reportType === 'Pipe Stretch' ||
              engineeringDashboardDetailsData[i].reportType === 'On Btm Tension Snapshot' ||
              engineeringDashboardDetailsData[i].reportType === 'Off Btm Tension Snapshot'
            ) {
              engineeringDashboardDetailsData[i].summaryData = event.newValue
            }
          }

          event.api.refreshCells({
            columns: ['summaryData'],
            force: true,
          })
        }
      }
      if (event.colDef.colId === 'calculatedData') {
        if (
          event.data.reportType === 'Pump Pressure' ||
          event.data.reportType === 'ECD' ||
          event.data.reportType === 'ECD Snapshot'
        ) {
          for (let i = 0; i < engineeringDashboardDetailsData.length; i++) {
            if (
              engineeringDashboardDetailsData[i].reportType === 'Pump Pressure' ||
              engineeringDashboardDetailsData[i].reportType === 'ECD' ||
              engineeringDashboardDetailsData[i].reportType === 'ECD Snapshot'
            ) {
              engineeringDashboardDetailsData[i].calculatedData = event.newValue
            }
          }

          event.api.refreshCells({
            columns: ['calculatedData'],
            force: true,
          })
        }
        if (event.data.reportType === 'Surge and Swab' || event.data.reportType === 'Tripping Speeds') {
          for (let i = 0; i < engineeringDashboardDetailsData.length; i++) {
            if (
              engineeringDashboardDetailsData[i].reportType === 'Surge and Swab' ||
              engineeringDashboardDetailsData[i].reportType === 'Tripping Speeds'
            ) {
              engineeringDashboardDetailsData[i].calculatedData = event.newValue
            }
          }

          event.api.refreshCells({
            columns: ['calculatedData'],
            force: true,
          })
        }
      }

      gridApi.current.refreshHeader()
    },
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
      }}>
      <div className={getAgGridTheme(theme)} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={engineeringDashboardDetailsData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
          gridOptions={gridOptions}
        />
      </div>
    </Box>
  )
}
export default EngineeringReportDetailsGrid
