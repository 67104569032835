import { useEffect, useState, useRef } from 'react'
import useAxiosGzip from './useAxiosGzip'
import { useRecoilValue } from 'recoil'
import { currentWellAtom, userUserRoleAtom } from 'atoms'
import PdfDocument from 'components/common/PDFGen/PdfDocument'
import { removeSpecialSymbols } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { round } from 'utils/numberFunctions'
import useInnovaAxios from './useInnovaAxios'
import { decToSexa } from 'utils/mapFunctions'
import { multiParse } from 'utils'
import { Buffer } from 'buffer'


function useAntiCollision() {
  const _isMounted = useRef(true)
  const antiCollisionData = useRef({})
  const isLoading = useRef(false)
  const [acError, setAcError] = useState(null)
  const [loading, setLoading] = useState(false)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(currentWell)
  const { getUnitsText } = useUnits()
  const userRole = useRecoilValue(userUserRoleAtom)
  const [logos, setLogos] = useState([])

  const getAntiCollisionGz = useAxiosGzip({
    url: '/antiCollision/getWellAntiCollisionGz',
  })

  const selectOffsetRadius = useInnovaAxios({
    url: '/well/selectOffsetWellsWithinRadius',
  })

  const getOperatorLogos = useInnovaAxios({
    url: '/operator/getLogos',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
    fetchAntiCollision()
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAntiCollision = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getAntiCollisionGz({ wellName: currentWellRef.current, calcSeperationFactor: true })
    if (_isMounted.current) {
      setLoading(false)

      if (response?.error) {
        setAcError(response ? response?.error : null)
      }

      antiCollisionData.current = response?.data ? response.data : null
    }

    isLoading.current = false

    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const responseLogos = await getOperatorLogos({ operator: response?.data?.calculationData?.operator?.opName })

    if (!_isMounted.current) return
    setLoading(false)
    isLoading.current = false

    if (responseLogos?.error) return
    if (!responseLogos?.data) return

    const mfpBoundary = responseLogos.data.substring(2, responseLogos.data.search('Content')).trim()
    let parts = multiParse(Buffer.from(responseLogos.data), mfpBoundary)
    let logoData = parts.map((part) => {
      let logoObj = { ...part }
      logoObj.data = 'data:image/*;base64,' + part.data
      return logoObj
    })
    setLogos(logoData)
  }

  const getLogo = (type) => {
    if (!Array.isArray(logos)) return null
    if (logos.length === 0) return null
    if (type === 'Primary') return logos[0].data
    if (type === 'Secondary' && logos.length > 1) return logos[1].data
    return null
  }

  const getLogoDimensions = (logo) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve({ width: img.width, height: img.height })
      img.onerror = (err) => reject(err)
      img.src = logo
    })
  }

  const calculateDimensions = async (logo) => {
    if (!logo) return null

    try {
      const { width, height } = await getLogoDimensions(logo)
      return width > height ? 'contain' : 'cover'
    } catch (err) {
      console.error('Error calculating dimensions:', err)
      return null
    }
  }

  const getLogoFill = async (type) => {
    if (!Array.isArray(logos) || logos.length === 0) return null

    const logo = type === 'Primary' ? logos[0]?.data : logos[1]?.data
    if (!logo) return null

    const fillType = await calculateDimensions(logo)
    return fillType
  }

  const findWellMinValues = (wellAcResults) => {
    let output = { minC2cMd: -1, minSfMd: -1, minEsMd: -1, minSf: 9999999, minEs: 9999999, minC2c: 9999999 }
    if (!wellAcResults) return output
    if (!Array.isArray(wellAcResults)) return output

    wellAcResults.forEach((station) => {
      if (station.SF < output.minSf && station.SF > -900) {
        output.minSf = station.SF
        output.minSfMd = station.refStation.md
      }

      if (station.ES < output.minEs) {
        output.minEs = station.ES
        output.minEsMd = station.refStation.md
      }

      if (station.C2C < output.minC2c) {
        output.minC2c = station.C2C
        output.minC2cMd = station.refStation.md
      }
    })

    return output
  }

  const fetchAntiCollisionData = () => {
    if (!antiCollisionData.current) return {}
    if (!antiCollisionData.current.hasOwnProperty('wells')) return {}
    if (!Array.isArray(antiCollisionData.current.wells) || antiCollisionData.current.wells.length <= 0) return {}

    let reducedData = []

    for (let i = 0; i < antiCollisionData.current.wells.length; i++) {
      if (!Array.isArray(antiCollisionData.current.wells[i].acResults)) continue

      let wellName = antiCollisionData.current.wells[i].wellName
      const { minSfMd, minEsMd, minC2cMd } = findWellMinValues(antiCollisionData.current.wells[i].acResults)
      antiCollisionData.current.wells[i].acResults.forEach((station) => {
        let newStation = { ...station }
        newStation.offsetName = wellName
        newStation.refMd = newStation.refStation.md
        newStation.refTvd = newStation.refStation.tvd
        newStation.refInc = newStation.refStation.inc
        newStation.refAzi = newStation.refStation.azi
        newStation.offMd = newStation.offStation.md
        newStation.offInc = newStation.offStation.inc
        newStation.offAzi = newStation.offStation.azi
        newStation.offNS = newStation.offStation.ns
        newStation.offEW = newStation.offStation.ew
        newStation.offTVD = newStation.offStation.tvd

        newStation.minSf = newStation.refMd === minSfMd
        newStation.minEs = newStation.refMd === minEsMd
        newStation.minC2c = newStation.refMd === minC2cMd
        reducedData.push({ ...newStation })
      })
    }
    antiCollisionData.current.acResults = reducedData.map((data, index) => ({ ...data, itemIndex: index }))

    return antiCollisionData.current
  }

  const getPdfData = (data) => {
    return new Promise(async (resolve) => {
      if (typeof data !== 'object') return null
      if (!data.hasOwnProperty('calculationData')) return null
      if (!currentWellRef.current) return null

      const pdfData = await generatePdfDocument(data)

      resolve({
        fileName: `AntiCollision Report - ${removeSpecialSymbols(currentWellRef.current)}`,
        data: (
          <PdfDocument
            data={pdfData}
            multiDocument={false}
            pageOrientation={'portrait'}
            reportSettings={userRole?.userPrefs?.reportSettings}
            footerText={`${userRole.organization} powered by Innova ${new Date().getFullYear()}`}
          />
        ),
      })
    })
  }

  const getSurveyProgram = (actualWell) => {
    if (!actualWell || !actualWell?.surveyProgram || !Array.isArray(actualWell?.surveyProgram)) return []
    let surveyProgram = [
      [
        { text: 'Survey Program', isHeader: true, width: '15%' },
        { text: actualWell.actualWell, isHeader: true, width: '85%', textAlign: 'left' },
      ],
      [
        { text: `Depth From (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true },
        { text: `Depth To (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true },
        { text: 'Survey', isBold: true },
        { text: 'Survey Tool', isBold: true },
      ],
    ]

    for (let i = 0; i < actualWell.surveyProgram.length; i++) {
      surveyProgram.push([
        { text: numberWithCommasDecimals(actualWell?.surveyProgram[i].DepthFrom, 2) },
        { text: numberWithCommasDecimals(actualWell?.surveyProgram[i].DepthTo, 2) },
        { text: actualWell?.surveyProgram[i].Survey, textAlign: 'left' },
        { text: actualWell?.surveyProgram[i].IPM, textAlign: 'left' },
      ])
    }

    return surveyProgram
  }

  const getResponse = (item) => {
    return item === true ? 'Yes' : item === false ? 'No' : item
  }

  const handleWarning = (warning, md, minSfMd, minEsMd, minC2cMd) => {
    let result = warning
    let boolWarnings = [0, 0, 0]

    if (result === '0.0\n' || result === '0.0' || !result) {
      result = ''
    }

    if (md === minSfMd) {
      if (boolWarnings[0]) result += ','
      result += ' Min SF'
      boolWarnings[1] = 1
    }

    if (md === minEsMd) {
      if (boolWarnings[1] || boolWarnings[0]) result += ','
      result += ' Min ES'
      boolWarnings[2] = 1
    }

    if (md === minC2cMd) {
      if (boolWarnings[2] || boolWarnings[1] || boolWarnings[0]) {
        result += ','
      }
      result += ' Min C2C'
    }

    if (!result) return ''

    return result
  }

  const getAcSummary = (actualWell, wells) => {
    if (!wells || !actualWell) return []

    let summary = [
      [
        { text: 'AC Summary', isHeader: true },
        { text: `Reference well: ${actualWell?.actualWell}`, isHeader: true, columnSpan: 6, textAlign: 'left' },
      ],
      [
        { text: 'Offset Name', isBold: true },
        { text: 'Ref MD', isBold: true },
        { text: 'Off MD', isBold: true },
        { text: 'C2C', isBold: true },
        { text: 'ES', isBold: true },
        { text: 'SF', isBold: true },
        { text: 'Warning', isBold: true },
      ],
    ]

    for (let i = 0; i < wells.length; i++) {
      if (!Array.isArray(wells[i].acResults)) continue

      const { minSfMd, minEsMd, minC2cMd } = findWellMinValues(wells[i].acResults)

      for (let j = 0; j < wells[i].acResults.length; j++) {
        let md = wells[i].acResults[j].refMd
        if (wells[i].acResults[j].C2C < 0) continue
        if (md !== minSfMd && md !== minEsMd && md !== minC2cMd) continue

        summary.push([
          { text: wells[i].acResults[j].offsetName },
          {
            text: wells[i].acResults[j].refMd ? numberWithCommasDecimals(wells[i].acResults[j].refMd, 2) : '0.00',
          },
          { text: numberWithCommasDecimals(wells[i].acResults[j].offMd, 2) },
          { text: numberWithCommasDecimals(wells[i].acResults[j].C2C, 2) },
          { text: numberWithCommasDecimals(wells[i].acResults[j].ES, 2) },
          {
            text: wells[i].acResults[j].SF ? numberWithCommasDecimals(wells[i].acResults[j].SF, 2) : ' ',
          },
          { text: handleWarning(wells[i].acResults[j].warning, md, minSfMd, minEsMd, minC2cMd) },
        ])
      }
    }

    return summary
  }

  const getAcWarning = (warning, md, minSfMd, minEsMd, minC2cMd) => {
    let result = warning
    let boolWarnings = [0, 0, 0]

    if (!result || result === '0.0' || result === '0.0\n') {
      result = ''
    }

    if (md === minSfMd) {
      if (boolWarnings[0]) result += ','
      result += ' SF'
      boolWarnings[1] = 1
    }

    if (md === minEsMd) {
      if (boolWarnings[1] || boolWarnings[0]) result += ','
      result += ' ES'
      boolWarnings[2] = 1
    }

    if (md === minC2cMd) {
      if (boolWarnings[2] || boolWarnings[1] || boolWarnings[0]) {
        result += ','
      }
      result += ' C2C'
    }

    if (!result) return ''

    return result
  }

  const getAcReport = (well) => {
    if (!well) return []

    const report = [
      [
        {
          text: `AC Report - ${well.wellName}`,
          fontSize: 9,
          isHeader: true,
          width: '100%',
          columnSpan: 16,
        },
      ],
      [
        { text: `Ref MD\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true, lines: 2 },
        { text: `Ref TVD\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true, lines: 2 },
        { text: `Off MD\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true, lines: 2 },
        { text: `Off TVD\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true, lines: 2 },
        { text: `Ref Semi M\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true, lines: 2 },
        { text: `Off Semi M\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true, lines: 2 },
        { text: `HS TFO\n(°)`, isHeader: true, lines: 2 },
        { text: `Off NS\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true, lines: 2 },
        { text: `Off EW\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true, lines: 2 },
        { text: `Ref OD\n(${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true, lines: 2 },
        { text: `Off OD\n(${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true, lines: 2 },
        { text: `C2C\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true, lines: 2 },
        { text: `ES\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true, lines: 2 },
        { text: `Min Sep\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true, lines: 2 },
        { text: 'SF', isHeader: true },
        { text: 'Warnings', isHeader: true },
      ],
    ]

    let acResults = well?.acResults
    if (!acResults) return []
    const { minSfMd, minEsMd, minC2cMd } = findWellMinValues(acResults)

    for (const acResult of acResults || []) {
      let md = acResult.refMd

      report.push([
        { text: numberWithCommasDecimals(acResult.refMd, 2) },
        { text: numberWithCommasDecimals(acResult.refTvd, 2) },
        { text: numberWithCommasDecimals(acResult.offMd, 2) },
        { text: numberWithCommasDecimals(acResult.offTVD, 2) },
        { text: numberWithCommasDecimals(acResult.refEllip, 2) },
        { text: numberWithCommasDecimals(acResult.offEllip, 2) },
        { text: numberWithCommasDecimals(acResult.tfo, 2) },
        { text: numberWithCommasDecimals(acResult.offNS, 2) },
        { text: numberWithCommasDecimals(acResult.offEW, 2) },
        { text: numberWithCommasDecimals(acResult.refCasingOd, 3) },
        { text: numberWithCommasDecimals(acResult.offCasingOd, 3) },
        { text: numberWithCommasDecimals(acResult.C2C, 2) },
        { text: numberWithCommasDecimals(acResult.ES, 2) },
        { text: numberWithCommasDecimals(acResult.errorRadius, 2) },
        { text: numberWithCommasDecimals(acResult.SF, 2) },
        { text: getAcWarning(acResult.warning, md, minSfMd, minEsMd, minC2cMd) },
      ])
    }

    return report
  }

  const getWellsData = (wells) => {
    if (!wells) return []
    if (!Array.isArray(wells)) return []
    let wellsData = []

    for (const well of wells) {
      wellsData.push({
        breakHeader: true,
        sectionAfter: 5,
        fontSize: 5,
        fixedHeaders: 2,
        multiPage: true,
        multiPageRow: 2,
        manualWidth: true,
        pageBreakAfter: true,
        columnWidths: [
          '5.5%',
          '5.5%',
          '5.5%',
          '5.5%',
          '5.5%',
          '5.5%',
          '5.5%',
          '5.5%',
          '5.5%',
          '5.5%',
          '5.5%',
          '5.5%',
          '5.5%',
          '5.5%',
          '5.5%',
          '17.5%',
        ],
        data: getAcReport(well),
      })
    }
    return wellsData
  }

  const generatePdfDocument = async (data) => {
    if (!data) return null
    if (!data.hasOwnProperty('calculationData')) return null
    const { actualWell, facility, field, well, operator } = data.calculationData
    const { wells } = data

    let logoFill = await getLogoFill('Primary')
    let logoSecondaryFill = await getLogoFill('Secondary')

    let docData = [
      {
        showTitle: true,
        title: 'Anti-Collision Report',
        showLogo: true,
        logo: getLogo('Primary'),
        logoHeight: 65,
        logoWidth: '25%',
        logoFill: logoFill,
        logoSecondary: getLogo('Secondary'),
        logoSecondaryHeight: 65,
        logoSecondaryWidth: '25%',
        logoSecondaryFill: logoSecondaryFill,
        columnWidths: ['12.5%', '37.5%', '12.5%', '37.5%'],
        height: 13,
        data: [
          [
            { text: 'Operator', isHeader: true },
            { text: operator?.opName },
            { text: 'Local co-ord ref', isHeader: true },
            { text: `${field.localCoordRef} Centered` },
          ],
          [
            { text: 'Field', isHeader: true },
            { text: field?.field },
            { text: 'TVD Reference', isHeader: true },
            { text: actualWell.depthDatum.datumName },
          ],
          [
            { text: 'Facility', isHeader: true },
            { text: facility?.facility },
            { text: 'North Reference', isHeader: true },
            { text: field?.northRef },
          ],
          [
            { text: 'Well', isHeader: true },
            { text: well?.well },
            { text: 'Survey Calc Method', isHeader: true },
            { text: 'Minimum Curvature' },
          ],
          [
            { text: 'Wellbore', isHeader: true },
            { text: actualWell?.actualWell },
            { text: 'Errors evaluated @', isHeader: true },
            { text: `${numberWithCommasDecimals(operator?.sigma, 3)} Sigma` },
          ],
        ],
      },
      {
        sectionAfter: 5,
        columnWidths: ['15%', '35%', '15%', '35%'],
        manualWidth: true,
        height: 13,
        data: [
          [
            { text: 'Field', isHeader: true, width: '15%' },
            { text: field?.field, isHeader: true, width: '85%', textAlign: 'left' },
          ],
          [
            { text: 'CRS', isBold: true },
            { text: field?.crs, textAlign: 'left' },
            { text: 'North Reference', isBold: true },
            { text: field?.northRef, textAlign: 'left' },
          ],
          [
            { text: 'Apply Scale Factor', isBold: true },
            {
              text: getResponse(field?.applySf),
              textAlign: 'left',
            },
            { text: 'Scale Factor', isBold: true },
            { text: numberWithCommasDecimals(field?.scaleFactor, 3), textAlign: 'left' },
          ],
          [
            { text: 'System Datum', isBold: true },
            { text: field?.systemDatum, textAlign: 'left' },
            { text: 'Depth Datum->MSL', isBold: true },
            {
              text: `${round(actualWell?.depthDatum?.datumElevation, 2)} ${getUnitsText(UNITS_FOR.Depth)}`,
              textAlign: 'left',
            },
          ],
          [
            { text: 'Scan Method', isBold: true },
            { text: operator?.scanMethod, textAlign: 'left' },
            { text: 'Error Surface', isBold: true },
            {
              text: operator?.errorSurface === 'Elliptical Conic' ? 'Pedal Curve' : operator?.errorSurface,
              textAlign: 'left',
            },
          ],
          [
            { text: 'Casing Included', isBold: true },
            { text: getResponse(operator?.incCasing), textAlign: 'left' },
            { text: 'Casing Method' },
            { text: getResponse(operator?.incCasing) === 'Yes' ? operator.casingMethod : '', textAlign: 'left' },
          ],
        ],
      },
      {
        sectionAfter: 5,
        columnWidths: ['15%', '35%', '15%', '35%'],
        manualWidth: true,
        height: 13,
        data: [
          [
            { text: 'Facility', isHeader: true, width: '15%' },
            { text: facility?.facility, isHeader: true, width: '85%', textAlign: 'left' },
          ],
          [
            { text: 'Map Northing', isBold: true },
            {
              text: facility?.facilityCoords?.gridNorth
                ? `${numberWithCommasDecimals(facility.facilityCoords.gridNorth, 2)} ${field?.crsUom}`
                : '',
              textAlign: 'left',
            },
            { text: 'Map Easting', isBold: true },
            {
              text: facility?.facilityCoords?.gridEast
                ? `${numberWithCommasDecimals(facility.facilityCoords.gridEast, 2)} ${field?.crsUom}`
                : '',
              textAlign: 'left',
            },
          ],
          [
            { text: 'Latitude', isBold: true },
            { text: `${decToSexa(facility?.facilityCoords?.latitude, 'lat')}`, textAlign: 'left' },
            { text: 'Longitude', isBold: true },
            { text: `${decToSexa(facility?.facilityCoords?.longitude, 'long')}`, textAlign: 'left' },
          ],
          [
            { text: 'Vertical Uncertainty', isBold: true },
            {
              text: `${numberWithCommasDecimals(facility?.uncert?.vertical, 2)} ${getUnitsText(UNITS_FOR.Depth)}`,
              textAlign: 'left',
            },
            { text: 'Horizontal Uncertainty', isBold: true },
            {
              text: `${numberWithCommasDecimals(facility?.uncert.horizontal, 2)} ${getUnitsText(UNITS_FOR.Depth)}`,
              textAlign: 'left',
            },
          ],
          [
            { text: 'Grid Convergence', isBold: true },
            { text: numberWithCommasDecimals(field?.gridConv, 3), textAlign: 'left' },
            { text: '' },
            { text: '' },
          ],
        ],
      },
      {
        sectionAfter: 5,
        columnWidths: ['15%', '35%', '15%', '35%'],
        manualWidth: true,
        height: 13,
        data: [
          [
            { text: 'Well', isHeader: true, width: '15%' },
            { text: well?.well, isHeader: true, width: '85%', textAlign: 'left' },
          ],
          [
            { text: 'Local North', isBold: true },
            {
              text: `${numberWithCommasDecimals(well?.wellCoords?.localNorth, 2)} ${getUnitsText(UNITS_FOR.Depth)}`,
              textAlign: 'left',
            },
            { text: 'Local East', isBold: true },
            {
              text: `${numberWithCommasDecimals(well?.wellCoords?.localEast, 2)} ${getUnitsText(UNITS_FOR.Depth)}`,
              textAlign: 'left',
            },
          ],
          [
            { text: 'Map Northing', isBold: true },
            { text: `${numberWithCommasDecimals(well?.wellCoords?.gridNorth, 2)} ${field?.crsUom}`, textAlign: 'left' },
            { text: 'Map Easting', isBold: true },
            { text: `${numberWithCommasDecimals(well?.wellCoords?.gridEast, 2)} ${field?.crsUom}`, textAlign: 'left' },
          ],
          [
            { text: 'Latitude', isBold: true },
            { text: `${decToSexa(well?.wellCoords?.latitude, 'lat')}`, textAlign: 'left' },
            { text: 'Longitude', isBold: true },
            { text: `${decToSexa(well?.wellCoords?.longitude, 'long')}`, textAlign: 'left' },
          ],
          [
            { text: 'Depth Datum', isBold: true },
            { text: actualWell?.depthDatum.datumName, textAlign: 'left' },
            { text: 'Datum Elevation', isBold: true },
            {
              text: `${numberWithCommasDecimals(actualWell?.depthDatum.datumElevation, 2)} ${getUnitsText(
                UNITS_FOR.Depth,
              )}`,
              textAlign: 'left',
            },
          ],
          [
            { text: actualWell?.depthDatum.isOffshore ? 'Water Depth' : 'GL Elevation', isBold: true },
            {
              text: `${numberWithCommasDecimals(actualWell?.depthDatum.glWaterDepth, 2)} ${getUnitsText(
                UNITS_FOR.Depth,
              )}`,
              textAlign: 'left',
            },
            { text: 'Vertical Uncertainty', isBold: true },
            {
              text: `${numberWithCommasDecimals(well?.uncert.vertical, 2)} ${getUnitsText(UNITS_FOR.Depth)}`,
              textAlign: 'left',
            },
          ],
          [
            { text: 'Grid Convergence', isBold: true },
            { text: numberWithCommasDecimals(field?.gridConv, 3), textAlign: 'left' },
            { text: 'Horizontal Uncertainty', isBold: true },
            {
              text: `${numberWithCommasDecimals(well?.uncert.horizontal, 2)} ${getUnitsText(UNITS_FOR.Depth)}`,
              textAlign: 'left',
            },
          ],
          [
            { text: 'Inc only wells vert', isBold: true },
            { text: 'Yes', textAlign: 'left' },
            { text: 'Bggm Error Estimates', isBold: true },
            { text: getResponse(operator?.useBggmErrorEstimate), textAlign: 'left' },
          ],
          [
            { text: 'Use WPTS Sep Rule', isBold: true },
            { text: getResponse(operator?.wptsData?.useWpts), textAlign: 'left' },
            { text: ' Surface Margin', isBold: true },
            { text: `${numberWithCommasDecimals(operator?.acAlerts?.surfaceMargin, 2)}`, textAlign: 'left' },
          ],
          [
            { text: 'Cone of Expansion', isBold: true },
            { text: `${numberWithCommasDecimals(operator?.acAlerts?.coneOfExpansion, 2)} /1000`, textAlign: 'left' },
            { text: 'Minimum Seperation', isBold: true },
            { text: `${numberWithCommasDecimals(operator?.wptsData?.surfaceMargin, 2)}`, textAlign: 'left' },
          ],
          [
            { text: 'Proj Ahead Uncert', isBold: true },
            { text: `${numberWithCommasDecimals(operator?.wptsData?.projectionUncert, 2)}`, textAlign: 'left' },
            { text: 'Warning levels @', isBold: true },
            { text: `${numberWithCommasDecimals(operator?.sigmaReports, 2)}`, textAlign: 'left' },
          ],
        ],
      },
      {
        sectionAfter: 5,
        columnWidths: ['15%', '35%', '15%', '35%'],
        manualWidth: true,
        height: 13,
        data: [
          [
            { text: 'Well bore', isHeader: true, width: '15%' },
            { text: actualWell?.actualWell, isHeader: true, width: '85%', textAlign: 'left' },
          ],
          [
            { text: 'Magnetic Model', isBold: true },
            { text: actualWell.magnetics.model, textAlign: 'left' },
            { text: 'Date', isBold: true },
            { text: actualWell.magnetics.date, textAlign: 'left' },
          ],
          [
            { text: 'Total Field', isBold: true },
            { text: numberWithCommasDecimals(actualWell.magnetics.bTotal, 3), textAlign: 'left' },
            { text: 'Dip Angle (°)', isBold: true },
            { text: numberWithCommasDecimals(actualWell.magnetics.dip, 3), textAlign: 'left' },
          ],
          [
            { text: 'Declination', isBold: true },
            { text: numberWithCommasDecimals(actualWell.magnetics.dec, 3), textAlign: 'left' },
            { text: '' },
            { text: '' },
          ],
          [
            { text: 'VS Origin', isBold: true },
            { text: 'Well', textAlign: 'left' },
            { text: 'VS Azimuth', isBold: true },
            {
              text: `${numberWithCommasDecimals(actualWell?.verticalSection?.azi, 2)} ${getUnitsText(UNITS_FOR.Depth)}`,
              textAlign: 'left',
            },
          ],
          [
            { text: 'VS Origin NS', isBold: true },
            {
              text: `${numberWithCommasDecimals(actualWell?.verticalSection?.ns, 2)} ${getUnitsText(UNITS_FOR.Depth)}`,
              textAlign: 'left',
            },
            { text: 'VS Origin EW', isBold: true },
            {
              text: `${numberWithCommasDecimals(actualWell?.verticalSection?.ew, 2)} ${getUnitsText(UNITS_FOR.Depth)}`,
              textAlign: 'left',
            },
          ],
        ],
      },
      {
        breakHeader: true,
        sectionAfter: 5,
        columnWidths: ['15%', '17.5%', '17.5%', '50%'],
        manualWidth: true,
        height: 13,
        data: getSurveyProgram(actualWell),
      },
      {
        sectionAfter: 5,
        columnWidths: ['40%', '7.5%', '7.5%', '7.5%', '7.5%', '7.5%', '22.5%'],
        manualWidth: true,
        height: 13,
        multiPage: true,
        multiPageRow: 1,
        data: getAcSummary(actualWell, wells),
      },
    ]

    let wellsData = getWellsData(wells)
    if (!Array.isArray(wellsData)) return docData

    docData.push(...wellsData)
    return docData
  }

  const setOffsetWellsRadius = async (data) => {
    if (!data) return false
    if (!currentWellRef.current) return false

    let payload = { ...data }
    let saveResponse = await selectOffsetRadius(payload)
    if (saveResponse.error) {
      setAcError(saveResponse?.error ? saveResponse?.error?.response?.data?.error : null)
      return false
    }

    await fetchAntiCollision()
    return true
  }

  return {
    loading,
    fetchAntiCollision,
    fetchAntiCollisionData,
    getPdfData,
    setOffsetWellsRadius,
    acError,
  }
}

export default useAntiCollision
