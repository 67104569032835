import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import ColorPickerCellEditor from 'components/common/CellEditors/ColorPickerCellEditor'
import LineCellRenderer from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/LineCellRenderer'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { Box, Checkbox, Typography } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import { appColors } from 'utils'
import { getRandomColor } from 'utils/colorFunctions'

const MarkerStyles = [
  'None',
  'Dash',
  'Plus',
  'Cross',
  'Dot',
  'Dot Solid',
  'Square',
  'Square Solid',
  'Diamond',
  'Diamond Solid',
  'Up Triangle',
  'Up Triangle Solid',
  'Down Triangle',
  'Down Triangle Solid',
]

const ChartSeriesStyleGrid = forwardRef(({ chartData }, ref) => {
  const showOffsets = useRef(chartData.showOffsets ? chartData.showOffsets : false)
  const showPrincipalPlan = useRef(chartData.showPrincipalPlan ? chartData.showPrincipalPlan : false)
  const showOffsetRandomColors = useRef(chartData.showOffsetRandomColors ? chartData.showOffsetRandomColors : false)
  const showOffsetMarkers = useRef(chartData.showOffsetMarkers ? chartData.showOffsetMarkers : false)
  const [rerender, setRerender] = useState(false)
  const seriesDataRef = useRef(chartData.series) // for holding changes not managed by the grid
  const gridApi = useRef(null)
  const { theme, getAgGridTheme } = useInnovaTheme()

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApi.current) gridApi.current.stopEditing()
    },
    getData: () => {
      let gridData = []
      gridApi.current?.forEachNode((node) => {
        if (node.data) gridData.push(node.data)
      })
      let seriesData = []
      chartData.series.forEach((series, idx) => {
        let data = gridData.find((data) => data.wellName === series.wellName)
        if (data) {
          data.type = series.type
          data.color = showOffsetRandomColors.current ? series.randomColor : data.color
          data.randomColor = series.randomColor
          data.defaultColor = series.defaultColor
          data.isPrincipal = series.isPrincipal
          seriesData.push(data)
        } else {
          seriesData.push({ ...series, randomColor: seriesDataRef.current[idx].randomColor })
        }
      })
      return {
        props: [
          { tag: 'showOffsets', value: showOffsets.current },
          { tag: 'showPrincipalPlan', value: showPrincipalPlan.current },
          { tag: 'showOffsetRandomColors', value: showOffsetRandomColors.current },
          { tag: 'showOffsetMarkers', value: showOffsetMarkers.current },
        ],
        series: seriesData,
      }
    },
  }))

  const getSeriesColor = (series) => {
    if (!series) return '#008800'
    if (showOffsetRandomColors.current === true) {
      const idx = seriesDataRef.current.findIndex((s) => s.wellName === series.wellName)
      if (idx > -1) return seriesDataRef.current[idx].randomColor
    }
    return series.color ? series.color : series.defaultColor || '#008800'
  }

  const getRowData = (data) => {
    let rowData = []
    if (!data) return rowData
    rowData.push({
      id: 0,
      wellName: data.series[0].wellName,
      type: data.series[0].type,
      color: getSeriesColor(data.series[0]),
      defaultColor: data.series[0].defaultColor,
      visible: data.series[0].visible === false ? false : true, // default to true if prop doesn't exist yet
      lineStyle: data.series[0].lineStyle ? data.series[0].lineStyle : 'solid',
      lineThickness: data.series[0].lineThickness ? data.series[0].lineThickness : 1,
      marker: data.series[0].marker ? data.series[0].marker : MarkerStyles[5], // 'Dot Solid'
    })

    if (showOffsets.current) {
      for (let i = 1; i < data.series.length; i++) {
        rowData.push({
          id: i,
          wellName: data.series[i].wellName,
          type: data.series[i].type,
          color: getSeriesColor(data.series[i]),
          visible: data.series[i].visible === false ? false : true, // default to true if prop doesn't exist yet
          lineStyle: data.series[i].lineStyle ? data.series[i].lineStyle : 'solid',
          lineThickness: data.series[i].lineThickness ? data.series[i].lineThickness : 1,
          marker: data.series[i].marker
            ? data.series[i].marker
            : MarkerStyles[((i - 1 + MarkerStyles.length) % (MarkerStyles.length - 1)) + 1], // rotate through the marker styles, avoiding 'None'
        })
      }
    }
    if (showPrincipalPlan.current) {
      for (let i = 1; i < data.series.length; i++) {
        if (data.series[i].isPrincipal) {
          rowData.push({
            id: i,
            wellName: data.series[i].wellName,
            type: data.series[i].type,
            color: getSeriesColor(data.series[i]),
            visible: data.series[i].visible === false ? false : true, // default to true if prop doesn't exist yet
            lineStyle: data.series[i].lineStyle ? data.series[i].lineStyle : 'solid',
            lineThickness: data.series[i].lineThickness ? data.series[i].lineThickness : 1,
            marker: data.series[i].marker
              ? data.series[i].marker
              : MarkerStyles[((i - 1 + MarkerStyles.length) % (MarkerStyles.length - 1)) + 1], // rotate through the marker styles, avoiding 'None'
          })
        }
      }
    }
    return rowData
  }

  useEffect(() => {
    if (gridApi.current) {
      gridApi.current.setGridOption('rowData', getRowData(chartData))
    }
  }, [rerender]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
    gridApi.current.setGridOption('rowData', getRowData(chartData))
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const toggleOffsetWells = () => {
    showOffsets.current = !showOffsets.current
    if (showPrincipalPlan.current) showPrincipalPlan.current = false
    setRerender((prev) => !prev)
  }

  const togglePrincipalPlan = () => {
    showPrincipalPlan.current = !showPrincipalPlan.current
    if (showOffsets.current) showOffsets.current = false
    setRerender((prev) => !prev)
  }

  const toggleOffsetRandomColors = () => {
    showOffsetRandomColors.current = !showOffsetRandomColors.current
    if (showOffsetRandomColors.current === true) {
      seriesDataRef.current.forEach((series, i) => {
        if (!series.randomColor || series.randomColor === '') {
          series.randomColor = getRandomColor()
        }
      })
    } else {
      seriesDataRef.current.forEach((series, i) => {
        series.color = series.defaultColor
      })
    }
    setRerender((prev) => !prev)
  }

  const toggleOffsetMarkers = () => {
    showOffsetMarkers.current = !showOffsetMarkers.current
    setRerender((prev) => !prev)
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
    }),
    [],
  )

  const allRowsChecked = (colKey) => {
    let allChecked = true
    gridApi.current?.forEachNode((node) => {
      if (node.data.wellName !== chartData.series[0].wellName) {
        if (!node.data[colKey]) allChecked = false
      }
    })
    return allChecked
  }

  const noRowsChecked = (colKey) => {
    let noneChecked = true
    gridApi.current?.forEachNode((node) => {
      if (node.data.wellName !== chartData.series[0].wellName) {
        if (node.data[colKey]) noneChecked = false
      }
    })
    return noneChecked
  }

  const toggleColumn = (colKey, checked) => {
    gridApi.current?.forEachNode((node) => {
      if (node.data.wellName !== chartData.series[0].wellName) {
        node.setDataValue(colKey, checked)
      }
    })
  }

  const HeaderCheckbox = ({ toggleColumn, colKey, displayName }) => {
    let allChecked = allRowsChecked(colKey)
    const noneChecked = noRowsChecked(colKey)
    const isIndeterminate = !allChecked && !noneChecked

    const handleChange = (event) => {
      toggleColumn(colKey, event.target.checked)
      allChecked = event.target.checked
      gridApi.current.refreshCells()
      gridApi.current.refreshHeader()
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <input
          id={displayName}
          type='checkbox'
          checked={allChecked}
          ref={(el) => {
            if (el) el.indeterminate = isIndeterminate
          }}
          onChange={handleChange}
        />
        {displayName}
      </Box>
    )
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Well',
        field: 'wellName',
        colId: 'wellName',
        pinned: 'left',
        lockPosition: 'left',
        autoHeight: true,
        minWidth: 100,
        width: 200,
        maxWidth: 250,
        cellStyle: leftAlignCell,
      },
      {
        headerName: 'Type',
        field: 'type',
        colId: 'type',
        minWidth: 100,
        width: 200,
        maxWidth: 250,
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'Color',
        field: 'color',
        colId: 'color',
        minWidth: 72,
        width: 72,
        maxWidth: 72,
        flex: 1,
        editable: !showOffsetRandomColors.current,
        cellDataType: false,
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            backgroundColor: params.value,
            color: params.value,
          }
        },
        cellEditorSelector: (params) => {
          return {
            component: ColorPickerCellEditor,
            params: {
              color: params.value,
              defaultColor: params.data?.defaultColor,
            },
            popup: true,
          }
        },
      },
      {
        headerName: 'Visible',
        field: 'visible',
        colId: 'visible',
        flex: 1,
        width: 100,
        editable: (params) => {
          return params.data?.wellName !== chartData.series[0].wellName
        },
        cellStyle: centerAlignCell,
        headerComponent: HeaderCheckbox,
        headerComponentParams: {
          colKey: 'visible',
          toggleColumn,
        },
        cellEditorSelector: () => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
      {
        headerName: 'Line',
        field: 'lineStyle',
        colId: 'lineStyle',
        width: 100,
        flex: 1,
        editable: true,
        cellDataType: false,

        cellStyle: centerAlignCell,
        cellEditorSelector: () => {
          return {
            component: 'agRichSelectCellEditor',
            popup: true,
            params: {
              cellRenderer: LineCellRenderer,
              values: ['solid', 'dotted', 'dashed'],
              valueListMaxHeight: 125,
            },
          }
        },
        cellRendererSelector: () => {
          return {
            component: LineCellRenderer,
          }
        },
      },
      {
        headerName: 'Thickness',
        field: 'lineThickness',
        colId: 'lineThickness',
        width: 100,
        maxWidth: 100,
        flex: 1,
        editable: true,
        cellDataType: false,
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'Marker',
        field: 'marker',
        colId: 'marker',
        width: 100,
        maxWidth: 100,
        flex: 1,
        editable: true,
        cellDataType: false,
        cellStyle: centerAlignCell,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: [
            'None',
            'Dash',
            'Plus',
            'Cross',
            'Dot',
            'Dot Solid',
            'Square',
            'Square Solid',
            'Diamond',
            'Diamond Solid',
            'Up Triangle',
            'Up Triangle Solid',
            'Down Triangle',
            'Down Triangle Solid',
          ],
        },
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setAllVisible = (visible) => {
    gridApi.current.forEachNode((node) => {
      if (node.data.wellName !== chartData.series[0].wellName) {
        node.setDataValue('visible', visible)
      }
    })
  }

  const getContextMenuItems = (params) => {
    var result = ['copy', 'paste', 'separator', 'export']

    if (params.column.getColId() === 'visible') {
      result.push(
        {
          name: 'Set All Visible',
          action: () => setAllVisible(true),
          icon: '<span class="iconify" data-icon="ep:select" data-width="20" style="color:#00FF00"></span>',
          cssClasses: ['leftAlign'],
        },
        {
          name: 'Set None Visible',
          action: () => setAllVisible(false),
          icon: '<span class="iconify" data-icon="maki:cross" data-width="20" style="color:#F00F00"></span>',
          cssClasses: ['leftAlign'],
        },
      )
    }

    return result
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          marginBottom: '5px',
          width: '100%',
        }}>
        <Checkbox
          checked={showOffsets.current}
          onChange={toggleOffsetWells}
          checkedIcon={
            <RadioButtonCheckedIcon fontSize='small' style={{ color: theme === 'dark' ? 'lime' : 'green' }} />
          }
          indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}></Checkbox>
        <Typography
          sx={{
            color: appColors.itemTextColor,
            marginRight: '16px',
          }}>
          Show Offset Wells
        </Typography>
        <Checkbox
          checked={showPrincipalPlan.current}
          onChange={togglePrincipalPlan}
          checkedIcon={
            <RadioButtonCheckedIcon fontSize='small' style={{ color: theme === 'dark' ? 'lime' : 'green' }} />
          }
          indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}></Checkbox>
        <Typography
          sx={{
            color: appColors.itemTextColor,
            marginRight: '16px',
          }}>
          Show Principal Plan Only
        </Typography>
        <Checkbox
          checked={showOffsetRandomColors.current}
          onChange={toggleOffsetRandomColors}
          checkedIcon={
            <RadioButtonCheckedIcon fontSize='small' style={{ color: theme === 'dark' ? 'lime' : 'green' }} />
          }
          indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}></Checkbox>
        <Typography
          sx={{
            color: appColors.itemTextColor,
            marginRight: '16px',
          }}>
          Randomize Colors
        </Typography>
        <Checkbox
          checked={showOffsetMarkers.current}
          onChange={toggleOffsetMarkers}
          checkedIcon={
            <RadioButtonCheckedIcon fontSize='small' style={{ color: theme === 'dark' ? 'lime' : 'green' }} />
          }
          indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}></Checkbox>
        <Typography
          sx={{
            color: appColors.itemTextColor,
            marginRight: '16px',
          }}>
          Show Markers
        </Typography>
      </Box>
      <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
        <AgGridReact
          className='ag-grid-theme-dark'
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          headerHeight={30}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
          getContextMenuItems={getContextMenuItems}
        />
      </div>
    </>
  )
})

export default ChartSeriesStyleGrid
