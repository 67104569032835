import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material'
import { cloneDeep } from 'lodash'
import StylesGrid from './StylesGrid'
import { ELEMENT_TYPES as elementType } from 'components/WellPages/WallplotComposer/Elements/elementDefs'
import { gridDataToStyles } from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/propertyUtils'
import ImageGrid from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ImageGrid'
import ChartOptionsGrid from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ChartOptionsGrid'
import ChartScalesGrid from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ChartScalesGrid'
import ChartSeriesStyleGrid from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ChartSeriesStyleGrid'
import ChartAxesXYGrid from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ChartAxesXYGrid'
import ChartLabelsGrid from './ChartLabelsGrid'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import ChartTargetsGrid from './ChartTargetsGrid'
import { LABEL_CATEGORIES } from '../ChartElements/labelDefinitions'

const STYLE_TAB = 'style' // used for both the style tab, as well as for assigning/extracting data from the dataRef object
const CONTEXT_TAB = 'context' // used for both the images tab as well as for assigning/extracting data from the dataRef object, originally also a tab
const OPTIONS_TAB = 'options'
const SCALES_TAB = 'scales'
const SERIES_TAB = 'series'
const AXESXY_TAB = 'axesXY'
const TARGETS_TAB = 'targets'
const LABELS_TAB = 'labels'

const ElementPropertyModal = ({ onClose, onApply, elementStyle, contextData, elemType, elemSubType }) => {
  const gridRef = React.useRef(null)
  const dataRef = React.useRef({ style: cloneDeep(elementStyle), context: cloneDeep(contextData) })
  const [tabSelection, setTabSelection] = React.useState(STYLE_TAB)
  const { theme } = useInnovaTheme()

  const handleUpdate = (updatedData, sourceType) => {
    if (!updatedData) return
    switch (sourceType) {
      case elementType.text:
        dataRef.current = cloneDeep(updatedData)
        break
      case elementType.image:
        break
      default:
        break
    }
  }

  const getGridData = () => {
    let data = {}

    // get the data from the current active grid
    getTabGridData()

    // perform any conversions needed
    data[STYLE_TAB] = gridDataToStyles(dataRef.current[STYLE_TAB])
    data[CONTEXT_TAB] = dataRef.current[CONTEXT_TAB]

    return data
  }

  const getTabGridData = () => {
    let gridData = []

    if (gridRef.current) gridData = gridRef.current.getData()
    let data = {}
    if (gridData.hasOwnProperty('props')) {
      for (let i = 0; i < gridData.props.length; i++) {
        if (gridData.props[i].hasOwnProperty('tag') && gridData.props[i].hasOwnProperty('value')) {
          data[gridData.props[i].tag] = gridData.props[i].value
        }
      }
    }
    if (gridData.hasOwnProperty('series')) {
      data.series = [...gridData.series]
    }
    if (tabSelection === LABELS_TAB) {
      data.labels = gridData.labels
    }

    if (gridData.hasOwnProperty('targets')) {
      data.targets = gridData.targets
      if (gridData.labels?.categories?.length > 0) {
        data.labels = {
          categories: [...dataRef.current.context.labels.categories, ...gridData.labels.categories],
          categoryOptions: [...dataRef.current.context.labels.categoryOptions],
          labelData: [...dataRef.current.context.labels.labelData, ...gridData.labels.labelData],
        }
      }
    }
    if (gridData.hasOwnProperty('errorEllipses')) {
      data.errorEllipses = gridData.errorEllipses
    }

    const mergedObject = {
      ...dataRef.current[tabSelection === STYLE_TAB ? STYLE_TAB : CONTEXT_TAB],
      ...data,
    }

    // apply cross-tab interactions here
    if (tabSelection === TARGETS_TAB) {
      if (mergedObject.hasOwnProperty('showTargetNames')) {
        if (mergedObject.showTargetNames === false) {
          mergedObject.labels.categories = mergedObject.labels.categories.filter(
            (cat) => cat.category !== LABEL_CATEGORIES.targets,
          )
          mergedObject.labels.labelData = mergedObject.labels.labelData.filter(
            (label) => label.catType !== LABEL_CATEGORIES.targets,
          )
        }
      }
      if (mergedObject.hasOwnProperty('showLeaseLineNames')) {
        if (mergedObject.showLeaseLineNames === false) {
          let filtered = mergedObject.labels.categories.filter((cat) => cat.category !== LABEL_CATEGORIES.leaseLines)
          mergedObject.labels.categories = filtered
          if (mergedObject.labels?.labelData?.length > 0) {
            filtered = mergedObject.labels.labelData.filter((label) => label.catType !== LABEL_CATEGORIES.leaseLines)
            mergedObject.labels.labelData = filtered
          }
        }
      }
    }
    dataRef.current[tabSelection === STYLE_TAB ? STYLE_TAB : CONTEXT_TAB] = mergedObject
  }

  const TabHeader = ({ tabData }) => {
    if (!tabData) return null
    if (!tabData.visible) return null
    const backColorActive = theme === 'dark' ? '#FFFFFF' : '#222222'
    const colorActive = theme === 'dark' ? '#181d1f' : '#FFFFFF'
    const backColor = theme === 'dark' ? '#181d1f' : '#DDDDDD'
    const color = theme === 'dark' ? '#FFFFFF' : '#181d1f'

    return (
      <Box
        sx={{
          maxWidth: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: '5px',
          paddingRight: '10px',
          fontWeight: tabSelection === tabData.tag ? 'bold' : 'normal',
          backgroundColor: tabSelection === tabData.tag ? backColorActive : backColor,
          color: tabSelection === tabData.tag ? colorActive : color,
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={tabData.onClick}>
        {tabData.label}
      </Box>
    )
  }

  const handleTabClick = (selectedTab) => {
    gridRef.current.stopEditing()
    getTabGridData()
    setTabSelection(selectedTab)
  }

  const getTabs = (type) => {
    let tabs = [
      {
        label: 'Style',
        onClick: () => {
          handleTabClick(STYLE_TAB)
        },
        tag: STYLE_TAB,
        visible: true,
      },
    ]
    if (type === elementType.image) {
      tabs.push({
        label: 'Image',
        onClick: () => {
          handleTabClick(CONTEXT_TAB)
        },
        tag: CONTEXT_TAB,
        visible: contextData ? true : false,
      })
    }
    if (type === elementType.chart) {
      tabs.push({
        label: 'Options',
        onClick: () => {
          handleTabClick(OPTIONS_TAB)
        },
        tag: OPTIONS_TAB,
        visible: contextData ? true : false,
      })
      tabs.push({
        label: 'Scales',
        onClick: () => {
          handleTabClick(SCALES_TAB)
        },
        tag: SCALES_TAB,
        visible: contextData ? true : false,
      })
      tabs.push({
        label: 'Well Paths',
        onClick: () => {
          handleTabClick(SERIES_TAB)
        },
        tag: SERIES_TAB,
        visible: contextData ? true : false,
      })
      tabs.push({
        label: 'Axes',
        onClick: () => {
          handleTabClick(AXESXY_TAB)
        },
        tag: AXESXY_TAB,
        visible: contextData ? true : false,
      })
      tabs.push({
        label: 'Targets',
        onClick: () => {
          handleTabClick(TARGETS_TAB)
        },
        tag: TARGETS_TAB,
        visible: contextData ? true : false,
      })
      tabs.push({
        label: 'Labels',
        onClick: () => {
          handleTabClick(LABELS_TAB)
        },
        tag: LABELS_TAB,
        visible: contextData ? true : false,
      })
    }

    return tabs
  }

  const getContextTabGrid = (type) => {
    switch (type) {
      case elementType.text:
        return null
      case elementType.image:
        return <ImageGrid imageData={dataRef.current.context} handleUpdate={handleUpdate} ref={gridRef} />
      default:
        return null
    }
  }

  const ActiveTab = ({ activeTab }) => {
    if (!activeTab) return null
    if (activeTab === STYLE_TAB)
      return <StylesGrid elementStyle={elementStyle} handleUpdate={handleUpdate} ref={gridRef} />
    if (activeTab === CONTEXT_TAB) return getContextTabGrid(elemType)
    if (activeTab === 'options')
      return (
        <ChartOptionsGrid
          chartData={dataRef.current.context}
          handleUpdate={handleUpdate}
          ref={gridRef}
          chartType={elemSubType}
        />
      )
    if (activeTab === 'scales')
      return <ChartScalesGrid chartData={dataRef.current.context} handleUpdate={handleUpdate} ref={gridRef} />
    if (activeTab === 'series')
      return (
        <ChartSeriesStyleGrid
          chartData={dataRef.current.context}
          handleUpdate={handleUpdate}
          ref={gridRef}
          chartType={elemSubType}
        />
      )
    if (activeTab === 'axesXY') return <ChartAxesXYGrid chartData={dataRef.current.context} ref={gridRef} />
    if (activeTab === 'targets') return <ChartTargetsGrid chartData={dataRef.current.context} ref={gridRef} />
    if (activeTab === 'labels')
      return <ChartLabelsGrid chartData={dataRef.current.context} ref={gridRef} chartType={elemSubType} />
    return null
  }

  const handleRightClick = (event) => {
    event.stopPropagation()
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      onContextMenu={handleRightClick}
      sx={{ zIndex: 9999 }}
      PaperProps={{
        sx: {
          minWidth: '600px',
          maxWidth: '1000px',
          width: '80vw',
          minHeight: '600px',
          height: '50vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>Properties</DialogTitle>
      <DialogContent
        sx={{
          padding: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            height: '32px',
            width: '100%',
          }}>
          {getTabs(elemType).map((tab, index) => (
            <TabHeader key={index} tabData={tab} />
          ))}
        </Box>
        <ActiveTab activeTab={tabSelection} />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose} color='secondary'>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          onClick={() => {
            // ensure color picker change is applied
            gridRef.current.stopEditing()
            onApply(getGridData())
          }}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ElementPropertyModal
