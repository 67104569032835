import { useEffect, useRef, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import DrillStringGrid from './DrillStringGrid'
import { currentWellAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Icon as Iconify } from '@iconify/react'
import DrillStringRecommendationsPane from './DrillStringRecommendationsPane'

const BOX_STYLE = {
  paddingLeft: '10px',
  paddingRight: '10px',
  borderLeft: '1px solid gray',
  borderRight: '1px solid gray',
  cursor: 'pointer',
  '&:hover': {
    color: '#429ceb',
  },
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordWrap: 'nowrap',
  height: '25px',
}

const DrillStringPane = ({
  showHideEditor,
  showEditor,
  drillStringData,
  isLoading,
  setFilteredData,
  onBhaPdfExport,
  onMotorReportPdfExport,
  setShowChart,
  showChartRef,
  onXlsExport,
  onIaddExport,
  onIaddXlsExport,
  refresh,
  importBha,
  setStatus,
  setImportBha,
  recommendations,
  fetchRecommendedDrillStrings,
  recommendationFilterData,
  setRecommendationFilters,
  totalRecommendations,
}) => {
  const _isMounted = useRef(false)
  const { getChartBackColor, getWindowBarColor } = useInnovaTheme()
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const [selectedEditor, setSelectedEditor] = useState('drillString')
  const [showFilter, setShowFilter] = useState(false)

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (!showEditor) {
    }
  }, [showEditor])

  const DrillString = useMemo(
    () => (
      <DrillStringGrid
        gridData={drillStringData}
        isLoading={isLoading}
        setFilteredData={setFilteredData}
        onBhaPdfExport={onBhaPdfExport}
        onMotorReportPdfExport={onMotorReportPdfExport}
        setStatus={setStatus}
        setShowChart={setShowChart}
        showChart={showChartRef}
        wellName={currentWell}
        onXlsExport={onXlsExport}
        onIaddExport={onIaddExport}
        onIaddXlsExport={onIaddXlsExport}
        refresh={refresh}
        importBha={importBha}
        setImportBha={setImportBha}
      />
    ),
    [drillStringData, isLoading, currentWell, importBha, setImportBha], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const showHideFilter = () => {
    setShowFilter(!showFilter)
  }

  const refreshRecommendations = () => {
    fetchRecommendedDrillStrings()
  }

  return (
    <Box sx={{ width: '100%', height: '100%', backgroundColor: getChartBackColor() }}>
      <Box
        sx={{
          width: '100%',
          height: '25px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          backgroundColor: getWindowBarColor(),
        }}>
        <Box
          sx={BOX_STYLE}
          color={selectedEditor === 'drillString' ? '#429ceb' : '#f0f0f0'}
          onClick={() => {
            setSelectedEditor('drillString')
            setShowChart(true)
            if (!showEditor) showHideEditor()
          }}>
          Drill String
        </Box>
        <Box
          sx={BOX_STYLE}
          color={selectedEditor === 'recommendations' ? '#429ceb' : '#f0f0f0'}
          onClick={() => {
            setSelectedEditor('recommendations')
            setShowChart(false)
            fetchRecommendedDrillStrings()
            if (!showEditor) showHideEditor()
          }}>
          Recommendations
        </Box>
        <Box
          onClick={showHideEditor}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: '#222628',
            cursor: 'pointer',
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
      </Box>
      {selectedEditor === 'drillString' && DrillString}
      {selectedEditor === 'recommendations' && (
        <DrillStringRecommendationsPane
          setStatus={setStatus}
          setImportBha={setImportBha}
          recommendations={recommendations}
          recommendationFilterData={recommendationFilterData}
          setRecommendationFilters={setRecommendationFilters}
          showHideFilter={showHideFilter}
          showFilter={showFilter}
          currentWell={currentWell}
          refreshRecommendations={refreshRecommendations}
          totalRecommendations={totalRecommendations}
          isLoading={isLoading}
        />
      )}
    </Box>
  )
}
export default DrillStringPane
