import { pointInterp, pointInterpTVD, calcVS } from 'utils/surveyFunctions'
import { getTargets2D } from 'components/WellPages/PlanView/targets2D'
import { roundUp } from 'utils/numberFunctions'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { CHART_TYPES as chartType } from '../elementDefs'

// annotationPlugin properties:
export const DEFAULT_ANNO_LABEL = {
  type: 'label',
  xValue: 2.5,
  yValue: 60,
  backgroundColor: 'rgba(245,245,245)',
  content: ['This is my text', 'This is my text, second line'],
  font: {
    size: 18,
  },
}

export const DEFAULT_ANNO_PROPS = {
  anchor: {
    x: 0,
    y: 0,
  },
  currentPos: {
    x: 0,
    y: 0,
  },
  tether: {
    enabled: false,
    style: {
      color: 'rgba(0,0,0)',
      lineWidth: 1,
      lineDash: [],
    },
  },
  plugInProps: {
    ...DEFAULT_ANNO_LABEL,
  },
}

export const LABEL_CATEGORIES = {
  surveyPts: 'Depth, Inc, Azi',
  wellNames: 'Well Names',
  casing: 'Casing',
  annotations: 'Annotations',
  targets: 'Targets',
  leaseLines: 'Lease Lines',
  lithologies: 'Lithologies',
  offsetWellNames: 'Offset Well Names',
  offsetSurveyPts: 'Offset Depth, Inc, Azi',
  userDefined: 'User Defined',
}

export const hasLabelCategory = (category, labels) => {
  if (!labels || !labels.categories) return false
  return labels.categories.some((cat) => cat.category === category)
}

export const getLabelAnnoDefaults = (content) => {
  return {
    type: 'label',
    callout: {
      display: true,
      borderColor: '#AAA',
    },
    content: content,
    font: {
      size: 10,
    },
  }
}

export const extractLabelUids = (input, labelSeparator) => {
  let separator = labelSeparator || '-'
  const lastDashIndex = input.lastIndexOf(separator)

  if (lastDashIndex === -1) {
    return [input, '']
  }
  const firstPart = input.slice(0, lastDashIndex)
  const secondPart = input.slice(lastDashIndex + 1)

  return [firstPart, secondPart]
}

export const getLabelPosByMd = (labelDepth, chartType, surveys, verticalSection) => {
  if (!surveys || surveys.length === 0) return { x: 0, y: 0 }
  const { VsAzi, VsOrgNorth, VsOrgEast } = verticalSection
  const { md, ew, ns, tvd, inc, azi } = pointInterp(surveys, labelDepth)
  if (md < 0) return { x: 0, y: 0 }
  // const vs = calcVS(VsAzi, VsOrgNorth, VsOrgEast, ew, ns)
  const vs = calcVS(VsAzi, ns, ew, VsOrgNorth, VsOrgEast)

  return {
    x: chartType === 'plan' ? ew : vs,
    y: chartType === 'plan' ? ns : tvd,
    azi: azi,
    inc: inc,
  }
}

export const getLastVisibleSurveyPt = (surveys, visibleLimits, chartType) => {
  if (surveys?.length < 1) return { ew: 0, ns: 0, vs: 0, tvd: 0 }
  // visible limits should be {xMin, xMax, yMin, yMax}
  for (let i = surveys.length - 1; i >= 0; i--) {
    if (chartType === 'plan') {
      if (
        surveys[i].ew >= visibleLimits.xMin &&
        surveys[i].ew <= visibleLimits.xMax &&
        surveys[i].ns >= visibleLimits.yMin &&
        surveys[i].ns <= visibleLimits.yMax
      )
        return surveys[i]
    } else {
      if (
        surveys[i].vs >= visibleLimits.xMin &&
        surveys[i].vs <= visibleLimits.xMax &&
        surveys[i].tvd >= visibleLimits.yMin &&
        surveys[i].tvd <= visibleLimits.yMax
      )
        return surveys[i]
    }
  }
  return surveys[surveys.length - 1]
}

export const getPositionValues = (catType, id, wellData, settings) => {
  if (!wellData || !id || !catType) return 0
  let xValue = 0
  let yValue = 0
  let wellName = ''
  let md = 0
  let surveys = []
  let labelUidData = []
  let svyPt = null
  let offsetWell = null

  switch (catType) {
    case LABEL_CATEGORIES.surveyPts:
      labelUidData = extractLabelUids(id)
      wellName = labelUidData[0]
      md = parseFloat(labelUidData[1])
      if (wellName === wellData.actualWellData.actualWellName) {
        surveys = wellData.surveys
      }
      svyPt = surveys.find((survey) => survey.md === md)
      if (svyPt) {
        xValue = settings?.chartType === 'plan' ? svyPt.ew : svyPt.vs
        yValue = settings?.chartType === 'plan' ? svyPt.ns : svyPt.tvd
      }
      break
    case LABEL_CATEGORIES.wellNames:
      wellName = id
      if (wellName === wellData.actualWellData.actualWellName) {
        surveys = wellData.surveys
      }
      const lastVisibleSurvey = getLastVisibleSurveyPt(
        wellData.surveys,
        {
          xMin: settings?.xMin,
          xMax: settings?.xMax,
          yMin: settings?.yMin,
          yMax: settings?.yMax,
        },
        settings?.chartType,
      )

      xValue = settings?.chartType === 'plan' ? lastVisibleSurvey.ew : lastVisibleSurvey.vs
      yValue = settings?.chartType === 'plan' ? lastVisibleSurvey.ns : lastVisibleSurvey.tvd
      break
    case LABEL_CATEGORIES.casing:
      labelUidData = extractLabelUids(id)
      md = parseFloat(labelUidData[1])
      const casingPos = getLabelPosByMd(
        md,
        settings?.chartType,
        wellData.surveys,
        wellData.actualWellData.verticalSection,
      )
      xValue = casingPos.x
      yValue = casingPos.y
      break
    case LABEL_CATEGORIES.annotations:
      labelUidData = extractLabelUids(id)
      md = parseFloat(labelUidData[1])
      const annoPos = getLabelPosByMd(
        md,
        settings?.chartType,
        wellData.surveys,
        wellData.actualWellData.verticalSection,
      )
      xValue = annoPos.x
      yValue = annoPos.y
      break
    case LABEL_CATEGORIES.targets:
    case LABEL_CATEGORIES.leaseLines:
      labelUidData = extractLabelUids(id, '|')
      const targetName = labelUidData[1]
      const targetData = []
      getTargets2D(wellData.targets, targetData, settings?.chartType, wellData?.actualWellData?.verticalSection, true)
      const target = targetData.find((tgt) => tgt.text === targetName)
      if (target) {
        xValue = target.x
        yValue = target.y
      }
      break
    case LABEL_CATEGORIES.lithologies:
      labelUidData = extractLabelUids(id)
      let tvd = parseFloat(labelUidData[1])

      xValue = settings.xMin
      yValue = tvd
      break
    case LABEL_CATEGORIES.offsetWellNames:
      wellName = id
      offsetWell = wellData.offsetWells.find((well) => well.offsetWellbore === wellName)
      if (offsetWell) {
        xValue =
          settings?.chartType === 'plan'
            ? offsetWell.surveyData[offsetWell.surveyData.length - 1].ew
            : offsetWell.surveyData[offsetWell.surveyData.length - 1].vs
        yValue =
          settings?.chartType === 'plan'
            ? offsetWell.surveyData[offsetWell.surveyData.length - 1].ns
            : offsetWell.surveyData[offsetWell.surveyData.length - 1].tvd
      }
      break
    case LABEL_CATEGORIES.offsetSurveyPts:
      labelUidData = extractLabelUids(id)
      wellName = labelUidData[0]
      md = parseFloat(labelUidData[1])
      offsetWell = wellData.offsetWells.find((well) => well.offsetWellbore === wellName)
      if (offsetWell) {
        svyPt = offsetWell.surveyData.find((survey) => survey.md === md)
        if (svyPt) {
          xValue = settings?.chartType === 'plan' ? svyPt.ew : svyPt.vs
          yValue = settings?.chartType === 'plan' ? svyPt.ns : svyPt.tvd
        }
      }
      break
    default:
      console.warn('[Innova] unknown label id position', catType)
      break
  }

  return { xValue, yValue }
}

export const getLabelContent = (label, wellData, chartType) => {
  if (!label || !wellData) return []
  const { catType, uid } = label
  let content = []
  let wellName = ''
  let labelUidData = []
  let md = 0
  let offsetWell = null

  if (label.content && label.content.length > 0) {
    return label.content
  }

  switch (catType) {
    case LABEL_CATEGORIES.surveyPts:
      labelUidData = extractLabelUids(uid)
      wellName = labelUidData[0]
      md = parseFloat(labelUidData[1])
      if (wellName === wellData.actualWellData.actualWellName) {
        const surveys = wellData.surveys
        const svyPt = surveys.find((survey) => survey.md === md)
        console.warn('NEED NEW DEPTH LABEL ALGORITHM')
        if (svyPt) {
          content.push(`MD: ${numberWithCommasDecimals(svyPt.md, 0)}, INC: ${svyPt.inc}, AZI: ${svyPt.azi}`)
        }
      }
      break
    case LABEL_CATEGORIES.wellNames:
      wellName = uid
      if (wellName === wellData.actualWellData.actualWellName) {
        content.push(wellName)
      }
      break
    case LABEL_CATEGORIES.casing:
      labelUidData = extractLabelUids(uid)
      md = parseFloat(labelUidData[1])
      const casingItem = wellData.actualWellData.casing.find((casing) => casing.MD === md)
      if (casingItem) {
        content.push(`${casingItem.OD.toFixed(3)} ${casingItem.Type}`)
      }
      break
    case LABEL_CATEGORIES.annotations:
      labelUidData = extractLabelUids(uid)
      md = parseFloat(labelUidData[1])
      if (Array.isArray(wellData?.actualWellData?.annotations)) {
        const annoItem = wellData.actualWellData.annotations.find((anno) => anno.md === md)
        if (annoItem) {
          content.push(`${annoItem.annotation}`)
        }
      }
      break
    case LABEL_CATEGORIES.targets:
    case LABEL_CATEGORIES.leaseLines:
      labelUidData = extractLabelUids(uid, '|')
      const targetName = labelUidData[1]
      const targetData = []
      getTargets2D(wellData.targets, targetData, chartType, wellData?.actualWellData?.verticalSection, true)
      const target = targetData.find((tgt) => tgt.text === targetName)
      if (target) {
        content.push(`${target.text}`)
      }
      break
    case LABEL_CATEGORIES.lithologies:
      labelUidData = extractLabelUids(uid)
      let tvd = parseFloat(labelUidData[1])
      const lithItem = wellData.actualWellData.lithologies.find((lith) => lith.tvd === tvd)
      if (lithItem) {
        content.push(`${lithItem.formation}`)
      }
      break
    case LABEL_CATEGORIES.offsetWellNames:
      wellName = uid
      offsetWell = wellData.offsetWells.find((well) => well.offsetWellbore === wellName)
      if (offsetWell) {
        content.push(wellName)
      }
      break
    case LABEL_CATEGORIES.offsetSurveyPts:
      labelUidData = extractLabelUids(uid)
      wellName = labelUidData[0]
      md = parseFloat(labelUidData[1])
      offsetWell = wellData.offsetWells.find((well) => well.offsetWellbore === wellName)
      if (offsetWell) {
        const svyPt = offsetWell.surveyData.find((survey) => survey.md === md)
        if (svyPt) {
          content.push(`MD: ${numberWithCommasDecimals(svyPt.md, 0)}, INC: ${svyPt.inc}, AZI: ${svyPt.azi}`)
        }
      }
      break
    default:
      console.warn('[Innova] unknown label id content', catType)
      break
  }

  return content
}

export const getLabelColor = (categories, label) => {
  if (label.style?.color) return label.style.color // if the label is customized, return custom color
  const category = categories.find((cat) => cat.category === label.catType)
  return category?.color ? category.color : '#000000' // if the category has a color, return it, otherwise default
}

export const getHasTether = (categories, label) => {
  if (label.hasTether) return label.hasTether
  const category = categories.find((cat) => cat.category === label.catType)
  return category?.hasTether ? category.hasTether : false
}

export const getLabelSize = (categories, label) => {
  if (label.style?.font?.size) return label.style.font.size
  const category = categories.find((cat) => cat.category === label.catType)
  return category?.font?.size ? category.font.size : 10
}

export const getLabelBold = (categories, label) => {
  if (label.style?.font?.bold) return label.style.font.bold
  const category = categories.find((cat) => cat.category === label.catType)
  return category?.bold ? category.bold : false
}

const createSvyLabel = (svy, options) => {
  if (!svy) return ''
  let mdText = `MD: ${numberWithCommasDecimals(svy.md, 0)} `
  let tvdText = `TVD: ${numberWithCommasDecimals(svy.tvd, 2)} `
  let incText = `INC: ${numberWithCommasDecimals(svy.inc, 2)} `
  let aziText = `AZI: ${numberWithCommasDecimals(svy.azi, 2)} `

  if (!options) return mdText + incText + aziText
  let text = options.mdTvd === 'MD' ? mdText : tvdText
  if (options.inc) text += incText
  if (options.azi) text += aziText
  return text
}

export const generateDepthLabels = (labelCategory, surveyData, verticalSection, catOptions) => {
  let options = {
    atSurvey: true,
    azi: true,
    inc: true,
    mdTvd: 'MD',
    range: false,
    startDepth: 0,
    endDepth: 0,
  }

  if (Array.isArray(catOptions) && catOptions.length > 0) {
    options = catOptions.find((opt) => opt.category === labelCategory)?.options
  }

  if (typeof options.startDepth !== 'number') options.startDepth = 0
  if (typeof options.endDepth !== 'number') options.endDepth = 0

  if (options.startDepth > options.endDepth) {
    let temp = options.startDepth
    options.startDepth = options.endDepth
    options.endDepth = temp
  }

  if (!Array.isArray(surveyData)) {
    surveyData = []
  }

  let labels = []
  if (options.atSurvey) {
    for (let i = 0; i < surveyData.length; i++) {
      let useSvy = true
      let val = options.mdTvd === 'MD' ? surveyData[i].md : surveyData[i].tvd

      if (options.range) {
        if (val < options.startDepth || val > options.endDepth) {
          useSvy = false
        }
      }

      if (!useSvy) continue
      labels.push({ ...surveyData[i], label: createSvyLabel(surveyData[i], options) })
    }
  }

  if (!options?.atSurvey && options?.interval > 1 && surveyData.length > 0) {
    let minVal = surveyData[0].md
    let maxVal = surveyData[surveyData.length - 1].md

    if (options.mdTvd === 'TVD') {
      minVal = 99999999
      maxVal = 0

      for (let i = 0; i < surveyData.length; i++) {
        if (surveyData[i].tvd < minVal) {
          minVal = surveyData[i].tvd
        }

        if (surveyData[i].tvd > maxVal) {
          maxVal = surveyData[i].tvd
        }
      }
    }

    if (options.range) {
      minVal = options.startDepth >= minVal ? options.startDepth : minVal
      maxVal = options.endDepth <= maxVal ? options.endDepth : maxVal
    }

    if (!options.range) {
      minVal = roundUp(minVal, options.interval)
      maxVal = roundUp(maxVal, options.interval)
    }

    const { VsAzi, VsOrgNorth, VsOrgEast } = verticalSection
    for (let i = minVal; i <= maxVal; i += options.interval) {
      let svy = options.mdTvd === 'TVD' ? pointInterpTVD(surveyData, i) : pointInterp(surveyData, i)
      if (svy.md < 0) continue

      // svy.vs = calcVS(VsAzi, VsOrgNorth, VsOrgEast, svy.ew, svy.ns)
      svy.vs = calcVS(VsAzi, svy.ns, svy.ew, VsOrgNorth, VsOrgEast)
      labels.push({ ...svy, label: createSvyLabel(svy, options) })
    }
  }
  return labels
}

// eslint-disable-next-line
function isEqual(obj1, obj2, ignoreList = []) {
  // Helper function to check if a property should be ignored
  function shouldIgnore(prop) {
    return ignoreList.includes(prop)
  }

  // Helper function to compare two values deeply
  function deepCompare(val1, val2) {
    if (typeof val1 !== typeof val2) return false

    if (typeof val1 === 'object' && val1 !== null && val2 !== null) {
      if (Array.isArray(val1) !== Array.isArray(val2)) return false

      if (Array.isArray(val1)) {
        if (val1.length !== val2.length) return false
        for (let i = 0; i < val1.length; i++) {
          if (!deepCompare(val1[i], val2[i])) return false
        }
        return true
      } else {
        const keys1 = Object.keys(val1).filter((key) => !shouldIgnore(key))
        const keys2 = Object.keys(val2).filter((key) => !shouldIgnore(key))

        if (keys1.length !== keys2.length) return false

        for (let key of keys1) {
          if (!keys2.includes(key) || !deepCompare(val1[key], val2[key])) return false
        }
        return true
      }
    } else {
      return val1 === val2
    }
  }

  return deepCompare(obj1, obj2)
}

export const shouldRegenerateLabels = (prevOptions, nextOptions) => {
  // if (!prevOptions || !nextOptions) return true
  // if (!isEqual(prevOptions, nextOptions)) return true

  // if label definitions are separated from the possibly-interpolated survey list, the below
  // checks would be useful to avoid rerenders. currently label is embedded in the survey array,
  // so array must be regenerated if any change is made to the label options.
  //
  if (prevOptions.mdTvd !== nextOptions.mdTvd) return true
  if (prevOptions.azi !== nextOptions.azi) return true
  if (prevOptions.inc !== nextOptions.inc) return true
  if (prevOptions.atSurvey !== nextOptions.atSurvey) return true
  if (!nextOptions.atSurvey && prevOptions.interval !== nextOptions.interval) return true
  if (prevOptions.range !== nextOptions.range) return true
  if (
    nextOptions.range &&
    (prevOptions.startDepth !== nextOptions.startDepth || prevOptions.endDepth !== nextOptions.endDepth)
  )
    return true
  if (prevOptions.useDot !== nextOptions.useDot) return true

  return false
}

const copyCategoryProps = (label, labelCategory, newLayout, seriesIdString) => {
  // saved props
  label.color = getColor(labelCategory, newLayout, seriesIdString)
  label.size = labelCategory.size
  label.bold = labelCategory.bold
  label.hasTether = labelCategory.hasTether
  // annotation props
  if (label.hasOwnProperty('style')) {
    if (label.style.hasOwnProperty('font')) label.style.font.size = labelCategory.size
    if (label.style.hasOwnProperty('borderColor'))
      label.style.borderColor = labelCategory.backgroundEnabled
        ? getColor(labelCategory, newLayout, seriesIdString)
        : '#AAA'
  }
  if (label.hasOwnProperty('font')) {
    label.font.size = labelCategory.size
    label.font.weight = labelCategory.bold ? 'bold' : 'normal'
  }
  if (label.hasOwnProperty('callout')) {
    label.callout.display = labelCategory.hasTether
    label.callout.borderColor = labelCategory.backgroundEnabled
      ? getColor(labelCategory, newLayout, seriesIdString)
      : '#AAA'
  }
  if (label.subType !== 'symbol') {
    label.backgroundColor = labelCategory.backgroundEnabled ? labelCategory.background : 'rgba(0,0,0,0)'
    label.borderColor = labelCategory.backgroundEnabled ? getColor(labelCategory, newLayout, seriesIdString) : '#AAA'
    label.borderWidth = labelCategory.backgroundEnabled ? 1 : 0
  }
}

const getColor = (labelCat, newLayout, seriesIdString) => {
  let idx = -1
  switch (labelCat?.category) {
    case LABEL_CATEGORIES.wellNames:
    case LABEL_CATEGORIES.annotations:
      if (!newLayout) {
        return labelCat.color
      }
      labelCat.defaultColor = newLayout.chartSettings.series[0].color // maybe?
      if (labelCat.defaultColorEnabled) {
        return labelCat.defaultColor
      }
      return labelCat.color
    case LABEL_CATEGORIES.surveyPts:
      if (!newLayout) {
        return labelCat.color
      }
      labelCat.defaultColor = newLayout.chartSettings.series[0].color // maybe?
      if (labelCat.defaultColorEnabled) {
        return labelCat.defaultColor
      }
      return labelCat.color
    case LABEL_CATEGORIES.casing:
      labelCat.defaultColor = `#000000`
      if (labelCat.defaultColorEnabled) {
        return labelCat.defaultColor
      }
      return labelCat.color
    case LABEL_CATEGORIES.targets:
    case LABEL_CATEGORIES.leaseLines:
      if (!newLayout) {
        return labelCat.color
      }
      idx = newLayout.chartSettings.targets.findIndex((t) => t.targetName === seriesIdString)
      if (idx >= 0) {
        const targetColor = newLayout.chartSettings.targets[idx].color
        labelCat.defaultColor = targetColor
      }
      if (labelCat.defaultColorEnabled) {
        return labelCat.defaultColor
      }
      return labelCat.color
    case LABEL_CATEGORIES.lithologies:
      if (labelCat.defaultColorEnabled) {
        return labelCat.defaultColor
      }
      return labelCat.color
    case LABEL_CATEGORIES.offsetWellNames:
    case LABEL_CATEGORIES.offsetSurveyPts:
      if (!newLayout) {
        return labelCat.color
      }
      idx = newLayout.chartSettings.series.findIndex((s) => s.wellName === seriesIdString)
      if (idx > 0) {
        const seriesColor = newLayout.chartSettings.showOffsetRandomColors
          ? newLayout.chartSettings.series[idx].randomColor
          : newLayout.chartSettings.series[idx].color
        labelCat.defaultColor = seriesColor
      }
      if (labelCat.defaultColorEnabled) {
        return labelCat.defaultColor // seriesColor
      }
      return labelCat.color //seriesColor // labelCat.color
    default:
      return '#000000'
  }
}

const isWellVisible = (wellName, chartSettings) => {
  return chartSettings.series.find((s) => s.wellName === wellName).visible === true
}

const isTargetVisible = (targetName, chartSettings) => {
  return chartSettings.targets.find((t) => t.targetName === targetName).visible === true
}

// labelData objects that shouldn't be recreated on layout change
const getPersistentLabels = (existingChartSettings, newLayout) => {
  const persistentLabels = []
  if (existingChartSettings && existingChartSettings.labels) {
    existingChartSettings.labels.labelData.forEach((label) => {
      if (
        label.catType === LABEL_CATEGORIES.userDefined &&
        !hasLabelCategory(LABEL_CATEGORIES.userDefined, newLayout.chartSettings.labels)
      ) {
        persistentLabels.push(label)
      }
    })
  }
  return persistentLabels
}

export const generateAllLabels = (newLayout, existingChartSettings, wellData) => {
  const labels = newLayout.chartSettings.labels
  const labelData = getPersistentLabels(existingChartSettings, newLayout)
  labels.categories.forEach((labelCat) => {
    switch (labelCat.category) {
      case LABEL_CATEGORIES.leaseLines:
        if (!existingChartSettings || !hasLabelCategory(LABEL_CATEGORIES.leaseLines, existingChartSettings.labels)) {
          const targetData = []
          getTargets2D(
            wellData.targets,
            targetData,
            newLayout.chartSettings.chartType,
            wellData?.actualWellData?.verticalSection,
            true,
          )
          wellData.targets.forEach((wellTarget) => {
            const target = targetData.find((t) => t.text === wellTarget.targetName)
            if (wellTarget.isLeaseLine && isTargetVisible(wellTarget.targetName, newLayout.chartSettings)) {
              // const target = targetData.find((t) => t.text === wellTarget.targetName)
              labelData.push({
                catType: LABEL_CATEGORIES.leaseLines,
                color: getColor(labelCat, newLayout, target.text),
                backgroundColor: labelCat.backgroundEnabled ? labelCat.background : 'rgba(0,0,0,0)',
                size: labelCat.size,
                bold: labelCat.bold,
                hasTether: labelCat.hasTether,
                id: `${wellData.actualWellData.actualWellName}|${target.text}`,
                uid: `${wellData.actualWellData.actualWellName}|${target.text}`,
                seriesId: target.text,
                borderWidth: labelCat.backgroundEnabled ? 1 : 0,
                borderColor: labelCat.backgroundEnabled ? getColor(labelCat, newLayout) : '#AAA',
                style: {
                  borderColor: '#AAA',
                  font: {
                    size: labelCat.size,
                    weight: labelCat.bold ? 'bold' : 'normal',
                    style: labelCat.italic ? 'italic' : 'normal',
                  },
                },
                position: {
                  x: 'end',
                  y: 'center',
                },
                type: 'label',
                drawTime: 'afterDatasetsDraw',
                rotation: 0,
                callout: {
                  display: labelCat.hasTether,
                  borderColor: getColor(labelCat, newLayout, target.text),
                },
                content: `${target.text}`,
                font: {
                  size: labelCat.size,
                  weight: labelCat.bold ? 'bold' : 'normal',
                  style: labelCat.italic ? 'italic' : 'normal',
                },
                xValue: target.x,
                yValue: target.y,
                xAdjust: 0,
                yAdjust: 0,
                moveOffset: {
                  x: 0,
                  y: 0,
                },
              })
            }
          })
        }
        if (hasLabelCategory(LABEL_CATEGORIES.leaseLines, existingChartSettings?.labels)) {
          existingChartSettings.labels.labelData.forEach((label) => {
            if (label.catType === LABEL_CATEGORIES.leaseLines) {
              copyCategoryProps(label, labelCat, newLayout, label.seriesId)
              labelData.push(label)
            }
          })
        }
        break
      case LABEL_CATEGORIES.targets:
        let genTargetLabels = true // would prefer to start with false, but need to check for visibility changes for each target
        // if a target has changed it's visibility, regenerate the labels for that target only
        // granular inspection of the target visibility is required--the below 'typical' code block
        // used for other categories is not sufficient for targets/lease lines

        if (
          !existingChartSettings ||
          !hasLabelCategory(LABEL_CATEGORIES.targets, existingChartSettings.labels) ||
          genTargetLabels
        ) {
          const targetData = []
          getTargets2D(
            wellData.targets,
            targetData,
            newLayout.chartSettings.chartType,
            wellData?.actualWellData?.verticalSection,
            true,
          )

          wellData.targets.forEach((wellTarget) => {
            const target = targetData.find((t) => t.text === wellTarget.targetName)
            if (!wellTarget.isLeaseLine && isTargetVisible(wellTarget.targetName, newLayout.chartSettings)) {
              labelData.push({
                catType: LABEL_CATEGORIES.targets,
                color: getColor(labelCat, newLayout, target.text),
                backgroundColor: labelCat.backgroundEnabled ? labelCat.background : 'rgba(0,0,0,0)',
                size: labelCat.size,
                bold: labelCat.bold,
                italic: labelCat.italic,
                hasTether: labelCat.hasTether,
                id: `${wellData.actualWellData.actualWellName}|${target.text}`,
                uid: `${wellData.actualWellData.actualWellName}|${target.text}`,
                seriesId: target.text,
                borderWidth: labelCat.backgroundEnabled ? 1 : 0,
                borderColor: labelCat.backgroundEnabled ? getColor(labelCat, newLayout) : '#AAA',
                style: {
                  borderColor: '#AAA',
                  font: {
                    size: labelCat.size,
                    weight: labelCat.bold ? 'bold' : 'normal',
                    style: labelCat.italic ? 'italic' : 'normal',
                  },
                },
                position: {
                  x: 'end',
                  y: 'center',
                },
                type: 'label',
                drawTime: 'afterDatasetsDraw',
                rotation: 0,
                callout: {
                  display: labelCat.hasTether,
                  borderColor: getColor(labelCat, newLayout, target.text),
                },
                content: `${target.text}`,
                font: {
                  size: labelCat.size,
                  weight: labelCat.bold ? 'bold' : 'normal',
                  style: labelCat.italic ? 'italic' : 'normal',
                },
                xValue: target.x,
                yValue: target.y,
                xAdjust: 0,
                yAdjust: 0,
                moveOffset: {
                  x: 0,
                  y: 0,
                },
              })
            }
          })
        }
        break
      case LABEL_CATEGORIES.lithologies:
        if (!existingChartSettings || !hasLabelCategory(LABEL_CATEGORIES.lithologies, existingChartSettings?.labels)) {
          wellData.actualWellData.lithologies.forEach((lith) => {
            const labelPos = { x: newLayout.chartSettings?.xMin || 0, y: lith.tvd }
            labelData.push({
              catType: LABEL_CATEGORIES.lithologies,
              color: getColor(labelCat, newLayout),
              size: labelCat.size,
              bold: labelCat.bold,
              italic: labelCat.italic,
              hasTether: labelCat.hasTether,
              id: `${wellData.actualWellData.actualWellName}-${lith.tvd}`,
              uid: `${wellData.actualWellData.actualWellName}-${lith.tvd}`,
              style: {
                borderColor: '#AAA',
                font: {
                  size: labelCat.size,
                  weight: labelCat.bold ? 'bold' : 'normal',
                  style: labelCat.italic ? 'italic' : 'normal',
                },
              },
              position: {
                x: 'start',
                y: 'end',
              },
              type: 'label',
              drawTime: 'afterDatasetsDraw',
              callout: {
                display: labelCat.hasTether,
                borderColor: getColor(labelCat, newLayout),
              },
              content: `${lith.formation}`,
              font: {
                size: labelCat.size,
                weight: labelCat.bold ? 'bold' : 'normal',
                style: labelCat.italic ? 'italic' : 'normal',
              },
              xValue: labelPos.x,
              yValue: labelPos.y,
              xAdjust: 10,
              yAdjust: 0,
              moveOffset: {
                x: 0,
                y: 0,
              },
            })
          })
        }
        // if labels for this categoryh exist, walk through and update them
        if (hasLabelCategory(LABEL_CATEGORIES.lithologies, existingChartSettings?.labels)) {
          existingChartSettings.labels.labelData.forEach((label) => {
            if (label.catType === LABEL_CATEGORIES.lithologies) {
              copyCategoryProps(label, labelCat, newLayout)
              labelData.push(label)
            }
          })
        }
        break
      case LABEL_CATEGORIES.offsetSurveyPts:
        let genOffsetLabels = false
        if (hasLabelCategory(LABEL_CATEGORIES.offsetSurveyPts, existingChartSettings?.labels)) {
          const priorOptions = existingChartSettings.labels.categoryOptions.find(
            (opts) => opts.category === LABEL_CATEGORIES.offsetSurveyPts,
          )
          const newOptions = labels.categoryOptions.find((opts) => opts.category === LABEL_CATEGORIES.offsetSurveyPts)
          genOffsetLabels = shouldRegenerateLabels(priorOptions.options, newOptions.options)
          if (!genOffsetLabels) {
            // check for angled labels
            const priorCatOptions = existingChartSettings.labels.categories.find(
              (cat) => cat.category === LABEL_CATEGORIES.offsetSurveyPts,
            )
            genOffsetLabels = priorCatOptions.angled !== labelCat.angled
          }
          if (!genOffsetLabels) {
            existingChartSettings.labels.labelData.forEach((label) => {
              if (label.catType === LABEL_CATEGORIES.offsetSurveyPts) {
                copyCategoryProps(label, labelCat, newLayout, label.seriesId)
                labelData.push(label)
              }
            })
          }
        }

        if (
          !existingChartSettings ||
          !hasLabelCategory(LABEL_CATEGORIES.offsetSurveyPts, existingChartSettings?.labels) ||
          genOffsetLabels
        ) {
          // object format the api will store (in dev, not complete):
          wellData.offsetWells?.forEach((offsetWell) => {
            if (
              offsetWell.offsetWellbore !== wellData.actualWellData.actualWellName &&
              isWellVisible(offsetWell.offsetWellbore, newLayout.chartSettings)
            ) {
              const labels = generateDepthLabels(
                LABEL_CATEGORIES.offsetSurveyPts,
                offsetWell.surveyData,
                wellData.actualWellData.verticalSection,
                newLayout.chartSettings?.labels?.categoryOptions,
              )

              labels.forEach((label, i) => {
                let angle = newLayout.chartSettings.chartType === 'plan' ? label.azi : label.inc
                if (newLayout.chartSettings.chartType !== 'plan' && label.inc >= 45) angle = -1.0 * angle
                if (newLayout.chartSettings.chartType === 'plan' && label.azi <= 180 && label.azi > 0)
                  angle = 180 + angle

                labelData.push({
                  catType: LABEL_CATEGORIES.offsetSurveyPts,
                  color: getColor(labelCat, newLayout, offsetWell.offsetWellbore),
                  backgroundColor: labelCat.backgroundEnabled ? labelCat.background : 'rgba(0,0,0,0)',
                  size: labelCat.size,
                  bold: labelCat.bold,
                  italic: labelCat.italic,
                  hasTether: labelCat.hasTether,
                  id: `${offsetWell.offsetWellbore}-${label.md}`,
                  uid: `${offsetWell.offsetWellbore}-${label.md}`,
                  seriesId: offsetWell.offsetWellbore,
                  borderWidth: labelCat.backgroundEnabled ? 1 : 0,
                  borderColor: labelCat.backgroundEnabled
                    ? getColor(labelCat, newLayout, offsetWell.offsetWellbore)
                    : '#AAA',
                  style: {
                    borderColor: '#AAA',
                    font: {
                      size: labelCat.size,
                      weight: labelCat.bold ? 'bold' : 'normal',
                      style: labelCat.italic ? 'italic' : 'normal',
                    },
                  },
                  position: {
                    x: 'end',
                    y: 'center',
                  },
                  type: 'label',
                  drawTime: 'afterDatasetsDraw',
                  rotation: labelCat.angled ? angle : 0,
                  callout: {
                    display: labelCat.hasTether,
                    borderColor: getColor(labelCat, newLayout, offsetWell.offsetWellbore),
                  },
                  content: label.label,
                  font: {
                    size: labelCat.size,
                    weight: labelCat.bold ? 'bold' : 'normal',
                    style: labelCat.italic ? 'italic' : 'normal',
                  },
                  xValue: newLayout.chartSettings.chartType === 'plan' ? label.ew : label.vs,
                  yValue: newLayout.chartSettings.chartType === 'plan' ? label.ns : label.tvd,
                  xAdjust: -20,
                  yAdjust: 0,
                  moveOffset: {
                    x: 0,
                    y: 0,
                  },
                })
              })
            }
          })
        }
        break
      case LABEL_CATEGORIES.offsetWellNames:
        let genOffsetWellLabels = false
        if (hasLabelCategory(LABEL_CATEGORIES.offsetWellNames, existingChartSettings?.labels)) {
          const priorCatOptions = existingChartSettings.labels.categories.find(
            (cat) => cat.category === LABEL_CATEGORIES.offsetWellNames,
          )
          genOffsetWellLabels = priorCatOptions.angled !== labelCat.angled
          // check if well visibility changed
          if (!genOffsetWellLabels) {
            newLayout.chartSettings.series.forEach((series) => {
              if (series.wellName !== wellData.actualWellData.actualWellName) {
                const idx = existingChartSettings.series.findIndex((well) => well.wellName === series.wellName)
                if (idx > -1 && existingChartSettings.series[idx].visible !== series.visible) {
                  genOffsetWellLabels = true
                }
              }
            })
          }
          if (!genOffsetWellLabels) {
            existingChartSettings.labels.labelData.forEach((label) => {
              if (label.catType === LABEL_CATEGORIES.offsetWellNames) {
                copyCategoryProps(label, labelCat, newLayout, label.seriesId)
                labelData.push(label)
              }
            })
          }
        }
        if (
          !existingChartSettings ||
          !hasLabelCategory(LABEL_CATEGORIES.offsetWellNames, existingChartSettings?.labels) ||
          genOffsetWellLabels
        ) {
          wellData.offsetWells?.forEach((offsetWell) => {
            if (
              offsetWell.offsetWellbore !== wellData.actualWellData.actualWellName &&
              isWellVisible(offsetWell.offsetWellbore, newLayout.chartSettings)
            ) {
              let angle =
                newLayout.chartSettings.chartType === 'plan'
                  ? offsetWell.surveyData[offsetWell.surveyData.length - 1].azi - 90
                  : 90 - offsetWell.surveyData[offsetWell.surveyData.length - 1].inc
              if (
                newLayout.chartSettings.chartType === 'plan' &&
                offsetWell.surveyData[offsetWell.surveyData.length - 1].inc < 2
              )
                angle = 0
              labelData.push({
                catType: LABEL_CATEGORIES.offsetWellNames,
                color: getColor(labelCat, newLayout, offsetWell.offsetWellbore),
                backgroundColor: labelCat.backgroundEnabled ? labelCat.background : 'rgba(0,0,0,0)',
                size: labelCat.size,
                bold: labelCat.bold,
                italic: labelCat.italic,
                hasTether: labelCat.hasTether,
                id: offsetWell.offsetWellbore,
                uid: offsetWell.offsetWellbore,
                seriesId: offsetWell.offsetWellbore,
                borderWidth: labelCat.backgroundEnabled ? 1 : 0,
                borderColor: labelCat.backgroundEnabled
                  ? getColor(labelCat, newLayout, offsetWell.offsetWellbore)
                  : '#AAA',
                style: {
                  borderColor: '#AAA',
                  font: {
                    size: labelCat.size,
                    weight: labelCat.bold ? 'bold' : 'normal',
                    style: labelCat.italic ? 'italic' : 'normal',
                  },
                },
                position: {
                  x: 'center',
                  y: 'center',
                },
                type: 'label',
                drawTime: 'afterDatasetsDraw',
                rotation: labelCat.angled ? angle : 0,
                callout: {
                  display: labelCat.hasTether,
                  borderColor: getColor(labelCat, newLayout, offsetWell.offsetWellbore),
                },
                content: offsetWell.offsetWellbore,
                font: {
                  size: labelCat.size,
                  weight: labelCat.bold ? 'bold' : 'normal',
                  style: labelCat.italic ? 'italic' : 'normal',
                },
                xValue:
                  newLayout.chartSettings.chartType === 'plan'
                    ? offsetWell.surveyData[offsetWell.surveyData.length - 1].ew
                    : offsetWell.surveyData[offsetWell.surveyData.length - 1].vs,
                yValue:
                  newLayout.chartSettings.chartType === 'plan'
                    ? offsetWell.surveyData[offsetWell.surveyData.length - 1].ns
                    : offsetWell.surveyData[offsetWell.surveyData.length - 1].tvd,
                xAdjust: 0,
                yAdjust: -100,
                moveOffset: {
                  x: 0,
                  y: 0,
                },
              })
            }
          })
        }
        break
      case LABEL_CATEGORIES.casing:
        let genCasingLabels = false
        if (hasLabelCategory(LABEL_CATEGORIES.casing, existingChartSettings?.labels)) {
          const priorOptions = existingChartSettings.labels.categoryOptions.find(
            (opts) => opts.category === LABEL_CATEGORIES.casing,
          )
          const newOptions = labels.categoryOptions.find((opts) => opts.category === LABEL_CATEGORIES.casing)
          genCasingLabels = shouldRegenerateLabels(priorOptions.options, newOptions.options)
          if (!genCasingLabels) {
            // check for angled labels
            const priorCatOptions = existingChartSettings.labels.categories.find(
              (cat) => cat.category === LABEL_CATEGORIES.casing,
            )
            genCasingLabels = priorCatOptions.angled !== labelCat.angled
          }
          if (!genCasingLabels) {
            existingChartSettings.labels.labelData.forEach((label) => {
              if (label.catType === LABEL_CATEGORIES.casing) {
                copyCategoryProps(label, labelCat, newLayout)
                labelData.push(label)
              }
            })
          }
        }

        if (
          !existingChartSettings ||
          !hasLabelCategory(LABEL_CATEGORIES.casing, existingChartSettings?.labels) ||
          genCasingLabels
        ) {
          // object format the api will store (in dev, not complete):
          wellData.actualWellData.casing.forEach((casingItem) => {
            const labelPos = getLabelPosByMd(
              casingItem.MD,
              newLayout.chartSettings.chartType,
              wellData.surveys,
              wellData.actualWellData.verticalSection,
            )
            const labelPos2 = getLabelPosByMd(
              casingItem.MD + 100,
              newLayout.chartSettings.chartType,
              wellData.surveys,
              wellData.actualWellData.verticalSection,
            )

            let angle = newLayout.chartSettings.chartType === 'plan' ? labelPos.azi : labelPos.inc
            if (newLayout.chartSettings.chartType !== 'plan' && labelPos.inc >= 45) angle = -1.0 * angle
            if (newLayout.chartSettings.chartType === 'plan' && labelPos.azi <= 180 && labelPos.azi > 0)
              angle = 180 + angle

            let symbolAngle = labelPos.azi
            if (symbolAngle > 180) symbolAngle -= 180
            if (newLayout.chartSettings.chartType !== 'plan') {
              let deltaX = labelPos2.x - labelPos.x // vs
              let deltaY = labelPos2.y - labelPos.y // tvd
              symbolAngle = Math.atan2(deltaY, deltaX) * (180 / Math.PI) - 90
            }

            let casingOptions = newLayout.chartSettings?.labels?.categoryOptions.find(
              (opt) => opt.category === LABEL_CATEGORIES.casing,
            )

            labelData.push({
              catType: LABEL_CATEGORIES.casing,
              color: getColor(labelCat, newLayout),
              backgroundColor: labelCat.backgroundEnabled ? labelCat.background : 'rgba(0,0,0,0)',
              size: labelCat.size,
              bold: labelCat.bold,
              italic: labelCat.italic,
              hasTether: labelCat.hasTether,
              id: `${casingItem.Type}-${casingItem.MD}`,
              uid: `${casingItem.Type}-${casingItem.MD}`,
              borderWidth: labelCat.backgroundEnabled ? 1 : 0,
              borderColor: labelCat.backgroundEnabled ? getColor(labelCat, newLayout) : '#AAA',
              style: {
                borderColor: '#AAA',
                font: {
                  size: labelCat.size,
                  weight: labelCat.bold ? 'bold' : 'normal',
                  style: labelCat.italic ? 'italic' : 'normal',
                },
              },
              position: {
                x: newLayout.chartSettings.chartType === chartType.planView ? 'start' : 'start',
                y: newLayout.chartSettings.chartType === chartType.planView ? 'start' : 'center',
              },
              type: 'label',
              drawTime: 'afterDatasetsDraw',
              rotation: labelCat.angled ? angle : 0,
              callout: {
                display: labelCat.hasTether,
                borderColor: getColor(labelCat, newLayout),
              },
              content: `${casingItem.OD.toFixed(3)} ${casingItem.Type}`,
              font: {
                size: labelCat.size,
                weight: labelCat.bold ? 'bold' : 'normal',
                style: labelCat.italic ? 'italic' : 'normal',
              },
              xValue: labelPos.x,
              yValue: labelPos.y,
              xAdjust: 0,
              yAdjust: 0,
              moveOffset: {
                x: 0,
                y: 0,
              },
            })
            // draw dot or symbol:
            if (casingOptions?.options?.useDot) {
              labelData.push({
                catType: LABEL_CATEGORIES.casing,
                subType: 'symbol',
                color: getColor(labelCat, newLayout),
                backgroundColor: 'rgba(0,0,0,0)',
                id: `${casingItem.Type}-${casingItem.MD}-symb`,
                uid: `${casingItem.Type}-${casingItem.MD}-symb`,
                type: 'label',
                drawTime: 'afterDatasetsDraw',
                rotation: labelCat.angled ? symbolAngle : 0,
                content: (ctx) => renderCasingDot(ctx),
                xValue: labelPos.x,
                yValue: labelPos.y,
                xAdjust: 0,
                yAdjust: 0,
                moveOffset: {
                  x: 0,
                  y: 0,
                },
              })
            }
            if (!casingOptions?.options?.useDot) {
              labelData.push({
                catType: LABEL_CATEGORIES.casing,
                subType: 'symbol',
                color: getColor(labelCat, newLayout),
                backgroundColor: 'rgba(0,0,0,0)',
                id: `${casingItem.Type}-${casingItem.MD}-symb`,
                uid: `${casingItem.Type}-${casingItem.MD}-symb`,
                type: 'label',
                drawTime: 'afterDatasetsDraw',
                content: (ctx) => renderCasingSymbol(ctx, symbolAngle),
                xValue: labelPos.x,
                yValue: labelPos.y,
                xAdjust: 0,
                yAdjust: 0,
                moveOffset: {
                  x: 0,
                  y: 0,
                },
              })
            }
          })
        }
        break
      case LABEL_CATEGORIES.annotations:
        if (!existingChartSettings || !hasLabelCategory(LABEL_CATEGORIES.annotations, existingChartSettings.labels)) {
          wellData.actualWellData.annotations?.forEach((anno) => {
            const labelPos = getLabelPosByMd(
              anno.md,
              newLayout.chartSettings.chartType,
              wellData.surveys,
              wellData.actualWellData.verticalSection,
            )
            labelData.push({
              catType: LABEL_CATEGORIES.annotations,
              color: getColor(labelCat, newLayout),
              backgroundColor: labelCat.backgroundEnabled ? labelCat.background : 'rgba(0,0,0,0)',
              size: labelCat.size,
              bold: labelCat.bold,
              italic: labelCat.italic,
              hasTether: labelCat.hasTether,
              id: `${wellData.actualWellData.actualWellName}-${anno.md}`,
              uid: `${wellData.actualWellData.actualWellName}-${anno.md}`,
              borderWidth: labelCat.backgroundEnabled ? 1 : 0,
              borderColor: labelCat.backgroundEnabled ? getColor(labelCat, newLayout) : '#AAA',
              style: {
                borderColor: '#AAA',
                font: {
                  size: labelCat.size,
                  weight: labelCat.bold ? 'bold' : 'normal',
                  style: labelCat.italic ? 'italic' : 'normal',
                },
              },
              position: {
                x: 'end',
                y: 'center',
              },
              type: 'label',
              drawTime: 'afterDatasetsDraw',
              callout: {
                display: labelCat.hasTether,
                borderColor: getColor(labelCat, newLayout),
              },
              content: `${anno.annotation}`,
              font: {
                size: labelCat.size,
                weight: labelCat.bold ? 'bold' : 'normal',
                style: labelCat.italic ? 'italic' : 'normal',
              },
              xValue: labelPos.x,
              yValue: labelPos.y,
              xAdjust: 10,
              yAdjust: 0,
              moveOffset: {
                x: 0,
                y: 0,
              },
            })
          })
        }
        // if labels for this category exist, walk through and update them
        if (hasLabelCategory(LABEL_CATEGORIES.annotations, existingChartSettings?.labels)) {
          existingChartSettings.labels.labelData.forEach((label) => {
            if (label.catType === LABEL_CATEGORIES.annotations) {
              copyCategoryProps(label, labelCat, newLayout)
              labelData.push(label)
            }
          })
        }
        break
      case LABEL_CATEGORIES.surveyPts:
        // check the new options vs old options
        let generateLabels = false
        if (hasLabelCategory(LABEL_CATEGORIES.surveyPts, existingChartSettings?.labels)) {
          const priorOptions = existingChartSettings.labels.categoryOptions.find(
            (opts) => opts.category === LABEL_CATEGORIES.surveyPts,
          )
          const newOptions = labels.categoryOptions.find((opts) => opts.category === LABEL_CATEGORIES.surveyPts)
          generateLabels = shouldRegenerateLabels(priorOptions.options, newOptions.options)
          if (!generateLabels) {
            // check for angled labels
            const priorCatOptions = existingChartSettings.labels.categories.find(
              (cat) => cat.category === LABEL_CATEGORIES.surveyPts,
            )
            generateLabels = priorCatOptions.angled !== labelCat.angled
          }
          if (!generateLabels) {
            existingChartSettings.labels.labelData.forEach((label) => {
              if (label.catType === LABEL_CATEGORIES.surveyPts) {
                copyCategoryProps(label, labelCat, newLayout)
                labelData.push(label)
              }
            })
          }
        }

        if (
          !existingChartSettings ||
          !hasLabelCategory(LABEL_CATEGORIES.surveyPts, existingChartSettings?.labels) ||
          generateLabels
        ) {
          // if index/interval options have changed, regenerate the labels
          const labels = generateDepthLabels(
            LABEL_CATEGORIES.surveyPts,
            wellData.surveys,
            wellData.actualWellData.verticalSection,
            newLayout.chartSettings?.labels?.categoryOptions,
          )

          labels.forEach((label, i) => {
            let angle = newLayout.chartSettings.chartType === 'plan' ? label.azi : label.inc
            if (newLayout.chartSettings.chartType !== 'plan' && label.inc >= 45) angle = -1.0 * angle
            if (newLayout.chartSettings.chartType === 'plan' && label.azi <= 180 && label.azi > 0) angle = 180 + angle

            labelData.push({
              catType: LABEL_CATEGORIES.surveyPts,
              color: getColor(labelCat, newLayout),
              backgroundColor: labelCat.backgroundEnabled ? labelCat.background : 'rgba(0,0,0,0)',
              size: labelCat.size,
              bold: labelCat.bold,
              italic: labelCat.italic,
              hasTether: labelCat.hasTether,
              id: `${wellData.actualWellData.actualWellName}-${label.md}`,
              uid: `${wellData.actualWellData.actualWellName}-${label.md}`,
              borderWidth: labelCat.backgroundEnabled ? 1 : 0,
              borderColor: labelCat.backgroundEnabled ? getColor(labelCat, newLayout) : 'transparent',
              style: {
                // borderColor: labelCat.backgroundEnabled ? getColor(labelCat, newLayout) : '#AAA',
                font: {
                  size: labelCat.size,
                  weight: labelCat.bold ? 'bold' : 'normal',
                  style: labelCat.italic ? 'italic' : 'normal',
                },
              },
              position: {
                x: 'start',
                y: 'center',
              },
              type: 'label',
              drawTime: 'afterDatasetsDraw',
              rotation: labelCat.angled ? angle : 0,
              callout: {
                display: labelCat.hasTether,
                borderColor: getColor(labelCat, newLayout),
                borderWidth: 1,
              },
              content: label.label,
              font: {
                size: labelCat.size,
                weight: labelCat.bold ? 'bold' : 'normal',
                style: labelCat.italic ? 'italic' : 'normal',
              },
              xValue: newLayout.chartSettings.chartType === 'plan' ? label.ew : label.vs,
              yValue: newLayout.chartSettings.chartType === 'plan' ? label.ns : label.tvd,
              xAdjust: 0,
              yAdjust: 0,
              moveOffset: {
                x: 0,
                y: 0,
              },
            })
          })
        }
        break
      case LABEL_CATEGORIES.wellNames:
        let generateWellLabels = false
        if (hasLabelCategory(LABEL_CATEGORIES.wellNames, existingChartSettings?.labels)) {
          const priorCatOptions = existingChartSettings.labels.categories.find(
            (cat) => cat.category === LABEL_CATEGORIES.wellNames,
          )
          generateWellLabels = priorCatOptions.angled !== labelCat.angled
          if (!generateWellLabels) {
            existingChartSettings.labels.labelData.forEach((label) => {
              if (label.catType === LABEL_CATEGORIES.wellNames) {
                copyCategoryProps(label, labelCat, newLayout)
                labelData.push(label)
              }
            })
          }
        }

        if (
          !existingChartSettings ||
          !hasLabelCategory(LABEL_CATEGORIES.wellNames, existingChartSettings?.labels) ||
          (generateWellLabels && wellData)
        ) {
          // object format the api will store (in dev, not complete):
          const lastVisibleSurvey = getLastVisibleSurveyPt(
            wellData.surveys,
            {
              xMin: newLayout.chartSettings.xMin,
              xMax: newLayout.chartSettings.xMax,
              yMin: newLayout.chartSettings.yMin,
              yMax: newLayout.chartSettings.yMax,
            },
            newLayout.chartSettings.chartType,
          )
          let angle =
            newLayout.chartSettings.chartType === 'plan' ? lastVisibleSurvey.azi - 90 : 90 - lastVisibleSurvey.inc
          if (newLayout.chartSettings.chartType === 'plan' && lastVisibleSurvey.inc < 2) angle = 0
          labelData.push({
            catType: LABEL_CATEGORIES.wellNames,
            color: getColor(labelCat, newLayout),
            backgroundColor: labelCat.backgroundEnabled ? labelCat.background : 'rgba(0,0,0,0)',
            size: labelCat.size,
            bold: labelCat.bold,
            italic: labelCat.italic,
            hasTether: labelCat.hasTether,
            id: wellData.actualWellData.actualWellName,
            uid: wellData.actualWellData.actualWellName,
            borderWidth: labelCat.backgroundEnabled ? 1 : 0,
            borderColor: labelCat.backgroundEnabled ? getColor(labelCat, newLayout) : '#AAA',
            style: {
              font: {
                size: labelCat.size,
                weight: labelCat.bold ? 'bold' : 'normal',
                style: labelCat.italic ? 'italic' : 'normal',
              },
            },
            position: {
              x: 'center',
              y: 'center',
            },
            // object properties the anno plugin expects:
            type: 'label',
            drawTime: 'afterDatasetsDraw',
            rotation: labelCat.angled ? angle : 0,
            callout: {
              display: labelCat.hasTether,
              borderColor: getColor(labelCat, newLayout),
            },
            content: wellData.actualWellData.actualWellName,
            font: {
              size: labelCat.size,
              weight: labelCat.bold ? 'bold' : 'normal',
              style: labelCat.italic ? 'italic' : 'normal',
            },
            xValue: newLayout.chartSettings.chartType === 'plan' ? lastVisibleSurvey.ew : lastVisibleSurvey.vs,
            yValue: newLayout.chartSettings.chartType === 'plan' ? lastVisibleSurvey.ns : lastVisibleSurvey.tvd,
            xAdjust: 0,
            yAdjust: -5,
            moveOffset: {
              x: 0,
              y: 0,
            },
            // these four values are to store the drawing position of the label (pixels) during the afterDraw
            x: 0,
            y: 0,
          })
        }
        break
      case LABEL_CATEGORIES.userDefined:
        labels.labelData.forEach((label) => {
          if (label.catType === LABEL_CATEGORIES.userDefined) {
            labelData.push({
              catType: LABEL_CATEGORIES.userDefined,
              color: label.color ? label.color : 'rgba(0,0,0,0)',
              backgroundColor: label.backgroundColor ? label.backgroundColor : 'rgba(0,0,0,0)',
              hasBackground: label.hasBackground === true ? true : false,
              size: label.size,
              bold: label.bold,
              italic: label.italic,
              hasTether: label.hasTether,
              id: label.uid,
              uid: label.uid,
              borderWidth: label.borderWidth ? 1 : 0,
              borderColor: label.borderColor ? label.borderColor : 'rgba(170, 170, 170, 1);',
              style: {
                font: {
                  size: label.size,
                  weight: label.bold ? 'bold' : 'normal',
                  style: label.italic ? 'italic' : 'normal',
                },
              },
              position: {
                x: 'center',
                y: 'center',
              },
              // object properties the anno plugin expects:
              type: 'label',
              drawTime: 'afterDatasetsDraw',
              rotation: label.rotation ? label.rotation : 0,
              callout: {
                display: label.hasTether ? label.hasTether : false,
                borderColor: label.callout?.borderColor ? label.callout.borderColor : '#AAA',
              },
              content: label.content,
              font: {
                size: label.size,
                weight: label.bold ? 'bold' : 'normal',
                style: label.italic ? 'italic' : 'normal',
              },
              xValue: label.xValue ? label.xValue : 0,
              yValue: label.yValue ? label.yValue : 0,
              xAdjust: label.xAdjust ? label.xAdjust : 0,
              yAdjust: label.yAdjust ? label.yAdjust : 0,
              moveOffset: {
                x: label.moveOffset?.x ? label.moveOffset.x : 0,
                y: label.moveOffset?.y ? label.moveOffset.y : 0,
              },
              // these four values are to store the drawing position of the label (pixels) during the afterDraw
              x: label.x ? label.x : 0,
              y: label.y ? label.y : 0,
            })
          }
        })
        break
      default:
        break
    }
  })

  return labelData // ...plus any new ones due to the current modal change
}

function renderCasingSymbol(annotationCtx, rotation) {
  const canvas = document.createElement('canvas')
  canvas.width = 60 // Double the width for padding during rotation
  canvas.height = 80 // Double the height for padding during rotation
  const ctx = canvas.getContext('2d')

  ctx.clearRect(0, 0, canvas.width, canvas.height)
  // Move the origin to the bottom center of the canvas
  ctx.translate(canvas.width / 2, canvas.height / 2)
  // Rotate the canvas around the bottom center
  ctx.rotate((rotation * Math.PI) / 180)

  ctx.fillStyle = '#000000'
  ctx.strokeStyle = '#000000'
  ctx.lineWidth = 1

  // Left side of the casing
  ctx.beginPath()
  ctx.moveTo(-8, 0)
  ctx.lineTo(-8, -30)
  ctx.lineTo(-9, -30)
  ctx.lineTo(-9, -10)
  ctx.lineTo(-15, 0)
  ctx.closePath()
  ctx.stroke()
  ctx.fill()

  // Right side of the casing
  ctx.beginPath()
  ctx.moveTo(8, 0)
  ctx.lineTo(8, -30)
  ctx.lineTo(9, -30)
  ctx.lineTo(9, -10)
  ctx.lineTo(15, 0)
  ctx.closePath()
  ctx.stroke()
  ctx.fill()

  return canvas
}

function renderCasingDot() {
  const canvas = document.createElement('canvas')
  canvas.width = 20
  canvas.height = 20
  const ctx = canvas.getContext('2d')
  ctx.fillStyle = '#000000'
  ctx.strokeStyle = '#000000'
  ctx.lineWidth = 1
  const centerX = 10
  const centerY = 10
  const radius = 5

  ctx.beginPath()
  ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI)
  ctx.fill()
  return canvas
}
