import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box, Tooltip, IconButton } from '@mui/material'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, CustomLoadingOverlay, htmlSymbolHandling } from 'components/common/AgGridUtils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { Icon as Iconify } from '@iconify/react'
import { currentWellAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'

const DrillStringRecommendationsGrid = ({
  setStatus,
  setImportBha,
  recommendations,
  recommendationFilterData,
  isLoading,
}) => {
  const _isMounted = useRef(true)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const [resetCols, setResetCols] = useState(false)
  const { getUnitsText } = useUnits()
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(currentWell)

  const importBha = useInnovaAxios({
    url: '/well/drillString/duplicateBha',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleImportBha = useCallback(
    async (data) => {
      if (!data) return

      data = htmlSymbolHandling(data)
      data.wellName = currentWellRef.current
      data.orgWellName = data.actualWell

      let res = await importBha(data)
      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
        return
      }

      setImportBha(res?.data)
    },
    [importBha, setStatus, setImportBha],
  )

  const importBhaCellRenderer = useCallback(
    (params) => {
      return (
        <React.Fragment>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Tooltip
              title={'Import BHA'}
              placement='left'
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: 'rgb(19,62,96)',
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                  },
                },
              }}>
              <IconButton
                style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
                onClick={() => handleImportBha(params.data)}
                size='large'>
                <Iconify color='white' icon='la:file-import' fontSize={24} />
              </IconButton>
            </Tooltip>
          </Box>
        </React.Fragment>
      )
    },
    [handleImportBha],
  )

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Rank',
        field: 'rank',
        colId: 'rank',
        pinned: 'left',
        lockPosition: 'left',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        headerName: '',
        field: 'actions',
        colId: 'actions',
        pinned: 'left',
        lockPosition: 'left',
        width: 45,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellRenderer: importBhaCellRenderer,
      },
      {
        headerName: 'Well Name',
        field: 'actualWell',
        colId: 'actualWell',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'BHA #',
        field: 'bhaNumRep',
        colId: 'bhaNumRep',
      },
      {
        headerName: 'Operator',
        field: 'operator',
        colId: 'operator',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `Hole Size ${getUnitsText(UNITS_FOR.Diameter)}`,
        field: 'bitData.Od',
        colId: 'bitData.Od',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'ROP',
        field: 'avgRop',
        colId: 'avgRop',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        headerName: 'Total Drilled',
        field: 'totalDrilled',
        colId: 'totalDrilled',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        headerName: 'Formation',
        field: 'formation',
        colId: 'formation',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Phase',
        field: 'phases',
        colId: 'phases',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Drive Type',
        field: 'driveType',
        colId: 'driveType',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Reason POOH',
        field: 'reasonPooh',
        colId: 'reasonPooh',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Motor Failed',
        field: 'motorFailed',
        colId: 'motorFailed',
        cellRenderer: 'agCheckboxCellRenderer',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'RSS Failed',
        field: 'rssFailed',
        colId: 'rssFailed',
        cellRenderer: 'agCheckboxCellRenderer',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'MWD Failed',
        field: 'mwdFailed',
        colId: 'mwdFailed',
        cellRenderer: 'agCheckboxCellRenderer',
        filter: 'agSetColumnFilter',
      },
    ],
    [getUnitsText, importBhaCellRenderer],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      autoHeight: true,
      editable: false,
      cellStyle: centerAlignCell,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [centerAlignCell])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('drillStringRecommendationsGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
    popupParent: document.querySelector('body'),
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const getContextMenuItems = (params) => {
    let menuItems = [
      {
        name: 'Reset columns',
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('drillStringGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]

    return menuItems
  }

  const getRowId = (data) => {
    return `${data.data.actualWell}-${data.data.operator}-${data.data.bhaNum}`
  }

  const onFirstDataRendered = () => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()
  }

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      filteredNodes.push(node.data)
    })
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    setTimeout(() => {
      gridApi.current?.autoSizeAllColumns()
    }, 100)
  }

  const getRowStyle = (params) => {
    if (!params.data) return
    if (!recommendationFilterData) return
    if (!Array.isArray(recommendationFilterData)) return
    if (recommendationFilterData.length === 0) return

    let showColors = false

    for (let i = 0; i < recommendationFilterData.length; i++) {
      if (recommendationFilterData[i].property === 'showColors') {
        if (recommendationFilterData[i].checked === true) {
          showColors = true
          break
        }
      }
    }

    if (!showColors) return

    if (params.data?.score <= 50) {
      return { backgroundColor: 'rgba(255, 0, 0, 0.75)' }
    }
    if (params.data?.score > 50 && params.data.score < 80) {
      return { backgroundColor: 'rgba(255, 165, 0, 0.75)' }
    }
    return { backgroundColor: 'rgba(0, 255, 0, 0.5)' }
  }

  return (
    <Box className={getAgGridTheme()} sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={recommendations}
        columnDefs={sortColDefs(columnDefs, 'drillStringRecommendationsGrid')}
        headerHeight={30}
        defaultcolDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        getContextMenuItems={getContextMenuItems}
        onFirstDataRendered={onFirstDataRendered}
        onFilterChanged={onFilterChanged}
        getRowId={getRowId}
        getRowStyle={getRowStyle}
        loading={isLoading}
      />
    </Box>
  )
}

export default DrillStringRecommendationsGrid
