import React, { useMemo, useState, useRef, useEffect } from 'react'
import { Drawer, Box, TextField, IconButton, List, ListItem, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import Send from '@mui/icons-material/Send'
import { makeStyles } from '@mui/styles'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { isoStringToLocaleStr } from 'utils/dateTimeFunctions'
import { saveItemToLS, getItemFromLS } from 'utils/localStorage'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import { getUserNameInitials } from 'utils/chatFunctions'

const useDrawerStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.itemBackground,
    maxWidth: '400px',
  },
}))

const StyledItemIconContainer = styled(IconButton)({
  position: 'absolute',
  right: '4px',
  width: '40px',
  height: '40px',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
})

const StyledPromptRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
  alignItems: 'center',
})

const StyledResponseRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
})

const ChatDrawer = ({ children, selectorToggle, setSelectorToggle }) => {
  const classes = useDrawerStyles()

  const onCloseDrawer = (event) => {
    setSelectorToggle(false)
  }

  return (
    <React.Fragment>
      <Drawer
        anchor='right'
        disableEnforceFocus
        open={selectorToggle}
        onClose={onCloseDrawer}
        classes={{ paper: classes.paper }}>
        {React.Children.map(children, (child) => {
          return child
        })}
      </Drawer>
    </React.Fragment>
  )
}

const ChatBotControl = ({ open: chatToggle, onClose: setSelectorToggle }) => {
  const _isMouted = useRef(false)
  const { theme, getLinearGradient } = useInnovaTheme()
  const chatPromptRef = useRef('')
  const [chatPrompt, setChatPrompt] = useState('')
  const [chatResponse, setChatResponse] = useState([])
  const scrollRef = useRef(null)
  const isLoading = useRef(false)
  const { user } = useInnovaAuth()

  const getChatBotResponse = useInnovaAxios({
    url: '/chatbot/getChatResponse',
  })

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [chatResponse])

  useEffect(() => {
    _isMouted.current = true
    let messageLog = getItemFromLS('chatbotMessages', user?.name)
    if (messageLog) {
      setChatResponse(messageLog)
    }

    return () => {
      _isMouted.current = false
    }
  }, [user])

  const textFieldStyle = useMemo(
    () => ({
      height: '30px',
      margin: '10px',
      backgroundColor: theme === 'dark' ? '#2d2d2d !important' : '#f0f0f0 !important',
      width: '250px',
      maxWidth: '400px',
      justifyContent: 'center',
    }),
    [theme],
  )

  const fetchChatBotResponse = async () => {
    if (!_isMouted.current) return
    if (isLoading.current) return
    isLoading.current = true

    let prompt = {
      type: 'prompt',
      message: chatPromptRef.current,
      userName: user?.name,
      timeStamp: new Date(Date.now()).toISOString(),
    }

    const res = await getChatBotResponse({ chatPrompt: chatPromptRef.current })

    isLoading.current = false

    if (res.error) {
      return
    }

    let response = {
      type: 'response',
      message: res.data,
      userName: 'ChatBot',
      timeStamp: new Date(Date.now()).toISOString(),
    }
    let messages = [...chatResponse]
    messages.push(prompt)
    messages.push(response)
    setChatResponse(messages)
    saveItemToLS('chatbotMessages', user?.name, messages)

    chatPromptRef.current = ''
    setChatPrompt('')
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      fetchChatBotResponse()
    }
  }

  const clearChat = () => {
    setChatResponse([])
    saveItemToLS('chatbotMessages', user?.name, [])
  }

  const renderChatMessage = (item, index) => {
    if (item.type === 'prompt') {
      return renderPrompts(item, index)
    }
    return renderResponses(item, index)
  }

  const renderResponses = (item, index) => {
    let msg = item.message.replace('\\\\', '\\')
    msg = msg.replace('&sq;', "'")
    return (
      <StyledResponseRow>
        <Box
          sx={{
            display: 'flex',
            padding: '12px',
            borderRadius: '10px',
            backgroundColor: '#000',
            color: '#FFF',
            marginLeft: '8px',
            marginRight: '35%',
            whiteSpace: 'normal',
            maxWidth: '326px',
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Box sx={{ fontSize: '16px' }}>{msg}</Box>
            <StyledResponseRow>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '12px',
                  fontWeight: 700,
                  margin: '8px 8px 0px 0px',
                  backgroundColor: 'lightgrey',
                  color: '#000',
                  border: '2px solid lightgrey',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                }}>
                {getUserNameInitials(item?.userName).toUpperCase()}
              </Box>
              <Box sx={{ padding: '8px 0px 0px 0px', fontSize: '10px', textAlign: 'right' }}>
                {isoStringToLocaleStr(item?.timeStamp, false, true, false)}
              </Box>
            </StyledResponseRow>
          </Box>
        </Box>
      </StyledResponseRow>
    )
  }

  const renderPrompts = (item, index) => {
    let msg = item.message.replace('\\\\', '\\')
    msg = msg.replace('&sq;', "'")
    return (
      <StyledPromptRow>
        <Box
          sx={{
            display: 'flex',
            padding: '12px 12px 6px 12px',
            borderRadius: '10px',
            backgroundColor: '#1679D0',
            color: '#FFF',
            marginLeft: '35%',
            whiteSpace: 'normal',
            maxWidth: '326px',
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
            <Box sx={{ fontSize: '16px' }}>{msg}</Box>
            <StyledPromptRow>
              <Box sx={{ padding: '8px 0px 0px 0px', fontSize: '10px', textAlign: 'right' }}>
                {isoStringToLocaleStr(
                  item?.timeStamp?.length > 1 ? item.timeStamp : new Date(Date.now()).toISOString(),
                  false,
                  true,
                  false,
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '12px',
                  fontWeight: 700,
                  margin: '8px 0px 0px 8px',
                  backgroundColor: 'lightgrey',
                  color: '#000',
                  border: '2px solid lightgrey',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                }}>
                {getUserNameInitials(item?.userName).toUpperCase()}
              </Box>
            </StyledPromptRow>
          </Box>
        </Box>
      </StyledPromptRow>
    )
  }

  return (
    <ChatDrawer selectorToggle={chatToggle} setSelectorToggle={setSelectorToggle}>
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '4px', width: '300px' }}>
        <Button variant='contained' color='primary' onClick={clearChat} sx={{ margin: '4px 8px 4px 8px' }}>
          Clear Chat
        </Button>
        <Box
          sx={{
            border: '1px solid #000',
            borderRadius: '5px',
            margin: '8px 8px 8px 8px',
            height: 'calc(calc(100vh - 154px) * 0.65)',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
          style={{ height: 'calc(100vh * 0.85)' }}>
          <List
            dense
            sx={{
              width: '100%',
              height: '100%',
              background: getLinearGradient(),
              overflow: 'auto',
              position: 'relative',
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
                WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#136cb9',
                outline: '1px solid #1679d0',
              },
            }}>
            {chatResponse?.map((item, index) => (
              <ListItem
                sx={{
                  display: 'flex',
                  padding: '4px 0px 4px 0px',
                  borderRadius: '5px',
                  width: 'calc(100% - 10px)',
                }}
                dense
                disableGutters={true}
                key={`${index}`}
                ref={index === chatResponse.length - 1 ? scrollRef : null}>
                {renderChatMessage(item, index)}
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TextField
            sx={textFieldStyle}
            fullWidth
            value={chatPrompt}
            onChange={(e) => {
              chatPromptRef.current = e.target.value
              setChatPrompt(e.target.value)
            }}
            onKeyUp={handleKeyUp}
          />
          <StyledItemIconContainer type='submit' size='large'>
            <Send onClick={fetchChatBotResponse} />
          </StyledItemIconContainer>
        </Box>
      </Box>
    </ChatDrawer>
  )
}

export default ChatBotControl
