import React, { useRef, useState } from 'react'
import { Box, Tooltip, Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material'

import { Icon as Iconify } from '@iconify/react'
import { uuidv4 } from 'utils/stringFunctions'
import { appColors } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import PageSizeModal from '../PageSizeModal'
import { BUTTON_SIZE, ContextMenuItemIcon } from 'components/WellPages/WallplotComposer/Toolbar/toolbarUtils'
import {
  ToolbarAddChartIconList,
  ToolbarAddTableIconList,
  ToolbarAddObjectIconList,
} from 'components/WellPages/WallplotComposer/Toolbar/ToolbarLists'

const ToolbarItemIcon = ({ icon, color, isImage }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: `${BUTTON_SIZE}px`,
        height: `${BUTTON_SIZE}px`,
      }}>
      {isImage ? (
        <img
          alt={'Icon'}
          src={icon}
          style={{
            width: `${BUTTON_SIZE - 4}px`,
            height: `${BUTTON_SIZE - 4}px`,
          }}
        />
      ) : (
        <Iconify
          icon={icon}
          width={`${BUTTON_SIZE - 4}px`}
          height={`${BUTTON_SIZE - 4}px`}
          color={color || '#a9abac'}
        />
      )}
    </Box>
  )
}

const ToolbarItemIconList = ({ icon, color, isImage, menuItems }) => {
  const ref = useRef(null)
  const [showList, setShowList] = useState(false)

  const onClose = () => {
    setShowList(false)
  }

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: `${BUTTON_SIZE}px`,
        height: `${BUTTON_SIZE}px`,
      }}
      onClick={() => {
        setShowList(!showList)
      }}>
      {isImage ? (
        <img
          alt={'Icon'}
          src={icon}
          style={{
            width: `${BUTTON_SIZE - 4}px`,
            height: `${BUTTON_SIZE - 4}px`,
          }}
        />
      ) : (
        <Iconify
          icon={icon}
          width={`${BUTTON_SIZE - 4}px`}
          height={`${BUTTON_SIZE - 4}px`}
          color={color || '#a9abac'}
        />
      )}
      {showList ? (
        Array.isArray(menuItems) && menuItems.length > 0 ? (
          <Menu
            sx={{
              zIndex: 10001,
              mt: '1px',
              '& .MuiMenu-paper': { backgroundColor: '#DDDDDD', border: '1px solid gray' },
            }}
            open={showList}
            onClose={onClose}
            anchorEl={ref.current}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
            {menuItems.map((item, index) => {
              if (item.isDivider) {
                return <Divider key={index} sx={{ bgcolor: 'primary.main' }} />
              }
              if (item.isCollapsible) {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      if (item.onClick) {
                        if (item.onClick()) onClose()
                      }
                    }}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText sx={{ color: '#000000' }}>{item.label}</ListItemText>
                    <ListItemIcon>{item.endIcon}</ListItemIcon>
                  </MenuItem>
                )
              }
              return (
                <MenuItem
                  key={index}
                  sx={{
                    color: item.selected ? '#DDDDDD' : '#000000',
                    backgroundColor: item.selected ? '#404040' : '#DDDDDD',
                    '&:hover': {
                      backgroundColor: '#808080',
                      color: '#000000',
                    },
                  }}
                  onClick={() => {
                    if (item.onClick) {
                      if (item.onClick()) onClose()
                    }
                  }}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>{item.label}</ListItemText>
                </MenuItem>
              )
            })}
          </Menu>
        ) : null
      ) : null}
    </Box>
  )
}

const getOrientationList = (callback, currentOrientation) => {
  let menuItems = []
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-landscape-20-regular'} color={'#000000'} />,
    label: `Landscape`,
    selected: currentOrientation === 'landscape',
    onClick: () => callback({ orientation: 'landscape' }),
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={'#000000'} />,
    label: 'Portrait',
    selected: currentOrientation === 'portrait',
    onClick: () => callback({ orientation: 'portrait' }),
  })
  return menuItems
}

const getMenuIconColor = (checkSize, currentSize) => {
  return checkSize === currentSize ? appColors.headerTextColor : '#000000'
}

const getPageSizeList = (callback, currentSize, callbackCustomSize) => {
  let menuItems = []
  menuItems.push({
    icon: (
      <ContextMenuItemIcon
        icon={'fluent:document-landscape-20-regular'}
        color={getMenuIconColor(currentSize, 'letter')}
      />
    ),
    label: `Letter 8.5" x 11"`,
    selected: currentSize === 'letter',
    onClick: () => {
      callback({ size: 'letter', width: 8.5, height: 11 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'tabloid')} />,
    label: 'Tabloid 11" x 17"',
    selected: currentSize === 'tabloid',
    onClick: () => {
      callback({ size: 'tabloid', width: 11, height: 17 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'legal')} />,
    label: 'Legal 8.5" x 14"',
    selected: currentSize === 'legal',
    onClick: () => {
      callback({ size: 'legal', width: 8.5, height: 14 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'A0')} />,
    label: 'A0 841mm x 1188mm',
    selected: currentSize === 'A0',
    onClick: () => {
      callback({ size: 'A0', width: 841 / 25.4, height: 1188 / 25.4 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'A1')} />,
    label: 'A1 594mm x 841mm',
    selected: currentSize === 'A1',
    onClick: () => {
      callback({ size: 'A1', width: 594 / 25.4, height: 841 / 25.4 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'A2')} />,
    label: 'A2 420mm x 594mm',
    selected: currentSize === 'A2',
    onClick: () => {
      callback({ size: 'A2', width: 420 / 25.4, height: 594 / 25.4 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'A3')} />,
    label: 'A3 297mm x 420mm',
    selected: currentSize === 'A3',
    onClick: () => {
      callback({ size: 'A3', width: 297 / 25.4, height: 420 / 25.4 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'A4')} />,
    label: 'A4 210mm x 297mm',
    selected: currentSize === 'A4',
    onClick: () => {
      callback({ size: 'A4', width: 210 / 25.4, height: 297 / 25.4 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'A5')} />,
    label: 'A5 148mm x 210mm',
    selected: currentSize === 'A5',
    onClick: () => {
      callback({ size: 'A5', width: 148 / 25.4, height: 210 / 25.4 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'Custom')} />,
    label: 'Custom',
    selected: currentSize === 'Custom',
    onClick: () => {
      callbackCustomSize(true) // open custom size modal
    },
  })
  return menuItems
}

const getZoomOptionsList = (units, callback) => {
  let menuItems = []
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'cil:zoom'} color={'#000000'} />,
    label: `50%`,
    selected: false,
    onClick: () => {
      callback('50%')
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'cil:zoom'} color={'#000000'} />,
    label: `75%`,
    selected: false,
    onClick: () => {
      callback('75%')
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'cil:zoom'} color={'#000000'} />,
    label: `100%`,
    selected: false,
    onClick: () => {
      callback('100%')
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'cil:zoom'} color={'#000000'} />,
    label: `Fit Whole Page`,
    selected: false,
    onClick: () => {
      callback('wholePage')
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'cil:zoom'} color={'#000000'} />,
    label: `200%`,
    selected: false,
    onClick: () => {
      callback('200%')
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'cil:zoom'} color={'#000000'} />,
    label: `300%`,
    selected: false,
    onClick: () => {
      callback('300%')
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'cil:zoom'} color={'#000000'} />,
    label: `Page Width`,
    selected: false,
    onClick: () => {
      callback('pageWidth')
    },
  })
  return menuItems
}

const getMarginOptionsList = (units, callback, currentMargin) => {
  let menuItems = []
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `Wide 1${units === 'm' ? 'cm' : 'in'}`,
    selected: currentMargin === 1.0,
    onClick: () => {
      callback({ marginTop: 1, marginBottom: 1, marginLeft: 1, marginRight: 1 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `Normal 0.5${units === 'm' ? 'cm' : 'in'}`,
    selected: currentMargin === 0.5,
    onClick: () => {
      callback({ marginTop: 0.5, marginBottom: 0.5, marginLeft: 0.5, marginRight: 0.5 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `Narrow 0.25${units === 'm' ? 'cm' : 'in'}`,
    selected: currentMargin === 0.25,
    onClick: () => {
      callback({ marginTop: 0.25, marginBottom: 0.25, marginLeft: 0.25, marginRight: 0.25 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `None`,
    selected: currentMargin === 0.0,
    onClick: () => {
      callback({ marginTop: 0.0, marginBottom: 0.0, marginLeft: 0.0, marginRight: 0.0 })
    },
  })
  return menuItems
}

const getGridOptionsList = (units, callback, currentSpacing) => {
  let menuItems = []
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `Wide 1${units === 'm' ? 'cm' : 'in'}`,
    selected: currentSpacing === 1.0,
    onClick: () => {
      callback({ gridSpacing: 1.0 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `Normal 0.5${units === 'm' ? 'cm' : 'in'}`,
    selected: currentSpacing === 0.5,
    onClick: () => {
      callback({ gridSpacing: 0.5 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `Small 0.25${units === 'm' ? 'cm' : 'in'}`,
    selected: currentSpacing === 0.25,
    onClick: () => {
      callback({ gridSpacing: 0.25 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `Narrow 0.125${units === 'm' ? 'cm' : 'in'}`,
    selected: currentSpacing === 0.125,
    onClick: () => {
      callback({ gridSpacing: 0.125 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `Tight 0.0625${units === 'm' ? 'cm' : 'in'}`,
    selected: currentSpacing === 0.0625,
    onClick: () => {
      callback({ gridSpacing: 0.0625 })
    },
  })
  return menuItems
}

const WpToolbar = ({ actions, selectedUids, config, units, pageLayout }) => {
  const { getWindowBarColor } = useInnovaTheme()

  const [showPageSizeModal, setShowPageSizeModal] = useState(false)
  const getToolBarItems = () => {
    let toolBarItems = [
      {
        icon: <ToolbarItemIcon icon={'pajamas:doc-new'} color={appColors.itemTextColor} />,
        label: 'New layout',
        onClick: () => {
          actions.onNewLayout()
        },
      },
      {
        icon: <ToolbarItemIcon icon={'fluent:save-28-regular'} color={'#00AA00'} />,
        label: 'Save layout',
        onClick: () => {
          actions.onSaveLayout()
        },
      },
      {
        icon: <ToolbarItemIcon icon={'fluent:save-as-24-regular'} color={'#00AA00'} />,
        label: 'Save layout as...',
        onClick: () => {
          actions.onSaveLayout(true)
        },
      },
      {
        icon: <ToolbarItemIcon icon={'material-symbols:file-open-outline-rounded'} color={'#AAAA00'} />,
        label: 'Load/manage layouts',
        onClick: () => {
          actions.onLoadLayout()
        },
      },
      {
        icon: <ToolbarItemIcon icon={'heroicons-outline:document-duplicate'} color={'#AAAA00'} />,
        label: 'Duplicate layout',
        onClick: () => {
          actions.onDuplicateLayout()
        },
      },
      {
        icon: <ToolbarItemIcon icon={'material-symbols:print-outline'} color={'#FFF'} />,
        label: 'Print',
        onClick: () => {
          actions.onPrint()
        },
      },
      {
        isDivider: true,
      },
      {
        icon: (
          <ToolbarAddChartIconList
            icon={'noto:chart-decreasing'}
            color={appColors.itemTextColor}
            action={actions.addElement}
          />
        ),
        label: 'Add chart',
      },
      {
        icon: (
          <ToolbarAddTableIconList
            icon={'vscode-icons:file-type-log'}
            color={appColors.itemTextColor}
            action={actions.addElement}
          />
        ),
        label: 'Add table',
      },
      {
        icon: (
          <ToolbarAddObjectIconList
            icon={'flat-color-icons:picture'}
            color={appColors.itemTextColor}
            action={actions.addElement}
          />
        ),
        label: 'Add object',
      },
      {
        isDivider: true,
      },
      {
        icon: <ToolbarItemIcon icon={'fa-regular:trash-alt'} color={'#C00000'} />,
        label: 'Delete elements',
        onClick: () => {
          actions.onDeleteElements()
        },
      },
      {
        isDivider: true,
      },
      {
        icon: <ToolbarItemIcon icon={'material-symbols:settings-outline'} color={appColors.itemTextColor} />,
        label: 'Properties',
        onClick: () => {
          actions.onShowProperties()
        },
      },
      {
        icon: <ToolbarItemIcon icon={'fluent:arrow-undo-24-regular'} color={appColors.itemTextColor} />,
        label: 'Undo',
        onClick: () => {
          actions.onUndo()
        },
      },
      {
        icon: <ToolbarItemIcon icon={'fluent:arrow-redo-24-regular'} color={appColors.itemTextColor} />,
        label: 'Redo',
        onClick: () => {
          actions.onRedo()
        },
      },
      {
        isDivider: true,
      },
      {
        icon: <ToolbarItemIcon icon={'carbon:bring-to-front'} color={appColors.itemTextColor} />,
        label: 'Bring to front',
        onClick: () => {
          if (actions.setZIndex) actions.setZIndex(selectedUids, 'front')
        },
      },
      {
        icon: <ToolbarItemIcon icon={'carbon:send-to-back'} color={appColors.itemTextColor} />,
        label: 'Send to back',
        onClick: () => {
          if (actions.setZIndex) actions.setZIndex(selectedUids, 'back')
        },
      },
      {
        isDivider: true,
      },
      {
        icon: (
          <ToolbarItemIcon
            icon={'radix-icons:ruler-square'}
            color={config?.showRulers ? '#00CC00' : appColors.headerTextColor}
          />
        ),
        label: 'Rulers',
        onClick: () => {
          actions.commitConfig({ showRulers: !config?.showRulers })
        },
      },
      {
        icon: (
          <ToolbarItemIcon
            icon={'material-symbols-light:background-grid-small-outline'}
            color={config?.showGrid ? '#00CC00' : appColors.headerTextColor}
          />
        ),
        label: 'Show grid',
        onClick: () => {
          actions.commitConfig({ showGrid: !config?.showGrid })
        },
      },
      {
        icon: (
          <ToolbarItemIcon
            icon={'fluent-mdl2:snap-to-grid'}
            color={config?.snapToGrid ? '#00CC00' : appColors.headerTextColor}
          />
        ),
        label: 'Snap to grid',
        onClick: () => {
          actions.commitConfig({ snapToGrid: !config?.snapToGrid })
        },
      },
      {
        icon: (
          <ToolbarItemIcon
            icon={'radix-icons:margin'}
            color={config?.showMargins ? '#00CC00' : appColors.headerTextColor}
          />
        ),
        label: 'Show margins',
        onClick: () => {
          actions.commitConfig({ showMargins: !config?.showMargins })
        },
      },
      {
        icon: (
          <ToolbarItemIcon
            icon={'tabler:zoom-reset'}
            color={config?.showMargins ? '#00CC00' : appColors.headerTextColor}
          />
        ),
        label: 'Reset page zoom',
        onClick: () => {
          if (actions.setZoom) actions.setZoom(1)
        },
      },
      {
        isDivider: true,
      },
      {
        icon: (
          <ToolbarItemIconList
            icon={'fluent:orientation-20-regular'}
            color={appColors.itemTextColor}
            menuItems={getOrientationList((e) => {
              actions.commitPageLayout(e)
            }, pageLayout?.orientation)}
          />
        ),
        label: 'Page orientation',
      },
      {
        icon: (
          <ToolbarItemIconList
            icon={'fluent-mdl2:size-legacy'}
            color={appColors.itemTextColor}
            menuItems={getPageSizeList(
              (e) => {
                actions.commitPageLayout(e)
              },
              pageLayout?.size,
              setShowPageSizeModal,
            )}
          />
        ),
        label: 'Page size',
      },
      {
        icon: (
          <ToolbarItemIconList
            icon={'material-symbols-light:background-grid-small-outline'}
            color={appColors.itemTextColor}
            menuItems={getGridOptionsList(
              units,
              (e) => {
                actions.commitPageLayout(e)
              },
              pageLayout?.gridSpacing,
            )}
          />
        ),
        label: 'Grid Spacing',
      },
      {
        icon: (
          <ToolbarItemIconList
            icon={'radix-icons:margin'}
            color={appColors.itemTextColor}
            menuItems={getMarginOptionsList(
              units,
              (e) => {
                actions.commitPageLayout(e)
              },
              pageLayout?.marginTop,
            )}
          />
        ),
        label: 'Margins',
      },
      {
        icon: (
          <ToolbarItemIconList
            icon={'octicon:zoom-in-16'}
            color={appColors.itemTextColor}
            menuItems={getZoomOptionsList(units, (e) => {
              actions.setZoom(e)
            })}
          />
        ),
        label: 'Zoom',
      },
    ]
    return toolBarItems
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: `${BUTTON_SIZE + 2}px`,
        background: getWindowBarColor(),
        display: 'flex',
        alignItems: 'center',
        padding: '2px',
        border: '1px solid #000',
      }}>
      {showPageSizeModal && (
        <PageSizeModal
          units={units}
          cancelFunction={() => setShowPageSizeModal(false)}
          submitFunction={(e) => {
            actions.commitPageLayout(e)
            setShowPageSizeModal(false)
          }}
          width={pageLayout.width}
          height={pageLayout.height}
        />
      )}
      {getToolBarItems().map((item) =>
        item.isDivider ? (
          <Iconify
            key={uuidv4()}
            icon={'fluent:divider-tall-16-regular'}
            width={`${BUTTON_SIZE - 4}px`}
            height={`${BUTTON_SIZE - 4}px`}
            color={'#a9abac'}
          />
        ) : (
          <Tooltip
            key={uuidv4()}
            title={item.label}
            placement='bottom'
            PopperProps={{
              style: { zIndex: 9999 }, //Required to draw on top of ruler
            }}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <Box
              sx={{
                width: `${BUTTON_SIZE}px`,
                height: `${BUTTON_SIZE}px`,
                color: '#FFFFFF',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: 'rgba(255,255,255,0.1)',
                },
              }}
              onClick={() => {
                if (item.onClick) item.onClick()
              }}>
              {item.icon}
            </Box>
          </Tooltip>
        ),
      )}
    </Box>
  )
}

export default WpToolbar
