import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import { appColors } from 'utils'
import ElementPropertyModal from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ElementPropertyModal'
import LabelPropertyModal from 'components/WellPages/WallplotComposer/Elements/LabelPropertyModal/LabelPropertyModal'
import { ELEMENT_TYPES as elementType } from 'components/WellPages/WallplotComposer/Elements/elementDefs'
import WallPlotChart from 'components/WellPages/WallplotComposer/Elements/ChartElements/WallPlotChart'
import { cloneDeep } from 'lodash'
import {
  LABEL_CATEGORIES as labelCategories,
  generateAllLabels,
} from 'components/WellPages/WallplotComposer/Elements/ChartElements/labelDefinitions'
import { DPI } from '../../Viewport/cssTransformUtils'

export const getChartElementDefaults = ({ pageLayout, zIndex }) => {
  return {
    uid: uuidv4(),
    type: elementType.chart,
    chartSettings: {
      majorTicksX: 10,
      minorTicksX: 0,
      majorTicksY: 10,
      minorTicksY: 0,
      gridBackgroundColor: '#FFF',
      majorGridLineColorX: '#404040',
      minorGridLineColorX: '#404040',
      majorGridLineColorY: '#404040',
      minorGridLineColorY: '#404040',
      showXAxisMajorGridLines: true,
      showXAxisMinorGridLines: false,
      showYAxisMajorGridLines: true,
      showYAxisMinorGridLines: false,
      showXAxisBottom: true,
      showXAxisTop: false,
      showYAxisLeft: true,
      showYAxisRight: false,
      showScales: true,
      fixedScaleCm: false,
      xAxisFixedScaleEnabled: false,
      yAxisFixedScaleEnabled: false,
      xAxisMinUserDefined: false,
      xAxisMaxUserDefined: false,
      xMin: 0,
      xMax: 100,
      xMinUser: 0,
      xMaxUser: 100,
      xAxisScale: -1,
      yAxisMinUserDefined: false,
      yAxisMaxUserDefined: false,
      yMinUser: 0,
      yMaxUser: 100,
      yMin: 0,
      yMax: 100,
      yAxisScale: -1,
      labels: {
        categories: [],
        categoryOptions: [
          {
            category: labelCategories.surveyPts,
            options: {
              mdTvd: 'MD',
              inc: false,
              azi: false,
              interval: 100,
              atSurvey: true,
              range: false,
              startDepth: 0,
              endDepth: 99999,
            },
          },
        ],
        labelData: [
          //uniqueness...
          // type: wellname, rownum, (depth)
          // position
          // stles
        ],
      },
    },
    left: pageLayout.marginLeft,
    top: pageLayout.marginTop,
    width: 3,
    height: 3,
    zIndex: zIndex,
    style: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#333',
      backgroundColor: '#fff',
      opacity: 1,
      cursor: 'pointer',
    },
  }
}

const ChartElement = forwardRef(({ id, element, wellData, actions, updateContainer, containerRef, scale }, ref) => {
  const [showProperties, setShowProperties] = useState(false)
  const [rerender, setRerender] = useState(false) // eslint-disable-line
  const currentContentRef = useRef({ style: element.style, chartSettings: element.chartSettings })
  const wallPlotChartRef = useRef(null)
  const [showLabelProperties, setShowLabelProperties] = useState(false)
  const labelRef = useRef(null)

  useImperativeHandle(ref, () => {
    return {
      showProperties() {
        setShowProperties(true)
      },
      getSettings() {
        return currentContentRef.current
      },
      resetChartZoom() {
        wallPlotChartRef.current.resetZoom()
      }
    }
  })

  const handleModalChange = (newData) => {
    let newContent = {
      style: { ...currentContentRef.current.style, ...newData.style },
      chartSettings: { ...currentContentRef.current.chartSettings, ...newData.context },
      // compile error // need to merge labelData in above chartSettings assignement. the above statement replaces the
      // the label data in currentContentRef.current.chartSettings with the empty labelData array from newData, which
      // we don't want to do. We want to merge the labelData arrays.
    }
    // currentContentRef.current = newContent
    // process label categories into chart data here...

    if (newContent.chartSettings.hasOwnProperty('labels')) {
      // remove offset label categories if showOffsets is false
      if (!newContent.chartSettings.showOffsets) {
        newContent.chartSettings.labels.categories = newContent.chartSettings.labels.categories.filter(
          (cat) => cat.category !== labelCategories.offsetWellNames && cat.category !== labelCategories.offsetSurveyPts,
        )
      }
      if (!newContent.chartSettings.showTargets) {
        newContent.chartSettings.labels.categories = newContent.chartSettings.labels.categories.filter(
          (cat) => cat.category !== labelCategories.targets,
        )
      }

      newContent.chartSettings.labels.labelData = generateAllLabels(
        newContent,
        currentContentRef.current.chartSettings,
        wellData,
      )
    }
    currentContentRef.current = newContent
    updateContainer({ ...newContent.style })
    actions.update(id, 'update', newContent)
    setShowProperties(false)
    setRerender((prev) => !prev)
  }

  const handleLabelPropsChange = (newData) => {
    // duplicate the current label, adjust the id/uid to make as user defined
    let newLabel = {}
    const oldLabel = currentContentRef.current.chartSettings.labels.labelData.find((label) => label.id === newData.id)
    if (!oldLabel) return
    if (oldLabel.uid.includes('userDefined')) {
      newLabel = oldLabel
      Object.assign(newLabel, newData)
    } else {
      newLabel = cloneDeep(oldLabel)
      Object.assign(newLabel, newData)
      newLabel.catType = labelCategories.userDefined
      newLabel.id = `userDefined_${uuidv4()}`
      newLabel.uid = newLabel.id
      // add new label to labels array
      currentContentRef.current.chartSettings.labels.labelData.push(newLabel)
    }

    if (
      !currentContentRef.current.chartSettings.labels.categories.find(
        (cat) => cat.category === labelCategories.userDefined,
      )
    )
      currentContentRef.current.chartSettings.labels.categories.push({
        'category': labelCategories.userDefined,
        'enabled': true,
        'color': '',
        'bold': true,
        'italic': false,
        'hasTether': true,
        'size': 12,
        'defaultColorEnabled': true,
        'defaultColor': '#FF5733',
        'angled': true,
        'backgroundEnabled': false,
        'background': '',
        'borderWidth': 0,
        'borderColor': '',
      })

    currentContentRef.current.chartSettings.labels.labelData = generateAllLabels(
      currentContentRef.current,
      currentContentRef.current.chartSettings,
      wellData,
    )

    // update the master wp settings
    labelRef.current = null
    setShowLabelProperties(false)
    setRerender((prev) => !prev)
  }

  const handleChartUpdate = (changeData) => {
    let newContent = {
      style: { ...currentContentRef.current.style },
      chartSettings: { ...currentContentRef.current.chartSettings, ...changeData },
    }
    actions.update(id, 'update', newContent)
    currentContentRef.current = newContent
    setRerender((prev) => !prev)
  }

  // in the below function, the 92 and 72 are the padding internal to chartjs
  // that is used to render the scales and axis labels.
  const handleChartResize = useCallback(
    (resizeData) => {
      actions.update(id, 'resizeSelf', resizeData)
      if (resizeData.width && containerRef.current)
        containerRef.current.style.width = `${resizeData.width * DPI * scale + 92}px`
      if (resizeData.height && containerRef.current)
        containerRef.current.style.height = `${resizeData.height * DPI * scale + 72}px`
    },
    [actions, containerRef, id, scale],
  )

  const handleChartUpdateNoRender = (changeData) => {
    let newContent = {
      style: { ...currentContentRef.current.style },
      chartSettings: { ...currentContentRef.current.chartSettings, ...cloneDeep(changeData) },
    }
    currentContentRef.current = newContent // significant functionality change!!!
    actions.update(id, 'updateSettings', newContent)
  }

  const handleElementProps = (element) => {
    // Perform state update here inside the setTimeout, to allow the chart to finish its render cycle (the plugin
    // event handler counts as render cycle...)
    setTimeout(() => {
      setShowProperties(true)
    }, 0)
  }

  const handleLabelProps = useCallback((element) => {
    // Perform state update here inside the setTimeout, to allow the chart to finish its render cycle (the plugin
    // event handler counts as render cycle...)
    labelRef.current = element
    setTimeout(() => {
      setShowLabelProperties(true)
    }, 0)
  }, [])

  const getChartSettings = () => {
    let chartSettings = {
      ...(wallPlotChartRef.current?.getSettings() || {}),
      ...currentContentRef.current.chartSettings,
    }

    if (!wellData) return chartSettings

    // if there are no settings predefined, add the main well (0th offset well)
    if (!chartSettings.hasOwnProperty('series') || !chartSettings.series) {
      chartSettings.series = [
        {
          wellName: wellData.actualWellData.actualWellName,
          color:
            wellData.actualWellData.isPlan === false ? appColors.surveyTrajectoryColor : appColors.planTrajectoryColor,
          defaultColor:
            wellData.actualWellData.isPlan === false ? appColors.surveyTrajectoryColor : appColors.planTrajectoryColor,
          randomColor: '',
          lineStyle: 'solid',
          lineThickness: 2,
          type: wellData.actualWellData.isPlan === false ? 'Actual' : 'Plan',
          isPrincipal: wellData.offsetWells[0].isPrincipal,
          visible: true,
        },
      ]

      // add the offset wells
      if (wellData.offsetWells?.length > 2) {
        for (let i = 1; i < wellData.offsetWells.length; i++) {
          chartSettings.series.push({
            wellName: wellData.offsetWells[i].offsetWellbore,
            color:
              wellData.offsetWells[i].wellStatus === 'Actual'
                ? appColors.surveyTrajectoryColor
                : appColors.planTrajectoryColor,
            defaultColor:
              wellData.offsetWells[i].wellStatus === 'Actual'
                ? appColors.surveyTrajectoryColor
                : appColors.planTrajectoryColor,
            randomColor: '',
            lineStyle: 'solid',
            lineThickness: 1,
            type: wellData.offsetWells[i].wellStatus,
            isPrincipal: wellData.offsetWells[i].isPrincipal,
            visible: true,
          })
        }
      }
    }

    // if targets exist in the chart settings, compare them the list of targets in the wellData
    if (chartSettings.hasOwnProperty('targets') && Array.isArray(chartSettings.targets)) {
      // first, add any new targets to the chart settings
      wellData.targets.forEach((target) => {
        let found = chartSettings.targets.find((t) => t.targetName === target.targetName)
        if (!found) {
          chartSettings.targets.push({
            uid: target.uid,
            targetName: target.targetName,
            isLeaseLine: target.isLeaseLine,
            color: target.isLeaseLine ? '#FF0000' : '#000000',
            lineStyle: 'solid',
            visible: true,
            filled: false,
            fillColor: target.isLeaseLine ? '#FFCACA' : '#CACACA',
          })
        }
      })
      // filter out any targets that no longer exist in the wellData
      chartSettings.targets = chartSettings.targets.filter((t) =>
        wellData.targets.find((tgt) => tgt.targetName === t.targetName),
      )
    }

    if (!chartSettings.hasOwnProperty('targets') || !Array.isArray(chartSettings.targets)) {
      let targets = []
      wellData.targets.forEach((target) => {
        targets.push({
          uid: target.uid,
          targetName: target.targetName,
          isLeaseLine: target.isLeaseLine,
          color: target.isLeaseLine ? '#FF0000' : '#000000',
          lineStyle: 'solid',
          visible: true,
          filled: false,
          fillColor: target.isLeaseLine ? '#FFCACA' : '#CACACA',
        })
      })

      chartSettings.targets = targets
    }

    return cloneDeep(chartSettings)
  }

  return (
    <>
      {showProperties && (
        <ElementPropertyModal
          onClose={() => setShowProperties(false)}
          elementStyle={currentContentRef.current.style}
          contextData={getChartSettings()}
          onApply={handleModalChange}
          elemType={elementType.chart}
          elemSubType={element.chartSettings.chartType}
        />
      )}
      {showLabelProperties && (
        <LabelPropertyModal
          onClose={() => {
            labelRef.current = null
            setShowLabelProperties(false)
          }}
          element={labelRef.current}
          onApply={handleLabelPropsChange}
        />
      )}
      <div
        style={{
          display: 'flex',
          height: 'calc(100% - 4px)',
          width: 'calc(100% - 4px)',
          position: 'absolute',
        }}>
        <Box
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            color: currentContentRef.current.style.color,
            padding: '0 8px',
            opacity: 1,
            cursor: currentContentRef.current.style.cursor,
          }}>
          <Box sx={{ width: '100%', height: '100%' }}>
            <WallPlotChart
              ref={wallPlotChartRef}
              data={wellData}
              refWellSurveys={wellData?.surveys}
              isPlan={false}
              chartType={element.chartSettings.chartType}
              onUpdate={handleChartUpdate}
              onUpdateNoRender={handleChartUpdateNoRender}
              onUpdateResize={handleChartResize}
              chartSettings={getChartSettings()}
              onShowElementProps={handleElementProps}
              onShowLabelProps={handleLabelProps}
              zoomScale={scale}
            />
          </Box>
        </Box>
      </div>
    </>
  )
})

export default ChartElement
