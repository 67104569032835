import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box } from '@mui/material'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
// import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { DPI } from '../../Viewport/cssTransformUtils'

const ChartScalesGrid = forwardRef(({ chartData }, ref) => {
  const gridApiXY = useRef(null)
  const gridApiFixed = useRef(null)
  // const { getUnitsText } = useUnits()
  const { theme, getAgGridTheme } = useInnovaTheme()

  const getRowIdXY = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getRowIdFixed = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApiXY.current) gridApiXY.current.stopEditing()
      if (gridApiFixed.current) gridApiFixed.current.stopEditing()
    },
    getData: () => {
      let gridData = []
      let gridDataXY = []
      gridApiXY.current?.forEachNode((node) => {
        if (node.data) gridDataXY.push(node.data)
      })

      let gridDataFixed = []
      gridApiFixed.current?.forEachNode((node) => {
        if (node.data) gridDataFixed.push(node.data)
      })

      // return data in property format
      // xAxis
      gridData.push({ tag: 'xMinUser', value: gridDataXY[0].min })
      gridData.push({ tag: 'xMin', value: gridDataXY[0].min })
      gridData.push({ tag: 'xAxisMinUserDefined', value: gridDataXY[0].minUserDefined })
      gridData.push({ tag: 'xMaxUser', value: gridDataXY[0].max })
      gridData.push({ tag: 'xMax', value: gridDataXY[0].max })
      gridData.push({ tag: 'xAxisMaxUserDefined', value: gridDataXY[0].maxUserDefined })
      gridData.push({ tag: 'xAxisScale', value: gridDataXY[0].scale })
      gridData.push({ tag: 'xAxisFixedScaleEnabled', value: gridDataXY[0].scaleUserDefined })
      // yAxis
      gridData.push({ tag: 'yMinUser', value: gridDataXY[1].min })
      gridData.push({ tag: 'yMin', value: gridDataXY[1].min })
      gridData.push({ tag: 'yAxisMinUserDefined', value: gridDataXY[1].minUserDefined })
      gridData.push({ tag: 'yMaxUser', value: gridDataXY[1].max })
      gridData.push({ tag: 'yMax', value: gridDataXY[1].max })
      gridData.push({ tag: 'yAxisMaxUserDefined', value: gridDataXY[1].maxUserDefined })
      gridData.push({ tag: 'yAxisScale', value: gridDataXY[1].scale })
      gridData.push({ tag: 'yAxisFixedScaleEnabled', value: gridDataXY[1].scaleUserDefined })
      // fixed Grid
      gridData.push({ tag: 'fixedScaleCm', value: gridDataFixed[0].fixedScaleCm })
      gridData.push({ tag: 'showScales', value: gridDataFixed[0].showScales })

      return { props: gridData }
    },
  }))

  const onGridReadyXY = (params) => {
    gridApiXY.current = params.api
  }

  const onGridReadyFixed = (params) => {
    gridApiFixed.current = params.api
  }

  const autoSizeColumnsXY = () => {
    if (gridApiXY.current === null) return
    gridApiXY.current?.autoSizeAllColumns()
  }

  const autoSizeColumnsFixed = () => {
    if (gridApiFixed.current === null) return
    gridApiFixed.current?.autoSizeAllColumns()
  }

  const onFirstDataRenderedXY = (params) => {
    if (gridApiXY.current) autoSizeColumnsXY()
  }

  const onFirstDataRenderedFixed = (params) => {
    if (gridApiFixed.current) autoSizeColumnsFixed()
  }

  const defaultColDefXY = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const defaultColDefFixed = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptionsXY = {
    suppressRowClickSelection: true,
  }

  const gridOptionsFixed = {
    suppressRowClickSelection: true,
  }

  const getGridDataXY = (params) => {
    let gridData = { x: {}, y: {} }
    gridApiXY.current.forEachNode((node) => {
      Object.keys(node.data).forEach((key) => {
        gridData[node.id === '0' ? 'x' : 'y'][key] = node.data[key]
      })
    })
    return gridData
  }

  const getGridDataFixed = () => {
    let gridData = {}
    gridApiFixed.current.forEachNode((node) => {
      Object.keys(node.data).forEach((key) => {
        gridData[key] = node.data[key]
      })
    })
    return gridData
  }

  const handleCellValueChangedXY = (params) => {
    let gridDataFixed = getGridDataFixed()

    switch (params.column.colId) {
      case 'min':
        // sanity check
        if (params.newValue >= params.node.data.max - 1) {
          params.node.setDataValue('min', params.node.data.max - 1)
          return
        }

        // update the scale if not user defined
        if (!params.data.scaleUserDefined) {
          params.api.forEachNode((node) => {
            if (node.id === params.node.id) {
              let pixelsPerDisplayUnit = DPI // 72 // 72 pixels per inch
              if (gridDataFixed.fixedScaleCm) pixelsPerDisplayUnit = DPI / 2.54 // 28.3465 // 28.3465 pixels per cm
              let diff = params.node.data.max - params.node.data.min // units (ft or m)
              let scale = diff / (chartData.width / pixelsPerDisplayUnit)
              node.setDataValue('scale', scale)
            }
          })
        }
        if (params.data.scaleUserDefined && !params.data.maxUserDefined) {
          params.api.forEachNode((node) => {
            if (node.id === params.node.id) {
              let diff = params.newValue - params.oldValue // units (ft or m)
              node.setDataValue('max', node.data.max + diff)
            }
          })
        }
        break
      case 'max':
        // sanity check
        if (params.newValue <= params.node.data.min + 1) {
          params.node.setDataValue('max', params.node.data.min + 1)
          return
        }
        // update the scale if not user defined
        if (!params.data.scaleUserDefined) {
          params.api.forEachNode((node) => {
            if (node.id === params.node.id) {
              let pixelsPerDisplayUnit = DPI // 72 pixels per inch
              if (gridDataFixed.fixedScaleCm) pixelsPerDisplayUnit = DPI / 2.54 // 28.3465 // 28.3465 pixels per cm
              let diff = params.node.data.max - params.node.data.min // units (ft or m)
              let scale = diff / (chartData.width / pixelsPerDisplayUnit)
              node.setDataValue('scale', scale)
            }
          })
        }
        if (params.data.scaleUserDefined && !params.data.minUserDefined) {
          params.api.forEachNode((node) => {
            if (node.id === params.node.id) {
              let diff = params.newValue - params.oldValue // units (ft or m)
              node.setDataValue('min', node.data.min + diff)
            }
          })
        }
        break
      case 'scale':
        params.api.forEachNode((node) => {
          if (node.id === params.node.id) {
            const diff = params.node.data.max - params.node.data.min // units (ft or m)
            const currSize = diff / params.oldValue // get axis length in display units
            const newDiff = currSize * params.newValue // get new axis length in display units
            const sizeDiff = newDiff - diff // get the difference in size
            const halfDiff = Math.abs(sizeDiff) / 2
            // if the min and max are not user defined, adjust them based on the whether
            // the scale is zooming in or out
            if (!node.data.minUserDefined && !node.data.maxUserDefined) {
              if (params.oldValue > params.newValue) {
                // zooming in
                node.setDataValue('min', node.data.min + halfDiff)
                node.setDataValue('max', node.data.max - halfDiff)
              } else {
                // zooming out
                node.setDataValue('min', node.data.min - halfDiff)
                node.setDataValue('max', node.data.max + halfDiff)
              }
            }
            if (node.data.minUserDefined && !node.data.maxUserDefined) {
              if (params.oldValue > params.newValue) {
                // zooming in
                node.setDataValue('max', node.data.max - diff)
              } else {
                // zooming out
                node.setDataValue('max', node.data.max - diff)
              }
            }
            if (!node.data.minUserDefined && node.data.maxUserDefined) {
              if (params.oldValue > params.newValue) {
                // zooming in
                node.setDataValue('min', node.data.min - diff)
              } else {
                // zooming out
                node.setDataValue('min', node.data.min - diff)
              }
            }
            if (node.data.minUserDefined && node.data.maxUserDefined) {
              console.warn('no action required')
            }
          }
        })
        break
      default:
        break
    }
  }

  const handleCellValueChangedFixed = (params) => {
    let gridDataXY = getGridDataXY()
    if (params.column.colId === 'fixedScaleCm') {
      let pixelsPerDisplayUnit = DPI // 72
      if (params.data.fixedScaleCm) pixelsPerDisplayUnit = DPI / 2.54 // 28.3465
      let xDiff = gridDataXY.x.max - gridDataXY.x.min
      let xScale = xDiff / (chartData.width / pixelsPerDisplayUnit)
      let yDiff = gridDataXY.y.max - gridDataXY.y.min
      let yScale = yDiff / (chartData.height / pixelsPerDisplayUnit)

      gridApiXY.current.refreshHeader()

      gridApiXY.current.forEachNode((node) => {
        switch (node.id) {
          case '0':
            node.setDataValue('scale', xScale)
            break
          case '1':
            node.setDataValue('scale', yScale)
            break
          default:
            break
        }
      })
    }
  }

  const checkIsEditableXY = (params) => {
    let returnVal = true
    switch (params.column.colId) {
      case 'min':
        returnVal = false
        if (params.data?.minUserDefined) returnVal = true
        break
      case 'max':
        returnVal = false
        if (params.data?.maxUserDefined) returnVal = true
        break
      case 'scale':
        returnVal = false
        if (params.data?.scaleUserDefined) returnVal = true
        break
      default:
        break
    }
    return returnVal
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const rightAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
    }),
    [],
  )

  const getPrecision = (value) => {
    let precision = 0
    if (!value) return precision

    if (value < 10) {
      precision = 2
    }
    return precision
  }

  const columnDefsXY = useMemo(
    () => [
      {
        headerName: 'Axis',
        field: 'axis',
        colId: 'axis',
        minWidth: 75,
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'Min',
        children: [
          {
            headerName: 'Value',
            field: 'min',
            colId: 'min',
            flex: 1,
            editable: (params) => checkIsEditableXY(params),
            cellStyle: rightAlignCell,
            cellEditorSelector: (params) => {
              return {
                component: 'agTextCellEditor',
                params: {
                  min: params.data?.min,
                  max: params.data?.max,
                  precision: params.data?.precision ? params.data?.precision : 0,
                },
              }
            },
            valueFormatter: (params) => {
              return `${numberWithCommasDecimals(params?.value, params.data?.precision || 0)}`
            },
          },
          {
            headerName: 'User Def',
            field: 'minUserDefined',
            colId: 'minUserDefined',
            flex: 1,
            editable: true,
            cellStyle: centerAlignCell,
            cellEditorSelector: (params) => {
              return {
                component: 'agCheckboxCellEditor',
              }
            },
          },
        ],
      },
      {
        headerName: 'Max',
        children: [
          {
            headerName: 'Value',
            field: 'max',
            colId: 'max',
            flex: 1,
            editable: (params) => checkIsEditableXY(params),
            cellStyle: rightAlignCell,
            cellEditorSelector: (params) => {
              return {
                component: 'agTextCellEditor',
                params: {
                  min: params.data?.min,
                  max: params.data?.max,
                  precision: params.data?.precision ? params.data?.precision : 0,
                },
              }
            },
            valueFormatter: (params) => {
              return `${numberWithCommasDecimals(params?.value, params.data?.precision || 0)}`
            },
          },
          {
            headerName: 'User Def',
            field: 'maxUserDefined',
            colId: 'maxUserDefined',
            flex: 1,
            editable: true,
            cellStyle: centerAlignCell,
            cellEditorSelector: (params) => {
              return {
                component: 'agCheckboxCellEditor',
              }
            },
          },
        ],
      },
      {
        headerName: 'Scale',
        groupId: 'scaleGroup',
        colId: 'scaleGroup',
        children: [
          {
            headerName: 'Value',
            field: 'scale',
            colId: 'scale',
            flex: 1,
            editable: (params) => checkIsEditableXY(params),
            cellStyle: centerAlignCell,
            cellEditorSelector: (params) => {
              return {
                component: 'agTextCellEditor',
                params: {
                  min: params.data?.min,
                  max: params.data?.max,
                  precision: params.data?.precision ? params.data?.precision : 0,
                },
              }
            },
            valueFormatter: (params) => {
              return `${numberWithCommasDecimals(params?.value, params.data?.precision || getPrecision(params?.value))}`
            },
          },
          {
            headerName: 'User Def',
            field: 'scaleUserDefined',
            colId: 'scaleUserDefined',
            flex: 1,
            editable: true,
            cellStyle: centerAlignCell,
            cellEditorSelector: (params) => {
              return {
                component: 'agCheckboxCellEditor',
              }
            },
          },
        ],
      },
    ],
    [rightAlignCell, centerAlignCell],
  )

  const columnDefsFixed = useMemo(
    () => [
      {
        headerName: 'Scale/cm',
        field: 'fixedScaleCm',
        colId: 'fixedScaleCm',
        flex: 1,
        editable: true,
        cellStyle: centerAlignCell,
        cellEditorSelector: (params) => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
      {
        headerName: 'Show Scales',
        field: 'showScales',
        colId: 'showScales',
        flex: 1,
        editable: true,
        cellStyle: centerAlignCell,
        cellEditorSelector: (params) => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
    ],
    [centerAlignCell],
  )

  const getRowDataXY = useCallback(() => {
    let propertyData = []
    if (!chartData) return propertyData

    propertyData.push({
      axis: `X`,
      min: chartData.xAxisMinUserDefined ? chartData.xMinUser : chartData.xMin,
      minUserDefined: chartData?.xAxisMinUserDefined ? chartData.xAxisMinUserDefined : false,
      max: chartData.xAxisMaxUserDefined ? chartData.xMaxUser : chartData.xMax,
      maxUserDefined: chartData.xAxisMaxUserDefined ? chartData.xAxisMaxUserDefined : false,
      scale: chartData.xAxisScale,
      scaleUserDefined: chartData.xAxisFixedScaleEnabled ? chartData.xAxisFixedScaleEnabled : false,
    })
    propertyData.push({
      axis: `Y`,
      min: chartData.yAxisMinUserDefined ? chartData.yMinUser : chartData.yMin,
      minUserDefined: chartData.yAxisMinUserDefined ? chartData.yAxisMinUserDefined : false,
      max: chartData.yAxisMaxUserDefined ? chartData.yMaxUser : chartData.yMax,
      maxUserDefined: chartData.yAxisMaxUserDefined ? chartData.yAxisMaxUserDefined : false,
      scale: chartData.yAxisScale,
      scaleUserDefined: chartData.yAxisFixedScaleEnabled ? chartData.yAxisFixedScaleEnabled : false,
    })

    for (let i = 0; i < propertyData.length; i++) {
      propertyData[i].id = i
    }

    return propertyData
  }, [chartData])

  const getRowDataFixed = useCallback(() => {
    let propertyData = []
    if (!chartData) return propertyData

    propertyData.push({
      fixedScaleCm: chartData.hasOwnProperty('fixedScaleCm') ? chartData.fixedScaleCm : false,
      showScales: chartData.hasOwnProperty('showScales') ? chartData.showScales : true,
    })

    for (let i = 0; i < propertyData.length; i++) {
      propertyData[i].id = i
    }

    return propertyData
  }, [chartData])

  const HeaderText = ({ text }) => {
    return (
      <Box
        sx={{
          textAlign: 'center',
          color: theme === 'dark' ? '#C0C0C0' : '#222222',
          width: '100%',
          fontSize: '16px',
          fontWeight: 800,
          marginTop: '20px',
          marginBottom: '15px',
        }}>
        {text}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 2px)',
        height: '100%',
        border: '1px solid',
        borderColor: '#68686e',
      }}>
      <HeaderText text={'X & Y Axis Defs'} />
      <Box className='gridbox' sx={{ display: 'flex', height: '200px', width: '100%' }}>
        <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
          <AgGridReact
            // ref={gridApiXY}
            columnDefs={columnDefsXY}
            defaultColDef={defaultColDefXY}
            gridOptions={gridOptionsXY}
            onGridReady={onGridReadyXY}
            rowData={getRowDataXY()}
            headerHeight={30}
            onFirstDataRendered={onFirstDataRenderedXY}
            getRowId={getRowIdXY}
            onCellEditingStopped={handleCellValueChangedXY}
          />
        </div>
      </Box>
      <HeaderText text={'Scale Options'} />
      <Box className='gridbox2' sx={{ display: 'flex', height: '100px', width: '100%' }}>
        <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
          <AgGridReact
            columnDefs={columnDefsFixed}
            defaultColDef={defaultColDefFixed}
            gridOptions={gridOptionsFixed}
            onGridReady={onGridReadyFixed}
            rowData={getRowDataFixed()}
            headerHeight={30}
            onFirstDataRendered={onFirstDataRenderedFixed}
            getRowId={getRowIdFixed}
            onCellEditingStopped={handleCellValueChangedFixed}
          />
        </div>
      </Box>
    </Box>
  )
})

export default ChartScalesGrid
