import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import ColorPickerCellEditor from 'components/common/CellEditors/ColorPickerCellEditor'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const LabeledCheckboxCellRenderer = (props) => {
  const { value, data, colDef, api, label } = props
  const checkboxLabel = label || ''

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div className='ag-cell-edit-input' role='presentation'>
        <input
          type='checkbox'
          checked={value}
          onChange={(e) => {
            // Update the value in the grid
            api.applyTransaction({
              update: [{ ...data, [colDef.field]: e.target.checked }],
            })
          }}
        />
      </div>
      <span style={{ marginLeft: '10px' }}>{checkboxLabel}</span>
    </div>
  )
}

const ChartAxesXYGrid = forwardRef(({ chartData }, ref) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApi.current) gridApi.current.stopEditing()
    },
    getData: () => {
      let gridData = []
      gridApi.current?.forEachNode((node) => {
        if (node.data) gridData.push(node.data)
      })
      // convert from grid to properties
      let props = []
      for (let i = 0; i < gridData.length; i++) {
        props.push({
          tag: gridData[i].xTag,
          value: gridData[i].xValue,
        })
        props.push({
          tag: gridData[i].yTag,
          value: gridData[i].yValue,
        })
      }
      return { props: props }
    },
  }))

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
    components: {
      labeledCheckBox: LabeledCheckboxCellRenderer,
    },
  }

  const getCheckboxLabel = (params) => {
    if (!params) return ''
    let label = ''
    if (params.colDef.field === 'xValue') {
      label = params.data.xLabel
    }
    if (params.colDef.field === 'yValue') {
      label = params.data.yLabel
    }
    return label
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'label',
        colId: 'label',
        minWidth: 150,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        headerName: 'X Axis',
        field: 'xValue',
        colId: 'xValue',
        flex: 1,
        editable: true,
        cellDataType: false,
        cellStyle: (params) => {
          if (params.data?.type === 'color') {
            return {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              backgroundColor: params.value,
              color: params.value,
            }
          }
          return { display: 'flex', alignItems: 'center', justifyContent: 'start' }
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'dropDown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          if (params.data?.type === 'color') {
            return {
              component: ColorPickerCellEditor,
              params: {
                color: params.value,
                defaultColor: params.data?.defaultValue,
              },
              popup: true,
            }
          }

          if (params.data?.type === 'number') {
            return {
              component: 'agTextCellEditor',
              params: {
                min: params.data?.min,
                max: params.data?.max,
                precision: params.data?.precision,
              },
            }
          }

          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          if (params.data?.type === 'labeledBool') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          return {
            component: 'agTextCellEditor',
          }
        },
        valueSetter: (params) => {
          if (params.oldValue === params.newValue) return

          let gridData = []
          gridApi.current.forEachNode((node) => {
            if (node.data) gridData.push(node.data)
          })

          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (index < 0) return

          if (params?.data?.type === 'number') {
            if (params.newValue === null || params.newValue === undefined) params.newValue = 0
            params.newValue = parseFloat(params.newValue)
          }

          gridData[index][params.colDef.field] = params.newValue

          gridApi.current.applyTransaction({ update: gridData })
        },
        cellRendererSelector: (params) => {
          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellRenderer',
            }
          }

          if (params.data?.type === 'labeledBool') {
            return {
              component: 'labeledCheckBox',
              params: {
                label: getCheckboxLabel(params),
              },
            }
          }

          return null
        },
        valueFormatter: (params) => {
          if (params?.data?.type === 'number') {
            return `${numberWithCommasDecimals(params?.data?.xValue, params.data?.precision || 0)}`
          }
          return params?.data?.xValue
        },
      },
      {
        headerName: 'Y Axis',
        field: 'yValue',
        colId: 'yValue',
        flex: 1,
        editable: true,
        cellDataType: false,
        cellStyle: (params) => {
          if (params.data?.type === 'color') {
            return {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              backgroundColor: params.value,
              color: params.value,
            }
          }
          return { display: 'flex', alignItems: 'center', justifyContent: 'start' }
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'dropDown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          if (params.data?.type === 'color') {
            return {
              component: ColorPickerCellEditor,
              params: {
                color: params.value,
                defaultColor: params.data?.defaultValue,
              },
              popup: true,
            }
          }

          if (params.data?.type === 'number') {
            return {
              component: 'agTextCellEditor',
              params: {
                min: params.data?.min,
                max: params.data?.max,
                precision: params.data?.precision,
              },
            }
          }

          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          if (params.data?.type === 'labeledBool') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          return {
            component: 'agTextCellEditor',
          }
        },
        valueSetter: (params) => {
          if (params.oldValue === params.newValue) return

          let gridData = []
          gridApi.current.forEachNode((node) => {
            if (node.data) gridData.push(node.data)
          })

          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (index < 0) return

          if (params?.data?.type === 'number') {
            if (params.newValue === null || params.newValue === undefined) params.newValue = 0
            params.newValue = parseFloat(params.newValue)
          }

          gridData[index][params.colDef.field] = params.newValue
          // gridData[index].value = params.newValue

          gridApi.current.applyTransaction({ update: gridData })
        },
        cellRendererSelector: (params) => {
          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellRenderer',
            }
          }

          if (params.data?.type === 'labeledBool') {
            return {
              component: 'labeledCheckBox',
              params: {
                label: getCheckboxLabel(params),
              },
            }
          }

          return null
        },
        valueFormatter: (params) => {
          if (params?.data?.type === 'number') {
            return `${numberWithCommasDecimals(params?.data?.yValue, params.data?.precision || 0)}`
          }
          return params?.data?.yValue
        },
      },
    ],
    [],
  )

  const getPropertyData = (data) => {
    let rowData = []
    if (!data) return rowData

    rowData.push({
      label: '',
      xValue: true,
      yValue: true,
      type: 'labeledBool',
      xLabel: 'Bottom',
      yLabel: 'Left',
      xTag: 'showXAxisBottom',
      yTag: 'showYAxisLeft',
    })
    rowData.push({
      label: '',
      xValue: false,
      yValue: false,
      type: 'labeledBool',
      xLabel: 'Top',
      yLabel: 'Right',
      xTag: 'showXAxisTop',
      yTag: 'showYAxisRight',
    })
    rowData.push({
      label: 'Show Major Gridlines',
      xValue: true,
      yValue: true,
      type: 'bool',
      xTag: 'showXAxisMajorGridLines',
      yTag: 'showYAxisMajorGridLines',
    })
    rowData.push({
      label: 'Show Minor Gridlines',
      xValue: false,
      yValue: false,
      type: 'bool',
      xTag: 'showXAxisMinorGridLines',
      yTag: 'showYAxisMinorGridLines',
    })
    rowData.push({
      label: 'Major Ticks',
      xValue: 10,
      yValue: 10,
      type: 'number',
      xTag: 'majorTicksX',
      yTag: 'majorTicksY',
    })
    rowData.push({
      label: 'Minor Ticks',
      xValue: 10,
      yValue: 10,
      type: 'number',
      xTag: 'minorTicksX',
      yTag: 'minorTicksY',
    })
    rowData.push({
      label: 'Major Grid Color',
      xValue: '#aaaaaa',
      yValue: '#aaaaaa',
      type: 'color',
      xTag: 'majorGridLineColorX',
      yTag: 'majorGridLineColorY',
    })
    rowData.push({
      label: 'Minor Grid Color',
      xValue: '#aaaaaa',
      yValue: '#aaaaaa',
      type: 'color',
      xTag: 'minorGridLineColorX',
      yTag: 'minorGridLineColorY',
    })

    for (let i = 0; i < rowData.length; i++) {
      rowData[i].id = i
    }

    for (let i = 0; i < rowData.length; i++) {
      if (rowData[i].xTag !== '' && data.hasOwnProperty(rowData[i].xTag)) {
        rowData[i].xValue = data[rowData[i].xTag]
      }
      if (rowData[i].yTag !== '' && data.hasOwnProperty(rowData[i].yTag)) {
        rowData[i].yValue = data[rowData[i].yTag]
      }
    }

    return rowData
  }

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowData={getPropertyData(chartData)}
        headerHeight={30}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
})

export default ChartAxesXYGrid
