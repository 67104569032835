import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { Line } from 'react-chartjs-2'
import { cloneDeep, merge } from 'lodash'
import { useResizeDetector } from 'react-resize-detector'

import { getChartData } from 'components/WellPages/CommonChartDef/ChartDefs'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { CHART_TYPES as chartTypes } from '../elementDefs'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { DPI } from '../../Viewport/cssTransformUtils'
import { LABEL_CATEGORIES, hasLabelCategory } from './labelDefinitions'

// draggability for chartjs-plugin-annotation:
let element
let lastEvent
let dragging = false

const drag = function (moveX, moveY) {
  element.x += moveX
  element.y += moveY
  element.x2 += moveX
  element.y2 += moveY
  element.centerX += moveX
  element.centerY += moveY
  if (element.elements && element.elements.length) {
    for (const subEl of element.elements) {
      subEl.x += moveX
      subEl.y += moveY
      subEl.x2 += moveX
      subEl.y2 += moveY
      subEl.centerX += moveX
      subEl.centerY += moveY
      subEl.bX += moveX
      subEl.bY += moveY
    }
  }
}

const handleElementDragging = function (event, eRef, scaleRef) {
  if (!lastEvent || !element /*|| !event.native.altKey*/) {
    return
  }
  const moveX = event.x - lastEvent.x
  const moveY = event.y - lastEvent.y
  if (eRef().current) {
    eRef().current.moveOffset.x += convertPixelsToUserUnits(moveX, scaleRef().current.x, scaleRef().current.cm)
    eRef().current.moveOffset.y += convertPixelsToUserUnits(moveY, scaleRef().current.y, scaleRef().current.cm)
  } else {
    console.warn('no element ref', event, element)
  }
  drag(moveX, moveY)
  lastEvent = event
  return true
}

const handleDrag = function (event, eRef, scaleRef) {
  if (element) {
    switch (event.type) {
      case 'mousemove':
        return handleElementDragging(event, eRef, scaleRef)
      case 'mouseout':
      case 'mouseup':
        dragging = false
        lastEvent = undefined
        break
      case 'mousedown':
        dragging = true
        lastEvent = event
        break
      default:
    }
  }
}

const dragger = {
  id: 'dragger',
  beforeEvent(chart, args, options) {
    if (handleDrag(args.event, options.elementRef, options.scaleRef)) {
      args.changed = true
      return
    }
  },
}

const doubleClickPlugin = {
  id: 'doubleClickPlugin',
  beforeEvent(chart, args, options) {
    const event = args.event
    if (event.type === 'dblclick') {
      if (element) {
        event.native.stopPropagation()
        event.native.preventDefault()
        options.showLabelProps(element)
      } else {
        event.native.stopPropagation()
        event.native.preventDefault()
        options.showElementProps()
      }
    }
  },
}

const borderPlugIn = {
  id: 'borderPlugIn',
  beforeDatasetsDraw: (chart, args, options) => {
    // if (!options?.color) return
    const ctx = chart.ctx
    const chartArea = chart.chartArea
    if (chartArea) {
      ctx.save()
      ctx.strokeStyle = options.color || '#000000'
      ctx.beginPath()
      ctx.lineWidth = 1
      ctx.moveTo(chartArea.left, chartArea.top)
      ctx.lineTo(chartArea.right, chartArea.top)
      ctx.lineTo(chartArea.right, chartArea.bottom)
      ctx.lineTo(chartArea.left, chartArea.bottom)
      ctx.closePath()
      ctx.stroke()
    }
  },
}

const lithologyPlugIn = {
  id: 'lithologyPlugIn',
  afterDraw: (chart, args, options) => {
    if (!options?.lithologies) return
    const lithologies = options.lithologies()
    const ctx = chart.ctx
    const yScale = chart.scales.y
    if (chart.chartArea) {
      ctx.save()
      lithologies.forEach((lithology) => {
        ctx.strokeStyle = lithology.color
        ctx.beginPath()
        ctx.lineWidth = 2
        ctx.moveTo(chart.chartArea.left, yScale.getPixelForValue(lithology.yValue))
        ctx.lineTo(chart.chartArea.right, yScale.getPixelForValue(lithology.yValue))
        ctx.closePath()
        ctx.stroke()
      })
      ctx.restore()
    }
  },
}

const polygonPlugIn = {
  id: 'polygonPlugIn',
  beforeDatasetsDraw: (chart, args, options) => {
    if (!options?.polygonDefs) return
    const {
      ctx,
      chartArea: { left, top, width, height },
      scales,
    } = chart
    const { x, y } = scales

    ctx.save()

    // Restrict drawing to the chart area
    ctx.beginPath()
    ctx.rect(left, top, width, height)
    ctx.clip()

    options.polygonDefs().forEach((polygon) => {
      // Start drawing the polygon
      ctx.beginPath()
      ctx.moveTo(x.getPixelForValue(polygon.points[0].x), y.getPixelForValue(polygon.points[0].y))

      for (let i = 1; i < polygon.points.length; i++) {
        ctx.lineTo(x.getPixelForValue(polygon.points[i].x), y.getPixelForValue(polygon.points[i].y))
      }

      // Close the path to make it a polygon
      ctx.closePath()

      // Fill and stroke the polygon
      ctx.fillStyle = polygon.backgroundColor // Example color, you can change this
      ctx.fill()
      ctx.strokeStyle = polygon.color
      ctx.lineWidth = 1
      ctx.stroke()
    })

    ctx.restore()
  },
}

const INITIAL_SCALE_LIMITS = {
  maxX: 50,
  minX: -50,
  maxY: 50,
  minY: -50,
  xLen: 100,
  yLen: 100,
}

const removeChar = (value, charToRemove) => {
  return value.split(charToRemove).join('')
}

const convertPixelsToUserUnits = (pixels, scaleUserPerDisplayUnit, fixedScaleCm) => {
  let pixelsPerDisplayUnit = DPI // 72
  if (fixedScaleCm) pixelsPerDisplayUnit = DPI / 2.54 // 28.3465
  return (pixels / pixelsPerDisplayUnit) * scaleUserPerDisplayUnit
}

const convertUserUnitsToPixels = (userUnits, scaleUserPerDisplayUnit, fixedScaleCm) => {
  if (!scaleUserPerDisplayUnit) return userUnits
  let pixelsPerDisplayUnit = DPI // 72
  if (fixedScaleCm) pixelsPerDisplayUnit = DPI / 2.54 // 28.3465
  return (userUnits / scaleUserPerDisplayUnit) * pixelsPerDisplayUnit
}

const WallPlotChart = forwardRef(
  (
    {
      data,
      chartType,
      refWellSurveys,
      isLoading,
      projections = [],
      isPlan = false,
      onUpdate,
      chartSettings,
      onUpdateNoRender,
      onUpdateResize,
      onShowLabelProps,
      onShowElementProps,
      zoomScale,
    },
    ref,
  ) => {
    const chartRef = useRef(null)
    const chartSizeRef = useRef({ width: 0, height: 0 })
    const { getUnitsText } = useUnits()
    const dataMinMax = useRef(cloneDeep(INITIAL_SCALE_LIMITS)) //Holds the min max of the well data
    const scaleMinMax = useRef(cloneDeep(INITIAL_SCALE_LIMITS)) //Holds the min max values of the chart scales preserving the aspect ratio
    const size = useRef({ width: 0, height: 0 })
    const axisLabels = useRef({ x: '', y: '' })
    const chartDataRef = useRef({ datasets: [] })
    const chartSettingsRef = useRef(chartSettings)
    const [rerender, setRerender] = React.useState(false) // eslint-disable-line no-unused-vars
    const elementRef = useRef(null)
    const { theme } = useInnovaTheme()
    const scaleRef = useRef({ x: 1, y: 1 })
    const chartPolygonsRef = useRef([]) // holds annotations generated by the getChartData() function

    useImperativeHandle(ref, () => {
      return {
        getSettings() {
          return chartSettingsRef.current
        },
        resetZoom() {
          resetChartZoom()
        },
      }
    })

    useEffect(() => {
      chartSettingsRef.current = cloneDeep(chartSettings)
      checkPanZoom()
      if (data) {
        const {
          chartData: newData,
          minMax,
          xAxisLabel,
          yAxisLabel,
          chartPolygons,
        } = getChartData(chartType, data, data?.surveys, projections, isPlan, chartSettingsRef.current, data?.geoZones)
        dataMinMax.current = cloneDeep(minMax)
        axisLabels.current = { x: xAxisLabel, y: yAxisLabel }
        chartDataRef.current = cloneDeep(newData)
        chartPolygonsRef.current = cloneDeep(chartPolygons)
        adjustChartScale()
        // chartDataRef.current = cloneDeep(newData)
        setRerender((prev) => !prev)
      }
    }, [data, chartSettings]) // eslint-disable-line react-hooks/exhaustive-deps

    const onResize = useCallback((width, height) => {
      if (chartRef.current) {
        chartRef.current.resize()
        if (chartRef.current?.chartArea) {
          chartSizeRef.current = {
            width: chartRef.current.chartArea.right - chartRef.current.chartArea.left,
            height: chartRef.current.chartArea.bottom - chartRef.current.chartArea.top,
          }
        }
      }
      size.current = { width, height }
      adjustChartScale()
      // need to update the element in wallplotComposer for saving, but without re-rendering the chart--remder loop
      if (onUpdateNoRender)
        onUpdateNoRender({
          xMin: chartSettingsRef.current.xMin,
          xMax: chartSettingsRef.current.xMax,
          yMin: chartSettingsRef.current.yMin,
          yMax: chartSettingsRef.current.yMax,
          xAxisScale: chartSettingsRef.current.xAxisScale,
          yAxisScale: chartSettingsRef.current.yAxisScale,
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const { ref: rszRef } = useResizeDetector({ onResize: onResize }) // , refreshMode: 'debounce', refreshRate: 25 })

    useEffect(() => {
      updateAxisLabels(chartSettingsRef.current?.xAxisScale, chartSettingsRef.current?.yAxisScale)
    }, [getUnitsText]) // eslint-disable-line react-hooks/exhaustive-deps

    const getGridColorX = (context, showMajor, showMinor) => {
      const defaultColor = '#404040'
      const isMajorGrid = context.index % (chartSettingsRef.current?.minorTicksX + 1) === 0
      if (isMajorGrid) {
        if (!showMajor) return chartSettingsRef.current.gridBackgroundColor // 'hide' major gridline
        return chartSettingsRef.current?.majorGridLineColorX ? chartSettingsRef.current.majorGridLineColorX : defaultColor
      }
      if (!showMinor) return '' // chartSettingsRef.current.gridBackgroundColor // 'hide' minor gridline
      return chartSettingsRef.current?.minorGridLineColorX ? chartSettingsRef.current.minorGridLineColorX : defaultColor
    }

    const getGridColorY = (context, showMajor, showMinor) => {
      const defaultColor = '#404040'
      const isMajorGrid = context.index % (chartSettingsRef.current?.minorTicksY + 1) === 0
      if (isMajorGrid) {
        if (!showMajor) return chartSettingsRef.current.gridBackgroundColor // 'hide' major gridline
        return chartSettingsRef.current?.majorGridLineColorY ? chartSettingsRef.current.majorGridLineColorY : defaultColor
      }
      if (!showMinor) return '' // chartSettingsRef.current.gridBackgroundColor // 'hide' minor gridline
      return chartSettingsRef.current?.minorGridLineColorY ? chartSettingsRef.current.minorGridLineColorY : defaultColor
    }

    const getLabelObj = (id) => {
      if (!id) return null
      if (!chartSettingsRef.current.labels?.labelData) return null
      return chartSettingsRef.current.labels.labelData.find((label) => label.id === id)
    }

    const getAnnotations = () => {
      if (!chartSettingsRef.current?.labels?.labelData) return null
      let annotations = []
      // chartAnnoRef.current.forEach((anno) => {
      //   annotations.push(anno)
      // })
      chartSettingsRef.current.labels.labelData.forEach((label) => {
        // apply scale before render...
        label.xAdjust = convertUserUnitsToPixels(
          label.moveOffset.x,
          chartSettingsRef.current.xAxisScale,
          chartSettingsRef.current.fixedScaleCm,
        )
        label.yAdjust = convertUserUnitsToPixels(
          label.moveOffset.y,
          chartSettingsRef.current.yAxisScale,
          chartSettingsRef.current.fixedScaleCm,
        )
        // for userDefined labels, turn off background if it's not needed, but save value for the user.
        if (label.catType !== LABEL_CATEGORIES.userDefined) annotations.push(label)
        if (
          label.catType === LABEL_CATEGORIES.userDefined &&
          hasLabelCategory(LABEL_CATEGORIES.userDefined, chartSettingsRef.current.labels)
        ) {
          const userLabel = cloneDeep(label)
          if (userLabel.hasOwnProperty('hasBackground')) {
            if (!userLabel.hasBackground) userLabel.backgroundColor = 'rgba(0,0,0,0)'
          }
          annotations.push(userLabel)
        }
      })
      return annotations
    }

    // this function uses the rendering location and uses the location as if it was dataMinMax to be used when scaling the chart
    const scanLabelsForMinMax = (settings) => {
      if (!chartRef.current?.chartArea) return
      const chartArea = chartRef.current.chartArea
      settings.labels.labelData.forEach((label) => {
        // convert pixels to an offset from origin in User units
        const leftUser = convertPixelsToUserUnits(label.x - chartArea.left, settings.xAxisScale, settings.fixedScaleCm)
        const rightUser = convertPixelsToUserUnits(
          label.x + label.width - chartArea.left,
          settings.xAxisScale,
          settings.fixedScaleCm,
        )
        const topUser = convertPixelsToUserUnits(label.y - chartArea.top, settings.yAxisScale, settings.fixedScaleCm)
        const bottomUser = convertPixelsToUserUnits(
          label.y + label.height - chartArea.top,
          settings.yAxisScale,
          settings.fixedScaleCm,
        )
        const left = leftUser + settings.xMin
        const right = rightUser + settings.xMin
        const top = settings.chartType === chartTypes.sectionView ? topUser + settings.yMin : settings.yMax - topUser
        const bottom =
          settings.chartType === chartTypes.sectionView ? bottomUser + settings.yMin : settings.yMax - bottomUser

        const minX = Math.min(left, right)
        const maxX = Math.max(left, right)
        const minY = Math.min(top, bottom)
        const maxY = Math.max(top, bottom)

        if (maxX > dataMinMax.current.maxX) {
          dataMinMax.current.maxX = maxX
        }
        if (minX < dataMinMax.current.minX) {
          dataMinMax.current.minX = minX
        }
        if (maxY > dataMinMax.current.maxY) {
          dataMinMax.current.maxY = maxY
        }
        if (minY < dataMinMax.current.minY) {
          dataMinMax.current.minY = minY
        }
      })
      dataMinMax.current.xLen = dataMinMax.current.maxX - dataMinMax.current.minX
      dataMinMax.current.yLen = dataMinMax.current.maxY - dataMinMax.current.minY
    }

    const getChartSettings = () => {
      return chartSettingsRef.current
    }

    const updateAnnotationPosition = (id, x, y, width, height) => {
      const settings = getChartSettings()
      const idx = settings.labels.labelData.findIndex((label) => label.id === id)
      if (idx < 0) return
      settings.labels.labelData[idx].x = x
      settings.labels.labelData[idx].y = y
      if (settings.labels.labelData[idx].subType !== 'symbol') {
        settings.labels.labelData[idx].width = width
        settings.labels.labelData[idx].height = height
      }
    }

    const getLithologies = () => {
      if (!chartSettingsRef.current?.labels?.labelData) return null
      let lithologies = []
      chartSettingsRef.current.labels.labelData.forEach((label) => {
        if (label.catType === 'Lithologies') {
          lithologies.push(label)
        }
      })
      return lithologies
    }

    const X_SCALE_DEFN = {
      type: 'linear',
      position: 'bottom',
      title: {
        display: true,
        text: `EW`,
        color: `#000000`, // appColors.headerTextColor,
      },
      ticks: {
        color: '#111', //'#FFF',
        autoSkip: false,
        enabled: true,
        // count: 10,
        count: () => {
          if (!chartSettingsRef.current?.majorTicksX) return 10
          const ticks =
            chartSettingsRef.current?.majorTicksX +
            (chartSettingsRef.current?.majorTicksX - 1) * chartSettingsRef.current?.minorTicksX
          return ticks > 0 ? ticks : 10
        },
        callback: (value, index, values) => {
          const range = Math.abs(values[values.length - 1].value - values[0].value)
          const numMajorTicks = chartSettingsRef.current?.majorTicksX || 10
          const distancePerTick = range / numMajorTicks
          let decimalPlaces = 0
          let roundingMultiplier = 1

          // Adjust decimal precision and rounding based on the distance per major tick
          if (distancePerTick < 0.1) {
            decimalPlaces = 2
          } else if (distancePerTick < 1) {
            decimalPlaces = 1
          } else if (distancePerTick > 100) {
            roundingMultiplier = 10 // Round to nearest 10 when distance per tick is large
          }

          const roundedValue = decimalPlaces === 0 ? Math.round(value / roundingMultiplier) * roundingMultiplier : value
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
          })

          // Determine if the current tick is a major tick
          let isMajorTick = index % (chartSettingsRef.current?.minorTicksX + 1) === 0
          if (isMajorTick) {
            return formatter.format(roundedValue)
          }
          return '' // Minor ticks have no labels
        },
      },
      grid: {
        display: true,
        borderColor: chartSettingsRef.current?.majorGridLineColor
          ? chartSettingsRef.current.majorGridLineColor
          : '#000',
        color: (context) => {
          if (!context?.tick || !context?.index) return '#404040'
          return getGridColorX(
            context,
            chartSettingsRef.current?.showXAxisMajorGridLines,
            chartSettingsRef.current?.showXAxisMinorGridLines,
          )
        },
      },
    }

    const Y_SCALE_DEFN = {
      type: 'linear',
      position: 'left',
      title: {
        display: true,
        text: `NS`,
        color: `#000000`, // appColors.headerTextColor,
      },
      ticks: {
        color: '#111', //'#FFF',
        autoSkip: false,
        enabled: true,
        // count: 10,
        count: () => {
          if (!chartSettingsRef.current?.majorTicksY) return 10
          const ticks =
            chartSettingsRef.current?.majorTicksY +
            (chartSettingsRef.current?.majorTicksY - 1) * chartSettingsRef.current?.minorTicksY
          return ticks > 0 ? ticks : 10
        },
        callback: (value, index, values) => {
          const range = Math.abs(values[values.length - 1].value - values[0].value)
          const numMajorTicks = chartSettingsRef.current?.majorTicksY || 10
          const distancePerTick = range / numMajorTicks
          let decimalPlaces = 0
          let roundingMultiplier = 1

          // Adjust decimal precision and rounding based on the distance per major tick
          if (distancePerTick < 0.1) {
            decimalPlaces = 2
          } else if (distancePerTick < 1) {
            decimalPlaces = 1
          } else if (distancePerTick > 100) {
            roundingMultiplier = 10 // Round to nearest 10 when distance per tick is large
          }

          const roundedValue = decimalPlaces === 0 ? Math.round(value / roundingMultiplier) * roundingMultiplier : value
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
          })

          // Determine if the current tick is a major tick
          let isMajorTick = index % (chartSettingsRef.current?.minorTicksY + 1) === 0
          if (isMajorTick) {
            return formatter.format(roundedValue)
          }
          return '' // Minor ticks have no labels
          // let tickText = value.toFixed(0)
          // const formatter = new Intl.NumberFormat('en-US', {
          //   style: 'decimal',
          //   minimumFractionDigits: 0,
          //   maximumFractionDigits: 0,
          // })

          // let roundedNumber = Math.round(value / 10) * 10
          // if (Math.abs(value) < 10) {
          //   roundedNumber = Math.round(value)
          // }
          // tickText = formatter.format(roundedNumber)

          // if (!chartSettingsRef.current?.minorTicksY) return tickText

          // if (index % (chartSettingsRef.current?.minorTicksY + 1) === 0) {
          //   return tickText
          // }
          // return ''
        },
      },
      grid: {
        display: true,
        borderColor: chartSettingsRef.current?.majorGridLineColor
          ? chartSettingsRef.current.majorGridLineColor
          : '#000',
        color: (context) => {
          if (!context?.tick || !context?.index) return '#404040'
          return getGridColorY(
            context,
            chartSettingsRef.current?.showYAxisMajorGridLines,
            chartSettingsRef.current?.showYAxisMinorGridLines,
          )
        },
      },
    }

    const CHART_OPTIONS = {
      maintainAspectRatio: false,
      scaleShowLabels: false,
      devicePixelRatio: 5,
      animation: {
        duration: 0,
      },
      layout: {
        padding: {
          right: 20,
          top: 20,
        },
      },
      events: ['mousedown', 'mouseup', 'mousemove', 'mouseout', 'dblclick'],
      plugins: {
        dataLabelPlugin: {
          color: '#000000', // '#ffffff',
          visible: true,
        },
        title: {
          display: false,
        },
        tooltip: {
          mode: 'point',
          intersect: false,
          callbacks: {
            title: function (tooltipItem) {
              return tooltipItem[0].dataset.label
            },
            label: function (context) {
              return [` ${+context.parsed.x.toFixed(2)} EW`, ` ${context.parsed.y.toFixed(2)} NS`]
            },
          },
          filter: function (tooltipItem, index) {
            if (index > 0) return false
            return true
          },
        },
        hover: {
          mode: 'nearest',
          intersect: false,
        },
        legend: {
          display: false,
        },
        zoom: {
          animation: {
            duration: 0,
          },
          pan: {
            enabled: true,
            mode: 'xy',
            // modifierKey: 'alt',
            onPanStart: ({ chart }) => {
              if (element) {
                return false
              }
              return true
            },
            onPanComplete: ({ chart }) => {
              // normalize the calcs here too
              const xAxis = chart.scales.x
              const yAxis = chart.scales.y
              if (onUpdateNoRender)
                onUpdateNoRender({
                  xMin: xAxis.min,
                  xMax: xAxis.max,
                  yMin: yAxis.min,
                  yMax: yAxis.max,
                  width: size.current.width,
                  height: size.current.height,
                  xAxisScale: chartSettingsRef.current.xAxisScale,
                  yAxisScale: chartSettingsRef.current.yAxisScale,
                })
            },
          },
          limits: {
            x: {
              min: -50,
              max: 50,
            },
            y: {
              min: -50,
              max: 50,
            },
            xTop: {
              min: -50,
              max: 50,
            },
            yRight: {
              min: -50,
              max: 50,
            },
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'xy',
            onZoom: ({ chart }) => {
              // this will be updated in a useEffect when the chartSettingsRef props change
              if (chartSettingsRef.current.yAxisMaxUserDefined)
                if (chart.scales.y.max > chartSettingsRef.current.yMax)
                  chart.scales.y.max = chartSettingsRef.current.yMax
            },
            onZoomComplete: ({ chart }) => {
              // this will be updated in a useEffect when the chartSettingsRef props change
              const xAxis = chart.scales.x
              const yAxis = chart.scales.y
              let xLen = xAxis.max - xAxis.min
              let yLen = yAxis.max - yAxis.min
              adjustChartScale()
              if (onUpdateNoRender) {
                let payload = {
                  width: size.current.width,
                  height: size.current.height,
                  xAxisScale: chartSettingsRef.current.xAxisScale,
                  yAxisScale: chartSettingsRef.current.yAxisScale,
                  xMin: xAxis.min,
                  xMax: xLen < 5.0 ? xAxis.max + 5.0 : xAxis.max, // clamp zoom-in, x-axis
                  yMin: yAxis.min,
                  yMax: yLen < 5.0 ? yAxis.max + 5.0 : yAxis.max, // clamp zoom-in y-axis
                }
                if (chartSettingsRef.current.xAxisMaxUserDefined) {
                  payload.xMax = chartSettingsRef.current.xMaxUser
                }
                if (chartSettingsRef.current.xAxisMinUserDefined) {
                  payload.xMin = chartSettingsRef.current.xMinUser
                }
                if (chartSettingsRef.current.yAxisMinUserDefined) {
                  payload.yMin = chartSettingsRef.current.yMinUser
                }
                if (chartSettingsRef.current.yAxisMaxUserDefined) {
                  payload.yMax = chartSettingsRef.current.yMaxUser
                }
                onUpdateNoRender(payload)
              }
            },
          },
        },
        GradientPlugIn: {
          showGradient: false,
          theme: theme,
        },
        borderPlugIn: {
          color: '#000000',
        },
        ChartBackgroundPlugIn: {
          showChartBackground: true,
          // chartBackground can be a color string or a function that returns a color string
          chartBackground: () => {
            return chartSettingsRef.current?.gridBackgroundColor || '#FFF'
          },
        },
        annotation: {
          // apparently, the click event in the annotation plugin is overridden by the dragger local plugin
          // click(ctx, event) {
          //   // this won't get called
          // },
          enter(ctx) {
            if (dragging) return // if a drag is in progress, don't update the element & refs
            element = ctx.element
            elementRef.current = getLabelObj(ctx.element.options.id)
            scaleRef.current = {
              x: chartSettingsRef.current.xAxisScale,
              y: chartSettingsRef.current.yAxisScale,
              cm: chartSettingsRef.current.fixedScaleCm,
            }
          },
          leave() {
            if (dragging) return // if a drag is in progress, don't update the element & refs
            element = undefined
            lastEvent = undefined
            // need to set the xAdjust and yAdjust of the label in the ChartElement parent before elementRef reset.
            if (onUpdateNoRender) {
              onUpdateNoRender(chartSettingsRef.current)
            }
            elementRef.current = undefined
          },
          afterDraw: (ctx) => {
            const { x, y, width, height } = ctx.element
            updateAnnotationPosition(ctx.id, x, y, width, height)
          },
          annotations: () => getAnnotations(),
        },
        dragger: {
          elementRef: () => {
            return elementRef
          },
          scaleRef: () => {
            return scaleRef
          },
        },
        lithologyPlugIn: {
          lithologies: () => getLithologies(),
        },
        polygonPlugIn: {
          polygonDefs: () => {
            return chartPolygonsRef.current
          },
        },
        doubleClickPlugin: {
          elementRef: () => {
            return elementRef
          },
          scaleRef: () => {
            return scaleRef
          },
          showLabelProps: (element) => {
            if (onShowLabelProps) {
              onShowLabelProps(elementRef.current)
            }
          },
          showElementProps: () => {
            if (onShowElementProps) {
              onShowElementProps()
            }
          },
        },
      },
      scales: {
        x: {
          ...X_SCALE_DEFN,
          display: () => {
            return chartSettingsRef.current?.showXAxisBottom
          },
        },
        xTop: {
          ...X_SCALE_DEFN,
          position: 'top',
          display: () => {
            return chartSettingsRef.current?.showXAxisTop
          },
        },
        y: {
          ...Y_SCALE_DEFN,
          display: () => {
            return chartSettingsRef.current?.showYAxisLeft
          },
        },
        yRight: {
          ...Y_SCALE_DEFN,
          position: 'right',
          display: () => {
            return chartSettingsRef.current?.showYAxisRight
          },
        },
      },
    }

    const optionsPlanView = (actions) => {
      return {
        plugins: {
          animation: {
            duration: 0,
          },
        },
        scales: {
          x: {
            text: 'EW',
          },
          y: {
            title: {
              text: 'NS',
            },
          },
        },
      }
    }

    const optionsSectionView = (actions) => {
      return {
        scales: {
          x: {
            text: 'VS',
          },
          y: {
            beginAtZero: true,
            reverse: true,
            title: {
              text: 'TVD',
            },
          },
          yRight: {
            beginAtZero: true,
            reverse: true,
            title: {
              text: 'TVD',
            },
          },
        },
      }
    }

    const getChartOptions = (type, onUpdate) => {
      let options = {}
      switch (type) {
        case chartTypes.planView:
          options = optionsPlanView({ onUpdate: onUpdate })
          break
        case chartTypes.sectionView:
          options = optionsSectionView({ onUpdate: onUpdate })
          break
        default:
          break
      }
      let baseOptions = cloneDeep(CHART_OPTIONS)
      let mergedOptions = merge(baseOptions, options)

      if (chartSettings && chartSettingsRef.current.hasOwnProperty('xMin')) {
        mergedOptions.scales.x.min = chartSettingsRef.current.xMin
        mergedOptions.scales.x.max = chartSettingsRef.current.xMax
        mergedOptions.scales.y.min = chartSettingsRef.current.yMin
        mergedOptions.scales.y.max = chartSettingsRef.current.yMax
        mergedOptions.scales.xTop.min = chartSettingsRef.current.xMin
        mergedOptions.scales.xTop.max = chartSettingsRef.current.xMax
        mergedOptions.scales.yRight.min = chartSettingsRef.current.yMin
        mergedOptions.scales.yRight.max = chartSettingsRef.current.yMax
      }
      return mergedOptions
    }

    const options = useRef(getChartOptions(chartType, onUpdate))

    const updateAxisLabels = useCallback(
      (xAxisScale, yAxisScale) => {
        if (!xAxisScale || !yAxisScale) return
        if (!chartRef.current) return
        const xScale = xAxisScale > 10 ? xAxisScale?.toFixed(0) : xAxisScale?.toFixed(2)
        chartRef.current.config.options.scales.x.title.text = `${axisLabels.current.x} ${
          chartSettingsRef.current.showScales
            ? '(' +
              xScale +
              ' ' +
              getUnitsText(UNITS_FOR.Depth) +
              '/' +
              (chartSettingsRef.current.fixedScaleCm ? 'cm' : 'in') +
              ')'
            : ''
        }`
        const yScale = yAxisScale > 10 ? yAxisScale?.toFixed(0) : yAxisScale?.toFixed(2)
        chartRef.current.config.options.scales.y.title.text = `${axisLabels.current.y} ${
          chartSettingsRef.current.showScales
            ? '(' +
              yScale +
              ' ' +
              getUnitsText(UNITS_FOR.Depth) +
              '/' +
              (chartSettingsRef.current.fixedScaleCm ? 'cm' : 'in') +
              ')'
            : ''
        }`
        chartRef.current.config.options.scales.xTop.title.text = chartRef.current.config.options.scales.x.title.text
        chartRef.current.config.options.scales.yRight.title.text = chartRef.current.config.options.scales.y.title.text
        chartRef.current.update()
      },
      [getUnitsText],
    )

    const checkPanZoom = () => {
      if (chartSettings) {
        if (chartRef.current) {
          let restrictPanOrZoom = false
          // seems cleaner to reinit pan & zoom modes here
          options.current.plugins.zoom.zoom.mode = 'xy'
          chartRef.current.config.options.plugins.zoom.zoom.mode = 'xy'
          options.current.plugins.zoom.pan.mode = 'xy'
          chartRef.current.config.options.plugins.zoom.pan.mode = 'xy'

          if (chartSettingsRef.current.xAxisFixedScaleEnabled) {
            restrictPanOrZoom = true
            // if pan mode and zoom mode are enabled, disable in the x-axis
            options.current.plugins.zoom.zoom.mode = removeChar(options.current.plugins.zoom.zoom.mode, 'x')
            chartRef.current.config.options.plugins.zoom.zoom.mode = options.current.plugins.zoom.zoom.mode
          }

          if (chartSettingsRef.current.xAxisMinUserDefined || chartSettingsRef.current.xAxisMaxUserDefined) {
            // restrict pan
            restrictPanOrZoom = true
            options.current.plugins.zoom.pan.mode = removeChar(options.current.plugins.zoom.pan.mode, 'x')
            chartRef.current.config.options.plugins.zoom.pan.mode = options.current.plugins.zoom.pan.mode
          }

          if (chartSettingsRef.current.yAxisFixedScaleEnabled) {
            restrictPanOrZoom = true
            // if pan mode and zoom mode are enabled, disable in the y-axis
            options.current.plugins.zoom.zoom.mode = removeChar(options.current.plugins.zoom.zoom.mode, 'y')
            chartRef.current.config.options.plugins.zoom.zoom.mode = options.current.plugins.zoom.zoom.mode
          }

          if (chartSettingsRef.current.yAxisMinUserDefined || chartSettingsRef.current.yAxisMaxUserDefined) {
            // restrict pan
            restrictPanOrZoom = true
            options.current.plugins.zoom.pan.mode = removeChar(options.current.plugins.zoom.pan.mode, 'y')
            chartRef.current.config.options.plugins.zoom.pan.mode = options.current.plugins.zoom.pan.mode
          }

          // if pan mode is disabled in both axis, disable the pan mode
          if (options.current.plugins.zoom.pan.mode === '') {
            options.current.plugins.zoom.pan.enabled = false
            chartRef.current.config.options.plugins.zoom.pan.enabled = false
          }

          // if zoom mode is disabled in both axis, disable the zoom mode
          if (options.current.plugins.zoom.zoom.mode === '') {
            options.current.plugins.zoom.zoom.enabled = false
            chartRef.current.config.options.plugins.zoom.zoom.enabled = false
          }

          // (reset to defaults) if no user-defined x- or y-axis, and no fixed scale,
          // enable both zoom and pan in both directions
          if (!restrictPanOrZoom) {
            options.current.plugins.zoom.zoom.wheel.enabled = true
            options.current.plugins.zoom.zoom.pinch.enabled = true
            chartRef.current.config.options.plugins.zoom.zoom.enabled = true
            chartRef.current.config.options.plugins.zoom.pan.enabled = true
            // chartRef.current.update()
          }
          chartRef.current.update()
        }
      }
    }

    const setAxisScale = useCallback((axis, minMax) => {
      const { scales } = chartRef.current.config.options

      let chartProp = ''
      switch (axis) {
        case 'x':
        case 'xTop':
          chartProp = minMax === 'min' ? 'xMinUser' : 'xMaxUser'
          break
        case 'y':
        case 'yRight':
          chartProp = minMax === 'min' ? 'yMinUser' : 'yMaxUser'
          break
        default:
          break
      }
      if (chartProp === '') return
      scales[axis][minMax] = chartSettingsRef.current[chartProp]
    }, [])

    const setZoomLimits = useCallback((axis, minMax) => {
      const { plugins } = chartRef.current.config.options

      let chartProp = ''
      switch (axis) {
        case 'x':
        case 'xTop':
          chartProp = minMax === 'min' ? 'xMinUser' : 'xMaxUser'
          break
        case 'y':
        case 'yRight':
          chartProp = minMax === 'min' ? 'yMinUser' : 'yMaxUser'
          break
        default:
          break
      }
      if (chartProp === '') return
      plugins.zoom.limits[axis][minMax] = chartSettingsRef.current[chartProp]
    }, [])

    const updateXAxisChartSettingsRef = useCallback(() => {
      const { scales } = chartRef.current.config.options
      chartSettingsRef.current.width = chartSizeRef.current.width // size.current.width
      chartSettingsRef.current.xMin = scales.x.min ? scales.x.min : chartRef.current.scales.x.min
      chartSettingsRef.current.xMax = scales.x.max ? scales.x.max : chartRef.current.scales.x.max

      // if an axis does not have a fixed scale, calculate the scale based on the chart size
      let pixelsPerDisplayUnit = DPI // 72
      if (chartSettingsRef.current.fixedScaleCm) pixelsPerDisplayUnit = DPI / 2.54 // 28.3465
      if (!chartSettingsRef.current.xAxisFixedScaleEnabled) {
        let xDiff = chartSettingsRef.current.xMax - chartSettingsRef.current.xMin
        let xAxisScale = xDiff / (chartSettingsRef.current.width / zoomScale / pixelsPerDisplayUnit)
        chartSettingsRef.current.xAxisScale = xAxisScale
      }
    }, [zoomScale])

    const updateYAxisChartSettingsRef = useCallback(() => {
      const { scales } = chartRef.current.config.options
      chartSettingsRef.current.height = chartSizeRef.current.height // size.current.height
      chartSettingsRef.current.yMin = scales.y.min ? scales.y.min : chartRef.current.scales.y.min
      chartSettingsRef.current.yMax = scales.y.max ? scales.y.max : chartRef.current.scales.y.max

      // if an axis does not have a fixed scale, calculate the scale based on the chart size
      let pixelsPerDisplayUnit = DPI // 72
      if (chartSettingsRef.current.fixedScaleCm) pixelsPerDisplayUnit = DPI / 2.54 // 28.3465
      if (!chartSettingsRef.current.yAxisFixedScaleEnabled) {
        let yDiff = chartSettingsRef.current.yMax - chartSettingsRef.current.yMin
        let yAxisScale = yDiff / (chartSettingsRef.current.height / zoomScale / pixelsPerDisplayUnit)
        chartSettingsRef.current.yAxisScale = yAxisScale
      }
    }, [zoomScale])

    const calcScaleLimitsFromData = useCallback(() => {
      let addXlen = dataMinMax.current.xLen * 0.05
      let addYlen = dataMinMax.current.yLen * 0.05

      scaleMinMax.current.maxX = Math.floor((dataMinMax.current.maxX + addXlen) * 1.1)
      scaleMinMax.current.minX = Math.floor((dataMinMax.current.minX - addXlen) * 1.1)
      scaleMinMax.current.maxY = Math.floor((dataMinMax.current.maxY + addYlen) * 1.1)
      scaleMinMax.current.minY = Math.floor((dataMinMax.current.minY - addYlen) * 1.1)
    }, [])

    const adjustChartScale = useCallback(() => {
      if (!chartRef.current) return
      scanLabelsForMinMax(chartSettingsRef.current)
      calcScaleLimitsFromData()

      setDefaultAxisScaleAndZoomLimits() // default to data limits, override below if any axis is user-defined

      if (chartSettingsRef.current.xAxisMinUserDefined) {
        setAxisScale('x', 'min')
        setAxisScale('xTop', 'min')
        setZoomLimits('x', 'min')
        setZoomLimits('xTop', 'min')
      }

      if (chartSettingsRef.current.xAxisMaxUserDefined) {
        setAxisScale('x', 'max')
        setAxisScale('xTop', 'max')
        setZoomLimits('x', 'max')
        setZoomLimits('xTop', 'max')
      }

      if (chartSettingsRef.current.yAxisMinUserDefined) {
        setAxisScale('y', 'min')
        setAxisScale('yRight', 'min')
        setZoomLimits('y', 'min')
        setZoomLimits('yRight', 'min')
      }

      if (chartSettingsRef.current.yAxisMaxUserDefined) {
        setAxisScale('y', 'max')
        setAxisScale('yRight', 'max')
        setZoomLimits('y', 'max')
        setZoomLimits('yRight', 'max')
      }

      // this block of code will rely upon the preceding handling of user-defined axis limits
      let newSize = { width: 0, height: 0 }
      if (chartSettingsRef.current.xAxisFixedScaleEnabled) {
        const { scales } = chartRef.current.config.options
        let pixelsPerDisplayUnit = DPI // 72
        if (chartSettingsRef.current.fixedScaleCm) pixelsPerDisplayUnit = DPI / 2.54 // 28.3465

        if (chartSettingsRef.current.xAxisMinUserDefined && chartSettingsRef.current.xAxisMaxUserDefined) {
          const xDiff = chartSettingsRef.current.xMaxUser - chartSettingsRef.current.xMinUser
          newSize.width = xDiff / chartSettingsRef.current.xAxisScale // * pixelsPerDisplayUnit
          // if the size change is minimal, don't update the size
          if (Math.abs(size.current.width - newSize.width * pixelsPerDisplayUnit) < 20) newSize.width = 0
        } else {
          if (chartSettingsRef.current.xAxisMinUserDefined) {
            chartSettingsRef.current.xMax =
              chartSettingsRef.current.xMinUser +
              (chartSizeRef.current.width / zoomScale / pixelsPerDisplayUnit) * chartSettingsRef.current.xAxisScale
            // apply xMax to the chart
            scales.x.max = chartSettingsRef.current.xMax
            scales.xTop.max = chartSettingsRef.current.xMax
          }
          if (chartSettingsRef.current.xAxisMaxUserDefined) {
            chartSettingsRef.current.xMin =
              chartSettingsRef.current.xMaxUser -
              (chartSizeRef.current.width / zoomScale / pixelsPerDisplayUnit) * chartSettingsRef.current.xAxisScale
            // apply xMin to the chart
            scales.x.min = chartSettingsRef.current.xMin
            scales.xTop.min = chartSettingsRef.current.xMin
          }
          if (!chartSettingsRef.current.xAxisMinUserDefined && !chartSettingsRef.current.xAxisMaxUserDefined) {
            // fixed scale, check if the min & max are valid for the scale given based upon the current width...
            const xDiff =
              (chartSizeRef.current.width / zoomScale / pixelsPerDisplayUnit) * chartSettingsRef.current.xAxisScale
            scales.x.min =
              chartSettingsRef.current.xMin < scaleMinMax.current.minX
                ? scaleMinMax.current.minX
                : chartSettingsRef.current.xMin
            scales.x.max = scales.x.min + xDiff // chartSettingsRef.current.xMin + xDiff
            scales.xTop.min = scales.x.min
            scales.xTop.max = scales.x.min + xDiff // chartSettingsRef.current.xMin + xDiff
            if (scales.x.max > scaleMinMax.current.maxX) {
              let overflowX = scales.x.max - scaleMinMax.current.maxX
              scales.x.min -= overflowX
              scales.x.max -= overflowX
              scales.xTop.min -= overflowX
              scales.xTop.max -= overflowX
            }
            chartSettingsRef.current.xMin = scales.x.min
            chartSettingsRef.current.xMax = scales.x.max
          }
        }
      }
      if (!chartSettingsRef.current.xAxisFixedScaleEnabled) {
        updateXAxisChartSettingsRef()
      }
      if (chartSettingsRef.current.yAxisFixedScaleEnabled) {
        const { scales } = chartRef.current.config.options
        let pixelsPerDisplayUnit = DPI // 72
        if (chartSettingsRef.current.fixedScaleCm) pixelsPerDisplayUnit = DPI / 2.54 // 28.3465

        if (chartSettingsRef.current.yAxisMinUserDefined && chartSettingsRef.current.yAxisMaxUserDefined) {
          const yDiff = chartSettingsRef.current.yMaxUser - chartSettingsRef.current.yMinUser
          newSize.height = yDiff / chartSettingsRef.current.yAxisScale
          if (Math.abs(size.current.height - newSize.height * pixelsPerDisplayUnit) < 20) newSize.height = 0
        } else {
          if (chartSettingsRef.current.yAxisMinUserDefined) {
            chartSettingsRef.current.yMax =
              chartSettingsRef.current.yMinUser +
              (chartSizeRef.current.height / zoomScale / pixelsPerDisplayUnit) * chartSettingsRef.current.yAxisScale
            scales.y.max = chartSettingsRef.current.yMax
            scales.yRight.max = chartSettingsRef.current.yMax
          }
          if (chartSettingsRef.current.yAxisMaxUserDefined) {
            chartSettingsRef.current.yMin =
              chartSettingsRef.current.yMaxUser -
              (chartSizeRef.current.height / zoomScale / pixelsPerDisplayUnit) * chartSettingsRef.current.yAxisScale
            scales.y.min = chartSettingsRef.current.yMin
            scales.yRight.min = chartSettingsRef.current.yMin
          }
          if (!chartSettingsRef.current.yAxisMinUserDefined && !chartSettingsRef.current.yAxisMaxUserDefined) {
            // fixed scale, check if the min & max are valid for the scale given based upon the current width...
            const yDiff =
              (chartSizeRef.current.height / zoomScale / pixelsPerDisplayUnit) * chartSettingsRef.current.yAxisScale
            scales.y.min =
              chartSettingsRef.current.yMin < scaleMinMax.current.minY
                ? scaleMinMax.current.minY
                : chartSettingsRef.current.yMin
            scales.y.max = scales.y.min + yDiff
            scales.yRight.min = scales.y.min
            scales.yRight.max = scales.y.min + yDiff
            if (scales.y.max > scaleMinMax.current.maxY) {
              let overflowY = scales.y.max - scaleMinMax.current.maxY
              scales.y.min -= overflowY
              scales.y.max -= overflowY
              scales.yRight.min -= overflowY
              scales.yRight.max -= overflowY
            }
            chartSettingsRef.current.yMin = scales.y.min
            chartSettingsRef.current.yMax = scales.y.max
          }
        }
      }
      if (!chartSettingsRef.current.yAxisFixedScaleEnabled) {
        updateYAxisChartSettingsRef()
      }
      if (newSize.width !== 0 || newSize.height !== 0) {
        let payload = {}
        if (newSize.width !== 0) payload.width = newSize.width
        if (newSize.height !== 0) payload.height = newSize.height
        onUpdateResize(payload)
      }

      updateAxisLabels(chartSettingsRef.current?.xAxisScale, chartSettingsRef.current?.yAxisScale)
    }, [
      setAxisScale,
      setZoomLimits,
      updateAxisLabels,
      onUpdateResize,
      updateXAxisChartSettingsRef,
      updateYAxisChartSettingsRef,
      zoomScale,
      calcScaleLimitsFromData,
    ])

    const setDefaultAxisScaleAndZoomLimits = () => {
      const { minX, maxX, minY, maxY } = scaleMinMax.current
      const { scales, plugins } = chartRef.current.config.options

      plugins.zoom.limits.x.min = minX
      plugins.zoom.limits.x.max = maxX
      plugins.zoom.limits.y.min = minY
      plugins.zoom.limits.y.max = maxY
      plugins.zoom.limits.xTop.min = minX
      plugins.zoom.limits.xTop.max = maxX
      plugins.zoom.limits.yRight.min = minY
      plugins.zoom.limits.yRight.max = maxY
      if (chartSettingsRef.current.xAxisScale === -1) {
        // apply initial scale limits
        scales.x.min = dataMinMax.current.minX - dataMinMax.current.xLen * 0.05
        scales.x.max = dataMinMax.current.maxX + dataMinMax.current.xLen * 0.05
        scales.xTop.min = dataMinMax.current.minX - dataMinMax.current.xLen * 0.05
        scales.xTop.max = dataMinMax.current.maxX + dataMinMax.current.xLen * 0.05
      }
      if (chartSettingsRef.current.yAxisScale === -1) {
        // apply initial scale limits
        scales.y.min = dataMinMax.current.minY - dataMinMax.current.yLen * 0.05
        scales.y.max = dataMinMax.current.maxY + dataMinMax.current.yLen * 0.05
        scales.yRight.min = dataMinMax.current.minY - dataMinMax.current.yLen * 0.05
        scales.yRight.max = dataMinMax.current.maxY + dataMinMax.current.yLen * 0.05
      }
    }

    useEffect(() => {
      if (chartRef.current) {
        const chart = chartRef.current
        chart.options.plugins.zoom.zoom.onZoom = function ({ chart }) {
          if (chartSettingsRef.current.xAxisMinUserDefined && chart.scales.x.min < chartSettingsRef.current.xMinUser) {
            chart.scales.x.min = chartSettingsRef.current.xMinUser
            chart.scales.xTop.min = chartSettingsRef.current.xMinUser
          }
          if (chartSettingsRef.current.xAxisMaxUserDefined && chart.scales.x.max > chartSettingsRef.current.xMaxUser) {
            chart.scales.x.max = chartSettingsRef.current.xMaxUser
            chart.scales.xTop.max = chartSettingsRef.current.xMaxUser
          }
          if (chartSettingsRef.current.yAxisMinUserDefined && chart.scales.y.min < chartSettingsRef.current.yMinUser) {
            chart.scales.y.min = chartSettingsRef.current.yMinUser
            chart.scales.yRight.min = chartSettingsRef.current.yMinUser
          }
          if (chartSettingsRef.current.yAxisMaxUserDefined && chart.scales.y.max > chartSettingsRef.current.yMaxUser) {
            chart.scales.y.max = chartSettingsRef.current.yMaxUser
            chart.scales.yRight.max = chartSettingsRef.current.yMaxUser
          }
        }

        chart.options.plugins.zoom.zoom.onZoomComplete = function ({ chart }) {
          // normalize the calcs here too
          const xAxis = chart.scales.x
          const yAxis = chart.scales.y
          let xLen = xAxis.max - xAxis.min
          let yLen = yAxis.max - yAxis.min

          adjustChartScale()
          if (onUpdateNoRender) {
            let payload = {
              width: size.current.width,
              height: size.current.height,
              xAxisScale: chartSettingsRef.current.xAxisScale,
              yAxisScale: chartSettingsRef.current.yAxisScale,
              xMin: xAxis.min,
              xMax: xLen < 5.0 ? xAxis.max + 5.0 : xAxis.max, // clamp zoom-in, x-axis
              yMin: yAxis.min,
              yMax: yLen < 5.0 ? yAxis.max + 5.0 : yAxis.max, // clamp zoom-in y-axis
            }
            if (chartSettingsRef.current.xAxisMaxUserDefined) {
              payload.xMax = chartSettingsRef.current.xMaxUser
            }
            if (chartSettingsRef.current.xAxisMinUserDefined) {
              payload.xMin = chartSettingsRef.current.xMinUser
            }
            if (chartSettingsRef.current.yAxisMinUserDefined) {
              payload.yMin = chartSettingsRef.current.yMinUser
            }
            if (chartSettingsRef.current.yAxisMaxUserDefined) {
              payload.yMax = chartSettingsRef.current.yMaxUser
            }
            onUpdateNoRender(payload)
          }
          chart.update()
        }
      }
    }, [
      chartSettingsRef.current.xMaxUser,
      chartSettingsRef.current.xMinUser,
      chartSettingsRef.current.yMaxUser,
      chartSettingsRef.current.yMinUser,
      chartSettingsRef.current.xAxisMaxUserDefined,
      chartSettingsRef.current.xAxisMinUserDefined,
      chartSettingsRef.current.yAxisMaxUserDefined,
      chartSettingsRef.current.yAxisMinUserDefined,
      adjustChartScale,
      onUpdateNoRender,
    ])
    const getOptions = () => {
      return options.current
    }

    const resetChartZoom = () => {
      if (chartRef.current) {
        // chartRef.current.resetZoom() // this would work, except we save/restore the 'current view'

        const { scales } = chartRef.current.config.options
        // reset initial scale limits
        if (!chartSettingsRef.current.xAxisMinUserDefined) {
          scales.x.min = dataMinMax.current.minX - dataMinMax.current.xLen * 0.05
          scales.xTop.min = dataMinMax.current.minX - dataMinMax.current.xLen * 0.05
        }
        if (!chartSettingsRef.current.xAxisMaxUserDefined) {
          scales.x.max = dataMinMax.current.maxX + dataMinMax.current.xLen * 0.05
          scales.xTop.max = dataMinMax.current.maxX + dataMinMax.current.xLen * 0.05
        }
        // reset initial scale limits
        if (!chartSettingsRef.current.yAxisMinUserDefined) {
          scales.y.min = dataMinMax.current.minY - dataMinMax.current.yLen * 0.05
          scales.yRight.min = dataMinMax.current.minY - dataMinMax.current.yLen * 0.05
        }
        if (!chartSettingsRef.current.yAxisMaxUserDefined) {
          scales.y.max = dataMinMax.current.maxY + dataMinMax.current.yLen * 0.05
          scales.yRight.max = dataMinMax.current.maxY + dataMinMax.current.yLen * 0.05
        }
        if (!chartSettingsRef.current.xAxisFixedScaleEnabled) {
          updateXAxisChartSettingsRef()
        }
        if (!chartSettingsRef.current.yAxisFixedScaleEnabled) {
          updateYAxisChartSettingsRef()
        }
        if (onUpdateNoRender) {
          const xAxis = scales.x
          const yAxis = scales.y
          let xLen = xAxis.max - xAxis.min
          let yLen = yAxis.max - yAxis.min
          let payload = {
            width: size.current.width,
            height: size.current.height,
            xAxisScale: chartSettingsRef.current.xAxisScale,
            yAxisScale: chartSettingsRef.current.yAxisScale,
            xMin: xAxis.min,
            xMax: xLen < 1.0 ? xAxis.max + 1.0 : xAxis.max, // clamp zoom-in, x-axis
            yMin: yAxis.min,
            yMax: yLen < 1.0 ? yAxis.max + 1.0 : yAxis.max, // clamp zoom-in y-axis
          }
          if (chartSettingsRef.current.xAxisMaxUserDefined) {
            payload.xMax = chartSettingsRef.current.xMaxUser
          }
          if (chartSettingsRef.current.xAxisMinUserDefined) {
            payload.xMin = chartSettingsRef.current.xMinUser
          }
          if (chartSettingsRef.current.yAxisMinUserDefined) {
            payload.yMin = chartSettingsRef.current.yMinUser
          }
          if (chartSettingsRef.current.yAxisMaxUserDefined) {
            payload.yMax = chartSettingsRef.current.yMaxUser
          }
          onUpdateNoRender(payload)
        }
        chartRef.current.update()
      }
    }

    const getData = () => {
      if (dataMinMax.current) adjustChartScale()

      return chartDataRef.current
    }

    return (
      <div ref={rszRef} style={{ height: '100%', width: '100%' }}>
        <Line
          ref={chartRef}
          type='line'
          options={getOptions()}
          data={getData()}
          plugins={[borderPlugIn, dragger, lithologyPlugIn, polygonPlugIn, doubleClickPlugin]}
        />
      </div>
    )
  },
)

export default WallPlotChart
