import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { currentPageAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { actionBarWidthAtom, userUserRoleAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Box, Tooltip, IconButton, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS, getItemFromLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from 'utils'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useOrgIcons from 'components/common/hooks/useOrgIcons'
import { Avatar } from '@mui/material'
import { styled } from '@mui/styles'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { checkPermission } from 'components/userPermissions'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import ProductFeaturesModal from 'components/Admin//Organizations/ProductFeatureModal'
import PickListVirtualDialog from 'components/common/PickListVirtualDialog'
import { debounce } from 'lodash'
import { cloneDeep } from 'lodash'
import AlertsModal from 'components/Alerts/AlertsModal'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const getInitialGroupRows = () => {
  let storedValue = getItemFromLS('userRolesGridGroupRows', 'groupRows')
  if (storedValue === null || storedValue === undefined) return true
  return storedValue
}

const UserRolesPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [orgList, setOrgList] = useState([])
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({ roleAttributes: {} })
  const { getCompanyIcon } = useOrgIcons()
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const userRole = useRecoilValue(userUserRoleAtom)
  const [groupRows, setGroupRows] = useState(getInitialGroupRows())
  const selectedRole = useRef(null)
  const [showPickOperators, setShowPickOperators] = useState(false)
  const [showPickFeatures, setShowPickFeatures] = useState(false)
  const [featureList, setFeatureList] = useState([])
  const [operatorList, setOperatorList] = useState({ operators: [], selectedOperators: [] })
  const [productKeys, setProductKeys] = useState([])
  const [showAlerts, setShowAlerts] = useState(false)

  const getData = useInnovaAxios({
    url: '/admin/user/roles',
  })

  const addData = useInnovaAxios({
    url: '/admin/user/role/save',
  })

  const duplicateData = useInnovaAxios({
    url: '/admin/user/role/duplicate',
  })

  const updateData = useInnovaAxios({
    url: '/admin/user/role/save',
  })

  const deleteData = useInnovaAxios({
    url: '/admin/user/role/delete',
  })

  const getOrgData = useInnovaAxios({
    url: '/admin/getOrgListByUser',
  })

  const getFeatures = useInnovaAxios({
    url: '/admin/getFeaturesByOrgWithDesc',
  })

  const getProductKeys = useInnovaAxios({
    url: '/admin/getAllProductKeys',
  })

  const saveFeatures = useInnovaAxios({
    url: '/admin/user/role/saveFeatures',
  })

  const getOperatorAccess = useInnovaAxios({
    url: '/admin/role/getOperatorAccess',
  })

  const updateOperatorAccess = useInnovaAxios({
    url: '/admin/role/updateOperatorAccess',
  })

  const getOperators = useInnovaAxios({
    url: '/admin/getOperatorList',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.adminRolesKey)
    fetchOrganizationsList()
    fetchFeatures()
    fetchProductKeys()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      //Timeout is required to stop a flush sync error
      if (gridApi.current) gridApi.current.refreshCells()
    }, 100)
  }, [getCompanyIcon]) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const fetchProductKeys = async () => {
    const res = await getProductKeys()
    if (!_isMounted.current) return
    if (res.error) return

    setProductKeys(Array.isArray(res.data) ? res.data : [])
  }

  const fetchOrganizationsList = async () => {
    const response = await getOrgData()
    if (response.error && _isMounted.current) {
      return
    }

    if (_isMounted.current && Array.isArray(response.data)) {
      let orgs = [...response.data]
      orgs.sort()

      setOrgList(orgs)
    }
  }

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getData()

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    gridApi.current.setGridOption(
      'rowData',
      Array.isArray(res?.data) ? res.data.sort((a, b) => a.organization.localeCompare(b.organization)) : [],
    )
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
    fetchData()
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const handleAddRow = useCallback(async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return

    isAdding.current = true
    inputRow.current = htmlSymbolHandling(inputRow.current)
    inputRow.current.roleAttributes = htmlSymbolHandling(inputRow.current.roleAttributes)

    let res = await addData(inputRow.current)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current = htmlSymbolHandling(res.data)
      inputRow.current.id = res.data.id
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }, [addData])

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('id')) return

      data = htmlSymbolHandling(data)
      data.roleAttributes = htmlSymbolHandling(data.roleAttributes)

      if (isUpdating.current) return
      isUpdating.current = true
      let res = await updateData(data)
      isUpdating.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res?.error) {
        gridApi.current.applyTransaction({
          update: [data],
        })
      }
    },
    [updateData],
  )

  const gridOptions = useMemo(() => {
    return {
      pinnedBottomRowData: [inputRow.current],
      onCellEditingStopped: (event) => {
        if (event.node?.rowPinned === 'bottom') {
          handleAddRow()
        }
        if (event.node?.rowPinned !== 'bottom') {
          handleUpdate(event.data)
        }
      },
      getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
      suppressRowClickSelection: true,
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
        ],
        defaultToolPanel: '',
        position: 'left',
      },
      onDragStopped: () => {
        saveColumnState()
      },
      onColumnVisible: () => {
        saveColumnState()
      },
      onExpandOrCollapseAll: () => {
        autoSizeColumns()
      },
      loadingOverlayComponent: CustomLoadingOverlay,
    }
  }, [handleAddRow, handleUpdate])

  const createPinnedCellPlaceholder = useCallback(({ colDef }) => {
    if (colDef.field !== 'organization' && colDef.field !== 'name' && colDef.field !== 'roleAttributes.description') {
      return ''
    }

    if (colDef.field === 'roleAttributes.description') return 'Description...'
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }, [])

  const isEmptyPinnedCell = useCallback(({ node, value }) => {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: false,
      cellStyle: centerAlignCell,
      headerClass: 'header-no-padding', //This style removes the 15px horizontal padding ag grid provides
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [isEmptyPinnedCell, createPinnedCellPlaceholder, centerAlignCell])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    expandAllNodes('expand')
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    setTimeout(() => {
      gridApi.current?.autoSizeAllColumns()
    }, 200)
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('userRolesGrid', 'colLayout', colLayout)
    }
  }

  const expandAllNodes = (action) => {
    if (typeof action !== 'string') return
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    if (action === 'expand') gridApi.current.expandAll()
    if (action !== 'expand') gridApi.current.collapseAll()
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('userRolesGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: groupRows ? 'Ungroup Rows' : 'Group Rows',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('userRolesGrid', 'colLayout', null)
          saveItemToLS('userRolesGridGroupRows', 'groupRows', !groupRows)
          setGroupRows(!groupRows)
        },
        icon: '<span class="iconify" data-icon="mdi:format-list-group" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Expand all',
        disabled: false,
        action: () => {
          expandAllNodes('expand')
        },
        icon: '<span class="iconify" data-icon="material-symbols:expand" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Collapse all',
        disabled: false,
        action: () => {
          expandAllNodes('collapse')
        },
        icon: '<span class="iconify" data-icon="pajamas:collapse" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'UserRoles.xlsx',
            sheetName: 'Roles',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  function isPinnedRowDataCompleted() {
    let requiredFields = ['organization', 'name', 'roleAttributes']
    for (let i = 0; i < requiredFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(requiredFields[i])) return false
    }

    if (!inputRow.current.roleAttributes.hasOwnProperty('description')) return false
    return true
  }

  const getNumRolesForOrg = (orgName) => {
    if (!orgName) return 0
    if (typeof orgName !== 'string') return 0
    if (!gridApi.current) return 0

    let numRoles = 0
    gridApi.current.forEachNode((rowNode) => {
      if (rowNode?.data?.hasOwnProperty('organization') && rowNode.data.organization === orgName) {
        numRoles++
      }
    })

    return numRoles
  }

  const handleDelete = async () => {
    if (!confirm.data) return
    if (!confirm.data.hasOwnProperty('id')) return

    if (getNumRolesForOrg(confirm.data.organization) <= 1) {
      setStatus({
        show: true,
        severity: 'error',
        message: `Cannot delete last role for ${confirm.data.orgName}`,
      })
      return
    }

    if (isDeleting.current) return
    isDeleting.current = true

    let res = await deleteData(confirm.data)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [confirm.data],
      })
    }
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => (params.node?.rowPinned !== 'bottom' ? onClickDelete(params.data) : handleAddRow())}
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' && !groupRows ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px', paddingRight: '4px', width: '25px' }}>
              {params.node.rowIndex + 1}
            </Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  const GroupRowInnerRenderer = (props) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '10px',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box
          sx={{
            margin: 'auto',
            width: 35,
            height: 35,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'lightgrey',
            borderRadius: '50%',
          }}>
          <Avatar
            alt={props.node.key}
            sx={{ width: '30px', height: '30px', marginLeft: '1px' }}
            src={getCompanyIcon(props.node.key)}
          />
        </Box>
        <Box
          sx={{
            marginLeft: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          {props.node.key}
        </Box>
      </Box>
    )
  }

  const groupRowRendererParams = {
    innerRenderer: GroupRowInnerRenderer,
    checkbox: false,
  }

  const handleDuplicateRole = async (data) => {
    if (isAdding.current) return

    isAdding.current = true
    let res = await duplicateData({ id: data.id })
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        add: [res.data],
      })
    }
  }

  const handleMenuClick = async (action, data) => {
    if (typeof action !== 'string') return
    if (!data) return

    selectedRole.current = data
    if (action === 'productsFeatures') {
      setShowPickFeatures(true)
    }

    if (action === 'operators') {
      await getOperatorsForRole()
      setShowPickOperators(true)
    }

    if (action === 'duplicate') {
      handleDuplicateRole(selectedRole.current)
    }

    if (action === 'alerts') {
      setShowAlerts(true)
    }
  }

  const doesArrayHaveValues = (arr) => {
    if (!Array.isArray(arr)) return false
    if (arr.length === 1 && arr[0] === '') return false
    return arr.length > 0
  }

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        headerName: '',
        colId: 'menu',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          return {
            showBottomRow: false,
            menuItems: [
              {
                label: 'Products & Features',
                action: 'productsFeatures',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='fluent-mdl2:product-variant' />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Operator Access',
                action: 'operators',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='mdi:barrel' />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Duplicate Role',
                action: 'duplicate',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='vaadin:copy' />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Alerts',
                action: 'alerts',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='vaadin:bell' />
                  </StyledIconContainer>
                ),
              },
            ],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'icon',
        colId: 'icon',
        headerName: '',
        width: 60,
        hide: groupRows,
        resizable: false,
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: (params) => {
          if (!params) return null
          if (!params.data) return null
          if (typeof params.data?.organization !== 'string') return null

          return (
            <Avatar
              alt={params?.data?.organization}
              sx={{ width: '30px', height: '30px', marginLeft: '1px' }}
              src={getCompanyIcon(params?.data?.organization)}
            />
          )
        },
      },
      {
        field: 'organization',
        colId: 'organization',
        headerName: 'Organization',
        rowGroup: groupRows,
        editable: (params) => params?.node?.rowPinned === 'bottom',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          color: appColors.itemTextColor,
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: Array.isArray(orgList) ? orgList : [],
          }
        },
      },
      {
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          color: appColors.itemTextColor,
        },
        valueGetter: (params) => unescapeHtml(params.data?.name),
      },
      {
        field: 'roleAttributes.description',
        colId: 'description',
        headerName: 'Description',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          color: appColors.itemTextColor,
        },
        valueGetter: (params) => unescapeHtml(params.data?.roleAttributes?.description),
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return

          let data = { ...params.data }
          data.roleAttributes.description = params.newValue

          if (params.node?.rowPinned !== 'bottom') {
            handleUpdate(data)
          }

          return true
        },
      },
      {
        field: 'roleAttributes.permissions.admin',
        colId: 'isAdmin',
        headerName: 'Admin',
        cellStyle: centerAlignCell,
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom') return null

          if (params.colDef.field.indexOf('.') < 0) return

          let fieldNames = params.colDef.field.split('.')
          if (fieldNames.length < 3) return

          params.data[fieldNames[0]][fieldNames[1]][fieldNames[2]] =
            !params.data[fieldNames[0]][fieldNames[1]][fieldNames[2]]

          if (params.node?.rowPinned !== 'bottom') handleUpdate(params.data)
          return true
        },
      },
      {
        field: 'roleAttributes.permissions.canEdit',
        colId: 'canEdit',
        headerName: 'Edit',
        cellStyle: centerAlignCell,
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom') return null

          if (params.colDef.field.indexOf('.') < 0) return

          let fieldNames = params.colDef.field.split('.')
          if (fieldNames.length < 3) return

          params.data[fieldNames[0]][fieldNames[1]][fieldNames[2]] =
            !params.data[fieldNames[0]][fieldNames[1]][fieldNames[2]]

          if (params.node?.rowPinned !== 'bottom') handleUpdate(params.data)
          return true
        },
      },
      {
        field: 'roleAttributes.permissions.canDelete',
        colId: 'canDelete',
        headerName: 'Delete',
        cellStyle: centerAlignCell,
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom') return null

          if (params.colDef.field.indexOf('.') < 0) return

          let fieldNames = params.colDef.field.split('.')
          if (fieldNames.length < 3) return

          params.data[fieldNames[0]][fieldNames[1]][fieldNames[2]] =
            !params.data[fieldNames[0]][fieldNames[1]][fieldNames[2]]

          if (params.node?.rowPinned !== 'bottom') handleUpdate(params.data)
          return true
        },
      },
      {
        field: 'roleAttributes.permissions.canCreate',
        colId: 'canCreate',
        headerName: 'Create',
        cellStyle: centerAlignCell,
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom') return null

          if (params.colDef.field.indexOf('.') < 0) return

          let fieldNames = params.colDef.field.split('.')
          if (fieldNames.length < 3) return

          params.data[fieldNames[0]][fieldNames[1]][fieldNames[2]] =
            !params.data[fieldNames[0]][fieldNames[1]][fieldNames[2]]

          if (params.node?.rowPinned !== 'bottom') handleUpdate(params.data)
          return true
        },
      },
      {
        field: 'roleAttributes.permissions.canApprove',
        colId: 'canApprove',
        headerName: 'Approve',
        cellStyle: centerAlignCell,
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom') return null

          if (params.colDef.field.indexOf('.') < 0) return

          let fieldNames = params.colDef.field.split('.')
          if (fieldNames.length < 3) return

          params.data[fieldNames[0]][fieldNames[1]][fieldNames[2]] =
            !params.data[fieldNames[0]][fieldNames[1]][fieldNames[2]]

          if (params.node?.rowPinned !== 'bottom') handleUpdate(params.data)
          return true
        },
      },
      {
        colId: 'hasFeatures',
        headerName: 'Products & Features',
        cellStyle: centerAlignCell,
        editable: false,
        cellRenderer: 'agCheckboxCellRenderer',
        valueGetter: (params) =>
          doesArrayHaveValues(
            Array.isArray(params.node?.data?.roleAttributes?.featureId)
              ? params.node?.data?.roleAttributes?.featureId
              : [],
          ),
        valueSetter: (params) => {
          return false
        },
      },
    ],
    [orgList, getCompanyIcon, groupRows], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const onClickDelete = (data) => {
    if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) return
    setConfirm({
      show: true,
      title: 'Delete Role',
      text: `Are you sure you want to delete ${data.name}?`,
      data: data,
    })
  }

  const applyProductFeatures = async (selectedFeatures) => {
    if (!selectedRole.current) return
    if (!selectedFeatures) return
    if (!Array.isArray(selectedFeatures)) return
    if (isUpdating.current) return
    if (!Array.isArray(selectedFeatures)) return

    let strArray = ''
    selectedFeatures.forEach((feature) => {
      if (feature === '') return
      if (strArray !== '') strArray += ','
      strArray += feature
    })

    isUpdating.current = true
    let res = await saveFeatures({
      id: selectedRole.current.id,
      featureIds: strArray,
    })
    isUpdating.current = false

    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    if (gridApi.current) {
      const rowNode = gridApi.current.getRowNode(selectedRole.current.id)
      if (rowNode && rowNode.hasOwnProperty('data')) {
        let newData = cloneDeep(rowNode.data)
        newData.roleAttributes.featureId = selectedFeatures

        gridApi.current.applyTransaction({
          update: [newData],
        })
      }
    }

    setShowPickFeatures(false)
  }

  const applyPickOperator = async (selectedOperators) => {
    if (!selectedRole.current) return
    if (!selectedOperators) return
    if (Array.isArray(selectedOperators) === false) return
    if (isUpdating.current) return

    let strArray = ''
    selectedOperators.forEach((op) => {
      if (strArray !== '') strArray += '|'
      strArray += op
    })

    isUpdating.current = true
    let res = await updateOperatorAccess({
      accessRoleId: selectedRole.current.id,
      operatorList: strArray,
    })
    isUpdating.current = false

    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setShowPickOperators(false)
  }

  const fetchFeatures = async () => {
    const res = await getFeatures()
    if (res.error) return

    setFeatureList(Array.isArray(res.data) ? res.data : [])
  }

  function createOrgFeatureList(organization) {
    if (!organization) return []
    if (!Array.isArray(featureList)) return []
    if (!Array.isArray(productKeys)) return []

    let index = featureList.findIndex((element) => element.organization === organization)
    if (index < 0) return []

    let output = productKeys.map((product) => {
      return {
        header: product.description,
        productKey: product.key,
        data: [],
      }
    })

    featureList[index].features.forEach((feature) => {
      let productIndex = output.findIndex((element) => feature.featureId.includes(element.productKey))
      if (productIndex >= 0) output[productIndex].data.push({ id: feature.featureId, desc: feature.description })
    })

    return output
  }

  const getOperatorsForRole = async () => {
    let newOpList = { operators: [], selectedOperators: [] }

    if (!selectedRole.current) {
      setOperatorList(newOpList)
      return
    }

    let res = await getOperators({
      roleId: selectedRole.current.id,
    })

    if (!_isMounted.current) return

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `Get operators: ${res?.error?.response?.data?.error}`,
      })
      setOperatorList(newOpList)
      return
    }

    if (Array.isArray(res.data)) {
      for (let i = 0; i < res.data.length; i++) {
        newOpList.operators.push({ id: res.data[i], desc: res.data[i] })
      }
    }

    res = await getOperatorAccess({
      accessRoleId: selectedRole.current.id,
    })

    if (!_isMounted.current) return

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `Get operators access: ${res?.error?.response?.data?.error}`,
      })
      setOperatorList(newOpList)
      return
    }

    newOpList.selectedOperators = Array.isArray(res.data) ? cloneDeep(res.data) : []
    setOperatorList(newOpList)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px)`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: 'calc(100vh - 64px)',
      }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => handleDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showPickOperators ? (
        <PickListVirtualDialog
          title='Select Operators'
          open={showPickOperators}
          setOpen={() => setShowPickOperators(false)}
          onApply={applyPickOperator}
          items={
            Array.isArray(operatorList.operators)
              ? operatorList.operators.sort((a, b) => a.desc.localeCompare(b.desc))
              : []
          }
          initSelItems={operatorList.selectedOperators}
        />
      ) : null}
      {showPickFeatures ? (
        <ProductFeaturesModal
          onCancel={() => setShowPickFeatures(false)}
          onApply={applyProductFeatures}
          allFeatures={createOrgFeatureList(selectedRole.current?.organization)}
          selectedFeatures={selectedRole.current?.roleAttributes.featureId}
        />
      ) : null}
      {showAlerts ? (
        <AlertsModal
          onCancel={() => setShowAlerts(false)}
          byUser={'false'}
          byRole={'true'}
          roleId={selectedRole.current?.id ? selectedRole.current?.id.toString() : ''}
          userName={''}
        />
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'userRolesGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            headerHeight={30}
            groupDisplayType={'groupRows'}
            groupRowsSticky={true}
            groupRowRendererParams={groupRowRendererParams}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchData()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default UserRolesPage
