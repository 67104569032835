import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box } from '@mui/material'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { CHART_TYPES as chartTypes } from 'components/WellPages/WallplotComposer/Elements/elementDefs'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import ChartDetailsPropertyGrid from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ChartDetailsPropertyGrid'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'

const getErrorEllipsesDefault = () => {
  return {
    interval: 2,
    lineStyle: 'solid',
    useSeriesLineStyle: false,
    useSeriesColor: false,
  }
}

const ChartOptionsGrid = forwardRef(({ chartData, chartType }, ref) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const { getUnitsText } = useUnits()

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApi.current) gridApi.current.stopEditing()
    },
    getData: () => {
      let gridData = []
      let errorEllipses = {}
      gridApi.current?.forEachNode((node) => {
        if (node.data) gridData.push(node.data)
        if (node.data?.tag === 'showErrorEllipses' && node.data?.value === true)
          errorEllipses = node.data.options || getErrorEllipsesDefault()
      })
      let payload = { props: [...gridData] }
      if (Object.keys(errorEllipses).length > 0) payload.errorEllipses = errorEllipses

      return payload
    },
  }))

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        cellRendererSelector: (params) => {
          if (params.data?.tag === 'showErrorEllipses') return { component: 'agGroupCellRenderer' }
          return null
        },
        width: 50,
        cellStyle: centerAlignCell,
        cellClass: 'grid-detail-icon',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        filter: null,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: '',
        field: 'label',
        colId: 'label',
        minWidth: 150,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        headerName: '',
        field: 'value',
        colId: 'value',
        flex: 1,
        editable: true,
        cellDataType: false,
        cellStyle: (params) => {
          if (params.data?.type === 'color') {
            return {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              backgroundColor: params.value,
              color: params.value,
            }
          }
          return { display: 'flex', alignItems: 'center', justifyContent: 'start' }
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'dropDown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          if (params.data?.type === 'number') {
            return {
              component: 'agTextCellEditor',
              params: {
                min: params.data?.min,
                max: params.data?.max,
                precision: params.data?.precision,
              },
            }
          }

          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          return {
            component: 'agTextCellEditor',
          }
        },
        valueSetter: (params) => {
          if (params.oldValue === params.newValue) return

          let gridData = []
          gridApi.current.forEachNode((node) => {
            if (node.data) gridData.push(node.data)
          })

          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (index < 0) return

          if (params?.data?.type === 'number') {
            if (params.newValue === null || params.newValue === undefined) params.newValue = 0
            params.newValue = parseFloat(params.newValue)
          }

          gridData[index].value = params.newValue

          gridApi.current.applyTransaction({ update: gridData })
        },
        cellRendererSelector: (params) => {
          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellRenderer',
            }
          }
          return null
        },
        valueFormatter: (params) => {
          if (params?.data?.type === 'number') {
            return `${numberWithCommasDecimals(params?.data?.value, params.data?.precision || 0)}`
          }
          if (params?.data?.type === 'fileInput') {
            return params?.data?.value.name
          }
          return params?.data?.value
        },
      },
    ],
    [gridApi.current], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getPropertyData = (data) => {
    let propertyData = []
    if (!data) return propertyData
    propertyData.push({
      label: `Error Ellipses`,
      value: false,
      type: 'bool',
      tag: `showErrorEllipses`,
      options: data.errorEllipses
        ? {
            interval: data.errorEllipses.interval,
            lineStyle: data.errorEllipses.lineStyle,
            useSeriesLineStyle: data.errorEllipses.useSeriesLineStyle,
            useSeriesColor: data.errorEllipses.useSeriesColor,
          }
        : getErrorEllipsesDefault(),
    })
    if (chartType === chartTypes.sectionView) {
      propertyData.push({
        label: `Geosteering`,
        value: false,
        type: 'bool',
        tag: `showGeosteering`,
      })
    }

    for (let i = 0; i < propertyData.length; i++) {
      propertyData[i].id = i
    }

    const gridData = { ...data }

    for (let i = 0; i < propertyData.length; i++) {
      if (propertyData[i].tag === '') continue
      if (!gridData.hasOwnProperty(propertyData[i].tag)) continue
      propertyData[i].value = gridData[propertyData[i].tag]
      if (propertyData[i].tag === 'showErrorEllipses') {
        propertyData[i].options = gridData.errorEllipses
      }
    }
    return propertyData
  }

  const getErrorEllipsesDetailRowData = (params) => {
    let detailRowData = []
    let options = params.data.options ? params.data.options : getErrorEllipsesDefault()
    // restore if we switch to ellipses from continuous range used now
    // detailRowData.push({
    //   label: 'Interval',
    //   value: options.interval,
    //   type: 'number',
    //   tag: 'interval',
    // })
    detailRowData.push({
      label: 'Line Style',
      value: options.lineStyle,
      type: 'line',
      tag: 'lineStyle',
      dropDownValues: ['solid', 'dotted', 'dashed'],
    })
    detailRowData.push({
      label: 'Use series line style',
      value: options.useSeriesLineStyle,
      type: 'bool',
      tag: 'useSeriesLineStyle',
    })
    detailRowData.push({
      label: 'Use series color',
      value: options.useSeriesColor,
      type: 'bool',
      tag: 'useSeriesColor',
    })

    detailRowData.forEach((row, index) => {
      row.id = index
    })

    return detailRowData
  }

  const detailUpdateFunc = (id, params) => {
    const gridData = []
    gridApi.current.forEachNode((node) => {
      if (node.data) gridData.push(node.data)
    })

    const index = gridData.findIndex((data) => data.id === id)
    if (index < 0) return

    gridData[index].options[params.tag] = params.value

    gridApi.current.applyTransaction({ update: gridData })
  }

  const DetailCellRenderer = (params) => {
    if (!params.data) return null

    let detailRowData = []
    switch (params.data.tag) {
      case 'showErrorEllipses':
        detailRowData = getErrorEllipsesDetailRowData(params)
        break
      default:
        return null
    }

    return (
      <Box sx={{ height: `${32 + 45 * detailRowData.length}px` }}>
        <ChartDetailsPropertyGrid
          id={params.data.id}
          data={detailRowData}
          handleUpdateFunc={detailUpdateFunc}
          units={getUnitsText(UNITS_FOR.Depth)}
        />
      </Box>
    )
  }

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
      <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
        <AgGridReact
          className='ag-grid-theme-dark'
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          rowData={getPropertyData(chartData)}
          headerHeight={0}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
          masterDetail={true}
          detailCellRenderer={DetailCellRenderer}
          detailRowAutoHeight={true}
        />
      </div>
    </div>
  )
})

export default ChartOptionsGrid
