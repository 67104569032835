import React, { useEffect, useRef, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Alert, Snackbar } from '@mui/material'
import LoadWpcGrid from 'components/WellPages/WallplotComposer/Templates/LoadWpcGrid'
import useWallPlotComposerTemplates from 'components/common/hooks/WallPlotComposer/useWallPlotComposerTemplates'

const LoadWpcTemplateModal = ({ onClose, onApply, wellName, isLoad }) => {
  const _isMounted = useRef(false)
  const gridRef = useRef(null)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  const { fetchWallPlots, wallplotList, deleteWallPlot, fetchAllWallPlots, renameWallPlot } =
    useWallPlotComposerTemplates(wellName)

  useEffect(() => {
    _isMounted.current = true
    isLoad ? fetchWallPlots() : fetchAllWallPlots()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      if (wellName) {
        isLoad ? fetchWallPlots() : fetchAllWallPlots()
      }
    }
  }, [wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleLoad = (templateId) => {
    if (templateId === undefined) {
      templateId = gridRef.current.getSelRow()
    }
    onApply(wallplotList[templateId])
  }

  const handleRename = async (wellName, templateId, newName) => {
    if (!templateId || !newName) return
    const result = await renameWallPlot({ wellName: wellName, templateUid: templateId, templateName: newName })
    if (result) {
      setStatus({ show: true, severity: 'error', message: 'rename failed, template name in use' })
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: 'WallPlot template renamed' })
    }
    return true
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      sx={{ zIndex: 9999 }}
      PaperProps={{
        sx: {
          minWidth: '400px',
          width: '50vw',
          minHeight: '600px',
          height: '50vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>{isLoad ? 'Load WallPlot' : 'Duplicate WallPlot'}</DialogTitle>
      <DialogContent
        sx={{
          padding: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <LoadWpcGrid
          templateData={wallplotList}
          ref={gridRef}
          handleUpdate={handleLoad}
          handleDelete={(params) => {
            deleteWallPlot({ templateUid: params.uid, wellName: params.actualWell })
          }}
          handleRename={handleRename}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose} color='secondary'>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          onClick={() => {
            handleLoad()
          }}>
          {isLoad ? 'Load' : 'Duplicate'}
        </Button>
      </DialogActions>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={3000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Dialog>
  )
}

export default LoadWpcTemplateModal
