import React, { useEffect, useMemo, useState } from 'react'
import DrillStringRecommendationsGrid from './DrillStringRecommendationsGrid'
import { Box, TextField, Button } from '@mui/material'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { Icon as Iconify } from '@iconify/react'
import DrillStringRecommendationsFilterGrid from './DrillStringRecommendationsFilterGrid'
import { appColors } from 'utils'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'
import { TemplateBoxComponent } from 'components/common/TemplateBox'

const verticalTextStyle = {
  writingMode: 'vertical-rl',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#222628',
    fontWeight: 'bold',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const DrillStringRecommendationsPane = ({
  setStatus,
  setImportBha,
  recommendations,
  recommendationFilterData,
  setRecommendationFilters,
  showHideFilter,
  showFilter,
  currentWell,
  refreshRecommendations,
  totalRecommendations,
  isLoading,
}) => {
  const _isMounted = React.useRef(true)
  const { getChartBackColor, getWindowBarColor, theme } = useInnovaTheme()
  const [radius, setRadius] = useState(getItemFromLS(currentWell, 'radius') || 5)
  const [templates, setTemplates] = useState(() => {
    const storedData = getItemFromLS('dsRecommendationTemplates', 'dsRecommendationTemplates') || []
    return storedData[currentWell] || []
  })
  const [newTemplateName, setNewTemplateName] = useState('')
  const [selectedTemplateName, setSelectedTemplateName] = useState('Default')

  const textFieldStyle = useMemo(
    () => ({
      height: '30px',
      margin: '5px',
      backgroundColor: theme === 'dark' ? '#2d2d2d !important' : '#f0f0f0 !important',
      width: '60px',
      maxWidth: '400px',
      justifyContent: 'center',
    }),
    [theme],
  )

  useEffect(() => {
    _isMounted.current = true

    if (templates.length === 0 && recommendationFilterData?.length > 0) {
      saveTemplate('Default', recommendationFilterData)
    }

    return () => {
      _isMounted.current = false
    }
  })

  const createNewTemplate = () => {
    if (!newTemplateName.trim()) return

    saveTemplate(newTemplateName)
    setSelectedTemplateName(newTemplateName)
    setNewTemplateName('')
  }

  const handleSelectTemplate = (templateName, template = templates[templateName]) => {
    setSelectedTemplateName(templateName)

    if (template) {
      setRecommendationFilters(JSON.parse(JSON.stringify(template)))
    }
  }

  const saveTemplate = (templateName, templateData) => {
    if (!templateName && selectedTemplateName === 'Default') return
    const storedData = getItemFromLS('dsRecommendationTemplates', 'dsRecommendationTemplates') || {}

    const updatedWellTemplates = {
      ...storedData[currentWell],
      [templateName ? templateName : selectedTemplateName]: templateData || recommendationFilterData,
    }

    setTemplates(updatedWellTemplates)

    const updatedData = {
      ...storedData,
      [currentWell]: updatedWellTemplates,
    }

    saveItemToLS('dsRecommendationTemplates', 'dsRecommendationTemplates', updatedData)
  }

  const deleteTemplate = (templateName) => {
    if (!templateName) templateName = selectedTemplateName
    if (!templateName.trim()) return
    if (templateName === 'Default') return
    const storedData = getItemFromLS('dsRecommendationTemplates', 'dsRecommendationTemplates') || {}

    if (!storedData[currentWell]) return

    const updatedRecommendationTemplates = { ...storedData[currentWell] }
    delete updatedRecommendationTemplates[templateName]

    const updatedWellTemplates = {
      ...storedData,
      [currentWell]: updatedRecommendationTemplates,
    }

    setTemplates(updatedRecommendationTemplates)
    handleSelectTemplate('Default')

    saveItemToLS('dsRecommendationTemplates', 'dsRecommendationTemplates', updatedWellTemplates)
  }

  const handleImportTemplate = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.json'
    input.onchange = (e) => {
      const file = e.target.files[0]
      if (!file) return

      const reader = new FileReader()
      reader.onload = (e) => {
        try {
          const content = e.target.result
          const template = JSON.parse(content)

          if (!isValidTemplate(template)) {
            alert(`Invalid template format. Please ensure the JSON matches the expected structure.`)
            return
          }

          const templateName = file.name.replace('.json', '')
          saveTemplate(templateName, template)
          handleSelectTemplate(templateName, template)
        } catch (error) {
          console.error('Error parsing imported JSON:', error)
          alert('Invalid JSON file. Please check the file format and try again.')
        }
      }

      reader.readAsText(file)
    }
    input.click()
  }

  const handleExportTemplate = () => {
    const template = templates[selectedTemplateName]
    const blob = new Blob([JSON.stringify(template)], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `${selectedTemplateName}.json`
    a.click()
  }

  const isValidTemplate = (template) => {
    if (!template) return false
    if (!Array.isArray(template)) return false
    if (template.length === 0) return false

    const requiredKeys = ['header', 'values', 'checked']
    const firstItem = template[0]

    for (const key of requiredKeys) {
      if (!firstItem.hasOwnProperty(key)) return false
    }

    return true
  }

  const handleSearch = (e) => {
    e.preventDefault()
    if (radius === '') return
    if (isNaN(radius)) return
    if (radius < 0) return

    setSelectedTemplateName('Default')
    refreshRecommendations()
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: getChartBackColor(),
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box
        component='form'
        onSubmit={handleSearch}
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          height: '35px',
          paddingLeft: '5px',
          color: appColors.itemTextColor,
        }}>
        Radius:
        <TextField
          sx={textFieldStyle}
          fullWidth
          value={radius}
          onChange={(e) => {
            let newVal = e.target.value
            setRadius(newVal)
            saveItemToLS(currentWell, 'radius', newVal)
          }}
        />
        miles
        <Box sx={{ marginLeft: '10px' }}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            size='small'>
            Search
          </Button>
        </Box>
        <Box sx={{ marginLeft: '20px' }}>#Wells: {totalRecommendations}</Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: 'calc(100% - 60px)',
          backgroundColor: getChartBackColor(),
          display: 'flex',
          flexDirection: 'row',
        }}>
        <Box
          sx={{
            width: '25px',
            height: '100%',
            backgroundColor: getWindowBarColor(),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
          }}>
          <Box
            onClick={showHideFilter}
            sx={{
              marginLeft: 'auto',
              width: '25px',
              height: '25px',
              backgroundColor: '#222628',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
            }}>
            <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
          </Box>
          <Box
            sx={{
              ...verticalTextStyle,
              fontWeight: showFilter ? 'bold' : '400',
              color: showFilter ? '#429ceb' : '#f0f0f0',
            }}
            onClick={() => {
              if (!showFilter) showHideFilter()
              if (showFilter) {
                showHideFilter()
                return
              }
            }}>
            Filter
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'calc(100% - 25px)',
            height: '100%',
          }}>
          {showFilter && (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '65%', height: '100%' }}>
              <TemplateBoxComponent
                templates={templates}
                newTemplateName={newTemplateName}
                setNewTemplateName={setNewTemplateName}
                selectedTemplateName={selectedTemplateName}
                handleSelectTemplate={handleSelectTemplate}
                createNewTemplate={createNewTemplate}
                saveTemplate={saveTemplate}
                deleteTemplate={deleteTemplate}
                handleExportTemplate={handleExportTemplate}
                handleImportTemplate={handleImportTemplate}
                width={'100%'}
              />
              <DrillStringRecommendationsFilterGrid
                recommendationFilterData={recommendationFilterData}
                setRecommendationFilters={setRecommendationFilters}
                isLoading={isLoading}
              />
            </Box>
          )}
          <DrillStringRecommendationsGrid
            recommendations={recommendations}
            recommendationFilterData={recommendationFilterData}
            setStatus={setStatus}
            setImportBha={setImportBha}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Box>
  )
}
export default DrillStringRecommendationsPane
