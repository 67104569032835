import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { stylesToGridData } from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/propertyUtils'
import ColorPickerCellEditor from 'components/common/CellEditors/ColorPickerCellEditor'
import LineCellRenderer from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/LineCellRenderer'
import FontFamilyCellRenderer from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/FontFamilyCellRenderer'
import SliderCellEditor from 'components/common/CellEditors/SliderCellEditor'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const extractOpacity = (color) => {
  const rgbaRegex = /^rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*([\d.]+)\)$/i
  const hexRegex = /^#([0-9a-f]{6})([0-9a-f]{2})$/i

  let matchRGBA = color.match(rgbaRegex)
  let matchHex = color.match(hexRegex)

  if (matchRGBA) {
    const alpha = parseFloat(matchRGBA[4])
    return Math.max(0, Math.min(alpha, 1)) // Clamp between 0 and 1
  } else if (matchHex) {
    // Convert hex alpha to decimal and normalize to 0-1 range
    const alphaHex = matchHex[2]
    const alpha = parseInt(alphaHex, 16) / 255
    return Math.max(0, Math.min(alpha, 1)) // Clamp between 0 and 1
  }
  // If neither format matches, return 1 (fully opaque) or handle as needed
  else {
    // console.warn('Color format not supported, using default opacity of 1.', color)
    return 1
  }
}

const extractRGB = (color) => {
  if (color.startsWith('#')) {
    // Normalize hex to full 6 characters
    if (color.length === 4) {
      color = '#' + color[1] + color[1] + color[2] + color[2] + color[3] + color[3]
    }

    const r = parseInt(color.slice(1, 3), 16)
    const g = parseInt(color.slice(3, 5), 16)
    const b = parseInt(color.slice(5, 7), 16)
    return `rgb(${r}, ${g}, ${b})`
  }
  if (color.startsWith('rgba')) {
    const rgbParts = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*[\d.]+)?\)/)
    if (rgbParts) {
      return `rgb(${rgbParts[1]}, ${rgbParts[2]}, ${rgbParts[3]})`
    }
  }
  // if error, return the original color
  return color
}

const LabelPropertiesGrid = forwardRef(({ labelProps }, ref) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApi.current) gridApi.current.stopEditing()
    },
    getData: () => {
      let gridData = []
      gridApi.current?.forEachNode((node) => {
        if (node.data) gridData.push(node.data)
      })
      gridData.push({ tag: 'id', value: labelProps.id })
      gridData.push({ tag: 'uid', value: labelProps.uid })

      return gridData
    },
  }))

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'label',
        colId: 'label',
        minWidth: 150,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        headerName: '',
        field: 'value',
        colId: 'value',
        maxWidth: 200,
        flex: 1,
        editable: true,
        cellDataType: false,
        cellStyle: (params) => {
          if (params.data?.type === 'color') {
            return {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              backgroundColor: params.value,
              color: params.value,
            }
          }
          return { display: 'flex', alignItems: 'center', justifyContent: 'start' }
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'dropDown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          if (params.data?.type === 'customDropDownLine') {
            return {
              component: 'agRichSelectCellEditor',
              popup: true,
              params: {
                cellRenderer: LineCellRenderer,
                values: params.data?.dropDownValues,
                valueListMaxHeight: 125,
              },
            }
          }

          if (params.data?.type === 'customDropDownFontFamily') {
            return {
              component: 'agRichSelectCellEditor',
              popup: true,
              params: {
                cellRenderer: FontFamilyCellRenderer,
                values: params.data?.dropDownValues,
                valueListMaxHeight: 125,
              },
            }
          }

          if (params.data?.type === 'color') {
            return {
              component: ColorPickerCellEditor,
              params: {
                color: params.value,
                defaultColor: params.data?.defaultValue,
              },
              popup: true,
            }
          }

          if (params.data?.type === 'number') {
            return {
              component: 'agTextCellEditor',
              params: {
                min: params.data?.min,
                max: params.data?.max,
                precision: params.data?.precision,
              },
            }
          }

          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          if (params.data?.type === 'slider') {
            return {
              component: SliderCellEditor,
              params: {
                cellRenderer: SliderCellEditor,
                min: params.data?.min,
                max: params.data?.max,
                step: params.data?.step,
              },
            }
          }

          return {
            component: 'agTextCellEditor',
          }
        },
        valueSetter: (params) => {
          if (params.oldValue === params.newValue) return

          let gridData = []
          gridApi.current.forEachNode((node) => {
            if (node.data) gridData.push(node.data)
          })

          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (index < 0) return

          if (params?.data?.type === 'number') {
            if (params.newValue === null || params.newValue === undefined) params.newValue = 0
            params.newValue = parseFloat(params.newValue)
          }

          gridData[index].value = params.newValue
          gridApi.current.applyTransaction({ update: gridData })
        },
        cellRendererSelector: (params) => {
          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellRenderer',
            }
          }

          if (params.data?.hasCustomRenderer) {
            return {
              component: 'colorCellRenderer',
              params: {
                width: params.data?.value,
              },
            }
          }

          if (params.data?.type === 'customDropDownLine') {
            return {
              component: LineCellRenderer,
            }
          }

          if (params.data?.type === 'customDropDownFontFamily') {
            return {
              component: FontFamilyCellRenderer,
            }
          }

          return null
        },
        valueFormatter: (params) => {
          if (params?.data?.type === 'number') {
            return `${numberWithCommasDecimals(params?.data?.value, params.data?.precision || 0)}`
          }
          return params?.data?.value
        },
      },
    ],
    [],
  )

  const getPropertyData = (data) => {
    let propertyData = []
    if (!data) return propertyData

    propertyData.push({ label: `Text`, value: 'Label', type: 'text', tag: 'content', defaultValue: 'Label' })
    propertyData.push({ label: `Text Color`, value: '#3333', type: 'color', tag: 'color', defaultValue: '#333333' })
    propertyData.push({
      label: `Tether Color`,
      value: '#3333',
      type: 'color',
      tag: 'tetherColor',
      defaultValue: '#333333',
    })

    propertyData.push({
      label: `Background Color`,
      value: '#fff',
      type: 'color',
      tag: `backgroundColor`,
      defaultValue: '#fff',
    })

    propertyData.push({
      label: `Background Opacity`,
      value: 1,
      type: 'slider',
      tag: `backgroundColorOpacity`,
      min: 0.05,
      max: 1,
      step: 0.05,
    })
    propertyData.push({
      label: `Has Background`,
      value: true,
      type: 'bool',
      tag: `hasBackground`,
    })

    propertyData.push({
      label: `Has Tether`,
      value: true,
      type: 'bool',
      tag: `hasTether`,
    })

    if (data.hasOwnProperty('fontFamily'))
      // no font family support yet
      propertyData.push({
        label: `Font`,
        value: 'Arial',
        type: 'customDropDownFontFamily',
        tag: `fontFamily`,
        dropDownValues: [
          'Times New Roman',
          'Arial',
          'Verdana',
          'Helvetica',
          'Courier New',
          'Georgia',
          'Palatino',
          'Lucida Console',
        ],
      })
    propertyData.push({
      label: `Font Size`,
      value: 14,
      type: 'number',
      tag: `size`,
      min: 8,
      max: 72,
      precision: 0,
    })
    propertyData.push({
      label: `Bold`,
      value: false,
      type: 'bool',
      tag: `bold`,
    })
    propertyData.push({
      label: `Italic`,
      value: false,
      type: 'bool',
      tag: `italic`,
    })
    propertyData.push({
      label: 'Angle',
      value: 0,
      type: 'number',
      tag: 'rotation',
    })

    for (let i = 0; i < propertyData.length; i++) {
      propertyData[i].id = i
    }

    const gridData = stylesToGridData(data)

    for (let i = 0; i < propertyData.length; i++) {
      if (propertyData[i].tag === '') continue
      if (propertyData[i].tag === 'italic') {
        propertyData[i].value = data.font.style ? data.font.style === 'italic' : false
        continue
      }
      if (propertyData[i].tag === 'backgroundColor') {
        propertyData[i].value = extractRGB(data.backgroundColor)
        continue
      }
      if (propertyData[i].tag === 'tetherColor') {
        propertyData[i].value = data.callout.borderColor ? data.callout.borderColor : '#333'
        continue
      }
      if (propertyData[i].tag === 'backgroundColorOpacity') {
        let opacity = extractOpacity(data.backgroundColor)
        propertyData[i].value = opacity
        continue
      }
      if (propertyData[i].tag === 'hasBackground') {
        propertyData[i].value = data.hasBackground === true ? true : false
        continue
      }
      propertyData[i].value = gridData[propertyData[i].tag]
    }
    return propertyData
  }

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowData={getPropertyData(labelProps)}
        headerHeight={0}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
})

export default LabelPropertiesGrid
