import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import LabelPropertiesGrid from 'components/WellPages/WallplotComposer/Elements/LabelPropertyModal/LabelPropertiesGrid'

const addAlphaToColor = (color, opacity) => {
  // Ensure opacity is within the range
  opacity = Math.min(1, Math.max(0, opacity))

  if (color.startsWith('#')) {
    // Convert hex to RGB
    let r, g, b
    if (color.length === 4) {
      // #rgb
      r = parseInt(color[1] + color[1], 16)
      g = parseInt(color[2] + color[2], 16)
      b = parseInt(color[3] + color[3], 16)
    } else if (color.length === 7) {
      // #rrggbb
      r = parseInt(color.substring(1, 3), 16)
      g = parseInt(color.substring(3, 5), 16)
      b = parseInt(color.substring(5, 7), 16)
    } else {
      return color // Return the input if it's not a valid hex format
    }
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  } else if (color.startsWith('rgba') || color.startsWith('rgb')) {
    // Extract the existing RGB(A) components, append/replace alpha
    const regex = color.startsWith('rgba')
      ? /rgba\((\d+),\s*(\d+),\s*(\d+),\s*[\d.]+\)/
      : /rgb\((\d+),\s*(\d+),\s*(\d+)\)/
    const match = color.match(regex)
    if (match) {
      return `rgba(${match[1]}, ${match[2]}, ${match[3]}, ${opacity})`
    }
  }
  return color
}

const LabelPropertyModal = ({ onClose, onApply, element }) => {
  const gridRef = React.useRef(null)
  const getGridData = () => {
    // get the data from the current active grid
    let gridData = []
    if (gridRef.current) gridData = gridRef.current.getData()

    let labelData = { ...element }
    labelData.content = gridData.find((data) => data.tag === 'content')?.value
    labelData.color = gridData.find((data) => data.tag === 'color')?.value
    labelData.backgroundColor = gridData.find((data) => data.tag === 'backgroundColor')?.value
    const opacity = gridData.find((data) => data.tag === 'backgroundColorOpacity')?.value
    if (opacity) {
      labelData.backgroundColor = addAlphaToColor(labelData.backgroundColor, opacity)
    }
    labelData.hasBackground = gridData.find((data) => data.tag === 'hasBackground')?.value
    labelData.borderColor = gridData.find((data) => data.tag === 'borderColor')?.value
    labelData.hasTether = gridData.find((data) => data.tag === 'hasTether')?.value
    labelData.size = gridData.find((data) => data.tag === 'size')?.value
    labelData.bold = gridData.find((data) => data.tag === 'bold')?.value
    labelData.italic = gridData.find((data) => data.tag === 'italic')?.value
    labelData.rotation = gridData.find((data) => data.tag === 'rotation')?.value
    labelData.callout.borderColor = gridData.find((data) => data.tag === 'tetherColor')?.value

    return labelData
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      sx={{ zIndex: 9999 }}
      PaperProps={{
        sx: {
          minWidth: '500px',
          maxWidth: '500px',
          width: '80vw',
          minHeight: '600px',
          height: '50vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>Label Properties</DialogTitle>
      <DialogContent
        sx={{
          padding: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <LabelPropertiesGrid labelProps={element} ref={gridRef} />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose} color='secondary'>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          onClick={() => {
            // ensure color picker change is applied
            gridRef.current.stopEditing()
            onApply(getGridData())
          }}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LabelPropertyModal
