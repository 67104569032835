import React from 'react'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import AddIcon from '@mui/icons-material/Add'
import { Icon as Iconify } from '@iconify/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import EditIcon from '@mui/icons-material/Edit'
import { userUserRoleAtom } from 'atoms'
import { checkPermission } from '../userPermissions'
import { useRecoilValue } from 'recoil'

export default function MenuButton({
  actions = [
    { icon: <AddIcon />, name: 'Add', onClick: () => {} },
    { icon: <RefreshIcon />, name: 'Refresh', onClick: () => {} },
    {
      icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} />,
      name: 'Export to XLSX',
      onClick: () => {},
    },
  ],
  direction = 'up',
  icon = <EditIcon style={{ color: '#ffffff' }}/>,
}) {
  const [open, setOpen] = React.useState(false)
  const userRole = useRecoilValue(userUserRoleAtom)

  return (
    <SpeedDial
      hidden={false}
      direction={direction}
      ariaLabel='open menu'
      icon={icon}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}>
      {actions.map((action) => {
        if (!open) return null
        if (!checkPermission('canCreate', userRole.roleAttributes?.permissions) && action.name === 'Add') return null

        return (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                  transition: '.5s',
                  '&:hover': {
                    dropShadow: ' 5px 5px 10px 10px grey',
                    color: 'white',
                  },
                },
              },
            }}
            onClick={() => action.onClick && action.onClick()}
          />
        )
      })}
    </SpeedDial>
  )
}
