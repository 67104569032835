import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { LABEL_CATEGORIES as labelCategories } from '../ChartElements/labelDefinitions'
import ColorPickerCellEditor from 'components/common/CellEditors/ColorPickerCellEditor'
import { getStringId } from 'components/common/AgGridUtils'
import { CHART_TYPES as chartTypes } from 'components/WellPages/WallplotComposer/Elements/elementDefs'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { Box } from '@mui/material'
import ChartDetailsPropertyGrid from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ChartDetailsPropertyGrid'

const getDepthLabelDefault = () => {
  return {
    mdTvd: 'MD',
    inc: false,
    azi: false,
    interval: 500,
    atSurvey: false,
    range: false,
    startDepth: 0,
    endDepth: 0,
  }
}

const getCasingLabelDefaultOptions = () => {
  return {
    useDot: false,
  }
}

const CustomHeader = (props) => {
  return (
    <Box
      style={{
        padding: '2px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Box style={{ flex: 1 }}>{props.displayName}</Box>
      <Box style={{ flex: 1 }}>{props.line2}</Box>
    </Box>
  )
}

const ChartLabelsGrid = forwardRef(({ chartData, chartType }, ref) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const { getUnitsText } = useUnits()

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApi.current) gridApi.current.stopEditing()
    },
    getData: () => {
      let categoryData = []
      let categoryOptions = []

      const rows = gridApi.current?.getSelectedRows()
      for (let i = rows.length - 1; i >= 0; i--) {
        categoryData.push({
          category: rows[i].category,
          enabled: true,
          defaultColor: rows[i].defaultColor,
          defaultColorEnabled: rows[i].defaultColorEnabled,
          color: rows[i].color,
          size: rows[i].size,
          bold: rows[i].bold,
          hasTether: rows[i].hasTether,
          angled: rows[i].angled,
          backgroundEnabled: rows[i].backgroundEnabled,
          background: rows[i].background,
        })
        if (
          rows[i].category === labelCategories.surveyPts ||
          rows[i].category === labelCategories.offsetSurveyPts ||
          rows[i].category === labelCategories.casing
        ) {
          categoryOptions.push({
            category: rows[i].category,
            options: rows[i].options,
          })
        }
      }
      let data = {
        labels: {
          categories: categoryData,
          categoryOptions: categoryOptions,
          labelData: chartData.labels.labelData.filter((label) => label.catType === labelCategories.userDefined),
        },
      }
      let idx = data.labels.categories.findIndex((cat) => cat.category === labelCategories.targets)
      let props = []
      if (idx >= 0) {
        props.push({ tag: 'showTargetNames', value: true })
      }
      if (idx < 0) {
        props.push({ tag: 'showTargetNames', value: false })
      }
      idx = data.labels.categories.findIndex((cat) => cat.category === labelCategories.leaseLines)
      if (idx >= 0) {
        props.push({ tag: 'showLeaseLineNames', value: true })
      }
      if (idx < 0) {
        props.push({ tag: 'showLeaseLineNames', value: false })
      }
      return { ...data, props: props }
    },
  }))

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    }
  }, [])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
    gridApi.current.setGridOption('rowData', getRowData())
    // // set rows as selected if enabled
    gridApi.current.forEachNode((node) => {
      node.setSelected(node.data.enabled)
    })
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
    popupParent: document.querySelector('chartLabelDiv'),
    onCellEditingStopped: (params) => {
      if (
        (params.node.data?.category === labelCategories.surveyPts ||
          params.node.data?.category === labelCategories.offsetSurveyPts ||
          params.node.data?.category === labelCategories.casing) &&
        params.colDef?.colId === 'options'
      ) {
        params.api.applyTransaction({ update: [params.node.data] })
      }
    },
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        cellRendererSelector: (params) => {
          if (params.data?.category === labelCategories.surveyPts) return { component: 'agGroupCellRenderer' }
          if (params.data?.category === labelCategories.offsetSurveyPts) return { component: 'agGroupCellRenderer' }
          if (params.data?.category === labelCategories.casing) return { component: 'agGroupCellRenderer' }
          return null
        },
        width: 50,
        cellStyle: centerAlignCell,
        cellClass: 'grid-detail-icon',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        filter: null,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: 'Category',
        headerCheckboxSelection: false,
        checkboxSelection: true,
        field: 'category',
        colId: 'category',
        minWidth: 100,
        width: 200,
        maxWidth: 250,
        editable: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: 'Default',
        field: 'defaultColorEnabled',
        colId: 'defaultColorEnabled',
        minWidth: 100,
        width: 100,
        maxWidth: 100,
        flex: 1,
        editable: (params) => {
          if (params.data?.category === labelCategories.userDefined) return false
          return true
        },
        cellStyle: centerAlignCell,
        headerComponentParams: { line2: 'Color' },
        cellEditorSelector: (params) => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
        valueSetter: (params) => {
          if (params.newValue === true) {
            params.data.color = ''
          }
          params.data.defaultColorEnabled = params.newValue
          return true
        },
      },
      {
        headerName: 'Color',
        field: 'color',
        colId: 'color',
        minWidth: 80,
        width: 80,
        maxWidth: 80,
        flex: 1,
        editable: (params) => {
          if (params.data?.category === labelCategories.userDefined) return false
          return params.data?.defaultColorEnabled === false
        },
        cellDataType: false,
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            backgroundColor: params.value,
            color: params.value,
          }
        },
        cellEditorSelector: (params) => {
          return {
            component: ColorPickerCellEditor,
            params: {
              color: params.value ? params.value : params.data?.defaultColor,
              defaultColor: params.data?.defaultColor,
            },
            popup: true,
          }
        },
      },
      {
        headerName: 'Size',
        field: 'size',
        colId: 'size',
        flex: 1,
        editable: (params) => {
          if (params.data?.category === labelCategories.userDefined) return false
          return true
        },
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'Bold',
        field: 'bold',
        colId: 'bold',
        flex: 1,
        editable: (params) => {
          if (params.data?.category === labelCategories.userDefined) return false
          return true
        },
        cellStyle: centerAlignCell,
        cellEditorSelector: (params) => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
      {
        headerName: 'Has',
        field: 'hasTether',
        colId: 'hasTether',
        flex: 1,
        editable: (params) => {
          if (params.data?.category === labelCategories.casing || params.data?.category === labelCategories.userDefined)
            return false
          return true
        },
        cellStyle: centerAlignCell,
        headerComponentParams: {
          line2: 'Tether',
        },
        cellEditorSelector: (params) => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
      {
        headerName: 'Angled',
        field: 'angled',
        colId: 'angled',
        flex: 1,
        editable: (params) => {
          if (
            params.data?.category === labelCategories.annotations ||
            params.data?.category === labelCategories.targets ||
            params.data?.category === labelCategories.lithologies ||
            params.data?.category === labelCategories.userDefined
          )
            return false
          return true
        },
        cellStyle: centerAlignCell,
        cellEditorSelector: (params) => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
      {
        headerName: 'Use',
        field: 'backgroundEnabled',
        colId: 'backgroundEnabled',
        minWidth: 80,
        width: 80,
        maxWidth: 80,
        flex: 1,
        editable: (params) => {
          if (params.data?.category === labelCategories.userDefined) return false
          return true
        },
        cellStyle: centerAlignCell,
        headerComponentParams: { line2: 'Background' },
        cellEditorSelector: (params) => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
        valueSetter: (params) => {
          if (!params.newValue) {
            params.data.background = ''
          }
          params.data.backgroundEnabled = params.newValue
          return true
        },
      },
      {
        headerName: 'Background',
        field: 'background',
        colId: 'background',
        minWidth: 80,
        width: 80,
        maxWidth: 80,
        flex: 1,
        headerComponentParams: { line2: 'Color' },
        editable: (params) => {
          if (params.data?.category === labelCategories.userDefined) return false
          return params.data?.backgroundEnabled
        },
        cellDataType: false,
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            backgroundColor: params.value,
            color: params.value,
          }
        },
        cellEditorSelector: (params) => {
          return {
            component: ColorPickerCellEditor,
            params: {
              color: params.value ? params.value : '#fff',
              defaultColor: '#fff',
            },
            popup: true,
          }
        },
      },
    ],
    [centerAlignCell],
  )

  const getRowData = (data) => {
    let rowData = []
    // if (!data) return rowData // enable when data is attached to layout template

    rowData.push({
      category: labelCategories.wellNames,
      defaultColorEnabled: true,
      color: '',
      defaultColor: '#a961fa',
      size: 12,
      bold: true,
      hasTether: true,
      angled: true,
      backgroundEnabled: false,
      background: '',
    })
    rowData.push({
      category: labelCategories.surveyPts,
      defaultColorEnabled: true,
      color: '',
      defaultColor: '#a961fa',
      size: 12,
      bold: false,
      hasTether: true,
      options: getDepthLabelDefault(),
      angled: true,
      backgroundEnabled: false,
      background: '',
    })
    rowData.push({
      category: labelCategories.casing,
      defaultColorEnabled: true,
      color: '',
      defaultColor: '#a961fa',
      size: 10,
      bold: false,
      hasTether: false,
      options: getCasingLabelDefaultOptions(),
      angled: true,
      backgroundEnabled: false,
      background: '',
    })
    rowData.push({
      category: labelCategories.annotations,
      defaultColorEnabled: true,
      defaultColor: '#a961fa',
      color: '',
      size: 9,
      bold: false,
      hasTether: true,
      angled: false,
      backgroundEnabled: false,
      background: '',
    })
    rowData.push({
      category: labelCategories.userDefined,
      defaultColorEnabled: true,
      defaultColor: '#a961fa',
      color: '',
      size: 9,
      bold: false,
      hasTether: true,
      angled: false,
      backgroundEnabled: false,
      background: '',
    })
    if (chartData.showTargets) {
      rowData.push({
        category: labelCategories.targets,
        defaultColorEnabled: true,
        color: '',
        defaultColor: '#a961fa',
        size: 10,
        bold: true,
        hasTether: true,
        angled: false,
        backgroundEnabled: false,
        background: '',
      })
      rowData.push({
        category: labelCategories.leaseLines,
        defaultColorEnabled: true,
        color: '',
        defaultColor: '#a961fa',
        size: 10,
        bold: true,
        hasTether: true,
        angled: false,
        backgroundEnabled: false,
        background: '',
      })
    }
    if (chartType === chartTypes.sectionView) {
      rowData.push({
        category: labelCategories.lithologies,
        defaultColorEnabled: true,
        color: '',
        defaultColor: '#a961fa',
        size: 12,
        bold: true,
        hasTether: true,
        angled: false,
        backgroundEnabled: false,
        background: '',
      })
    }
    if (chartData.showOffsets) {
      rowData.push({
        category: labelCategories.offsetWellNames,
        defaultColorEnabled: true,
        color: '',
        defaultColor: '#a961fa',
        size: 9,
        bold: true,
        hasTether: true,
        angled: true,
        backgroundEnabled: false,
        background: '',
      })
      rowData.push({
        category: labelCategories.offsetSurveyPts,
        defaultColorEnabled: true,
        color: '',
        defaultColor: '#a961fa',
        size: 9,
        bold: true,
        hasTether: true,
        options: getDepthLabelDefault(),
        angled: true,
        backgroundEnabled: false,
        background: '',
      })
    }

    rowData.forEach((row, index) => {
      row.enabled = false
      const idx = chartData.labels.categories.findIndex((cat) => cat.category === row.category)
      if (idx >= 0) {
        row.enabled = chartData.labels.categories[idx].enabled
        row.defaultColorEnabled = chartData.labels.categories[idx].defaultColorEnabled
        row.defaultColor = chartData.labels.categories[idx].defaultColor
        row.color = chartData.labels.categories[idx].color
        if (typeof row.defaultColorEnabled === 'boolean' && row.defaultColorEnabled && row.defaultColor !== '') {
          row.color = ''
        }
        row.size = chartData.labels.categories[idx].size
        row.bold = chartData.labels.categories[idx].bold
        row.hasTether = chartData.labels.categories[idx].hasTether
        row.angled = chartData.labels.categories[idx].angled
        row.backgroundEnabled = chartData.labels.categories[idx].backgroundEnabled
        row.background = chartData.labels.categories[idx].background
        if (row.category === labelCategories.surveyPts) {
          row.options = chartData.labels.categoryOptions.find(
            (opt) => opt.category === labelCategories.surveyPts,
          )?.options
        }
        if (row.category === labelCategories.offsetSurveyPts) {
          row.options = chartData.labels.categoryOptions.find(
            (opt) => opt.category === labelCategories.offsetSurveyPts,
          )?.options
        }
        if (row.category === labelCategories.casing) {
          row.options = chartData.labels.categoryOptions.find((opt) => opt.category === labelCategories.casing)?.options
        }
      }
      row.id = index
    })

    return rowData
  }

  const detailUpdateFunc = (id, params) => {
    const gridData = []
    gridApi.current.forEachNode((node) => {
      if (node.data) gridData.push(node.data)
    })

    const index = gridData.findIndex((data) => data.id === id)
    if (index < 0) return

    gridData[index].options[params.tag] = params.value

    gridApi.current.applyTransaction({ update: gridData })
  }

  const getDepthLabelDetailRowData = (params) => {
    let detailRowData = []
    let options = params.data.options ? params.data.options : getDepthLabelDefault()
    detailRowData.push({
      label: 'MD/TVD',
      value: options.mdTvd,
      type: 'dropDown',
      tag: 'mdTvd',
      dropDownValues: ['MD', 'TVD'],
    })
    detailRowData.push({
      label: 'Inc',
      value: options.inc,
      type: 'bool',
      tag: 'inc',
    })
    detailRowData.push({
      label: 'Azi',
      value: options.azi,
      type: 'bool',
      tag: 'azi',
    })
    detailRowData.push({
      label: 'At Survey',
      value: options.atSurvey,
      type: 'bool',
      tag: 'atSurvey',
    })
    detailRowData.push({
      label: 'Interval',
      value: options.interval,
      type: 'number',
      tag: 'interval',
      min: 1,
      max: 1000,
      precision: 0,
    })
    detailRowData.push({
      label: 'Range',
      value: options.range,
      type: 'bool',
      tag: 'range',
    })
    detailRowData.push({
      label: 'Start Depth',
      value: options.startDepth,
      type: 'number',
      tag: 'startDepth',
      min: 1,
      max: 1000,
      precision: 0,
    })
    detailRowData.push({
      label: 'End Depth',
      value: options.endDepth,
      type: 'number',
      tag: 'endDepth',
      min: 1,
      max: 99999,
      precision: 0,
    })

    detailRowData.forEach((row, index) => {
      row.id = index
    })

    return detailRowData
  }

  const getCasingLabelDetailRowData = (params) => {
    let detailRowData = []

    let options = params.data.options ? params.data.options : getCasingLabelDefaultOptions()

    detailRowData.push({
      label: 'Use Dot as Symbol',
      value: options.useDot,
      type: 'bool',
      tag: 'useDot',
    })

    detailRowData.forEach((row, index) => {
      row.id = index
    })

    return detailRowData
  }

  const DetailCellRenderer = (params) => {
    if (!params.data) return null

    let detailRowData = []
    switch (params.data.category) {
      case labelCategories.surveyPts:
      case labelCategories.offsetSurveyPts:
        detailRowData = getDepthLabelDetailRowData(params)
        break
      case labelCategories.casing:
        detailRowData = getCasingLabelDetailRowData(params)
        break
      default:
        return null
    }

    return (
      <Box sx={{ height: `${32 + 45 * detailRowData.length}px` }}>
        <ChartDetailsPropertyGrid
          id={params.data.id}
          data={detailRowData}
          handleUpdateFunc={detailUpdateFunc}
          units={getUnitsText(UNITS_FOR.Depth)}
        />
      </Box>
    )
  }

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()} id='chartLabelDiv'>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        headerHeight={40}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
        rowSelection='multiple'
        masterDetail={true}
        detailCellRenderer={DetailCellRenderer}
        detailRowAutoHeight={true}
        components={components} // multiline col header
      />
    </div>
  )
})

export default ChartLabelsGrid
