import { memo, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Box,
} from '@mui/material'
import ConfirmDialog from 'components/common/ConfirmDialog'
import EngineeringReportGrid from './EngineeringReportGrid'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'
import { TemplateBoxComponent } from 'components/common/TemplateBox'

const EngineeringReportSettingsModal = ({ onClose, reportData: initialReportData, selectedReport }) => {
  const [reportData, setReportData] = useState(initialReportData.template)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [templates, setTemplates] = useState(
    () => getItemFromLS('engineeringReportTemplates', 'engineeringReportTemplates') || {},
  )
  const [newTemplateName, setNewTemplateName] = useState('')
  const [selectedTemplateName, setSelectedTemplateName] = useState(initialReportData.templateName)

  const openDeleteModal = () => {
    setShowDeleteModal(true)
  }
  
  const closeDeleteModal = () => {
    setShowDeleteModal(false)
  }

  const handleConfirmDelete = async () => {
    deleteTemplate()
    closeDeleteModal()
  }

  const createNewTemplate = () => {
    if (!newTemplateName.trim()) return
    if (newTemplateName === 'Default') return

    saveTemplate(newTemplateName)
    setSelectedTemplateName(newTemplateName)
    setNewTemplateName('')
  }

  const saveTemplate = (templateName) => {
    if (!templateName && selectedTemplateName === 'Default') return
    const updatedTemplates = {
      ...templates,
      [templateName ? templateName : selectedTemplateName]: reportData,
    }

    setTemplates(updatedTemplates)
    saveItemToLS('engineeringReportTemplates', 'engineeringReportTemplates', updatedTemplates)
  }

  const handleSelectTemplate = (templateName, template = templates[templateName]) => {
    setSelectedTemplateName(templateName)

    if (template) {
      setReportData(JSON.parse(JSON.stringify(template)))
    }
  }

  const handleSaveAndClose = () => {
    saveTemplate()
    saveItemToLS('engineeringReportDetails', 'engineeringReportDetails', {
      'templateName': selectedTemplateName,
      'template': reportData,
    })
    onClose(false)
  }

  const deleteTemplate = (templateName) => {
    if (!templateName) templateName = selectedTemplateName
    if (templateName === 'Default') return
    delete templates[templateName]

    setTemplates(templates)
    handleSelectTemplate('Default')
    saveItemToLS('engineeringReportTemplates', 'engineeringReportTemplates', templates)
  }

  const handleImportTemplate = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.json'
    input.onchange = (e) => {
      const file = e.target.files[0]
      if (!file) return

      const reader = new FileReader()
      reader.onload = (e) => {
        const content = e.target.result
        const template = JSON.parse(content)
        setTemplates({ ...templates, [file.name.replace('.json', '')]: template })
        saveItemToLS('engineeringReportTemplates', 'engineeringReportTemplates', {
          ...templates,
          [file.name.replace('.json', '')]: template,
        })

        handleSelectTemplate(file.name.replace('.json', ''), template)
      }

      reader.readAsText(file)
    }
    input.click()
  }

  const handleExportTemplate = () => {
    const template = templates[selectedTemplateName]
    const blob = new Blob([JSON.stringify(template)], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `${selectedTemplateName}.json`
    a.click()
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth='md'
      PaperProps={{
        sx: {
          width: '90vw',
          height: '80vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            width: '100%',
          }}>
          PDF Print Settings
          <TemplateBoxComponent
            templates={templates}
            newTemplateName={newTemplateName}
            setNewTemplateName={setNewTemplateName}
            selectedTemplateName={selectedTemplateName}
            handleSelectTemplate={handleSelectTemplate}
            createNewTemplate={createNewTemplate}
            saveTemplate={saveTemplate}
            deleteTemplate={openDeleteModal}
            handleExportTemplate={handleExportTemplate}
            handleImportTemplate={handleImportTemplate}
          />
        </Box>
      </DialogTitle>

      <DialogContent>
        <EngineeringReportGrid engineeringDashboardData={reportData} selectedReport={selectedReport} />
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' onClick={() => onClose(false)} color='secondary' sx={{ height: '36px' }}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleSaveAndClose} color='primary' sx={{ height: '36px' }}>
          Save
        </Button>
      </DialogActions>
      <ConfirmDialog
        open={showDeleteModal}
        setOpen={closeDeleteModal}
        title='Delete Template'
        onConfirm={handleConfirmDelete}
        children='Are you sure you want to delete this template?'/>
    </Dialog>
  )
}

export default memo(EngineeringReportSettingsModal)
