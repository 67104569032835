import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Box } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import AutoSizer from 'react-virtualized-auto-sizer'
import Canvas from 'components/common/Canvas'
import { getMagnetics } from 'components/WellPages/WallplotComposer/Elements/TableDefs'
import ElementPropertyModal from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ElementPropertyModal'
import { ELEMENT_TYPES as elementType } from 'components/WellPages/WallplotComposer/Elements/elementDefs'

const deg2Rad = (deg) => (deg * Math.PI) / 180

export const getNorthRefElementDefaults = ({ pageLayout, zIndex }) => {
  return {
    uid: uuidv4(),
    type: elementType.northReference,
    left: pageLayout.marginLeft,
    top: pageLayout.marginTop,
    width: 2,
    height: 4,
    zIndex: zIndex,
    style: {
      color: '#333',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#333',
      backgroundColor: '#fff',
      fontFamily: 'Arial',
      fontSize: '10px',
      fontWeight: '400',
      fontStyle: 'normal',
      opacity: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
}

const NorthRefControl = ({ h, w, magData }) => {
  // The draw function to render the compass-like dial
  const drawLineWithArrow = ({ ctx, startX, startY, endX, endY }) => {
    ctx.beginPath()
    ctx.moveTo(startX, startY)
    ctx.lineTo(endX, endY)
    ctx.stroke()

    // Draw arrowhead
    const arrowLength = 15 // Length of the arrowhead lines
    const angle = Math.atan2(endY - startY, endX - startX) // line angle

    // Draw the right part of the arrowhead
    ctx.beginPath()
    ctx.moveTo(endX, endY)
    ctx.lineTo(endX - arrowLength * Math.cos(angle - Math.PI / 6), endY - arrowLength * Math.sin(angle - Math.PI / 6))
    ctx.stroke()

    // Draw the left part of the arrowhead
    ctx.beginPath()
    ctx.moveTo(endX, endY)
    ctx.lineTo(endX - arrowLength * Math.cos(angle + Math.PI / 6), endY - arrowLength * Math.sin(angle + Math.PI / 6))
    ctx.stroke()
  }

  const drawLineWithSolidArrow = ({ ctx, startX, startY, endX, endY }) => {
    ctx.beginPath()
    ctx.moveTo(startX, startY)
    ctx.lineTo(endX, endY)
    ctx.stroke()

    const arrowLength = 15
    const angle = Math.atan2(endY - startY, endX - startX)

    // Draw the right part of the arrowhead
    ctx.fillStyle = 'rgba(0, 0, 0, 1)'
    ctx.beginPath()
    ctx.moveTo(endX, endY)
    ctx.lineTo(endX - arrowLength * Math.cos(angle - Math.PI / 6), endY - arrowLength * Math.sin(angle - Math.PI / 6))
    // Draw the left part of the arrowhead
    ctx.lineTo(endX - arrowLength * Math.cos(angle + Math.PI / 6), endY - arrowLength * Math.sin(angle + Math.PI / 6))
    ctx.closePath()
    ctx.fill()
  }

  const draw = (ctx) => {
    if (!magData) return
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height) // Clear the canvas
    ctx.fillStyle = 'rgba(255,255,255,1)' // Set the background color
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height) // Fill the background

    const centerX = ctx.canvas.width / 2
    const centerY = (ctx.canvas.height * 3) / 4
    const radius = Math.min(centerX, centerY) * 0.4 // Set the radius for our compass

    ctx.beginPath()
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI) // Draw the outer circle
    ctx.strokeStyle = 'rgba(0, 0, 0, 1)' // Set the color of the circle
    ctx.lineWidth = 2 // Set the line width
    ctx.stroke()

    // Draw the vertical line
    ctx.beginPath()
    ctx.moveTo(centerX, centerY - radius * 1.5)
    ctx.lineTo(centerX, centerY + radius * 1.5)
    ctx.stroke()

    // Draw the horizontal line
    ctx.beginPath()
    ctx.moveTo(centerX - radius * 1.5, centerY)
    ctx.lineTo(centerX + radius * 1.5, centerY)
    ctx.stroke()

    // calc mag and grid angles
    let magAngle = 0
    let gridAngle = 0
    let captionText = 'GRID'
    if (Math.abs(magData.Dec > 0)) {
      if (magData.Dec > 0) {
        magAngle = Math.abs(magData.Dec) > Math.abs(magData.gridConv) ? 40 : 20
      } else {
        magAngle = Math.abs(magData.Dec) > Math.abs(magData.gridConv) ? -40 : -20
      }
    }
    if (Math.abs(magData.gridConv > 0)) {
      if (magData.gridConv > 0) {
        gridAngle = Math.abs(magData.Dec) > Math.abs(magData.gridConv) ? 20 : 40
      } else {
        gridAngle = Math.abs(magData.Dec) > Math.abs(magData.gridConv) ? -20 : -40
      }
    }
    if (magData.northRef !== 'TRUE') {
      magAngle = 0
      if (magData.Dec !== 0) {
        if (magData.Dec > 0) {
          magAngle = Math.abs(magData.Dec) > Math.abs(magData.gridConv) ? 40 : 20
        } else {
          magAngle = Math.abs(magData.Dec) > Math.abs(magData.gridConv) ? -40 : -20
        }
      }

      gridAngle = 0
      if (magData.gridConv !== 0) {
        if (magData.gridConv > 0) {
          gridAngle = Math.abs(magData.Dec) > Math.abs(magData.gridConv) ? 20 : 40
        } else {
          gridAngle = Math.abs(magData.Dec) > Math.abs(magData.gridConv) ? -20 : -40
        }
      }
      captionText = 'TRUE'
      magAngle = magAngle - gridAngle
      gridAngle = -gridAngle
    }

    // ref north arrow
    let textMetrics = ctx.measureText(magData.northRef)
    ctx.beginPath()
    drawLineWithSolidArrow({ ctx, startX: centerX, startY: centerY, endX: centerX, endY: centerY - radius * 4 })
    ctx.fillStyle = 'black'
    ctx.fillText(magData.northRef, centerX - textMetrics.width / 2, centerY - radius * 4.1)

    // magnetic north arrow
    ctx.beginPath()
    drawLineWithArrow({
      ctx,
      startX: centerX,
      startY: centerY,
      endX: centerX + radius * 3 * Math.sin(deg2Rad(magAngle)),
      endY: centerY - radius * 3 * Math.cos(deg2Rad(magAngle)),
    })
    textMetrics = ctx.measureText('MAG')
    ctx.fillText(
      'MAG',
      centerX + radius * 3.1 * Math.sin(deg2Rad(magAngle)) - textMetrics.width / 2,
      centerY - radius * 3.1 * Math.cos(deg2Rad(magAngle)),
    )

    // TRUE or GRID north arrow
    ctx.beginPath()
    drawLineWithArrow({
      ctx,
      startX: centerX,
      startY: centerY,
      endX: centerX + radius * 2 * Math.sin(deg2Rad(gridAngle)),
      endY: centerY - radius * 2 * Math.cos(deg2Rad(gridAngle)),
    })
    textMetrics = ctx.measureText(captionText)
    ctx.fillText(
      captionText,
      centerX + radius * 2.1 * Math.sin(deg2Rad(gridAngle)) - textMetrics.width / 2,
      centerY - radius * 2.1 * Math.cos(deg2Rad(gridAngle)),
    )
  }

  return <Canvas square={false} draw={draw} width={w} height={h} style={{ width: w, height: h }} />
}

const DrawRow = ({ label, value }) => {
  return (
    <Box sx={{ className: 'colContainer', display: 'flex', flexDirection: 'row', paddingTop: '4px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          width: '65%',
          paddingLeft: '4px',
          fontWeight: 700,
          paddingBottom: '0px',
        }}>
        {label}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          width: '35%',
          paddingLeft: '4px',
          fontWeight: 400,
          paddingBottom: '0px',
        }}>
        {value}
      </Box>
    </Box>
  )
}

const NorthReferenceElement = forwardRef(({ id, wellData, element, actions, scale }, ref) => {
  const [magnetics, setMagnetics] = useState(null)
  const [showProperties, setShowProperties] = useState(false)
  const [currentStyle, setCurrentStyle] = useState(element.style)

  useEffect(() => {
    setMagnetics({
      ...getMagnetics(wellData),
      northRef: wellData.actualWellData?.northRef,
      gridConv: wellData.actualWellData?.gridConv,
    })
  }, [wellData])

  useImperativeHandle(ref, () => {
    return {
      showProperties() {
        setShowProperties(true)
      },
    }
  })

  const handleChange = (newData) => {
    actions.update(id, 'update', { style: { ...newData.style } })
    setCurrentStyle({ ...newData.style })
    setShowProperties(false)
  }

  const getNorthRefTableData = (magData) => {
    if (!magData) return []
    let northRefData = []
    northRefData.push({
      label: 'North Reference',
      value: magData.northRef,
    })
    northRefData.push({
      label: 'Magnetic Declination',
      value: magData.Dec?.toFixed(3),
    })
    northRefData.push({
      label: 'Grid Convergence',
      value: magData.gridConv.toFixed(3),
    })
    northRefData.push({
      label: 'Dip Angle',
      value: magData.Dip.toFixed(3),
    })
    northRefData.push({
      label: 'Magnetic Model',
      value: magData.Model,
    })
    northRefData.push({
      label: 'Total Field (nT)',
      value: magData.TotalField.toFixed(2),
    })
    northRefData.push({
      label: 'Date',
      value: magData.UKDate,
    })

    if (magData.northRef === 'TRUE') {
      northRefData.push({
        label: 'MAG to TRUE:',
        value: `Add ${magData.Dec.toFixed(2)}`,
      })
      northRefData.push({
        label: 'GRID to TRUE:',
        value: `Add ${magData.gridConv.toFixed(2)}`,
      })
    } else {
      northRefData.push({
        label: 'MAG to GRID:',
        value: `Add ${(magData.Dec - magData.gridConv).toFixed(2)}`,
      })
      northRefData.push({
        label: 'TRUE to GRID:',
        value: `Add ${(-magData.gridConv).toFixed(2)}`,
      })
    }
    return northRefData
  }

  return (
    <React.Fragment>
      {showProperties && (
        <ElementPropertyModal
          onClose={() => setShowProperties(false)}
          elementStyle={currentStyle}
          elementData={null}
          onApply={handleChange}
          elemType={elementType.northReference}
        />
      )}
      <div
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          position: 'absolute',
        }}
        onDoubleClick={(e) => {
          e.stopPropagation()
          setShowProperties(true)
        }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            color: currentStyle.color,
            fontFamily: currentStyle.fontFamily,
            fontSize: scale && !isNaN(scale) ? `${parseFloat(currentStyle.fontSize) * scale}px` : currentStyle.fontSize,
            fontWeight: currentStyle.fontWeight,
            fontStyle: currentStyle.fontStyle,
            padding: '0 8px',
            opacity: 1,
          }}>
          <Box sx={{ width: '100%', height: '60%', margin: 'auto' }}>
            <AutoSizer>
              {({ height, width }) => {
                return <NorthRefControl h={height} w={width} magData={magnetics} />
              }}
            </AutoSizer>
          </Box>
          <Box sx={{ width: '100%', height: '40%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                justifyContent: 'flex-end',
                paddingBottom: '8px',
              }}>
              {getNorthRefTableData(magnetics).map((data, index) => (
                <DrawRow key={index} label={data.label} value={data.value} />
              ))}
            </Box>
          </Box>
        </Box>
      </div>
    </React.Fragment>
  )
})

export default NorthReferenceElement
