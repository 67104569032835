import { decToSexa } from 'utils/mapFunctions'
import { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommasDecimals } from 'utils/stringFunctions'

export const  getWellDataValue = (data, tags, formatter, unitsText) => {
  let currentObject = data

  for (let tag of tags) {
    // Check if the current level of the object has the property defined by the current tag
    if (currentObject && currentObject.hasOwnProperty(tag)) {
      // Move deeper into the object
      currentObject = currentObject[tag]
    } else {
      // If any tag in the chain doesn't exist, return an empty value
      return ''
    }
  }

  // after iterating through all tags, currentObject will be the value of the final property
  if (formatter) return formatter(currentObject, unitsText)
  return currentObject !== undefined ? currentObject : ''
}

const getCrsUnits = (units) => {
  if (units === 'US survey foot') return 'Usft'
  return units
}

const latLongValueFormatter = (value, coordType, unitsText) => {
  if (unitsText === 'dms') {
    return decToSexa(value, coordType)
  }

  return numberWithCommasDecimals(value, 6)
}

export const getMagnetics = (data) => {
  if (!data || !data.actualWellData || !data.actualWellData.magnetics || !data.actualWellData.magnetics.magneticsGrid)
    return null

  for (let i = 0; i < data.actualWellData.magnetics.magneticsGrid.length; i++) {
    if (data.actualWellData.magnetics.magneticsGrid[i].IsActive) {
      return data.actualWellData.magnetics.magneticsGrid[i]
    }
  }
  if (data.actualWellData.magnetics.magneticsGrid.length > 0) {
    return data.actualWellData.magnetics.magneticsGrid[0]
  }

  return null
}

export const projectDetailsTableDefn = {
  head: {
    lines: [
      {
        label: 'Project Details',
        style: 'header',
      },
    ],
  },
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Operator',
            labelStyle: 'header',
            tag: ['actualWellData', 'operator'],
          },
          {
            label: 'Field',
            labelStyle: 'header',
            tag: ['actualWellData', 'field'],
          },
          {
            label: 'Facility',
            labelStyle: 'header',
            tag: ['actualWellData', 'facility'],
          },
          {
            label: 'Well',
            labelStyle: 'header',
            tag: ['actualWellData', 'wellName'],
          },
          {
            label: 'Wellbore',
            labelStyle: 'header',
            tag: ['wellName'],
          },
        ],
      },
    ],
  },
}

export const referencesTableDefn = {
  head: {
    lines: [
      {
        label: 'Project References',
        style: 'header',
      },
    ],
  },
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Local co-ord ref',
            labelStyle: 'header',
            tag: ['actualWellData', 'localCoordRef'],
            valueFormatter: (value) => {
              return value === 'Well' ? 'Well Centered' : 'Facility Centered'
            },
          },
          {
            label: 'TVD Reference',
            labelStyle: 'header',
            tag: ['actualWellData', 'depthReference', 'Name'],
          },
          {
            label: 'North Reference',
            labelStyle: 'header',
            tag: ['actualWellData', 'northRef'],
          },
          {
            label: 'Survey Calc Method',
            labelStyle: 'header',
            tag: [],
            value: 'Minimum Curvature',
          },
        ],
      },
    ],
  },
}

export const fieldDetailsTableDefn = {
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Field',
            labelStyle: 'header',
            tag: ['actualWellData', 'field'],
            tagStyle: 'header',
          },
          {
            label: 'CRS',
            labelStyle: 'header',
            tag: ['actualWellData', 'crs'],
          },
          {
            label: 'Apply scale factor',
            labelStyle: 'header',
            tag: ['actualWellData', 'applySf'],
            valueFormatter: (value) => {
              return value === true ? 'YES' : 'NO'
            },
          },
          {
            label: 'Scale Factor',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return numberWithCommasDecimals(data?.actualWellData?.crsData.scaleFactor, 6)
            },
          },
          {
            label: 'System Datum',
            labelStyle: 'header',
            tag: ['actualWellData', 'systemDatumName'],
          },
          {
            label: 'Depth Datum->MSL',
            labelStyle: 'header',
            tag: ['actualWellData', 'systemElevation'],
            tagUnits: UNITS_FOR.Depth,
            valueFormatter: (value, unitsText) => {
              return numberWithCommasDecimals(value, 2) + ' ' + unitsText
            },
          },
          {
            label: 'Map Northing',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return (
                numberWithCommasDecimals(data?.actualWellData?.fieldCoords?.gridNorth, 2) +
                getCrsUnits(data?.actualWellData?.crsData.uom)
              )
            },
          },
          {
            label: 'Map Easting',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return (
                numberWithCommasDecimals(data?.actualWellData?.fieldCoords?.gridEast, 2) +
                getCrsUnits(data?.actualWellData?.crsData.uom)
              )
            },
          },
          {
            label: 'Latitude',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return latLongValueFormatter(data?.actualWellData?.fieldCoords?.latitude, 'lat', UNITS_FOR.LatLong)
            },
          },
          {
            label: 'Longitude',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return latLongValueFormatter(data?.actualWellData?.fieldCoords?.longitude, 'long', UNITS_FOR.LatLong)
            },
          },
          {
            label: 'Grid Convergence',
            labelStyle: 'header',
            tag: ['actualWellData', 'gridConv'],
          },
        ],
      },
    ],
  },
}

export const facilityDetailsTableDefn = {
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Facility',
            labelStyle: 'header',
            tag: ['actualWellData', 'facility'],
            tagStyle: 'header',
          },
          {
            label: 'Map Northing',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return (
                numberWithCommasDecimals(data?.actualWellData?.facilityCoords?.gridNorth, 2) +
                getCrsUnits(data?.actualWellData?.crsData.uom)
              )
            },
          },
          {
            label: 'Map Easting',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return (
                numberWithCommasDecimals(data?.actualWellData?.facilityCoords?.gridEast, 2) +
                getCrsUnits(data?.actualWellData?.crsData.uom)
              )
            },
          },
          {
            label: 'Latitude',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return latLongValueFormatter(data?.actualWellData?.facilityCoords?.latitude, 'lat', UNITS_FOR.LatLong)
            },
          },
          {
            label: 'Longitude',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return latLongValueFormatter(data?.actualWellData?.facilityCoords?.longitude, 'long', UNITS_FOR.LatLong)
            },
          },
          {
            label: 'Vertical Uncertainty',
            labelStyle: 'header',
            tagUnits: UNITS_FOR.Depth,
            dataExtractor: (data, unitsText) => {
              return numberWithCommasDecimals(data?.actualWellData?.facilityUncert?.vertUncert, 2) + ' ' + unitsText
            },
          },
          {
            label: 'Horizontal Uncertainty',
            labelStyle: 'header',
            tagUnits: UNITS_FOR.Depth,
            dataExtractor: (data, unitsText) => {
              return numberWithCommasDecimals(data?.actualWellData?.facilityUncert?.horzUncert, 2) + ' ' + unitsText
            },
          },
          {
            label: 'Grid Convergence',
            labelStyle: 'header',
            tag: ['actualWellData', 'gridConv'],
          },
        ],
      },
    ],
  },
}

export const wellDetailsTableDefn = {
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Well',
            labelStyle: 'header',
            tag: ['actualWellData', 'wellName'],
            tagStyle: 'header',
          },
          {
            label: 'Local North',
            labelStyle: 'header',
            tagUnits: UNITS_FOR.Depth,
            dataExtractor: (data, unitsText) => {
              return numberWithCommasDecimals(data?.actualWellData?.wellCoords?.localNorth, 2) + ' ' + unitsText
            },
          },
          {
            label: 'Local East',
            labelStyle: 'header',
            tagUnits: UNITS_FOR.Depth,
            dataExtractor: (data, unitsText) => {
              return numberWithCommasDecimals(data?.actualWellData?.wellCoords?.localEast, 2) + ' ' + unitsText
            },
          },
          {
            label: 'Map Northing',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return (
                numberWithCommasDecimals(data?.actualWellData?.wellCoords?.gridNorth, 2) +
                getCrsUnits(data?.actualWellData?.crsData.uom)
              )
            },
          },
          {
            label: 'Map Easting',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return (
                numberWithCommasDecimals(data?.actualWellData?.wellCoords?.gridEast, 2) +
                getCrsUnits(data?.actualWellData?.crsData.uom)
              )
            },
          },
          {
            label: 'Latitude',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return latLongValueFormatter(data?.actualWellData?.wellCoords?.latitude, 'lat', UNITS_FOR.LatLong)
            },
          },
          {
            label: 'Longitude',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return latLongValueFormatter(data?.actualWellData?.wellCoords?.longitude, 'long', UNITS_FOR.LatLong)
            },
          },
          {
            label: 'Depth Datum',
            labelStyle: 'header',
            tag: ['actualWellData', 'depthReference', 'Name'],
          },
          {
            label: 'Datum Elevation',
            labelStyle: 'header',
            tagUnits: UNITS_FOR.Depth,
            dataExtractor: (data, unitsText) => {
              return (
                numberWithCommasDecimals(data?.actualWellData?.depthReference.Elevation, 2) +
                ' ' +
                unitsText +
                ' above ' +
                data.actualWellData.systemDatumName
              )
            },
          },
          {
            dynamicLabel: (data) => {
              return data?.actualWellData?.depthReference?.offshoreCheck ? 'Water Depth' : 'GL Elevation'
            },
            labelStyle: 'header',
            tagUnits: UNITS_FOR.Depth,
            dataExtractor: (data, unitsText) => {
              return (
                numberWithCommasDecimals(data?.actualWellData?.depthReference?.waterDepthGl, 2) +
                ' ' +
                unitsText +
                (data?.actualWellData?.depthReference?.offshoreCheck
                  ? ' '
                  : ' above ' + data.actualWellData.systemDatumName)
              )
            },
          },
          {
            label: 'Grid Convergence',
            labelStyle: 'header',
            tag: ['actualWellData', 'gridConv'],
          },
        ],
      },
    ],
  },
}

export const wellboreDetailsTableDefn = {
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Well bore',
            labelStyle: 'header',
            tag: ['actualWellData', 'actualWellName'],
            tagStyle: 'header',
          },
          {
            label: 'Magnetic Model',
            labelStyle: 'header',
            dataExtractor: (data) => {
              let magData = getMagnetics(data)
              if (!magData || !magData.Model) return ''
              return magData.Model
            },
          },
          {
            label: 'Date',
            labelStyle: 'header',
            dataExtractor: (data) => {
              let magData = getMagnetics(data)
              if (!magData || !magData.TotalField) return ''
              return magData.UKDate
            },
          },
          {
            label: 'Total Field (nt)',
            labelStyle: 'header',
            dataExtractor: (data) => {
              let magData = getMagnetics(data)
              if (!magData || !magData.TotalField) return ''
              return numberWithCommasDecimals(magData.TotalField, 2)
            },
          },
          {
            label: 'Dip Angle (deg)',
            labelStyle: 'header',
            dataExtractor: (data) => {
              let magData = getMagnetics(data)
              if (!magData || !magData.Dip) return ''
              return numberWithCommasDecimals(magData.Dip, 3)
            },
          },
          {
            label: 'Declination  (deg)',
            labelStyle: 'header',
            dataExtractor: (data) => {
              let magData = getMagnetics(data)
              if (!magData || !magData.Dec) return ''
              return numberWithCommasDecimals(magData.Dec, 2)
            },
          },
          {
            label: 'VS Origin',
            labelStyle: 'header',
            value: 'Well',
          },
          {
            label: 'VS Azimuth',
            labelStyle: 'header',
            tag: ['actualWellData', 'verticalSection', 'VsAzi'],
          },
          {
            label: 'VS Origin NS',
            labelStyle: 'header',
            tag: ['actualWellData', 'verticalSection', 'VsOrgNorth'],
            tagUnits: UNITS_FOR.Depth,
            valueFormatter: (value, unitsText) => {
              return numberWithCommasDecimals(value, 2) + ' ' + unitsText
            },
          },
          {
            label: 'VS Origin EW',
            labelStyle: 'header',
            tag: ['actualWellData', 'verticalSection', 'VsOrgEast'],
            tagUnits: UNITS_FOR.Depth,
            valueFormatter: (value, unitsText) => {
              return numberWithCommasDecimals(value, 2) + ' ' + unitsText
            },
          },
        ],
      },
    ],
  },
}

export const surveyProgramTableDefn = {
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Survey Program',
            labelStyle: 'header',
            tag: ['actualWellData', 'wellName'],
            tagStyle: 'header',
          },
        ],
        head: [
          {
            label: 'Depth From',
            labelUnits: UNITS_FOR.Depth,
            style: 'header',
          },
          {
            label: 'Depth To',
            labelUnits: UNITS_FOR.Depth,
            style: 'header',
          },
          {
            label: 'Survey',
            style: 'header',
          },
          {
            label: 'Survey Tool',
            style: 'header',
          },
        ],
        array: {
          tag: ['actualWellData', 'surveyProgram'],
          style: 'normal',
          cols: [
            {
              tag: ['DepthFrom'],
              tagDecimals: 2,
            },
            {
              tag: ['DepthTo'],
              tagDecimals: 2,
            },
            {
              tag: ['Survey'],
              tagDecimals: 2,
            },
            {
              tag: ['IPM'],
            },
          ],
        },
      },
    ],
  },
}

export const casingDetailsTableDefn = {
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Casing',
            labelStyle: 'header',
          },
        ],
        head: [
          {
            label: 'MD',
            labelUnits: UNITS_FOR.Depth,
            style: 'header',
          },
          {
            label: 'TVD',
            labelUnits: UNITS_FOR.Depth,
            style: 'header',
          },
          {
            label: 'Size',
            labelUnits: UNITS_FOR.Diameter,
            style: 'header',
          },
          {
            label: 'Hole Size',
            labelUnits: UNITS_FOR.Diameter,
            style: 'header',
          },
          {
            label: 'Type',
            style: 'header',
          },
        ],
        array: {
          tag: ['actualWellData', 'casing'],
          style: 'normal',
          cols: [
            {
              tag: ['MD'],
              tagDecimals: 2,
            },
            {
              tag: ['TVD'],
              tagDecimals: 2,
            },
            {
              tag: ['OD'],
              tagDecimals: 3,
            },
            {
              tag: ['HoleSize'],
              tagDecimals: 3,
            },
            {
              tag: ['Type'],
            },
          ],
        },
      },
    ],
  },
}

export const lithologyTableDefn = {
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Lithology',
            labelStyle: 'header',
          },
        ],
        head: [
          {
            label: 'MD',
            labelUnits: UNITS_FOR.Depth,
            style: 'header',
          },
          {
            label: 'TVD',
            labelUnits: UNITS_FOR.Depth,
            style: 'header',
          },
          {
            label: 'Lithologies',
            style: 'header',
          },
        ],
        array: {
          tag: ['actualWellData', 'lithologies'],
          style: 'normal',
          cols: [
            {
              tag: ['md'],
              tagDecimals: 2,
            },
            {
              tag: ['tvd'],
              tagDecimals: 2,
            },
            {
              tag: ['formation'],
            },
          ],
        },
      },
    ],
  },
}

export const targetDetailsTableDefn = {
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Targets',
            labelStyle: 'header',
          },
        ],
        head: [
          {
            label: 'Name',
            style: 'header',
          },
          {
            label: 'TVD',
            labelUnits: UNITS_FOR.Depth,
            style: 'header',
          },
          {
            label: 'NS',
            labelUnits: UNITS_FOR.Depth,
            style: 'header',
          },
          {
            label: 'EW',
            labelUnits: UNITS_FOR.Depth,
            style: 'header',
          },
          {
            label: 'Grid NS',
            style: 'header',
          },
          {
            label: 'Grid EW',
            style: 'header',
          },
        ],
        array: {
          tag: ['targets'],
          style: 'normal',
          dataExtractor: (data, tag) => {
            let tagData = getWellDataValue(data, tag)
            if (!Array.isArray(tagData)) return []
            let targetData = []
            tagData.forEach((item) => {
              if (!item.isLeaseLine)
                targetData.push({
                  targetName: item.targetName,
                  tvd: item.targetCenter.tvd,
                  localNorth: item.targetCenter.localNorth,
                  localEast: item.targetCenter.localEast,
                  gridNorth: item.targetCenter.gridNorth,
                  gridEast: item.targetCenter.gridEast,
                })
            })
            return targetData
          },
          cols: [
            {
              tag: ['targetName'],
              tagDecimals: 2,
            },
            {
              tag: ['tvd'],
              tagDecimals: 2,
            },
            {
              tag: ['localNorth'],
              tagDecimals: 2,
            },
            {
              tag: ['localEast'],
              tagDecimals: 2,
            },
            {
              tag: ['gridNorth'],
              tagDecimals: 2,
            },
            {
              tag: ['gridEast'],
              tagDecimals: 2,
            },
          ],
        },
      },
    ],
  },
}

export const summaryTableDefn = {
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Well Name',
            labelStyle: 'header',
            tag: ['wellName'],
            tagStyle: 'header',
          },
          {
            label: 'RTE Elevation',
            labelStyle: 'header',
            tagUnits: UNITS_FOR.Depth,
            dataExtractor: (data, unitsText) => {
              return (
                numberWithCommasDecimals(data?.actualWellData?.depthReference?.Elevation, 2) +
                ' ' +
                unitsText +
                ' above ' +
                data.actualWellData.systemDatumName
              )
            },
          },
          {
            dynamicLabel: (data) => {
              return data?.actualWellData?.depthReference?.offshoreCheck ? 'Water Depth' : 'GL Elevation'
            },
            labelStyle: 'header',
            tagUnits: UNITS_FOR.Depth,
            dataExtractor: (data, unitsText) => {
              return (
                numberWithCommasDecimals(data?.actualWellData?.depthReference?.waterDepthGl, 2) +
                ' ' +
                unitsText +
                (data?.actualWellData?.depthReference?.offshoreCheck
                  ? ' '
                  : ' above ' + data.actualWellData.systemDatumName)
              )
            },
          },
          {
            label: 'Calculation Method',
            labelStyle: 'header',
            tag: [],
            value: 'Minimum Curvature',
          },
          {
            label: 'Local Co-Ordinate Ref',
            labelStyle: 'header',
            tag: ['actualWellData', 'localCoordRef'],
            valueFormatter: (value) => {
              return value === 'WELL' ? 'Well Centered' : 'Facility Centered'
            },
          },
          {
            label: 'Grid East',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return (
                numberWithCommasDecimals(data?.actualWellData?.wellCoords?.gridEast, 2) +
                getCrsUnits(data?.actualWellData?.crsData.uom)
              )
            },
          },
          {
            label: 'Grid North',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return (
                numberWithCommasDecimals(data?.actualWellData?.wellCoords?.gridNorth, 2) +
                getCrsUnits(data?.actualWellData?.crsData.uom)
              )
            },
          },
          {
            label: 'Local North',
            labelStyle: 'header',
            tagUnits: UNITS_FOR.Depth,
            dataExtractor: (data, unitsText) => {
              return numberWithCommasDecimals(data?.actualWellData?.wellCoords?.localNorth, 2) + ' ' + unitsText
            },
          },
          {
            label: 'Local East',
            labelStyle: 'header',
            tagUnits: UNITS_FOR.Depth,
            dataExtractor: (data, unitsText) => {
              return numberWithCommasDecimals(data?.actualWellData?.wellCoords?.localEast, 2) + ' ' + unitsText
            },
          },
          {
            label: 'Latitude',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return latLongValueFormatter(data?.actualWellData?.wellCoords?.latitude, 'lat', UNITS_FOR.LatLong)
            },
          },
          {
            label: 'Longitude',
            labelStyle: 'header',
            dataExtractor: (data) => {
              return latLongValueFormatter(data?.actualWellData?.wellCoords?.longitude, 'long', UNITS_FOR.LatLong)
            },
          },
          {
            label: 'CRS',
            labelStyle: 'header',
            tag: ['actualWellData', 'crs'],
          },
          {
            label: 'VS Origin',
            labelStyle: 'header',
            value: 'Well',
          },
          {
            label: 'North Ref',
            labelStyle: 'header',
            tag: ['actualWellData', 'northRef'],
          },
          {
            label: 'Depth Datum',
            labelStyle: 'header',
            tag: ['actualWellData', 'depthReference', 'Name'],
          },
          {
            label: 'Grid Convergence',
            labelStyle: 'header',
            tag: ['actualWellData', 'gridConv'],
          },
        ],
      },
    ],
  },
}

export const approvalsTableDefn = {
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Created by',
            labelStyle: 'header',
            tag: [],
            value: '____________________',
          },
          {
            label: 'Checked by',
            labelStyle: 'header',
            tag: [],
            value: '____________________',
          },
          {
            label: 'Reviewed by',
            labelStyle: 'header',
            tag: [],
            value: '____________________',
          },
          {
            label: 'Approved by',
            labelStyle: 'header',
            tag: [],
            value: '____________________',
          },
        ],
      },
      {
        lines: [
          {
            label: 'Date',
            labelStyle: 'header',
            labelAlign: 'right',
            tag: [],
            value: '_______________',
          },
          {
            label: 'Date',
            labelStyle: 'header',
            labelAlign: 'right',
            tag: [],
            value: '_______________',
          },
          {
            label: 'Date',
            labelStyle: 'header',
            labelAlign: 'right',
            tag: [],
            value: '_______________',
          },
          {
            label: 'Date',
            labelStyle: 'header',
            labelAlign: 'right',
            tag: [],
            value: '_______________',
          },
        ],
      },
    ],
  },
}

export const leaseLineTableDefn = {
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Lease Lines',
            labelStyle: 'header',
          },
        ],
        head: [
          {
            label: 'Name',
            style: 'header',
          },
          {
            label: 'TVD',
            labelUnits: UNITS_FOR.Depth,
            style: 'header',
          },
          {
            label: 'NS',
            labelUnits: UNITS_FOR.Depth,
            style: 'header',
          },
          {
            label: 'EW',
            labelUnits: UNITS_FOR.Depth,
            style: 'header',
          },
          {
            label: 'Grid NS',
            style: 'header',
          },
          {
            label: 'Grid EW',
            style: 'header',
          },
        ],
        array: {
          tag: ['targets'],
          style: 'normal',
          dataExtractor: (data, tag) => {
            let tagData = getWellDataValue(data, tag)
            if (!Array.isArray(tagData)) return []
            let targetData = []
            tagData.forEach((item) => {
              if (item.isLeaseLine)
                targetData.push({
                  targetName: item.targetName,
                  tvd: item.targetCenter.tvd,
                  localNorth: item.targetCenter.localNorth,
                  localEast: item.targetCenter.localEast,
                  gridNorth: item.targetCenter.gridNorth,
                  gridEast: item.targetCenter.gridEast,
                })
            })
            return targetData
          },
          cols: [
            {
              tag: ['targetName'],
            },
            {
              tag: ['tvd'],
              tagDecimals: 2,
            },
            {
              tag: ['localNorth'],
              tagDecimals: 2,
            },
            {
              tag: ['localEast'],
              tagDecimals: 2,
            },
            {
              tag: ['gridNorth'],
              tagDecimals: 2,
            },
            {
              tag: ['gridEast'],
              tagDecimals: 2,
            },
          ],
        },
      },
    ],
  },
}

export const annotationsTableDefn = {
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Annotations',
            labelStyle: 'header',
          },
        ],
        head: [
          {
            label: 'MD',
            labelUnits: UNITS_FOR.Depth,
            style: 'header',
          },
          {
            label: 'TVD',
            labelUnits: UNITS_FOR.Depth,
            style: 'header',
          },
          {
            label: 'Annotation',
            style: 'header',
          },
        ],
        array: {
          tag: ['actualWellData', 'annotations'],
          style: 'normal',
          cols: [
            {
              tag: ['md'],
              tagDecimals: 2,
            },
            {
              tag: ['tvd'],
              tagDecimals: 2,
            },
            {
              tag: ['annotation'],
            },
          ],
        },
      },
    ],
  },
}

export const mappingGridTableDefn = {
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Geodetic Datum',
            labelStyle: 'header',
          },
          {
            tag: ['actualWellData', 'crs'],
            tagStyle: 'header',
          },
        ],
      },
    ],
  },
}

export const titleBarTableDefn = {
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Well Name',
            labelStyle: 'header',
          },
          {
            tag: ['actualWellData', 'wellName'],
          },
        ],
      },
      {
        lines: [
          {
            label: 'Project Name',
            labelStyle: 'header',
          },
          {
            tag: ['actualWellData', 'field'],
          },
        ],
      },
      {
        lines: [
          {
            label: 'Pad Name',
            labelStyle: 'header',
          },
          {
            tag: ['actualWellData', 'facility'],
          },
        ],
      },
    ],
  },
}

export const datumElevationTableDefn = {
  body: {
    columns: [
      {
        lines: [
          {
            label: 'Depth Datum',
            labelStyle: 'header',
            tag: ['actualWellData', 'depthReference', 'Name'],
            tagStyle: 'header',
          },
          {
            label: 'Elevation',
            tagUnits: UNITS_FOR.Depth,
            dataExtractor: (data, unitsText) => {
              return (
                numberWithCommasDecimals(data?.actualWellData?.depthReference?.waterDepthGl, 2) +
                ' ' +
                unitsText +
                (data?.actualWellData?.depthReference?.offshoreCheck
                  ? ' '
                  : ' above ' + data.actualWellData.systemDatumName)
              )
            },
          },
        ],
      },
    ],
  },
}

