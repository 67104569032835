import { useEffect, useState, useRef, useCallback } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { currentWellAtom, selectedEngineeringCaseAtom, userUserRoleAtom } from 'atoms'
import { appColors } from 'utils'
import html2canvas from 'html2canvas'
import PdfDocument from 'components/common/PDFGen/PdfDocument'
import { removeSpecialSymbols, replaceEscapeSymbols } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { normalize } from 'utils/numberFunctions.js'
import { lerpColor } from 'utils/colorFunctions'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import useBhaImages from '../useBhaImages'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import { multiParse } from 'utils'
import { Buffer } from 'buffer'

function useCasingStandOff() {
  const _isMounted = useRef(true)
  const standOffData = useRef({})
  const isLoading = useRef(false)
  const [loading, setLoading] = useState(false)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(currentWell)
  const selectedEngCase = useRecoilValue(selectedEngineeringCaseAtom)
  const { getUnitsText } = useUnits()
  const { user } = useInnovaAuth()
  const bhaImages = useBhaImages()
  const [logos, setLogos] = useState([])
  const userRole = useRecoilValue(userUserRoleAtom)

  const getData = useInnovaAxios({
    url: '/engineering/casingStandOff',
  })

  const getOperatorLogos = useInnovaAxios({
    url: '/operator/getLogos',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const fetchStandOff = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (selectedEngCase === null || selectedEngCase === undefined) return
    if (!selectedEngCase.hasOwnProperty('recordId')) return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)
    standOffData.current = {}

    const response = await getData({ wellName: currentWellRef.current, engCaseId: selectedEngCase?.recordId })

    if (!_isMounted.current) return
    setLoading(false)
    isLoading.current = false
    standOffData.current.errors = []

    if (response?.error) {
      standOffData.current.errors = [`${response.error?.response?.data?.error}`]
      return
    }

    standOffData.current = response?.data ? response.data : null
    standOffData.current.errors = []

    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const responseLogos = await getOperatorLogos({ operator: response?.data?.jobDetails?.operator })

    if (!_isMounted.current) return
    setLoading(false)
    isLoading.current = false

    if (responseLogos?.error) return
    if (!responseLogos?.data) return

    const mfpBoundary = responseLogos.data.substring(2, responseLogos.data.search('Content')).trim()
    let parts = multiParse(Buffer.from(responseLogos.data), mfpBoundary)
    let logoData = parts.map((part) => {
      let logoObj = { ...part }
      logoObj.data = 'data:image/*;base64,' + part.data
      return logoObj
    })
    setLogos(logoData)
  }

  const getLogo = (type) => {
    if (!Array.isArray(logos)) return null
    if (logos.length === 0) return null
    if (type === 'Primary') return logos[0].data
    if (type === 'Secondary' && logos.length > 1) return logos[1].data
    return null
  }

  const getLogoDimensions = (logo) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve({ width: img.width, height: img.height })
      img.onerror = (err) => reject(err)
      img.src = logo
    })
  }

  const calculateDimensions = async (logo) => {
    if (!logo) return null

    try {
      const { width, height } = await getLogoDimensions(logo)
      return width > height ? 'contain' : 'cover'
    } catch (err) {
      console.error('Error calculating dimensions:', err)
      return null
    }
  }

  const getLogoFill = async (type) => {
    if (!Array.isArray(logos) || logos.length === 0) return null

    const logo = type === 'Primary' ? logos[0]?.data : logos[1]?.data
    if (!logo) return null

    const fillType = await calculateDimensions(logo)
    return fillType
  }

  const getStandOffGeometryAnnotations = () => {
    if (!standOffData.current) return null
    if (!standOffData.current.hasOwnProperty('wellGeometry')) return null
    if (!standOffData.current.hasOwnProperty('results')) return null
    if (!Array.isArray(standOffData.current.wellGeometry)) return null
    if (!Array.isArray(standOffData.current.results)) return null

    let maxDepth = 0
    if (Array.isArray(standOffData.current.results) && standOffData.current.results.length > 0) {
      maxDepth = standOffData.current.results[standOffData.current.results.length - 1].md
    }

    const annotations = {}
    for (let i = 0; i < standOffData.current.wellGeometry.length; i++) {
      if (standOffData.current.wellGeometry[i].md > maxDepth) continue
      annotations[`geometry` + (i + 1)] = {
        type: 'line',
        value: standOffData.current.wellGeometry[i].md,
        scaleID: 'y',
        borderColor: appColors.itemTextColor,
        borderDash: [10, 4],
        borderDashOffset: 5,
        borderWidth: 1,
        lineTension: 0.8,
        label: {
          content: `${standOffData.current.wellGeometry[i].od} ${standOffData.current.wellGeometry[i].compType}`,
          enabled: true,
          backgroundColor: 'transparent',
          position: 'start',
          color: appColors.itemTextColor,
          font: { weight: 'normal' },
          yAdjust: -5,
          display: true,
        },
      }
    }

    return annotations
  }

  const interpChartColor = (pallete, index, maxSeries) => {
    let bluePalette = ['#006cd1', '#00deff']
    let redPalette = ['#fa6e7a', '#fa000c']
    let greenPalette = ['#93fab4', '#8ee800']
    let cyanPalette = ['#49ecf5', '#226f73']
    let purplePalette = ['#e300b2', '#bc6bdb']
    let yellowPalette = ['#fffb05', '#ffa505']

    let startColor = bluePalette[0]
    let endColor = bluePalette[1]
    if (pallete === 'green') {
      startColor = greenPalette[0]
      endColor = greenPalette[1]
    }

    if (pallete === 'red') {
      startColor = redPalette[0]
      endColor = redPalette[1]
    }

    if (pallete === 'cyan') {
      startColor = cyanPalette[0]
      endColor = cyanPalette[1]
    }

    if (pallete === 'purple') {
      startColor = purplePalette[0]
      endColor = purplePalette[1]
    }

    if (pallete === 'yellow') {
      startColor = yellowPalette[0]
      endColor = yellowPalette[1]
    }

    let ratio = normalize(index, 0, maxSeries)
    return lerpColor(startColor, endColor, ratio)
  }

  const getStandOffData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!standOffData.current) return chartData
    if (!standOffData.current.hasOwnProperty('results')) return chartData
    if (!Array.isArray(standOffData.current.results)) return chartData
    if (standOffData.current.results.length === 0) return chartData

    chartData.datasets.push({
      label: 'SO Required',
      borderColor: interpChartColor('red', 1, 1),
      backgroundColor: interpChartColor('red', 1, 1),
      data: standOffData.current.results.map((pt) => {
        return { x: pt.standOffReq, y: pt.md }
      }),
    })

    chartData.datasets.push({
      label: 'SO Cemented At',
      borderColor: interpChartColor('purple', 0, 1),
      backgroundColor: interpChartColor('purple', 0, 1),
      data: standOffData.current.results.map((pt) => {
        return { x: pt.standOffCemented.standOffAtCent, y: pt.md }
      }),
    })

    chartData.datasets.push({
      label: 'SO Cemented Mid',
      borderColor: interpChartColor('purple', 1, 1),
      backgroundColor: interpChartColor('purple', 1, 1),
      data: standOffData.current.results.map((pt) => {
        return { x: pt.standOffCemented.standOffMid, y: pt.md }
      }),
    })

    chartData.datasets.push({
      label: 'SO Running At',
      borderColor: interpChartColor('blue', 0, 1),
      backgroundColor: interpChartColor('blue', 0, 1),
      data: standOffData.current.results.map((pt) => {
        return { x: pt.standOffInPos.standOffAtCent, y: pt.md }
      }),
    })

    chartData.datasets.push({
      label: 'SO Running Mid',
      borderColor: interpChartColor('blue', 1, 1),
      backgroundColor: interpChartColor('blue', 1, 1),
      data: standOffData.current.results.map((pt) => {
        return { x: pt.standOffInPos.standOffMid, y: pt.md }
      }),
    })

    return chartData
  }

  const getStandOffHookloadData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!standOffData.current) return chartData
    if (!standOffData.current.hasOwnProperty('results')) return chartData
    if (!Array.isArray(standOffData.current.results)) return chartData
    if (standOffData.current.results.length === 0) return chartData

    chartData.datasets.push({
      label: 'Slack Off',
      borderColor: interpChartColor('red', 1, 1),
      backgroundColor: interpChartColor('red', 1, 1),
      data: standOffData.current.results.map((pt) => {
        return { x: pt.so, y: pt.md }
      }),
    })

    chartData.datasets.push({
      label: 'Rotating',
      borderColor: interpChartColor('green', 0, 1),
      backgroundColor: interpChartColor('green', 0, 1),
      data: standOffData.current.results.map((pt) => {
        return { x: pt.rot, y: pt.md }
      }),
    })

    chartData.datasets.push({
      label: 'Pick Up',
      borderColor: interpChartColor('blue', 1, 1),
      backgroundColor: interpChartColor('blue', 1, 1),
      data: standOffData.current.results.map((pt) => {
        return { x: pt.pu, y: pt.md }
      }),
    })

    return chartData
  }

  const getStandOffCentSpacingData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!standOffData.current) return chartData
    if (!standOffData.current.hasOwnProperty('results')) return chartData
    if (!Array.isArray(standOffData.current.results)) return chartData
    if (standOffData.current.results.length === 0) return chartData

    chartData.datasets.push({
      label: 'Spacing',
      borderColor: interpChartColor('red', 1, 1),
      backgroundColor: interpChartColor('red', 1, 1),
      data: standOffData.current.results.map((pt) => {
        return { x: pt.centSpacing, y: pt.md }
      }),
    })

    return chartData
  }

  const getDLSData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!standOffData.current) return chartData
    if (!standOffData.current.hasOwnProperty('results')) return chartData
    if (!Array.isArray(standOffData.current.results)) return chartData
    if (standOffData.current.results.length === 0) return chartData

    chartData.datasets.push({
      label: 'DLS',
      borderColor: interpChartColor('red', 1, 1),
      backgroundColor: interpChartColor('red', 1, 1),
      data: standOffData.current.results.map((pt) => {
        return { x: pt.dls, y: pt.md }
      }),
    })

    return chartData
  }

  const getStandOffTorqueData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!standOffData.current) return chartData
    if (!standOffData.current.hasOwnProperty('results')) return chartData
    if (!Array.isArray(standOffData.current.results)) return chartData
    if (standOffData.current.results.length === 0) return chartData

    chartData.datasets.push({
      label: 'Torque',
      borderColor: interpChartColor('red', 1, 1),
      backgroundColor: interpChartColor('red', 1, 1),
      data: standOffData.current.results.map((pt) => {
        return { x: pt.torque, y: pt.md }
      }),
    })

    return chartData
  }

  const getStandOffWarnings = () => {
    if (!standOffData.current) return []
    if (!standOffData.current.hasOwnProperty('warnings')) return []
    if (!Array.isArray(standOffData.current.warnings)) return []
    if (standOffData.current.warnings.length === 0) return []

    return standOffData.current.warnings
  }

  const getStandOffErrors = () => {
    if (!standOffData.current) return []
    if (!standOffData.current.hasOwnProperty('errors')) return []
    if (!Array.isArray(standOffData.current.errors)) return []
    if (standOffData.current.errors.length === 0) return []

    return standOffData.current.errors
  }

  const getCumLengthOfDrillString = (drillStrings) => {
    let cumLength = 0

    for (let i = 0; i < drillStrings?.length; i++) {
      cumLength = cumLength + drillStrings[i].length
    }

    return numberWithCommasDecimals(cumLength, 2)
  }

  const generatePdfDocument = async (
    standOffChartImage,
    standOffCentSpacingChartImage,
    standOffHookloadChartImage,
    standOffTorqueChartImage,
    standOffDLSChartImage,
    wellSchematicImage,
    sectionPlanImage,
    imageHeight,
  ) => {
    let logoFill = await getLogoFill('Primary')
    let logoSecondaryFill = await getLogoFill('Secondary')
    let docData = [
      {
        tableType: 'header',
        showTitle: true,
        title: `Tubular Deflections Calculations & Centralizer Recommendations Program`,
        showLogo: true,
        logo: getLogo('Primary'),
        logoWidth: '25%',
        logoFill: logoFill,
        logoSecondary: getLogo('Secondary'),
        logoSecondaryWidth: '25%',
        logoSecondaryFill: logoSecondaryFill,
        fontSize: 6,
        sectionAfter: 5,
        manualWidth: true,
        columnWidths: ['50%', '50%'],
        data: [
          [
            { text: 'Well:', isHeader: true },
            { text: currentWellRef.current, textAlign: 'left' },
          ],
          [
            { text: 'Case:', isHeader: true },
            { text: unescapeHtml(selectedEngCase?.caseDesc.replace('&dq;', '"')), textAlign: 'left' },
          ],
        ],
      },
      {
        columnWidths: ['15%', '18%', '15%', '18%', '15%', '18%'],
        manualWidth: true,
        fontSize: 6,
        sectionAfter: 5,
        data: [
          [
            { text: 'Client:', isHeader: true },
            { text: standOffData.current.jobDetails?.operator, textAlign: 'left' },
            { text: 'Field:', isHeader: true },
            { text: standOffData.current.jobDetails?.field, textAlign: 'left' },
            { text: 'Well:', isHeader: true },
            { text: standOffData.current.jobDetails?.wellName, textAlign: 'left' },
          ],
          [
            { text: 'Case:', isHeader: true },
            { text: unescapeHtml(selectedEngCase?.caseDesc.replace('&dq;', '"')), textAlign: 'left' },
            { text: 'Prepared By:', isHeader: true },
            { text: getUserNameFromEmail(user?.name), textAlign: 'left' },
            { text: 'Date:', isHeader: true },
            { text: dateTimeFormatter(new Date(Date.now()).toISOString()), textAlign: 'left' },
          ],
          [
            { text: 'Contact', isHeader: true },
            { text: unescapeHtml(standOffData.current.jobDetails?.contact), textAlign: 'left' },
            { text: 'Address', isHeader: true },
            { text: unescapeHtml(standOffData.current.jobDetails?.address), textAlign: 'left', columnSpan: 3 },
          ],
        ],
      },
      {
        columnWidths: ['15%', '18%', '15%', '18%', '15%', '18%'],
        manualWidth: true,
        fontSize: 6,
        sectionAfter: 5,
        data: [
          [{ text: 'Calculation Parameters', columnSpan: 6 }],
          [
            { text: `Calculation Depth (${getUnitsText(UNITS_FOR.Depth)}):`, isHeader: true },
            { text: numberWithCommasDecimals(standOffData.current.calcDetails?.calcDepth, 2), textAlign: 'left' },
            { text: `Block Weight (${getUnitsText(UNITS_FOR.Weight)}):`, isHeader: true },
            { text: standOffData.current.calcDetails?.blockWeight, textAlign: 'left' },
            { text: 'Case Hole Friction Factor:', isHeader: true },
            { text: standOffData.current.calcDetails?.frictionFactorCh, textAlign: 'left' },
          ],
          [
            { text: `Setting Depth (${getUnitsText(UNITS_FOR.Depth)}):`, isHeader: true },
            { text: getCumLengthOfDrillString(standOffData.current.drillString), textAlign: 'left' },
            { text: `Standoff Requirement (%):`, isHeader: true },
            { text: standOffData.current.calcDetails?.requiredStandOff, textAlign: 'left' },
            { text: `Open Hole Friction Factor:`, isHeader: true },
            { text: standOffData.current.calcDetails?.frictionFactorOh, textAlign: 'left' },
          ],
          [
            { text: `Mud Weight (${getUnitsText(UNITS_FOR.MudWeight)}):`, isHeader: true },
            { text: standOffData.current.calcDetails?.mudWeight, textAlign: 'left' },
            { text: `Cement Weight (${getUnitsText(UNITS_FOR.MudWeight)}):`, isHeader: true },
            { text: standOffData.current.calcDetails?.cementWeight, textAlign: 'left' },
          ],
        ],
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['25%', '25%', '25%', '25%'],
        wrap: false,
        data: addWellboreGeometry(standOffData.current.wellGeometry),
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['3%', '3%', '11%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '13%'],
        wrap: false,
        data: addDrillStrings(standOffData.current.drillString, standOffData.current.calcDetails?.calcDepth),
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['3%', '3%', '8%', '8%', '8%', '7%', '8%', '6%', '10%', '10%', '8%', '8%', '13%'],
        wrap: false,
        data: addDrillStringsAdditional(standOffData.current.drillString, standOffData.current.calcDetails?.calcDepth),
      },
      {
        breakHeader: true,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['12%', '12%', '12%', '12%', '13%', '13%', '13%', '13%'],
        wrap: false,
        data: addRequiredCentralizers(standOffData.current.centralizers, standOffData.current.drillString),
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['20%', '20%', '20%', '20%', '20%'],
        wrap: false,
        data: addRequiredStopCollars(standOffData.current.stopCollars, standOffData.current.drillString),
      },
      {
        breakHeader: true,
        sectionAfter: 5,
        columnWidths: ['34%', '33%', '33%'],
        manualWidth: true,
        data: [
          [
            { text: wellSchematicImage, isImage: true, width: '100%', height: imageHeight * 2 },
            { text: sectionPlanImage, isImage: true, width: '100%', height: imageHeight * 2, columnSpan: 2 },
          ],
        ],
      },
      {
        breakHeader: true,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        multiPage: true,
        multiPageRow: 2,
        columnWidths: [
          '6%',
          '4%',
          '4%',
          '6%',
          '4%',
          '5%',
          '5%',
          '5%',
          '5%',
          '5%',
          '5%',
          '5%',
          '5%',
          '5%',
          '5%',
          '5%',
          '4%',
          '9%',
          '8%',
        ],
        data: addStandOffResultsData(
          standOffData.current.results,
          standOffData.current.sections,
          standOffData.current.centralizers,
        ),
      },
      {
        breakHeader: true,
        sectionAfter: 5,
        data: [[{ text: standOffChartImage, isImage: true, width: '100%', height: imageHeight }]],
      },
      {
        sectionAfter: 5,
        data: [[{ text: standOffCentSpacingChartImage, isImage: true, width: '100%', height: imageHeight }]],
      },
      {
        breakHeader: true,
        sectionAfter: 5,
        data: [[{ text: standOffHookloadChartImage, isImage: true, width: '100%', height: imageHeight }]],
      },
      {
        sectionAfter: 5,
        data: [[{ text: standOffTorqueChartImage, isImage: true, width: '100%', height: imageHeight }]],
      },
      {
        breakHeader: true,
        sectionAfter: 5,
        data: [[{ text: standOffDLSChartImage, isImage: true, width: '100%', height: imageHeight }]],
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['50%', '50%'],
        data: addCentralizerStopCollarData(
          standOffData.current.centralizers,
          standOffData.current.stopCollars,
          standOffData.current.drillString,
        ),
      },
    ]

    if (userRole.organization === 'CENTEK') {
      docData.push({
        fontSize: 10,
        manualWidth: true,
        columnWidths: ['100%'],
        data: [
          [{ text: 'Disclaimer', isHeader: true }],
          [{ text: getDisclaimerText('Centek Limited'), textAlign: 'left' }],
        ],
      })
    }

    return docData
  }

  const getDisclaimerText = (name) => {
    if (!name) name = 'Organization'
    return `${name} presents the following data for clients to establish, with confidence, the placement and quantity of chosen centralisers to give running and standoff conditions within the wellbore or previously installed tubular, for optimum centralisation. Calculations use actual centraliser performance curve figures, of Centek Limited manufactured centralisers, tested in accordance with API10D. Should customers wish for an update, from actual data direct from the drilling operations, this can be performed with a minimum of effort. The program utilises mathematical models as outlined in ISO-10427-2 and API RP 10D-2 (CENTRALIZER PLACEMENT AND STOP COLLAR TESTING) SECOND EDITION, AUGUST 2023. Corrective updates have been included and mathematical formulae have been verified. The mathematical construction and resultant data and recommendations are presented in good faith. Centek Limited and Innova Drilling and Intervention Limited do not warrant or guarantee the use of the attached data and assumes no liability for the use of said data or any recommendations or advice resulting from this data.`
  }

  const addCentralizerStopCollarData = (centralizers, stopCollars, drillStrings) => {
    if (!centralizers) return []
    if (!stopCollars) return []
    if (!Array.isArray(centralizers)) return []
    if (!Array.isArray(stopCollars)) return []

    let data = []

    data.push([{ text: 'Pro Forma Order Summary', columnSpan: 2 }])

    data.push([{ text: 'Centralizers', isHeader: true, columnSpan: 2 }])

    data.push([
      { text: 'Part Number', isHeader: true },
      { text: 'Surveyed Quantity', isHeader: true },
    ])

    for (let i = 0; i < centralizers.length; i++) {
      let numCentralizers = getNumberOfCentralizersByType(centralizers[i].name, drillStrings)

      data.push([{ text: centralizers[i].name }, { text: numberWithCommasDecimals(numCentralizers, 0) }])
    }

    data.push([{ text: 'Stop Collars', isHeader: true, columnSpan: 2 }])

    data.push([
      { text: 'Part Number', isHeader: true },
      { text: 'Surveyed Quantity', isHeader: true },
    ])

    for (let i = 0; i < stopCollars.length; i++) {
      let numStopCollars = getNumberOfStopCollarsByType(stopCollars[i].description, drillStrings)
      data.push([{ text: stopCollars[i].description }, { text: numberWithCommasDecimals(numStopCollars, 0) }])
    }

    return data
  }

  const addStandOffResultsData = (standOffResults, sectionData, centralizers) => {
    if (!standOffResults) return []
    if (!Array.isArray(standOffResults)) return []

    let data = []
    data.push(
      [{ text: 'Survey and Results Data', columnSpan: 19, backgroundColor: '#FFFFFF' }],
      [
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Inc °`, isHeader: true },
        { text: `Azi °`, isHeader: true },
        { text: `TVD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `DLS °/100ft`, isHeader: true },
        { text: `PU Hookload (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true },
        { text: `Rot Hookload (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true },
        { text: `SO Hookload (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true },
        { text: `Torque @ RT (${getUnitsText(UNITS_FOR.Torque)})`, isHeader: true },
        { text: `Cent. Od (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Cent. Spacing (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Pipe Deflection (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Lateral Force (${getUnitsText(UNITS_FOR.Force)})`, isHeader: true },
        { text: `Standoff Mid RIH (%)`, isHeader: true },
        { text: `Standoff Cent. RIH (%)`, isHeader: true },
        { text: `Standoff Mid IP (%)`, isHeader: true },
        { text: `Standoff Cent. IP (%)`, isHeader: true },
        { text: `Cent. Type`, isHeader: true },
        { text: `Holesize (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      ],
    )

    for (let i = 0; i < standOffResults.length; i++) {
      let centralizerId = sectionData[0].centralizer.uid
      let holeSize = sectionData[0].holeSize
      if (i !== 0) {
        centralizerId = sectionData[i - 1].centralizer.uid
        holeSize = sectionData[i - 1].holeSize
      }

      let centralizer = centralizers.find((c) => c.uid === centralizerId)

      data.push([
        { text: numberWithCommasDecimals(standOffResults[i].md, 2) },
        { text: numberWithCommasDecimals(standOffResults[i].inc, 2) },
        { text: numberWithCommasDecimals(standOffResults[i].azi, 2) },
        { text: numberWithCommasDecimals(standOffResults[i].tvd, 2) },
        { text: numberWithCommasDecimals(standOffResults[i].dls, 2) },
        { text: numberWithCommasDecimals(standOffResults[i].pu, 2) },
        { text: numberWithCommasDecimals(standOffResults[i].rot, 2) },
        { text: numberWithCommasDecimals(standOffResults[i].so, 2) },
        { text: numberWithCommasDecimals(standOffResults[i].torque, 2) },
        { text: numberWithCommasDecimals(centralizer?.od ? centralizer.od : 0.0, 3) },
        { text: numberWithCommasDecimals(standOffResults[i].centSpacing, 2) },
        { text: numberWithCommasDecimals(standOffResults[i].pipeDef, 3) },
        { text: numberWithCommasDecimals(standOffResults[i].sideForce, 2) },
        {
          text: numberWithCommasDecimals(standOffResults[i].standOffInPos.standOffMid, 0),
          color:
            numberWithCommasDecimals(standOffData.current.calcDetails?.requiredStandOff, 0) >
              numberWithCommasDecimals(standOffResults[i].standOffInPos.standOffMid, 0) &&
              numberWithCommasDecimals(standOffResults[i].standOffInPos.standOffMid, 0) > 0 &&
              centralizer?.name !== ''
              ? '#FF6347'
              : '',
        },
        {
          text: numberWithCommasDecimals(standOffResults[i].standOffInPos.standOffAtCent, 0),
          color:
            numberWithCommasDecimals(standOffData.current.calcDetails?.requiredStandOff, 0) >
              numberWithCommasDecimals(standOffResults[i].standOffInPos.standOffAtCent, 0) &&
              numberWithCommasDecimals(standOffResults[i].standOffInPos.standOffAtCent, 0) > 0 &&
              centralizer?.name !== ''
              ? '#FF6347'
              : '',
        },
        {
          text: numberWithCommasDecimals(standOffResults[i].standOffCemented.standOffMid, 0),
          color:
            numberWithCommasDecimals(standOffData.current.calcDetails?.requiredStandOff, 0) >
              numberWithCommasDecimals(standOffResults[i].standOffCemented.standOffMid, 0) &&
              numberWithCommasDecimals(standOffResults[i].standOffCemented.standOffMid, 0) > 0 &&
              centralizer?.name !== ''
              ? '#FF6347'
              : '',
        },
        {
          text: numberWithCommasDecimals(standOffResults[i].standOffCemented.standOffAtCent, 0),
          color:
            numberWithCommasDecimals(standOffData.current.calcDetails?.requiredStandOff, 0) >
              numberWithCommasDecimals(standOffResults[i].standOffCemented.standOffAtCent, 0) &&
              numberWithCommasDecimals(standOffResults[i].standOffCemented.standOffAtCent, 0) > 0 &&
              centralizer?.name !== ''
              ? '#FF6347'
              : '',
        },
        { text: unescapeHtml(centralizer?.name) },
        { text: numberWithCommasDecimals(holeSize, 3) },
      ])
    }

    return data
  }

  const addDrillStrings = (drillStrings, startDepth) => {
    if (!drillStrings) return []
    if (!Array.isArray(drillStrings)) return []

    let data = []
    data.push(
      [{ text: 'String Data', columnSpan: 11 }],

      [
        { text: '', isHeader: true },
        { text: '#', isHeader: true },
        { text: 'Description', isHeader: true },
        { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Unit Weight (${getUnitsText(UNITS_FOR.UnitWeight)})`, isHeader: true },
        { text: `Comp Weight (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true },
        { text: `Total Weight (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true },
        { text: `Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Total Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `MD Start/End (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
      ],
    )

    let totalCompWeight = 0
    let cumLength = 0

    for (let i = 0; i < drillStrings.length; i++) {
      totalCompWeight = totalCompWeight + drillStrings[i].casingWeight * drillStrings[i].length
      cumLength += drillStrings[i].length

      let compTop = startDepth - cumLength
      let compBottom = compTop + drillStrings[i].length
      let image = bhaImages.getPrintImage(drillStrings[i], false)

      data.push([
        { text: image, isImage: true, height: 31, rotate: false },
        { text: `${i + 1}` },
        { text: unescapeHtml(drillStrings[i].pipeName.replace('&dq;', '"')) },
        { text: numberWithCommasDecimals(drillStrings[i].pipeOd, 3) },
        { text: numberWithCommasDecimals(drillStrings[i].pipeId, 3) },
        { text: numberWithCommasDecimals(drillStrings[i].casingWeight, 2) },
        { text: numberWithCommasDecimals((drillStrings[i].casingWeight * drillStrings[i].length) / 1000.0, 2) },
        { text: numberWithCommasDecimals(totalCompWeight / 1000.0, 2) },
        { text: numberWithCommasDecimals(drillStrings[i].length, 2) },
        { text: numberWithCommasDecimals(cumLength, 2) },
        {
          text: `${numberWithCommasDecimals(compTop, 2)}/${numberWithCommasDecimals(compBottom, 2)}`,
        },
      ])
    }

    return data
  }

  const addRequiredCentralizers = (centralizers, drillStrings) => {
    if (!centralizers) return []
    if (!Array.isArray(centralizers)) return []

    let data = []
    data.push([{ text: 'Centralizer Requirements', columnSpan: 8 }])

    data.push([
      { text: 'Code', isHeader: true },
      { text: 'Type', isHeader: true },
      { text: 'No. Required', isHeader: true },
      { text: `Pipe Size (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: 'No. of bows', isHeader: true },
      { text: `Min Flexed OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: `Max Flexed OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: `Restoring Force (${getUnitsText(UNITS_FOR.Force)})`, isHeader: true },
    ])

    for (let i = 0; i < centralizers.length; i++) {
      let numCentralizers = getNumberOfCentralizersByType(centralizers[i].name, drillStrings)

      data.push([
        { text: unescapeHtml(centralizers[i].name) },
        { text: unescapeHtml(centralizers[i].type) },
        { text: numberWithCommasDecimals(numCentralizers, 0) },
        { text: numberWithCommasDecimals(centralizers[i].size, 3) },
        { text: numberWithCommasDecimals(centralizers[i].numberOfBows, 0) },
        { text: numberWithCommasDecimals(centralizers[i].minFlexOd, 3) },
        { text: numberWithCommasDecimals(centralizers[i].od, 3) },
        { text: numberWithCommasDecimals(centralizers[i].restoreForce, 2) },
      ])
    }

    return data
  }

  const addRequiredStopCollars = (stopCollars, drillStrings) => {
    if (!stopCollars) return []
    if (!Array.isArray(stopCollars)) return []

    let data = []
    data.push([{ text: 'Stop Collar Requirements', columnSpan: 5 }])

    data.push([
      { text: 'Code', isHeader: true },
      { text: 'Type', isHeader: true },
      { text: 'No. Required', isHeader: true },
      { text: `Pipe Size (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: `Max OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
    ])

    for (let i = 0; i < stopCollars.length; i++) {
      let numStopCollars = getNumberOfStopCollarsByType(stopCollars[i].description, drillStrings)

      data.push([
        { text: unescapeHtml(stopCollars[i].description) },
        { text: unescapeHtml(stopCollars[i].type) },
        { text: numberWithCommasDecimals(numStopCollars, 0) },
        { text: numberWithCommasDecimals(stopCollars[i].size, 3) },
        { text: numberWithCommasDecimals(stopCollars[i].maxOd, 3) },
      ])
    }

    return data
  }

  const addDrillStringsAdditional = (drillStrings, startDepth) => {
    if (!drillStrings) return []
    if (!Array.isArray(drillStrings)) return []

    let data = []
    data.push(
      [{ text: 'String Properties', columnSpan: 13 }],
      [
        { text: '', isHeader: true },
        { text: '#', isHeader: true },
        { text: 'Description', isHeader: true },
        { text: `In Position Fluids`, isHeader: true },
        { text: `Running Fluids`, isHeader: true },
        { text: `Avg. Joint Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Cent. Spacing Selection`, isHeader: true },
        { text: `Stop Collar Qty`, isHeader: true },
        { text: `Stop Collar Type`, isHeader: true },
        { text: `Centralizer Type`, isHeader: true },
        { text: `Number of Centralizers`, isHeader: true },
        { text: `Number of Stop Collars`, isHeader: true },
        { text: `MD Start/End (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
      ],
    )

    let totalCentralizers = 0
    let totalStopCollars = 0
    let cumLength = 0

    for (let i = 0; i < drillStrings.length; i++) {
      let numCentralizers = getNumberOfCentralizers(drillStrings[i])
      let numStopCollars = numCentralizers * drillStrings[i].stopCollarQty

      let image = bhaImages.getPrintImage(drillStrings[i], false)

      totalCentralizers += numCentralizers
      totalStopCollars += numStopCollars

      cumLength += drillStrings[i].length
      let compTop = startDepth - cumLength
      let compBottom = compTop + drillStrings[i].length

      data.push([
        { text: image, isImage: true, height: 31, rotate: false },
        { text: `${i + 1}` },
        { text: unescapeHtml(drillStrings[i].pipeName.replace('&dq;', '"')) },
        { text: drillStrings[i].inPositionFluids },
        { text: drillStrings[i].runningFluids },
        { text: numberWithCommasDecimals(drillStrings[i].avgJointLength, 2) },
        { text: drillStrings[i].centSpacingSelection },
        { text: drillStrings[i].stopCollarQty },
        { text: drillStrings[i].stopCollarType },
        { text: drillStrings[i].centralizerType },
        { text: numberWithCommasDecimals(numCentralizers, 0) },
        { text: numberWithCommasDecimals(numStopCollars, 0) },
        {
          text: `${numberWithCommasDecimals(compTop, 2)}/${numberWithCommasDecimals(compBottom, 2)}`,
        },
      ])
    }

    data.push([
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: numberWithCommasDecimals(totalCentralizers, 0) },
      { text: numberWithCommasDecimals(totalStopCollars, 0) },
      { text: '' },
    ])

    return data
  }

  const addWellboreGeometry = (wellGeometry) => {
    if (!wellGeometry) return []
    if (!Array.isArray(wellGeometry)) return []

    let data = []
    data.push(
      [{ text: 'Wellbore Geometry', columnSpan: 4 }],
      [
        { text: 'Component Type', isHeader: true },
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      ],
    )

    for (let i = 0; i < wellGeometry.length; i++) {
      data.push([
        { text: unescapeHtml(wellGeometry[i].compType) },
        { text: numberWithCommasDecimals(wellGeometry[i].md, 2) },
        { text: numberWithCommasDecimals(wellGeometry[i].od, 3) },
        { text: numberWithCommasDecimals(wellGeometry[i].id, 3) },
      ])
    }

    return data
  }

  const getStandOffPdfData = (chartElement, wellElement) => {
    return new Promise(async (resolve) => {
      if (typeof currentWellRef.current !== 'string') return null
      if (currentWellRef.current === '') return null
      if (!selectedEngCase) return null
      if (!selectedEngCase.hasOwnProperty('recordId')) return null
      if (!standOffData.current) return null

      let title = 'Tubular Deflections'

      let standOffElement = chartElement.getChartType('standOff')
      const standOffCanvas = await html2canvas(standOffElement.current)
      const standOffImage = standOffCanvas.toDataURL('image/png', 1.0)

      let standOffCentSpacingElement = chartElement.getChartType('standOffCentSpacing')
      const standOffCentSpacingCanvas = await html2canvas(standOffCentSpacingElement.current)
      const standOffCentSpacingImage = standOffCentSpacingCanvas.toDataURL('image/png', 1.0)

      let standOffHookloadElement = chartElement.getChartType('standOffHookload')
      const standOffHookloadCanvas = await html2canvas(standOffHookloadElement.current)
      const standOffHookloadImage = standOffHookloadCanvas.toDataURL('image/png', 1.0)

      let standOffTorqueElement = chartElement.getChartType('standOffTorque')
      const standOffTorqueCanvas = await html2canvas(standOffTorqueElement.current)
      const standOffTorqueImage = standOffTorqueCanvas.toDataURL('image/png', 1.0)

      let standOffDLSElement = chartElement.getChartType('standOffDLS')
      const standOffDLSCanvas = await html2canvas(standOffDLSElement.current)
      const standOffDLSImage = standOffDLSCanvas.toDataURL('image/png', 1.0)

      let wellSchematicElement = wellElement.getChartType('wellSchematic')
      const wellSchematicCanvas = await html2canvas(wellSchematicElement.current)
      const wellSchematicImage = wellSchematicCanvas.toDataURL('image/png', 1.0)

      let sectionPlanViewElement = wellElement.getChartType('sectionPlanView')
      const sectionPlanViewCanvas = await html2canvas(sectionPlanViewElement.current)
      const sectionPlanViewImage = sectionPlanViewCanvas.toDataURL('image/png', 1.0)

      const pdfDoc = await generatePdfDocument(
        standOffImage,
        standOffCentSpacingImage,
        standOffHookloadImage,
        standOffTorqueImage,
        standOffDLSImage,
        wellSchematicImage,
        sectionPlanViewImage,
        300,
      )

      resolve({
        fileName: `${title} - ${replaceEscapeSymbols(removeSpecialSymbols(currentWellRef.current))}`,
        data: (
          <PdfDocument
            data={pdfDoc}
            multiDocument={false}
            pageOrientation={'portrait'}
            reportSettings={userRole?.userPrefs?.reportSettings}
            footerText={`${userRole.organization} powered by Innova ${new Date().getFullYear()}`}
          />
        ),
      })
    })
  }

  const getStandOffCasing = () => {
    if (!standOffData.current) return null
    if (!standOffData.current.hasOwnProperty('wellGeometry')) return null
    if (!Array.isArray(standOffData.current.wellGeometry)) return null

    return standOffData.current.wellGeometry.map((geometry) => ({
      MD: geometry.md,
      OD: geometry.od,
      TVD: geometry.tvd,
      HoleSize: geometry.id,
      Type: geometry.compType,
    }))
  }

  const getNumberOfCentralizers = (drillString) => {
    if (!drillString) return null

    let numCentralizers = 0
    let centSpacing = 0
    if (drillString.centSpacingSelection === '1 per joint') {
      centSpacing = drillString.avgJointLength
    }

    if (drillString.centSpacingSelection === '2 per joint') {
      centSpacing = drillString.avgJointLength / 2
    }

    if (drillString.centSpacingSelection === '3 per 2 joints') {
      centSpacing = (drillString.avgJointLength * 2) / 3
    }

    if (centSpacing !== 0) numCentralizers = Math.floor(drillString.length / centSpacing)

    return numCentralizers
  }

  const getNumberOfCentralizersByType = (type, drillStrins) => {
    if (!drillStrins) return null
    if (!Array.isArray(drillStrins)) return null

    let totalCentralizers = 0
    for (let i = 0; i < drillStrins.length; i++) {
      if (drillStrins[i].centralizerType !== type) continue
      let numCentralizers = getNumberOfCentralizers(drillStrins[i])
      totalCentralizers += numCentralizers
    }

    return totalCentralizers
  }

  const getNumberOfStopCollarsByType = (type, drillStrins) => {
    if (!drillStrins) return null
    if (!Array.isArray(drillStrins)) return null

    let totalCollars = 0
    for (let i = 0; i < drillStrins.length; i++) {
      if (drillStrins[i].stopCollarType !== type) continue
      let numCentralizers = getNumberOfCentralizers(drillStrins[i])
      totalCollars += numCentralizers * drillStrins[i].stopCollarQty
    }

    return totalCollars
  }

  return {
    loading,
    fetchStandOff,
    getStandOffData,
    getStandOffGeometryAnnotations,
    getStandOffWarnings,
    getStandOffErrors,
    getStandOffPdfData,
    getStandOffHookloadData,
    getStandOffTorqueData,
    getStandOffCentSpacingData,
    getDLSData,
    getStandOffCasing,
  }
}

export default useCasingStandOff
