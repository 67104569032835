import { useState, useMemo } from 'react'
import {
  Button,
  Box,
  Menu,
  MenuItem,
  TextField,
  IconButton,
  Typography,
} from '@mui/material'
import { appColors } from 'utils'
import AddIcon from '@mui/icons-material/Add'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DropDownPicker from 'components/common/DropDownPicker'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ExportTemplateButton = ({ handleExportTemplate }) => {
    return (
      <Button variant='outlined' onClick={handleExportTemplate} color='primary' sx={{ height: '36px' }}>
        Export
      </Button>
    )
  }
  
  const ImportTemplateButton = ({ handleImportTemplate }) => {
    return (
      <Button variant='outlined' onClick={handleImportTemplate} color='primary' sx={{ height: '36px' }}>
        Import
      </Button>
    )
  }
  
  const ThreeDotMenu = ({ onSave, onDelete }) => {
    const { theme, getBackColor } = useInnovaTheme()
    const [anchorEl, setAnchorEl] = useState(null)
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget)
    }
  
    const handleClose = () => {
      setAnchorEl(null)
    }
  
    const handleSave = () => {
      onSave()
      handleClose()
    }
  
    const handleDelete = () => {
      onDelete()
      handleClose()
    }
  
    return (
      <>
        <IconButton onClick={handleClick}>
          <MoreVertIcon color={theme === 'dark' ? 'primary' : 'default'} />
        </IconButton>
        <Menu
          id='three-dot-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            sx: {
              fontSize: 16,
              borderColor: appColors.borderColor,
              backgroundColor: getBackColor(),
              color: appColors.itemTextColor,
            },
          }}>
          <MenuItem onClick={handleSave}>Save</MenuItem>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </>
    )
  }
  
  const TemplateBoxComponent = (props) => {
    const {
      templates,
      newTemplateName,
      setNewTemplateName,
      selectedTemplateName,
      handleSelectTemplate,
      createNewTemplate,
      saveTemplate,
      deleteTemplate,
      handleExportTemplate,
      handleImportTemplate,
      width,
    } = props
    const { theme } = useInnovaTheme()
  
    const textFieldStyle = useMemo(
      () => ({
        height: '40px',
        backgroundColor: theme === 'dark' ? '#2d2d2d !important' : '#f0f0f0 !important',
        textColor: appColors.itemTextColor,
        width: '100%',
        maxWidth: '250px',
      }),
      [theme],
    )
  
    const inputLabelStyle = useMemo(
      () => ({
        style: { color: theme === 'dark' ? appColors.itemTextColor : '#000' },
      }),
      [theme],
    )
  
    return (
      <Box
        sx={{
          border: '1px solid black',
          padding: 1,
          alignSelf: 'flex-start',
          width: width ? width : 'auto',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 1,
            marginLeft: 1,
            marginRight: 2,
            gap: 1,
          }}>
          <Typography variant='subtitle1' sx={{ color: theme === 'dark' ? appColors.itemTextColor : '#000' }}>
            Template Settings
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}>
            <ExportTemplateButton handleExportTemplate={handleExportTemplate} />
            <ImportTemplateButton handleImportTemplate={handleImportTemplate} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
          }}>
          <Box
            component='form'
            onSubmit={(e) => {
              e.preventDefault()
              createNewTemplate()
            }}
            noValidate>
            <TextField
              id='new-template-name'
              sx={textFieldStyle}
              size='small'
              InputLabelProps={inputLabelStyle}
              label='New Template Name'
              variant='outlined'
              value={newTemplateName}
              onChange={(e) => setNewTemplateName(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton
                    edge='end'
                    onClick={(e) => {
                      e.preventDefault()
                      createNewTemplate()
                    }}>
                    <AddIcon color={theme === 'dark' ? 'primary' : 'default'} />
                  </IconButton>
                ),
              }}
            />
          </Box>
  
          <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '200px' }}>
            <DropDownPicker
              value={selectedTemplateName}
              onChange={handleSelectTemplate}
              listOptions={Object.keys(templates).map((templateName) => ({
                label: templateName,
                value: templateName,
              }))}
            />
            <ThreeDotMenu onSave={saveTemplate} onDelete={deleteTemplate} />
          </Box>
        </Box>
      </Box>
    )
  }

export { ImportTemplateButton, ThreeDotMenu, TemplateBoxComponent }
  