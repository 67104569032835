export const ELEMENT_TYPES = {
  text: 'text',
  image: 'image',
  imageOrgLogo: 'imageOrgLogo',
  imageOperLogo: 'imageOperatorLogo',
  imageOperLogo2: 'imageOperatorLogo2',
  table: 'table',
  chart: 'chart',
  northReference: 'northRefernce',
}

export const TABLE_TYPES = {
  projectDetails: 'projectDetails',
  references: 'references',
  fieldDetails: 'fieldDetails',
  facilityDetails: 'facilityDetails',
  wellDetails: 'wellDetails',
  wellboreDetails: 'wellboreDetails',
  surveyProgram: 'surveyProgram',
  planSections: 'planSections',
  casingDetails: 'casingDetails',
  lithology: 'lithology',
  targetDetails: 'targetDetails',
  summary: 'summary',
  approvals: 'approvals',
  leaseLine: 'leaseLine',
  annotations: 'annotations',
  mappingGrid: 'mappingGrid',
  titleBar: 'titleBar',
  datumElevation: 'datumElevation',
}

export const CHART_TYPES = {
  planView: 'plan',
  sectionView: 'section',
}

export const ELEMENT_DEFS = {
  static: {
    label: 'static',
  },
  text: {
    label: 'text',
    propertyTabs: [
      {
        title: 'Style',
        tag: 'style',
      },
      {
        title: 'Text',
        tag: 'context',
      },
    ],
  },
  image: {
    label: 'image',
    propertyTabs: [
      {
        title: 'Style',
        tag: 'style',
      },
      {
        title: 'Image',
        tag: 'context',
      },
    ],
  },
}
