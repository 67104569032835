import React, { useRef, useEffect, useState, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react'
import { Box, Tooltip, IconButton, Avatar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { cloneDeep } from 'lodash'
import useDrillStringDropDowns from 'components/common/hooks/useDrillStringDropDowns'
import { getDrillStringIcon } from 'components/common/drillStringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import DrillStringComponentPropertiesGrid from './DrillStringComponentPropertiesGrid'
import CatalogPane from './Catalogs/CatalogPane'
import ConfirmDialog from 'components/common/ConfirmDialog'
import SplitPane from 'components/common/SplitPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import MwdProbeOrderModal from './MwdProbeOrderModal'
import useObjectQc from 'components/common/hooks/useObjectQc'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DrillStringComponentsGrid = ({ wellName, bhaNum, setStatus, isEngineering, masterGridApi }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const inputRow = useRef({})
  const selectedComponentRef = useRef({})
  const [showMwdProbeOrderModal, setShowMwdProbeOrder] = useState(false)
  const [showCatalogPane, setShowCatalogPane] = useState(false)
  const [resetCols, setResetCols] = useState(false)
  const { getCompTypesList, hasToolJoint, getVisibleColumns, getDropDownLists } = useDrillStringDropDowns(wellName)
  const { getUnitsText } = useUnits()
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [selectedComponent, setSelectedComponent] = useState(null)
  const [actualWellData, setActualWellData] = useState(null)
  const { getChecks, getCellStyle } = useObjectQc()
  const { getAgGridTheme } = useInnovaTheme()

  let checks = useMemo(() => getChecks(actualWellData?.operator, 'BHA'), [actualWellData, getChecks])

  const getActualWellData = useInnovaAxios({
    url: '/well/actualWellData',
  })

  const getBhaComponents = useInnovaAxios({
    url: '/well/drillString/getBha',
  })

  const addBhaComponent = useInnovaAxios({
    url: '/well/drillString/addBhaItem',
  })

  const updateBhaComponent = useInnovaAxios({
    url: '/well/drillString/updateBhaItem',
  })

  const deleteBhaComponent = useInnovaAxios({
    url: '/well/drillString/deleteBhaItem',
  })

  const updateComponentOrder = useInnovaAxios({
    url: '/well/drillString/updateBhaSequence',
  })

  const pasteBhaComponent = useInnovaAxios({
    url: '/well/drillString/pasteBhaItem',
  })

  useEffect(() => {
    fetchActualWellData()
  }, [wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const fetchActualWellData = async () => {
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return

    const res = await getActualWellData({ wellName: wellName })

    if (res?.error) return
    if (!_isMounted.current) return

    setActualWellData(res.data)
  }

  const fetchBhaComponents = async () => {
    if (bhaNum === null || bhaNum === undefined) return
    if (typeof bhaNum !== 'number') return
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return

    if (isLoading) return
    setLoading(true)
    const res = await getBhaComponents({ wellName: wellName, bhaNum: bhaNum })

    if (res?.error) return
    if (!_isMounted.current) return

    gridApi.current.setGridOption('rowData', Array.isArray(res?.data) ? res.data : [])
    setLoading(false)
  }

  useEffect(() => {
    _isMounted.current = true

    return () => {
      if (masterGridApi && masterGridApi?.gridOptions?.api) {
        masterGridApi.removeDetailGridInfo(`COMP_GRID-${bhaNum}`)
      }

      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchBhaComponents()
  }, [bhaNum]) // eslint-disable-line react-hooks/exhaustive-deps

  const onGridReady = (params) => {
    gridApi.current = params.api

    if (masterGridApi) {
      let detailId = `COMP_GRID-${bhaNum}`
      const detailGridInfo = {
        id: detailId,
        api: params.api,
      }

      masterGridApi.addDetailGridInfo(detailId, detailGridInfo)
    }

    fetchBhaComponents()
  }

  const handleComponentOrderChange = async (data) => {
    if (!Array.isArray(data)) return
    if (data.length === 0) return
    if (bhaNum === null || bhaNum === undefined) return
    if (typeof bhaNum !== 'number') return
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return
    if (isUpdating.current) return

    isUpdating.current = true

    let res = await updateComponentOrder({
      bhaItems: JSON.stringify(
        data.map((item) => {
          return { uid: item.uid, sequenceNo: item.sequenceNo }
        }),
      ),
      bhaNum: bhaNum,
      wellName: wellName,
    })
    isUpdating.current = false

    if (!_isMounted.current) return
    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    gridApi.current.applyTransaction({
      update: data,
    })

    updateCumulativeLength()
  }

  function onSelectionChanged() {
    const selectedRows = gridApi.current.getSelectedRows()
    if (!Array.isArray(selectedRows)) setSelectedComponent(null)
    if (selectedRows.length === 0) setSelectedComponent(null)
    setSelectedComponent(selectedRows[0])
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (params) => {
      if (params.node?.rowPinned === 'bottom') {
        gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
        handleAddRow()
        return
      }

      handleUpdate(params?.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    onRowDragEnd: (params) => {
      let bhaComponents = []
      params.api.forEachNode((node, i) => {
        let data = cloneDeep(node.data)
        data.sequenceNo = i
        bhaComponents.push(data)
      })

      if (bhaComponents.length === 0) return
      handleComponentOrderChange(bhaComponents)
    },
    loadingOverlayComponent: CustomLoadingOverlay,
    onSelectionChanged: onSelectionChanged,
  }

  const reqFields = useMemo(() => ['type', 'od', 'length'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const handleUpdate = useCallback(
    async (data) => {
      if (bhaNum === null || bhaNum === undefined) return
      if (typeof bhaNum !== 'number') return
      if (!wellName) return
      if (typeof wellName !== 'string') return
      if (wellName === '') return
      if (!data) return
      if (!data.hasOwnProperty('sequenceNo')) return
      if (isUpdating.current) return

      if (!hasToolJoint(data.type)) {
        data.tjOd = 0
        data.tjId = 0
      }

      let payload = htmlSymbolHandling(data)
      payload.bitJets = JSON.stringify(payload.bitJets)
      payload.jetString = ''

      isUpdating.current = true
      let res = await updateBhaComponent({
        ...payload,
        bhaNum: bhaNum,
        wellName: wellName,
      })
      isUpdating.current = false

      if (res?.error && setStatus) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      //Update connections
      let compAbove = null
      let compBelow = null
      gridApi.current.forEachNode((node) => {
        if (node.data.sequenceNo === data.sequenceNo - 1) {
          compBelow = node.data
        }

        if (node.data.sequenceNo === data.sequenceNo + 1) {
          compAbove = node.data
        }
      })

      let dataToUpdate = [data]
      if (compAbove) {
        compAbove.cnxBtm = data.cnxTop
        dataToUpdate.push(compAbove)

        const detailGrid = gridApi.current.getDetailGridInfo(`PROP_GRID-${compAbove.uid}`)
        if (detailGrid) {
          detailGrid.api.applyTransaction({
            update: [compAbove],
          })
        }
      }

      if (compBelow) {
        compBelow.cnxTop = data.cnxBtm
        dataToUpdate.push(compBelow)

        const detailGrid = gridApi.current.getDetailGridInfo(`PROP_GRID-${compBelow.uid}`)
        if (detailGrid) {
          detailGrid.api.applyTransaction({
            update: [compBelow],
          })
        }
      }

      gridApi.current.applyTransaction({
        update: dataToUpdate,
      })

      const detailGrid = gridApi.current.getDetailGridInfo(`PROP_GRID-${data.uid}`)
      if (detailGrid) {
        detailGrid.api.applyTransaction({
          update: [data],
        })
      }

      updateCumulativeLength()
    },
    [updateBhaComponent, bhaNum, setStatus, wellName, hasToolJoint],
  )

  const handlePaste = useCallback(
    async (data) => {
      if (bhaNum === null || bhaNum === undefined) return
      if (typeof bhaNum !== 'number') return
      if (!data) return
      if (!Array.isArray(data)) return
      if (data.length === 0) return
      if (!wellName) return
      if (typeof wellName !== 'string') return
      if (wellName === '') return
      if (isUpdating.current) return

      const fullPayload = []
      for (let i = 0; i < data.length; i++){
        if (!data[i].hasOwnProperty('sequenceNo')) continue

        if (!hasToolJoint(data[i].type)) {
          data[i].tjOd = 0
          data[i].tjId = 0
        }

        let payload = htmlSymbolHandling(data[i])
        payload.jetString = ''
        fullPayload.push(payload)
      }

      isUpdating.current = true
      let res = await pasteBhaComponent({
        drillStringComponents: JSON.stringify(fullPayload),
        bhaNum: bhaNum,
        wellName: wellName,
      })
      isUpdating.current = false

      if (res?.error && setStatus) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      //Update connections
      let compAbove = null
      let compBelow = null
      gridApi.current.forEachNode((node) => {
        if (node.data.sequenceNo === data.sequenceNo - 1) {
          compBelow = node.data
        }

        if (node.data.sequenceNo === data.sequenceNo + 1) {
          compAbove = node.data
        }
      })

      let dataToUpdate = data
      if (compAbove) {
        compAbove.cnxBtm = data.cnxTop
        dataToUpdate.push(compAbove)

        const detailGrid = gridApi.current.getDetailGridInfo(`PROP_GRID-${compAbove.uid}`)
        if (detailGrid) {
          detailGrid.api.applyTransaction({
            update: [compAbove],
          })
        }
      }

      if (compBelow) {
        compBelow.cnxTop = data.cnxBtm
        dataToUpdate.push(compBelow)

        const detailGrid = gridApi.current.getDetailGridInfo(`PROP_GRID-${compBelow.uid}`)
        if (detailGrid) {
          detailGrid.api.applyTransaction({
            update: [compBelow],
          })
        }
      }

      gridApi.current.applyTransaction({
        update: dataToUpdate,
      })

      const detailGrid = gridApi.current.getDetailGridInfo(`PROP_GRID-${data.uid}`)
      if (detailGrid) {
        detailGrid.api.applyTransaction({
          update: data,
        })
      }

      updateCumulativeLength()
    },
    [pasteBhaComponent, bhaNum, setStatus, wellName, hasToolJoint],
  )

  const updateCumulativeLength = () => {
    if (!gridApi.current) return

    let rowData = []

    gridApi.current.forEachNode((rowNode) => {
      rowData.push({ ...rowNode.data })
    })

    let cumLength = 0
    for (let i = 0; i < rowData.length; i++) {
      cumLength += rowData[i].length
      rowData[i].cumulativeLength = cumLength
    }

    gridApi.current.applyTransaction({
      update: rowData,
    })
  }

  const getNumRows = () => {
    if (!gridApi.current) return

    let count = 0
    gridApi.current.forEachNode((rowNode) => {
      count++
    })

    return count
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()
            if (!focusedCell?.column?.colDef.editable) return false
            params.data[focusedCell?.column?.colDef?.field] = ''
            handleUpdate(cloneDeep(params.data))
          }
        }
        return false
      },
      resizable: true,
      sortable: false,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [createPinnedCellPlaceholder, handleUpdate])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    setTimeout(() => gridApi.current?.autoSizeAllColumns(), 100)
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('bhaComponentsGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    let menuItems = [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('bhaComponentsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'Bha.xlsx',
            sheetName: 'Bha',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]

    if (params.node.data?.type === 'MWD/LWD') {
      menuItems.push('separator')

      menuItems.push({
        name: 'MWD Probe Order',
        disabled: false,
        action: () => {
          selectedComponentRef.current = params.node.data
          setShowMwdProbeOrder(true)
        },
        icon: '<span class="iconify" data-icon="streamline:ascending-number-order" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      })
    }

    return menuItems
  }

  const handleAddRow = async () => {
    if (bhaNum === null || bhaNum === undefined) return
    if (typeof bhaNum !== 'number') return
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    inputRow.current.wellName = wellName
    inputRow.current.bhaNum = bhaNum
    isAdding.current = true

    let res = await addBhaComponent(inputRow.current)
    isAdding.current = false

    if (res?.error && setStatus) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current.sequenceNo = res.data.sequenceNo
      inputRow.current.uid = res.data.uid
      inputRow.current.cnxTop = res.data.cnxTop
      inputRow.current.cnxBtm = res.data.cnxBtm
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })

      updateCumulativeLength()
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }

  const handleDelete = async () => {
    if (bhaNum === null || bhaNum === undefined) return
    if (typeof bhaNum !== 'number') return
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return
    if (!selectedComponentRef.current) return
    if (!selectedComponentRef.current.hasOwnProperty('sequenceNo')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteBhaComponent({
      ...selectedComponentRef.current,
      bhaNum: bhaNum,
      wellName: wellName,
    })
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      selectedComponentRef.current = null
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedComponentRef.current],
      })

      updateCumulativeLength()
    }

    selectedComponentRef.current = null
  }

  const componentIconRenderer = useCallback((params) => {
    if (!params.data) return null
    if (!params?.data?.hasOwnProperty('type')) return null
    return (
      <Box
        sx={{
          width: 30,
          height: 30,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: '2px',
        }}>
        <Avatar
          alt={params?.data?.type}
          src={getDrillStringIcon(params?.data?.type)}
          style={{ width: 30, height: 30, alignItems: 'center', justifyContent: 'center' }}
        />
      </Box>
    )
  }, [])

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => (params.node?.rowPinned !== 'bottom' ? onClickDelete(params.data) : handleAddRow())}
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  let columnDefs = useMemo(
    () => [
      {
        colId: 'dragHandle',
        headerName: '',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        maxWidth: 80,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: centerAlignCell,
        rowDrag: true,
      },
      {
        field: 'actions',
        colId: 'actions',
        maxWidth: 80,
        headerName: '',
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        filter: null,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        cellStyle: centerAlignCell,
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'componentProperties',
        colId: 'componentProperties',
        headerName: '',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        sortable: false,
        resizable: false,
        editable: false,
        maxWidth: 50,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: 'agGroupCellRenderer',
        cellStyle: centerAlignCell,
      },
      {
        field: 'componentIcon',
        colId: 'componentIcon',
        headerName: '',
        editable: false,
        filter: null,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        cellStyle: centerAlignCell,
        cellRenderer: componentIconRenderer,
      },
      {
        field: 'description',
        colId: 'description',
        headerName: 'Description',
        cellStyle: (params) => {
          return getCellStyle(params, checks, 'left')
        },
        valueFormatter: (params) => unescapeHtml(params.value),
        editable: (params) => params?.node?.rowPinned !== 'bottom',
      },
      {
        colId: 'type',
        field: 'type',
        headerName: 'Component Type',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          const toolTypes = getCompTypesList()
          return { values: Array.isArray(toolTypes) ? toolTypes : [] }
        },
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false
          if (params?.node?.rowPinned === 'bottom' && params.newValue === 'Drill Bit' && getNumRows() > 0) {
            setStatus({
              show: true,
              severity: 'error',
              message: `Drill bit must be first component`,
            })
            return false
          }

          if (params?.node?.rowIndex > 0 && params.newValue === 'Drill Bit') {
            setStatus({
              show: true,
              severity: 'error',
              message: `Drill bit must be first component`,
            })
            return false
          }

          let data = { ...params.data }
          data.type = params.newValue

          if (params.node?.rowPinned !== 'bottom') {
            handleUpdate(data)
            return true
          }

          inputRow.current[params.colDef.field] = params.newValue
          return true
        },
      },
      {
        field: 'od',
        colId: 'od',
        headerName: `OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        cellStyle: (params) => {
          return getCellStyle(params, checks, 'center')
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) {
            return 'Od....'
          }

          return params.value > 0 ? numberWithCommasDecimals(params.value, 3) : ''
        },
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false
          if (parseFloat(params.newValue) < parseFloat(params?.data?.id)) {
            setStatus({
              show: true,
              severity: 'error',
              message: `OD must be greater than id`,
            })
            return false
          }

          let data = { ...params.data }
          data.od = params.newValue

          if (params.node?.rowPinned !== 'bottom') {
            handleUpdate(data)
            return true
          }

          inputRow.current[params.colDef.field] = params.newValue
          return true
        },
      },
      {
        field: 'id',
        colId: 'id',
        headerName: `ID (${getUnitsText(UNITS_FOR.Diameter)})`,
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: (params) => {
          return getCellStyle(params, checks, 'center')
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => (params.value > 0 ? numberWithCommasDecimals(params.value, 3) : ''),
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false
          if (parseFloat(params.newValue) > parseFloat(params?.data?.od)) {
            setStatus({
              show: true,
              severity: 'error',
              message: `ID must be less than od`,
            })
            return false
          }

          let data = { ...params.data }
          data.id = params.newValue
          handleUpdate(data)
          return true
        },
      },
      {
        field: 'tjOd',
        colId: 'tjOd',
        headerName: `TJ OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        cellStyle: (params) => {
          let style = getCellStyle(params, checks, 'center')
          if (!hasToolJoint(params.data?.type)) style.backgroundColor = ''
          return style
        },
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return false
          return hasToolJoint(params.data?.type)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => (params.value > 0 ? numberWithCommasDecimals(params.value, 3) : ''),
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false
          if (parseFloat(params.newValue) < parseFloat(params?.data?.tjId)) {
            setStatus({
              show: true,
              severity: 'error',
              message: `TJ OD must be greater than TJ ID`,
            })
            return false
          }

          let data = { ...params.data }
          data.tjOd = params.newValue
          handleUpdate(data)
          return true
        },
      },
      {
        field: 'tjId',
        colId: 'tjId',
        headerName: `TJ ID (${getUnitsText(UNITS_FOR.Diameter)})`,
        cellStyle: (params) => {
          let style = getCellStyle(params, checks, 'center')
          if (!hasToolJoint(params.data?.type)) style.backgroundColor = ''
          return style
        },
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return false

          return hasToolJoint(params.data?.type)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => (params.value > 0 ? numberWithCommasDecimals(params.value, 3) : ''),
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false
          if (parseFloat(params.newValue) > parseFloat(params?.data?.tjOd)) {
            setStatus({
              show: true,
              severity: 'error',
              message: `TJ ID must be less than TJ OD`,
            })
            return false
          }

          let data = { ...params.data }
          data.tjId = params.newValue
          handleUpdate(data)
          return true
        },
      },
      {
        field: 'weight',
        colId: 'weight',
        headerName: `Wt (${getUnitsText(UNITS_FOR.UnitWeight)})`,
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: (params) => {
          return getCellStyle(params, checks, 'center')
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => (params.value > 0 ? numberWithCommasDecimals(params.value, 3) : ''),
      },
      {
        field: 'length',
        colId: 'length',
        headerName: `Length (${getUnitsText(UNITS_FOR.Depth)})`,
        cellStyle: (params) => {
          return getCellStyle(params, checks, 'center')
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000000,
          precision: 3,
        },
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) {
            return 'Length....'
          }

          return params.value > 0 ? numberWithCommasDecimals(params.value, 3) : ''
        },
      },
      {
        field: 'cumulativeLength',
        colId: 'cumulativeLength',
        headerName: `Cum. Length (${getUnitsText(UNITS_FOR.Depth)})`,
        editable: false,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => (params.value > 0 ? numberWithCommasDecimals(params.value, 3) : ''),
      },
      {
        field: 'nonMag',
        colId: 'nonMag',
        headerName: 'Non Mag',
        valueGetter: (params) => (params?.data?.nonMag ? params?.data?.nonMag : false),
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        cellEditor: 'agCheckboxCellEditor',
        cellRendererSelector: (params) => {
          if (params?.node?.rowPinned !== 'bottom') {
            return { component: 'agCheckboxCellRenderer' }
          }
          return { component: () => <></> }
        },
      },
      {
        field: 'sn',
        colId: 'sn',
        headerName: 'Serial#',
        valueFormatter: (params) => unescapeHtml(params.value),
        editable: (params) => params?.node?.rowPinned !== 'bottom',
      },
    ],
    [getUnitsText, componentIconRenderer, getCompTypesList, hasToolJoint, setStatus, handleUpdate], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const icons = useMemo(() => {
    return {
      groupExpanded:
        '<img src="https://api.iconify.design/ic/baseline-settings.svg?color=blue" style="height: 25px; width: 25px; margin-left: 8px; border-radius: 50%; background: white"/>',
      groupContracted:
        '<img src="https://api.iconify.design/ic/baseline-settings.svg?color=%23429ceb" style="height: 25px; width: 25px; margin-left: 8px; border-radius: 50%"/>',
    }
  }, [])

  const DetailCellRenderer = forwardRef((params, ref) => {
    useImperativeHandle(ref, () => {
      return {
        refresh() {
          return true
        },
      }
    })

    return (
      <Box sx={{ height: 200 }}>
        <DrillStringComponentPropertiesGrid
          getCellStyle={getCellStyle}
          checks={checks}
          wellName={wellName}
          componentData={params?.data}
          setStatus={setStatus}
          visibleColumns={getVisibleColumns(params?.data?.type, isEngineering)}
          handleUpdateParentGrid={handleUpdate}
          dropDownLists={getDropDownLists()}
          masterGridApi={gridApi.current}
        />
      </Box>
    )
  })

  const convertCompCatalogItemToBhaComponent = (data) => {
    if (data.toolType < 0 || data.toolType > 17) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'invalid tool type',
      })
      return null
    }

    return {
      description: data.description,
      od: data.od,
      id: data.id,
      tjOd: data.tjOd,
      tjId: data.tjId,
      length: data.length,
      weight: data.adjWeight,
      type: data.compType,
      cnxTop: data.connection,
      cnxBtm: data.connection,
      pressureDrop: data.pressureDrop,
      tensileYield: data.tensileYield,
      torsionalYield: data.torsionalYield,
      burst: data.burst,
      collapse: data.collapse,
      matGrade: data.materialGrade,
    }
  }

  const convertInventoryItemToBhaComponent = (data) => {
    let componentExists = false
    gridApi.current.forEachNode((node) => {
      if (
        node.data.description === data.description &&
        node.data.sn === data.serialNum &&
        node.data.length === data.length
      ) {
        componentExists = true
      }
    })

    if (componentExists) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'component already exists',
      })
      return null
    }

    let unitWeight = 0
    if (typeof data.weight === 'number' && typeof data.length === 'number' && data.weight > 0 && data.length > 0) {
      unitWeight = data.weight / data.length
    }

    if (data.toolType < 0 || data.toolType > 17) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'invalid tool type',
      })
      return null
    }

    return {
      description: data.description,
      sn: data.serialNum,
      od: data.od,
      id: data.id,
      length: data.length,
      make: data.make,
      model: data.model,
      weight: unitWeight,
      type: data.toolType,
      cnxTop: data.connectionTop,
      cnxBtm: data.connectionBottom,
      assetId: data.assetId,
    }
  }

  const gridDrop = async (event) => {
    event.preventDefault()
    if (!gridApi.current) return
    if (bhaNum === null || bhaNum === undefined) return
    if (typeof bhaNum !== 'number') return
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return
    if (isAdding.current) return
    if (isUpdating.current) return

    const jsonData = event.dataTransfer.getData('application/json')
    const data = JSON.parse(jsonData)

    if (!data) return

    let dropType = data.hasOwnProperty('onLocation')
      ? 'inventory'
      : data.hasOwnProperty('maxOd')
      ? 'stopCollar'
      : data.hasOwnProperty('operatingCsa')
      ? 'centralizer'
      : 'compCatalog'

    if (dropType === 'compCatalog' && data.recordId == null) return
    if (dropType !== 'compCatalog' && data.uid == null) return

    if (dropType === 'stopCollar' || dropType === 'centralizer') {
      if (!selectedComponent) return
      if (selectedComponent.type !== 'Casing/Tubing' && selectedComponent.type !== 'Liner') return

      if (dropType === 'stopCollar') {
        selectedComponent.stopCollarUid = data.uid
        selectedComponent.stopCollarDesc = data.description
      }

      if (dropType === 'centralizer') {
        selectedComponent.centralizerUid = data.uid
        selectedComponent.centralizerDesc = data.name
      }

      isUpdating.current = true
      let res = await updateBhaComponent({
        ...selectedComponent,
        wellName: wellName,
      })
      isUpdating.current = false

      if (res?.error && setStatus) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res?.error) {
        gridApi.current.applyTransaction({
          update: [selectedComponent],
        })

        const detailGrid = gridApi.current.getDetailGridInfo(`PROP_GRID-${selectedComponent.uid}`)
        if (!detailGrid) return true

        detailGrid.api.applyTransaction({
          update: [selectedComponent],
        })
      }

      return
    }

    let newBhaComponent =
      dropType === 'inventory' ? convertInventoryItemToBhaComponent(data) : convertCompCatalogItemToBhaComponent(data)

    if (!newBhaComponent) return

    isAdding.current = true
    let res = await addBhaComponent({
      ...newBhaComponent,
      bhaNum: bhaNum,
      wellName: wellName,
    })
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    newBhaComponent.sequenceNo = res.data.sequenceNo
    newBhaComponent.uid = res.data.uid
    newBhaComponent.bhaNum = res.data.bhaNum
    newBhaComponent.bhaNumRep = res.data.bhaNumRep

    gridApi.current.applyTransaction({
      add: [newBhaComponent],
    })

    updateCumulativeLength()
  }

  const gridDragOver = (event) => {
    const dragSupported = event.dataTransfer.types.length

    if (dragSupported) {
      event.dataTransfer.dropEffect = 'copy'
      event.preventDefault()
    }
  }

  const onClickDelete = (data) => {
    selectedComponentRef.current = cloneDeep(data)
    setConfirm({
      show: true,
      title: 'Delete BHA',
      text: `Are you sure you want to delete ${data.description ? unescapeHtml(data.description) : data.type}?`,
    })
  }

  const processDataFromClipboard = (params) => {
    if (!params.hasOwnProperty('data') && !Array.isArray(params.data) && params.data?.length < 1) {
      return null
    }
    let data = params.data?.map((dataRow) => {
      return dataRow.slice()
    })

    // delete the empty last row in the clipboard data, if one exists
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }

    for (let i = 0; i < data.length; i += 1) {
      for (let j = 0; j < data[i].length; j++) {
        if (typeof data[i][j] === 'string') continue
        data[i][j] = data[i][j].replace(',', '')
      }
    }

    // make sure there's data to process after we remove the typical empty row
    if (data.length < 1) return null

    const lastIndex = gridApi.current.getDisplayedRowCount() - 1
    const focusedCell = gridApi.current.getFocusedCell()

    const rowsToUpdate = []
    let curRowIndex = focusedCell.rowIndex
    if (focusedCell?.rowPinned !== 'bottom') {
      for (let i = 0; i < data.length; i++) {
        const row = data[i].slice(0, 1)[0]
        let rowData = row.split(',')
        if (curRowIndex > lastIndex) break
        if (!Array.isArray(rowData)) continue

        let drillStringData = cloneDeep(gridApi.current.getDisplayedRowAtIndex(curRowIndex))
        let currentColumn = focusedCell.column

        for (let j = 0; j < rowData.length; j++) {
          if (!currentColumn) continue
          drillStringData.data[currentColumn.colDef.field] = rowData[j]
          if (currentColumn.colDef.cellDataType === 'number') {
            drillStringData.data[currentColumn.colDef.field] = parseFloat(rowData[j])
          } 
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        rowsToUpdate.push(cloneDeep(drillStringData.data))
        curRowIndex++
      }
    }

    if (rowsToUpdate.length > 0) {
      handlePaste(rowsToUpdate)
    }

    return null
  }

  return (
    <React.Fragment>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => handleDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showMwdProbeOrderModal ? (
        <MwdProbeOrderModal
          bhaNum={selectedComponentRef.current.bhaNum}
          wellName={wellName}
          onClose={() => setShowMwdProbeOrder(false)}
        />
      ) : null}
      <SplitPane
        split='vertical'
        defaultSize={'calc(100% - 25px)'}
        size={showCatalogPane ? '70%' : 'calc(100% - 25px)'}
        allowResize={showCatalogPane}
        style={{
          height: isEngineering ? 'calc(100% - 25px)' : '100%',
          width: `100%`,
          maxWidth: `100%`,
        }}>
        <div
          className={getAgGridTheme()}
          id='drill-string-grid'
          style={{ width: '100%', height: '100%' }}
          onDragOver={gridDragOver}
          onDrop={(e) => gridDrop(e)}>
          <AgGridReact
            columnDefs={sortColDefs(columnDefs, 'bhaComponentsGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            loading={isLoading}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            rowDragManaged={true}
            onGridReady={onGridReady}
            rowSelection={'single'}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            masterDetail={true}
            icons={icons}
            detailCellRenderer={DetailCellRenderer}
            detailRowAutoHeight={true}
            processDataFromClipboard={processDataFromClipboard}
          />
        </div>
        <CatalogPane
          wellName={wellName}
          selectedComponent={selectedComponent}
          showHideCatalog={() => setShowCatalogPane(!showCatalogPane)}
          catalogVisible={showCatalogPane}
        />
      </SplitPane>
    </React.Fragment>
  )
}

export default DrillStringComponentsGrid
