import React, { useState, useRef, useEffect } from 'react'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Checkbox,
  Tooltip,
  Snackbar,
  Alert,
  CircularProgress,
  Backdrop,
} from '@mui/material'
import { appColors } from 'utils'
import { styled } from '@mui/styles'
import FileTypeIconSelector from 'components/common/FileTypeIconSelector'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import useExternalFiles from 'components/common/hooks/useExternalFiles'
import { Icon as Iconify } from '@iconify/react'
import AddIcon from '@mui/icons-material/Add'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import axios from 'axios'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import fileDownload from 'js-file-download'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import SearchBar from 'components/common/SearchBar'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  color: '#fff',
  zIndex: theme.zIndex.drawer + 1,
}))

const StyledCheckbox = styled(Checkbox)({
  maxHeight: '30px',
  visibility: 'hidden',
  '&.Mui-checked': {
    visibility: 'visible',
  },
})

const FileManager = () => {
  const _isMounted = useRef(false)
  const rawData = useRef([])
  const [data, setData] = useState([])
  const [currentFolder, setCurrentFolder] = useState(['/root'])
  const [selItems, setSelItems] = useState([])
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const { getAccessTokenSilently, user, getDatabaseOrg } = useInnovaAuth()
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const displayedContents = useRef([])
  const selectMultipleRef = useRef(null)
  const [extFileObjects, setExtFileObjects] = useState([])
  const { uploadExternalFiles } = useExternalFiles(currentFolder[currentFolder.length - 1])
  const currentWellName = useRef('')
  const [showWait, setShowWait] = useState(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const [searchText, setSearchText] = useState('')
  const { searchBarStyle, theme, getChartBackColor, getTextColor } = useInnovaTheme()

  const StyledListItem = styled(ListItem)({
    borderBottom: '1px solid rgba(200, 200, 200, 0.3)',
    '&:hover': {
      cursor: 'pointer',
      background: theme === 'dark' ? '#4D5156' : '#f0f0f0',
      '& .MuiCheckbox-root': {
        visibility: 'visible',
      },
    },
  })

  const getExternalFiles = useAxiosGzip({
    url: '/files/getExternalFiles',
  })

  const downloadExternalFiles = useInnovaAxios({
    url: '/files/downloadExternalFiles',
    responseType: 'blob',
  })

  const downloadSingleExternalFile = useInnovaAxios({
    url: '/files/downloadSingleExternalFile',
    responseType: 'blob',
  })

  useEffect(() => {
    if (extFileObjects?.length > 0 && _isMounted.current) {
      onUploadFiles()
    }
  }, [extFileObjects]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.innovaAdminFileManagerPageKey)
    fetchExternalFiles()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchExternalFiles = async () => {
    const response = await getExternalFiles()

    if (_isMounted.current) {
      if (response?.data) {
        rawData.current = response.data.children
        setData(response.data.children)
      } else {
        rawData.current = []
        setData([])
      }
    }
  }

  const handleFolderClick = (e, path) => {
    if (selItems.length > 0) {
      setSelItems([])
    }
    if (currentFolder?.length === 1) {
      let wellName = path.split('/')[2] //critical for API payloads
      currentWellName.current = wellName
    }
    setCurrentFolder([...currentFolder, path])
  }

  const handleFileClick = (e, index) => {
    if (selItems.length > 0) setSelItems([])
    handleDownloadFiles(e, index)
  }

  const onItemSelect = (event, id) => {
    let newSelectedItems = [...selItems]

    if (getChecked(id) === false) {
      newSelectedItems.push(id)
      if (_isMounted.current === true) setSelItems(newSelectedItems)
    } else {
      if (Array.isArray(newSelectedItems) === true) {
        const index = newSelectedItems.indexOf(id)
        if (index > -1) newSelectedItems.splice(index, 1)
      } else {
        newSelectedItems = []
      }

      if (_isMounted.current === true) setSelItems(newSelectedItems)
    }
  }

  const onSelectAll = () => {
    if (!_isMounted.current) return

    if (displayedContents.current?.length === selItems?.length && selItems?.length > 0) {
      setSelItems([])
    } else {
      setSelItems(displayedContents.current?.map((_, index) => index))
    }
  }

  const getChecked = (id) => {
    if (!selItems) return false
    if (Array.isArray(selItems) === false) return false
    if (selItems.includes(id) === true) return true

    return false
  }

  const renderListItem = (props) => {
    const { index, style } = props
    const item = displayedContents.current[index]
    return (
      <StyledListItem style={style} key={`list-item-${index}`}>
        <StyledCheckbox
          onClick={(e) => {
            e.stopPropagation() // eliminate bubbling for folder / file onClick
          }}
          edge='start'
          onChange={(event) => {
            onItemSelect(event, index)
          }}
          checked={getChecked(index)}
          checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
          indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
        />
        <ListItemIcon>
          <FileTypeIconSelector
            file={item.isDir ? 'folder' : item.description}
            style={{ height: '30px', width: '30px', color: item.isDir ? '#FFDA6A' : '#429CEB' }}
          />
        </ListItemIcon>
        <ListItemText sx={{ color: theme === 'dark' ? 'white' : '#000' }}>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box
              sx={{
                flex: 1,
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={(e) =>
                displayedContents.current[index].children !== null
                  ? handleFolderClick(e, item.path)
                  : handleFileClick(e, index)
              }>
              {item.isDir ? item.name : item.description?.replace(/XX-BIT_IMAGE-XX/g, '')}
            </Box>
            <Box sx={{ flex: 1, color: theme === 'dark' ? appColors.headerTextColor : '#000' }}>
              {item.isDir ? item.description : item.name}
            </Box>
          </Box>
        </ListItemText>
      </StyledListItem>
    )
  }

  const processWellsData = (data, path = '') => {
    if (data === null) data = []

    if (searchText.length > 0) {
      let searchLwr = searchText.toLowerCase()
      data = data.filter((item) => {
        return item.name.toLowerCase()?.includes(searchLwr) || item.description.toLowerCase()?.includes(searchLwr)
      })
    }

    let listData = [...data]
    let sortedData = listData.sort(function (a, b) {
      return b.isDir - a.isDir
    })
    let filteredData = sortedData.filter((item) => {
      if (item.description === 'Well Files' || currentFolder.indexOf(item.path.replace(`/${item.name}`, '')) > 0) {
        return item
      } else {
        return null
      }
    })
    displayedContents.current = filteredData
    if (filteredData.length > 0) {
      return (
        <Box
          sx={{
            display: 'flex',
            overflowY: 'auto',
            overflowX: 'hidden',
            height: 'calc(70vh)',
            maxHeight: 'calc(70vh)',
            backgroundColor: 'itemBackground',
            width: '100%',
          }}>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <FixedSizeList
                  className='fixedSizeListScrollbar'
                  style={{ display: 'flex', borderRadius: '4px' }}
                  height={height}
                  width={width}
                  itemCount={filteredData.length}
                  itemSize={65}>
                  {renderListItem}
                </FixedSizeList>
              )
            }}
          </AutoSizer>
          <StyledBackdrop open={showWait}>
            <CircularProgress color='inherit' />
          </StyledBackdrop>
        </Box>
      )
    } else {
      return null
    }
  }

  function findObjectByPath(path, data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].path === path) {
        return data[i]
      }
      if (data[i].isDir && data[i].children) {
        const result = findObjectByPath(path, data[i].children)
        if (result) {
          return result
        }
      }
    }
    return null
  }

  const getCurrentFolderContents = () => {
    if (currentFolder.length === 1) {
      const wellFiles = rawData.current.find((item) => item.description === 'Well Files')
      if (wellFiles !== undefined && wellFiles.children !== null) {
        return processWellsData(wellFiles.children)
      }
    } else {
      let currentFolderObj = findObjectByPath(currentFolder[currentFolder.length - 1], rawData.current)
      if (currentFolderObj !== undefined && currentFolderObj.children !== null) {
        return processWellsData(currentFolderObj.children)
      }
    }
    return null
  }

  const handleDirectoryNavigation = (path) => {
    if (currentFolder.length > 1) {
      let slicedArr = [...currentFolder]
      let newSliced = slicedArr.slice(0, slicedArr.indexOf(path) + 1)
      setCurrentFolder(newSliced)
    }
  }

  const handleDeleteSelectedItems = () => {
    setConfirm({
      show: true,
      title: 'Delete Files',
      text: `Are you sure you want to delete?`,
    })
  }

  const getFileNameFromHeader = (disposition) => {
    var filename = ''
    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      var matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '')
      }
    }

    return filename?.replace(/XX-BIT_IMAGE-XX/g, '')
  }

  const handleDownloadFiles = async (e, index) => {
    if (displayedContents.current.length < 1) return
    let fileNames = []
    if (!index || index < 0) {
      // selected file(s) download
      if (selItems.length >= 1) {
        selItems.forEach((item) => {
          if (displayedContents.current[item] !== undefined) {
            fileNames.push(displayedContents.current[item])
          }
        })
      }
    } else {
      // single-click file download
      if (displayedContents.current[index] !== undefined) {
        fileNames.push(displayedContents.current[index])
      }
    }
    if (fileNames.length < 1) return

    let splitFolderPath = currentFolder[currentFolder.length - 1].split('/').slice(1)
    let subFolder = splitFolderPath[splitFolderPath.length - 1]
    let folderName = 'root'

    if (splitFolderPath.length > 1) {
      folderName = splitFolderPath[1]
    }

    if (subFolder === folderName) {
      subFolder = ''
    }

    let payload = {
      folder: folderName,
      subFolder: subFolder,
      fileNames: JSON.stringify(fileNames),
    }

    let response = null
    if (!index || index < 0) {
      response = await downloadExternalFiles(payload)
    } else {
      response = await downloadSingleExternalFile(payload)
    }

    if (!response.error) {
      setStatus({ show: true, severity: 'success', message: 'Downloaded successfully' })
      const fileName = getFileNameFromHeader(response.headers['content-disposition'])
      let blobData = new Blob([response.data])
      fileDownload(blobData, fileName)
    } else {
      const errMsg = 'Download failed: ' + response?.error?.message
      setStatus({ show: true, severity: 'error', message: errMsg })
    }

    setSelItems([])
    return
  }

  const deleteFiles = async (deletedFileNames) => {
    let formData = new FormData()

    const accessToken = await getAccessTokenSilently()

    let splitFolderPath = currentFolder[currentFolder.length - 1].split('/').slice(1)
    let subFolder = splitFolderPath[splitFolderPath.length - 1]
    let folderName = 'root'

    if (splitFolderPath.length > 1) {
      folderName = splitFolderPath[1]
    }

    if (subFolder === folderName) {
      subFolder = ''
    }

    let options = {
      url: '/files/deleteExternalFiles',
    }

    formData.append('userName', user?.name)
    formData.append('databaseOrg', getDatabaseOrg())
    formData.append('productKey', process.env.REACT_APP_ICP_PRODUCT_KEY)
    formData.append('folder', folderName)
    formData.append('subFolder', subFolder)
    formData.append('fileNames', deletedFileNames)

    const res = await axios({
      method: 'post',
      baseURL: process.env.REACT_APP_ICP_API,
      timeout: 60000,
      ...options,
      data: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return false
    }

    setStatus({ show: true, severity: 'success', message: 'Files deleted' })
    return true
  }

  const confirmDelete = async () => {
    if (selItems.length >= 1 && displayedContents.current.length >= 1) {
      let filesArr = []

      selItems.forEach((item) => {
        if (displayedContents.current[item] !== undefined) {
          filesArr.push(displayedContents.current[item])
        }
      })

      const res = await deleteFiles(JSON.stringify(filesArr))
      if (!res) return
        
      setSelItems([])
      fetchExternalFiles()
    }
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const onUploadFiles = async () => {
    let uploadFiles = []
    let fileErrors = []

    extFileObjects.forEach((file, i) => {
      if (file.file.name !== 'empty') {
        if (file.file.size > 10000000) {
          fileErrors.push(file.file)
        }
        uploadFiles.push(file.file)
      }
    })
    if (fileErrors?.length > 0) {
      return setStatus({
        show: true,
        severity: 'error',
        message: `File size exceeded 10MB: ${fileErrors.map((file) => ` ${file.name} (${file.size})`)}`,
      })
    }

    setShowWait(true)
    const result = await uploadExternalFiles(
      uploadFiles,
      currentFolder[currentFolder.length - 1],
      currentWellName.current,
    )
    if (result?.response) {
      setShowWait(false)
      setStatus({ show: true, severity: 'success', message: 'Files uploaded' })
      fetchExternalFiles()
    } else if (result?.response === false && result.hasOwnProperty('error')) {
      setShowWait(false)
      setStatus({ show: true, severity: 'error', message: result.error })
    } else {
      setShowWait(false)
      setStatus({ show: true, severity: 'error', message: 'Files upload failed' })
    }
  }

  const onSelectMultipleImages = (event) => {
    let newFileObjs = []
    for (let i = 0; i < event.target.files.length; i++) {
      const newFile = {
        file: event.target.files[i],
      }
      newFileObjs.push(newFile)
    }
    event.target.value = null
    setExtFileObjects(newFileObjs)
  }

  const onClickSelectFiles = () => {
    if (selItems?.length > 0) setSelItems([])
    selectMultipleRef.current.click()
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100% - ${leftPos}px)`,
        maxWidth: '100%',
      }}>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={status.message.includes('size exceeded') ? null : 2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
      <input
        style={{ display: 'none' }}
        ref={selectMultipleRef}
        type='file'
        multiple={true}
        accept='image/png, image/jpeg, image/bmp, application/pdf, text/csv, text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip'
        onChange={onSelectMultipleImages}
      />
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          background: getChartBackColor(),
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '5px',
        }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          {currentFolder.length > 1 && !currentFolder[currentFolder.length - 1].includes('EXTERNAL_IMAGE') ? (
            <Box sx={{ margin: '8px 8px 0px 8px', '&:hover': { cursor: 'pointer' } }}>
              <Tooltip
                title='Add Files'
                placement='bottom'
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: 'rgb(19,62,96)',
                      fontSize: '12px',
                      fontFamily: 'Roboto',
                    },
                  },
                }}>
                <AddIcon fontSize='large' onClick={() => onClickSelectFiles()} />
              </Tooltip>
            </Box>
          ) : null}
          <Box
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              width: '300px',
              padding: '5px',
            }}>
            <SearchBar
              value={searchText}
              onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
              onCancelSearch={() => setSearchText('')}
              style={searchBarStyle()}
            />
          </Box>
          <Box
            sx={{
              margin: '8px 8px 0px 8px',
              marginRight: '16px',
              fontSize: '30px',
              '&:hover': { cursor: selItems.length > 0 ? 'pointer' : 'default' },
            }}>
            <Tooltip
              title={selItems.length > 0 ? 'Download Selected' : null}
              placement='bottom'
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: 'rgb(19,62,96)',
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                  },
                },
              }}>
              <Box onClick={selItems.length > 0 ? handleDownloadFiles : null}>
                <Iconify
                  icon='material-symbols:download-sharp'
                  style={{
                    color: selItems.length > 0 ? 'rgb(66,156,235)' : 'rgb(66,156,235, 0.4)',
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
          <Box
            sx={{
              margin: '8px 8px 0px 8px',
              marginRight: '16px',
              fontSize: '30px',
              '&:hover': { cursor: selItems.length > 0 ? 'pointer' : 'default' },
            }}>
            <Tooltip
              title={selItems.length > 0 ? 'Delete Selected' : null}
              placement='bottom'
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: 'rgb(19,62,96)',
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                  },
                },
              }}>
              <Box onClick={selItems.length > 0 ? handleDeleteSelectedItems : null}>
                <Iconify
                  icon='fa-regular:trash-alt'
                  style={{
                    color: selItems.length > 0 ? 'rgba(211,47,47)' : 'rgba(211,47,47, 0.4)',
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
          }}>
          <Box
            sx={{
              px: 1,
              fontSize: currentFolder.length === 1 ? '18px' : '16px',
              color:
                currentFolder.length === 1 ? getTextColor() : theme === 'dark' ? appColors.headerTextColor : '#000',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              setSelItems([])
              setCurrentFolder(['/root'])
              currentWellName.current = ''
            }}>
            Home
          </Box>
          {currentFolder.length > 1 &&
            currentFolder.map((path, idx) => {
              if (idx > 0) {
                let renderedPathName = path.split('/')
                return (
                  <Box
                    key={`path-name-${idx}`}
                    sx={{
                      px: 1,
                      color: theme === 'dark' ? appColors.headerTextColor : '#000',
                      display: 'flex',
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}>
                    <Box sx={{ pr: 2 }}>{'>'}</Box>
                    <Box
                      sx={{
                        fontSize: idx === currentFolder.length - 1 ? '18px' : '16px',
                        color:
                          idx === currentFolder.length - 1
                            ? getTextColor()
                            : theme === 'dark'
                            ? appColors.headerTextColor
                            : '#000',
                      }}
                      onClick={() => handleDirectoryNavigation(path)}>
                      {renderedPathName[renderedPathName.length - 1]}
                    </Box>
                  </Box>
                )
              } else {
                return null
              }
            })}
        </Box>
        <ListItem
          sx={{
            marginTop: '8px',
            borderBottom: '1px solid rgba(200, 200, 200, 0.3)',
            pb: 0,
            '&:hover': {
              cursor: 'default',
              '& .MuiCheckbox-root': {
                visibility: 'visible',
              },
            },
          }}>
          <StyledCheckbox
            onClick={(e) => {
              e.stopPropagation() // eliminate bubbling for folder / file onClick
            }}
            edge='start'
            onChange={(event) => {
              onSelectAll()
            }}
            checked={displayedContents.current?.length === selItems?.length && selItems?.length > 0}
            checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
            indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
            icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          />
          <ListItemIcon>
            <FileTypeIconSelector style={{ height: '30px', width: '30px', color: '#429CEB' }} />
          </ListItemIcon>
          <ListItemText sx={{ color: theme === 'dark' ? 'white' : '#000' }}>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box sx={{ flex: 1 }}>Name</Box>
              <Box sx={{ flex: 1, paddingRight: '12px' }}>Description</Box>
            </Box>
          </ListItemText>
        </ListItem>
        <Box sx={{ height: '80vh' }}>
          {currentFolder.length === 1 ? processWellsData(data) : getCurrentFolderContents()}
        </Box>
      </Box>
    </Box>
  )
}

export default FileManager
