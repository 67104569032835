import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { appColors } from 'utils'
import ConfirmDialog from 'components/common/ConfirmDialog'
import Alert from '@mui/material/Alert'
import SearchBar from 'components/common/SearchBar'
import DropDownPicker from 'components/common/DropDownPicker'
import {
  sortColDefs,
  htmlSymbolHandling,
  relativeTime,
  isValidDate,
  CustomLoadingOverlay,
  dateComparator,
  isDateLessThan,
  getStringId,
} from 'components/common/AgGridUtils'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { saveItemToLS, getItemFromLS } from 'utils/localStorage'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Icon as Iconify } from '@iconify/react'
import { styled } from '@mui/styles'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import useCrmDropDowns from 'components/common/hooks/useCrmDropDowns'
import useInnovaAdminDropDowns from 'components/common/hooks/useInnovaAdminDropDown'
import { cloneDeep, debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const getInitialGroupRows = () => {
  let storedValue = getItemFromLS('crmSystemGroupRows', 'groupRows')
  if (storedValue === null || storedValue === undefined) return false
  return storedValue
}

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledPopupMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const CrmSystemGrid = ({ isLoading, setIsLoading, setFilteredData, showChart, setShowChart }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const inputRow = useRef({})
  const selectedInteraction = useRef(null)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [groupRows, setGroupRows] = useState(getInitialGroupRows())
  const [crmData, setCrmData] = useState([])
  const [dateFilter, setDateFilter] = useState('Current Year')
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [adminDropDowns] = useInnovaAdminDropDowns(false)
  const [dropDowns] = useCrmDropDowns(false)
  const { getAgGridTheme, searchBarStyle, getChartBackColor } = useInnovaTheme()

  const getData = useInnovaAxios({
    url: '/crm/getCrmInteractions',
  })

  const duplicateCrmInteraction = useInnovaAxios({
    url: '/crm/duplicateCrmInteraction',
  })

  const deleteCrmInteraction = useInnovaAxios({
    url: '/crm/deleteCrmInteraction',
  })

  const addCrmInteraction = useInnovaAxios({
    url: '/crm/addCrmInteraction',
  })

  const updateCrmInteraction = useInnovaAxios({
    url: '/crm/updateCrmInteraction',
  })

  const reqFields = ['customerName', 'description', 'createdBy', 'dateTime']

  function createPinnedCellPlaceholder({ colDef }) {
    if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  function isPinnedRowDataCompleted() {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current?.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }

  const handleAddRow = async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    isAdding.current = true
    inputRow.current.active = true
    inputRow.current = htmlSymbolHandling(inputRow.current)

    let res = await addCrmInteraction(inputRow.current)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current.uid = res.data.uid
      gridApi.current.applyTransaction({
        add: [inputRow.current],
        addIndex: getInsertIndex(inputRow.current),
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }

  const defaultColDef = {
    resizable: true,
    sortable: true,
    autoHeight: true,
    wrapText: false,
    editable: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      excelMode: 'windows',
    },
    valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })

  const fetchCrmData = async () => {
    if (isLoading) return

    if (_isMounted.current) setIsLoading(true)
    const res = await getData()

    if (!_isMounted.current) return
    setIsLoading(false)

    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setCrmData(Array.isArray(res.data) ? res.data : [])
  }

  useEffect(() => {
    _isMounted.current = true
    fetchCrmData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }
  }, [dateFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('uid')) return
      if (!data.followUpRequired) data.followUpDate = ''

      if (isUpdating.current) return
      data = htmlSymbolHandling(data)

      isUpdating.current = true
      let res = await updateCrmInteraction(data)
      isUpdating.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res?.error) {
        gridApi.current.applyTransaction({
          update: [data],
        })
      }
    },
    [updateCrmInteraction],
  )

  const dateFilterComparator = useCallback(dateComparator, [])
  const relativeTimeFormatter = useCallback(relativeTime, [])

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (value.indexOf('T') < 0) value += 'T00:00:01'
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const leftAlignedCell = useMemo(() => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }
  }, [])

  const handleDelete = useCallback(async () => {
    if (!selectedInteraction.current) return
    if (!selectedInteraction.current.hasOwnProperty('uid')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteCrmInteraction(selectedInteraction.current)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        remove: [selectedInteraction.current],
      })
    }

    selectedInteraction.current = null
  }, [deleteCrmInteraction])

  const onClickDelete = useCallback(() => {
    setConfirm({
      show: true,
      title: 'Delete Interaction',
      text: `Are you sure you want to delete ${selectedInteraction.current.customerName}?`,
    })
  }, [])

  const getInsertIndex = useCallback((data) => {
    if (!gridApi.current) return 0
    if (!data) return 0
    if (!data.hasOwnProperty('dateTime')) return 0
    if (!isValidDate(data.dateTime)) return 0

    const dateTimeObj = new Date(Date.parse(data.dateTime)).getTime()

    let rowData = []
    gridApi.current.forEachNode((node) => {
      if (node.data) rowData.push(node.data)
    })

    let insertIndex = rowData.findIndex((item) => {
      const itemDateTime = new Date(item.dateTime).getTime()
      return dateTimeObj < itemDateTime
    })

    if (insertIndex === -1) {
      insertIndex = rowData.length
    }

    return insertIndex
  }, [])

  const duplicateInteraction = useCallback(async () => {
    if (!selectedInteraction.current) return
    if (isLoading) return

    if (_isMounted.current) setIsLoading(true)
    const res = await duplicateCrmInteraction(selectedInteraction.current)
    if (!_isMounted.current) return
    setIsLoading(false)

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `Copy interaction failed - ${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (gridApi.current && res.data) {
      selectedInteraction.current.uid = res.data.uid
      selectedInteraction.current.dateTime = res.data.dateTime
      gridApi.current.applyTransaction({
        add: [selectedInteraction.current],
        addIndex: getInsertIndex(selectedInteraction.current),
      })
    }

    selectedInteraction.current = null

    setStatus({ show: true, severity: 'success', message: 'Interaction copied successfully' })
  }, [duplicateCrmInteraction, setIsLoading, isLoading, getInsertIndex])

  const handleMenuClick = useCallback(
    async (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return

      selectedInteraction.current = cloneDeep(data)
      if (action === 'delete') {
        onClickDelete()
      }

      if (action === 'duplicate') {
        duplicateInteraction()
      }
    },
    [onClickDelete, duplicateInteraction],
  )

  const getInteractionFromCustomerName = useCallback((customer, firstName) => {
    if (!customer) return null
    if (typeof customer !== 'string') return null
    if (!gridApi.current) return null

    if (!firstName) firstName = ''
    if (typeof firstName !== 'string') firstName = ''

    let foundNode = null
    gridApi.current.forEachNode((node) => {
      if (node.data && !foundNode) {
        if (typeof node.data?.customerName === 'string') {
          if (node.data.customerName.toLowerCase() === customer.toLowerCase() && firstName === '') {
            foundNode = node.data
          }

          if (
            node.data?.customerName.toLowerCase() === customer?.toLowerCase() &&
            node.data?.contactFirstName?.toLowerCase() === firstName?.toLowerCase() &&
            firstName !== ''
          ) {
            foundNode = node.data
          }
        }
      }
    })

    return foundNode
  }, [])

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'menu',
        colId: 'menu',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          return {
            showBottomRow: false,
            menuItems: [
              {
                label: 'Delete',
                action: 'delete',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledPopupMenuIcon icon='fa-regular:trash-alt' color={'red'} />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Duplicate',
                action: 'duplicate',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledPopupMenuIcon icon='ant-design:copy-filled' />
                  </StyledIconContainer>
                ),
              },
            ],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'dateTime',
        colId: 'dateTime',
        headerName: 'Date',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '1950-01-01',
          max: '2200-01-01',
        },
        valueFormatter: (params) => {
          if (params.node?.rowPinned !== 'bottom') {
            return `${dateTimeFormatter(params.data?.dateTime)} [${relativeTimeFormatter(params.data?.dateTime)}]`
          }

          if (inputRow.current?.dateTime) {
            return `${dateTimeFormatter(params.data?.dateTime)}`
          }

          return 'DateTime...'
        },
      },
      {
        field: 'createdBy',
        colId: 'createdBy',
        headerName: 'Innova Rep',
        cellStyle: leftAlignedCell,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let users = Array.isArray(adminDropDowns.users) ? adminDropDowns?.users : []
          return {
            values: users.map((u) => u.label),
          }
        },
      },
      {
        field: 'existingCustomer',
        colId: 'existingCustomer',
        headerName: 'Existing',
        cellStyle: centerAlignCell,
      },
      {
        field: 'customerName',
        colId: 'customerName',
        headerName: 'Customer',
        rowGroup: groupRows,
        cellStyle: leftAlignedCell,
        valueGetter: (params) => unescapeHtml(params?.data?.customerName),
        cellEditorSelector: (params) => {
          let isExisting = false
          if (params.node?.rowPinned !== 'bottom') {
            isExisting = params.data?.existingCustomer
          }

          if (params.node?.rowPinned === 'bottom' && inputRow.current?.existingCustomer) {
            isExisting = inputRow.current.existingCustomer
          }

          if (!isExisting) {
            return {
              component: 'agTextCellEditor',
              popup: false,
            }
          }

          let dropDownValues = Array.isArray(dropDowns.innovaCustomers) ? dropDowns?.innovaCustomers : []
          return {
            component: 'agRichSelectCellEditor',
            params: {
              values: dropDownValues.map((v) => v.label),
            },
            popup: true,
          }
        },
        valueSetter: (params) => {
          let customerName = params.newValue
          if (!customerName) return false
          let foundInteraction = getInteractionFromCustomerName(customerName, '')

          if (params?.node?.rowPinned === 'bottom') {
            inputRow.current.customerName = params.newValue
            if (foundInteraction) {
              inputRow.current.buisnessType = foundInteraction.buisnessType
              inputRow.current.location = foundInteraction.location
              inputRow.current.contactFirstName = foundInteraction.contactFirstName
              inputRow.current.contactLastName = foundInteraction.contactLastName
              inputRow.current.contactEmail = foundInteraction.contactEmail
              inputRow.current.contactPhoneNum = foundInteraction.contactPhoneNum
              inputRow.current.contactPosition = foundInteraction.contactPosition
            }

            return true
          }

          let newData = cloneDeep(params.data)
          newData.customerName = params.newValue

          if (foundInteraction) {
            newData.buisnessType = foundInteraction.buisnessType
            newData.location = foundInteraction.location
            newData.contactFirstName = foundInteraction.contactFirstName
            newData.contactLastName = foundInteraction.contactLastName
            newData.contactEmail = foundInteraction.contactEmail
            newData.contactPhoneNum = foundInteraction.contactPhoneNum
            newData.contactPosition = foundInteraction.contactPosition
          }

          handleUpdate(newData)
          return true
        },
      },
      {
        field: 'buisnessType',
        colId: 'buisnessType',
        headerName: 'Buisness Type',
        cellStyle: leftAlignedCell,
        editable: (params) => params.node?.rowPinned !== 'bottom',
        valueGetter: (params) => unescapeHtml(params?.data?.buisnessType),
      },
      {
        field: 'location',
        colId: 'location',
        headerName: 'Location',
        cellStyle: leftAlignedCell,
        editable: (params) => params.node?.rowPinned !== 'bottom',
        valueGetter: (params) => unescapeHtml(params?.data?.location),
      },
      {
        field: 'interactionType',
        colId: 'interactionType',
        headerName: 'Interaction Type',
        cellStyle: leftAlignedCell,
        cellEditor: 'agSelectCellEditor',
        editable: (params) => params.node?.rowPinned !== 'bottom',
        cellEditorParams: (params) => {
          let users = Array.isArray(dropDowns?.interactionType) ? dropDowns.interactionType : []
          return {
            values: users.map((u) => u.label),
          }
        },
      },
      {
        field: 'interactionStage',
        colId: 'interactionStage',
        headerName: 'Stage',
        cellStyle: leftAlignedCell,
        cellEditor: 'agSelectCellEditor',
        editable: (params) => params.node?.rowPinned !== 'bottom',
        cellEditorParams: (params) => {
          let users = Array.isArray(dropDowns?.interactionStage) ? dropDowns.interactionStage : []
          return {
            values: users.map((u) => u.label),
          }
        },
      },
      {
        field: 'contactFirstName',
        colId: 'contactFirstName',
        headerName: 'First Name',
        cellStyle: leftAlignedCell,
        editable: (params) => params.node?.rowPinned !== 'bottom',
        valueGetter: (params) => unescapeHtml(params?.data?.contactFirstName),
        valueSetter: (params) => {
          let contactFirstName = params.newValue
          if (!contactFirstName) return false
          let foundInteraction = getInteractionFromCustomerName(params?.data?.customerName, contactFirstName)

          if (params?.node?.rowPinned === 'bottom') {
            inputRow.current.contactFirstName = params.newValue
            if (foundInteraction) {
              inputRow.current.buisnessType = foundInteraction.buisnessType
              inputRow.current.location = foundInteraction.location
              inputRow.current.contactFirstName = foundInteraction.contactFirstName
              inputRow.current.contactLastName = foundInteraction.contactLastName
              inputRow.current.contactEmail = foundInteraction.contactEmail
              inputRow.current.contactPhoneNum = foundInteraction.contactPhoneNum
              inputRow.current.contactPosition = foundInteraction.contactPosition
            }

            return true
          }

          let newData = cloneDeep(params.data)
          newData.contactFirstName = params.newValue
          if (foundInteraction) {
            newData.buisnessType = foundInteraction.buisnessType
            newData.location = foundInteraction.location
            newData.contactFirstName = foundInteraction.contactFirstName
            newData.contactLastName = foundInteraction.contactLastName
            newData.contactEmail = foundInteraction.contactEmail
            newData.contactPhoneNum = foundInteraction.contactPhoneNum
            newData.contactPosition = foundInteraction.contactPosition
          }

          handleUpdate(newData)
          return true
        },
      },
      {
        field: 'contactLastName',
        colId: 'contactLastName',
        headerName: 'Last Name',
        cellStyle: leftAlignedCell,
        editable: (params) => params.node?.rowPinned !== 'bottom',
        valueGetter: (params) => unescapeHtml(params?.data?.contactLastName),
      },
      {
        field: 'contactPosition',
        colId: 'contactPosition',
        headerName: 'Position',
        cellStyle: leftAlignedCell,
        editable: (params) => params.node?.rowPinned !== 'bottom',
        valueGetter: (params) => unescapeHtml(params?.data?.contactPosition),
      },
      {
        field: 'contactEmail',
        colId: 'contactEmail',
        headerName: 'Email',
        cellStyle: leftAlignedCell,
        editable: (params) => params.node?.rowPinned !== 'bottom',
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false

          if (typeof params.newValue !== 'string') return false
          if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(params.newValue)) {
            setStatus({ show: true, severity: 'error', message: 'not a valid email address' })
            return false
          }

          if (params.node?.rowPinned === 'bottom') {
            inputRow.current.contactEmail = params.newValue
            return true
          }

          let newData = cloneDeep(params.data)
          newData.contactEmail = params.newValue
          handleUpdate(newData)
          return true
        },
      },
      {
        field: 'contactPhoneNum',
        colId: 'contactPhoneNum',
        headerName: 'Phone#',
        cellStyle: centerAlignCell,
        editable: (params) => params.node?.rowPinned !== 'bottom',
      },
      {
        field: 'interestedIn',
        colId: 'interestedIn',
        headerName: 'Interested In',
        cellStyle: leftAlignedCell,
        cellEditor: 'agSelectCellEditor',
        editable: (params) => params.node?.rowPinned !== 'bottom',
        cellEditorParams: (params) => {
          let users = Array.isArray(dropDowns?.interestedIn) ? dropDowns.interestedIn : []
          return {
            values: users.map((u) => u.label),
          }
        },
      },
      {
        field: 'followUpRequired',
        colId: 'followUpRequired',
        headerName: 'Follow Up',
        cellStyle: centerAlignCell,
      },
      {
        field: 'followUpDate',
        colId: 'followUpDate',
        headerName: 'Follow Up Date',
        editable: (params) => {
          if (params.node?.rowPinned === 'bottom') return false
          if (!params?.data?.followUpRequired) return false
          return true
        },
        cellStyle: centerAlignCell,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '1950-01-01',
          max: '2200-01-01',
        },
        valueGetter: (params) => {
          if (params.node?.rowPinned === 'bottom') return ''
          if (!params?.data?.followUpRequired) return ''
          if (!params?.value) return ''
          return params.value
        },
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom') return ''
          if (!params?.data?.followUpRequired) return ''
          if (!params?.data?.followUpDate) return ''
          return `${dateTimeFormatter(params.data?.followUpDate)} [${relativeTimeFormatter(params.data?.followUpDate)}]`
        },
      },
      {
        field: 'estimatedValue',
        colId: 'estimatedValue',
        headerName: 'Estimated Value',
        cellStyle: centerAlignCell,
        editable: (params) => params.node?.rowPinned !== 'bottom',
      },
      {
        field: 'description',
        colId: 'description',
        headerName: 'Description',
        cellStyle: leftAlignedCell,
        maxWidth: 400,
        valueGetter: (params) => unescapeHtml(params?.data?.description),
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 2000,
          rows: 10,
          cols: 50,
        },
      },
    ],
    [
      groupRows,
      dateTimeFormatter,
      dateFilterComparator,
      dropDowns,
      leftAlignedCell,
      relativeTimeFormatter,
      adminDropDowns,
      handleMenuClick,
      handleUpdate,
      getInteractionFromCustomerName,
    ],
  )

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      if (node.data) filteredNodes.push(node.data)
    })

    setFilteredData(filteredNodes)
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('crmSystemGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
        handleAddRow()
        return
      }

      handleUpdate(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    onExpandOrCollapseAll: () => {
      autoSizeColumns()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    expandAllNodes('expand')
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const expandAllNodes = (action) => {
    if (typeof action !== 'string') return
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    if (action === 'expand') gridApi.current.expandAll()
    if (action !== 'expand') gridApi.current.collapseAll()
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('crmSystemGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: groupRows ? 'Ungroup Rows' : 'Group Rows',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('crmSystemGrid', 'colLayout', null)
          saveItemToLS('crmSystemGroupRows', 'groupRows', !groupRows)
          setGroupRows(!groupRows)
        },
        icon: '<span class="iconify" data-icon="mdi:format-list-group" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Expand all',
        disabled: false,
        action: () => {
          expandAllNodes('expand')
        },
        icon: '<span class="iconify" data-icon="material-symbols:expand" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Collapse all',
        disabled: false,
        action: () => {
          expandAllNodes('collapse')
        },
        icon: '<span class="iconify" data-icon="pajamas:collapse" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'CrmInteractions.xlsx',
            sheetName: 'Interactions',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const getDateFromAndToFilters = useCallback(() => {
    let dateTo = new Date(Date.now())
    let dateFrom = new Date(Date.parse('1900-01-01'))

    if (typeof dateFilter === 'string' && dateFilter !== 'All') {
      if (dateFilter === 'Current Month') {
        dateFrom = new Date(Date.now())
        dateFrom.setDate(1)
      }

      if (dateFilter === 'Previous Month') {
        dateFrom = new Date(Date.now())
        dateFrom.setMonth(dateFrom.getMonth() - 1)
        dateFrom.setDate(1)

        let daysInMonth = new Date(dateFrom.getFullYear(), dateFrom.getMonth() + 1, 0).getDate()
        dateTo = new Date(Date.parse(dateFrom.toISOString()))
        dateTo.setDate(daysInMonth)
        dateTo.setYear(dateFrom.getFullYear())
      }

      if (dateFilter === 'Last 30 Days') {
        dateFrom = new Date(Date.now())
        dateFrom.setDate(dateFrom.getDate() - 30)
      }

      if (dateFilter === 'Last 60 Days') {
        dateFrom = new Date(Date.now())
        dateFrom.setDate(dateFrom.getDate() - 60)
      }

      if (dateFilter === 'Last 6 Months') {
        dateFrom = new Date(Date.now())
        dateFrom.setMonth(dateFrom.getMonth() - 6)
      }

      if (dateFilter === 'Current Year') {
        dateFrom = new Date(Date.parse(`${dateTo.getFullYear()}-01-01T00:01:00`))
      }

      if (dateFilter === 'Last Year') {
        dateFrom = new Date(Date.parse(`${dateTo.getFullYear() - 1}-01-01T00:00:01`))
        dateTo = new Date(Date.parse(`${dateTo.getFullYear()}-01-01T23:59:59`))
      }
    }

    return { dateTo: dateTo.toISOString().substring(0, 10), dateFrom: dateFrom.toISOString().substring(0, 10) }
  }, [dateFilter])

  const isExternalFilterPresent = useCallback(() => {
    return dateFilter !== 'All'
  }, [dateFilter])

  const doesExternalFilterPass = useCallback(
    (node) => {
      let { dateFrom, dateTo } = getDateFromAndToFilters()
      if (node.data) {
        if (!isValidDate(node.data.dateTime)) return false

        let dateFromObj = new Date(Date.parse(dateFrom))
        let dateToObj = new Date(Date.parse(dateTo))

        let dt = new Date(Date.parse(node.data.dateTime)).getTime()
        if (dt < dateFromObj.getTime() || dt > dateToObj.getTime()) return false
      }
      return true
    },
    [getDateFromAndToFilters],
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', backgroundColor: getChartBackColor() }}>
      <Box
        sx={{
          padding: '2px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {confirm.show ? (
          <ConfirmDialog
            title={confirm?.title}
            open={confirm?.show}
            setOpen={() => setConfirm({ show: false })}
            onConfirm={() => handleDelete()}>
            {confirm?.text}
          </ConfirmDialog>
        ) : null}
        <Box sx={{ flex: 1, marginLeft: '10px', marginRight: '10px' }}>
          <DropDownPicker
            listOptions={[
              { label: 'All', value: 'All' },
              { label: 'Current Month', value: 'Current Month' },
              { label: 'Previous Month', value: 'Previous Month' },
              { label: 'Last 30 Days', value: 'Last 30 Days' },
              { label: 'Last 60 Days', value: 'Last 60 Days' },
              { label: 'Last 6 Months', value: 'Last 6 Months' },
              { label: 'Current Year', value: 'Current Year' },
              { label: 'Last Year', value: 'Last Year' },
              { label: 'User Defined', value: 'User Defined' },
            ]}
            value={dateFilter}
            onChange={(newValue) => {
              setDateFilter(newValue)
            }}
          />
        </Box>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle(6)}
        />
      </Box>
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          loading={isLoading}
          rowData={crmData}
          columnDefs={sortColDefs(columnDefs, 'crmSystemGrid')}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          onFilterChanged={onFilterChanged}
          getContextMenuItems={getContextMenuItems}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          headerHeight={30}
          groupDisplayType={'groupRows'}
          groupRowsSticky={true}
        />
      </div>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
      <StyledMenuIcon>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchCrmData()
              },
            },
            {
              icon: (
                <Iconify
                  icon={showChart ? 'bxs:hide' : 'bxs:show'}
                  style={{ color: appColors.itemTextColor, width: 28, height: 28 }}
                />
              ),
              name: showChart ? 'Hide Charts' : 'Show Charts',
              onClick: () => setShowChart(!showChart),
            },
          ]}
        />
      </StyledMenuIcon>
    </Box>
  )
}

export default CrmSystemGrid
