import React, { useRef, useState } from 'react'
import { Box, Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import {
  ELEMENT_TYPES as elementType,
  TABLE_TYPES as tableType,
  CHART_TYPES as chartType,
} from 'components/WellPages/WallplotComposer/Elements/elementDefs'
import { BUTTON_SIZE, ContextMenuItemIcon } from 'components/WellPages/WallplotComposer/Toolbar/toolbarUtils'

const getAddChartOptionsList = (action) => {
  let menuItems = []
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'bi:graph-down'} color={'#000000'} />,
    label: 'Plan View',
    onClick: () => {
      action(elementType.chart, chartType.planView)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'bi:graph-down'} color={'#000000'} />,
    label: 'Section View',
    onClick: () => {
      action(elementType.chart, chartType.sectionView)
      return true
    },
  })
  return menuItems
}

const getAddObjectOptionsList = (action) => {
  let menuItems = []
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Text',
    onClick: () => {
      action(elementType.text)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Org Logo',
    onClick: () => {
      action(elementType.imageOrgLogo)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Operator Logo (primary)',
    onClick: () => {
      action(elementType.imageOperLogo)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Operator Logo (secondary)',
    onClick: () => {
      action(elementType.imageOperLogo2)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'User Image',
    onClick: () => {
      action(elementType.image)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'North Reference',
    onClick: () => {
      action(elementType.northReference)
      return true
    },
  })
  return menuItems
}

const getAddTableOptionsList = (action) => {
  let menuItems = []
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Project Detail Table',
    onClick: () => {
      action(elementType.table, tableType.projectDetails)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'References Table',
    onClick: () => {
      action(elementType.table, tableType.references)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Field Details Table',
    onClick: () => {
      action(elementType.table, tableType.fieldDetails)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Facility Details Table',
    onClick: () => {
      action(elementType.table, tableType.facilityDetails)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Well Details Table',
    onClick: () => {
      action(elementType.table, tableType.wellDetails)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Wellbore Details Table',
    onClick: () => {
      action(elementType.table, tableType.wellboreDetails)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Survey Program Table',
    onClick: () => {
      action(elementType.table, tableType.surveyProgram)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Casing Details Table',
    onClick: () => {
      action(elementType.table, tableType.casingDetails)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Lithology Table',
    onClick: () => {
      action(elementType.table, tableType.lithology)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Target Details Table',
    onClick: () => {
      action(elementType.table, tableType.targetDetails)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Summary Table',
    onClick: () => {
      action(elementType.table, tableType.summary)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Approvals',
    onClick: () => {
      action(elementType.table, tableType.approvals)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Lease Lines Table',
    onClick: () => {
      action(elementType.table, tableType.leaseLine)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Annotations Table',
    onClick: () => {
      action(elementType.table, tableType.annotations)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Mapping Grid Table',
    onClick: () => {
      action(elementType.table, tableType.mappingGrid)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Title Bar',
    onClick: () => {
      action(elementType.table, tableType.titleBar)
      return true
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Datum Elevation Table',
    onClick: () => {
      action(elementType.table, tableType.datumElevation)
      return true
    },
  })
  return menuItems
}

export const ToolbarIconList = ({ listItems, icon, color, isImage, action }) => {
  const ref = useRef(null)
  const [showList, setShowList] = useState(false)

  const onClose = () => {
    setShowList(false)
  }

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: `${BUTTON_SIZE}px`,
        height: `${BUTTON_SIZE}px`,
      }}
      onClick={() => {
        setShowList(!showList)
      }}>
      {isImage ? (
        <img
          alt={'Icon'}
          src={icon}
          style={{
            width: `${BUTTON_SIZE - 4}px`,
            height: `${BUTTON_SIZE - 4}px`,
          }}
        />
      ) : (
        <Iconify
          icon={icon}
          width={`${BUTTON_SIZE - 4}px`}
          height={`${BUTTON_SIZE - 4}px`}
          color={color || '#a9abac'}
        />
      )}

      {showList ? (
        <Menu
          sx={{
            zIndex: 10001,
            mt: '1px',
            '& .MuiListItemText-root': { padding: 0, color: '#000000' },
            '& .MuiListItemIcon-root': { padding: 0, color: '#000000' },
            '& .MuiMenu-paper': {
              backgroundColor: '#DDDDDD',
              border: '1px solid gray',
            },
          }}
          open={showList}
          onClose={onClose}
          anchorEl={ref.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          {listItems.map((item, index) => {
            if (item.isDivider) {
              return <Divider key={index} sx={{ bgcolor: 'primary.main' }} />
            }
            if (item.isCollapsible) {
              return (
                <MenuItem
                  key={index}
                  onClick={(e) => {
                    if (item.onClick) {
                      if (item.onClick(e)) onClose()
                    }
                  }}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText sx={{ color: '#000000' }}>{item.label}</ListItemText>
                  <ListItemIcon>{item.endIcon}</ListItemIcon>
                </MenuItem>
              )
            }
            return (
              <MenuItem
                style={{ marginLeft: `${item.submenu ? '12px' : '0px'}` }}
                key={index}
                onClick={(e) => {
                  e.stopPropagation()
                  if (item.onClick) {
                    if (item.onClick(e)) onClose()
                  }
                }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText sx={{ color: '#000000' }}>{item.label}</ListItemText>
              </MenuItem>
            )
          })}
        </Menu>
      ) : null}
    </Box>
  )
}

export const ToolbarAddChartIconList = ({ icon, color, isImage, action }) => {
  return (
    <ToolbarIconList
      listItems={getAddChartOptionsList(action)}
      icon={icon}
      color={color}
      isImage={isImage}
      action={action}
    />
  )
}

export const ToolbarAddTableIconList = ({ icon, color, isImage, action }) => {
  return (
    <ToolbarIconList
      listItems={getAddTableOptionsList(action)}
      icon={icon}
      color={color}
      isImage={isImage}
      action={action}
    />
  )
}

export const ToolbarAddObjectIconList = ({ icon, color, isImage, action }) => {
  return (
    <ToolbarIconList
      listItems={getAddObjectOptionsList(action)}
      icon={icon}
      color={color}
      isImage={isImage}
      action={action}
    />
  )
}
